import { Injectable } from '@angular/core';
import { StorageKey } from '../model/custom-types';
import { Session } from '../model/usuario.model';
import { session$ } from '../globals/globals';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
    session$.next(this.getSession() || null);
  }

  getSession(): Session {
    return JSON.parse(localStorage.getItem("SESSION"));
  }

  setSession(session: Session) {
    localStorage.setItem("SESSION", JSON.stringify(session));
    session$.next(session);
  }

  removeSession() {
    localStorage.removeItem("SESSION");
    session$.next(null);
  }

  set(key: StorageKey, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  get(key: StorageKey) {
    return JSON.parse(localStorage.getItem(key));
  }

  remove(key: StorageKey) {
    localStorage.removeItem(key);
  }
}
