<div id="container">
  <div class="m-4">
    <div id="header" class="mb-4">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">Cor e ícone</h3>
        <button class="btn" (click)="dismiss()">
          <ng-mat-icon name="close" />
        </button>
      </div>
    </div>

    <div id="content" class="px-2">
      <section id="cor">
        <small-label>
          <span class="fw-bold"> Selecione um cor </span>
        </small-label>

        <div id="list-colors" class="list mt-3 mb-5 gap-3">
          @for (cor of cores; track $index) {
            <div class="item color cursor-pointer" [style]="'--bg-color-rgb: ' + cor" [class.selected]="cor === selectedColor" (click)="selectColor(cor)"></div>
          }
        </div>
      </section>

      <section id="icone">
        <small-label>
          <span class="fw-bold"> Selecione um ícone </span>
        </small-label>

        <div id="list-icons" class="list mt-3 gap-4 p-2">
          @for (icone of showAll ? icones : iconesSliced; track $index) {
            <div class="item icon cursor-pointer" [style]="'--bg-color-rgb: ' + (selectedColor || 'transparent')" [class.selected]="icone === selectedIcon" (click)="selectIcon(icone)">
              <ng-mat-icon [name]="icone" [outlined]="false" />
            </div>
          }

          @if (!showAll) {
            <button class="btn w-100 mt-4" (click)="showAll = true">
              <span class="fw-600"> Ver todos </span>
            </button>
          }
        </div>
      </section>
    </div>

    <div id="footer">
      <div id="buttons" class="d-flex justify-content-end mt-4 gap-4">
        <button type="button" class="px-4 btn btn-outline" (click)="dismiss()" color="roxo">Cancelar</button>

        <button type="button" class="px-4 btn" (click)="save()" color="roxo">Salvar</button>
      </div>
    </div>
  </div>
</div>
