<div
  class="d-flex gap-2 align-items-center p-2 rounded-2 fw-bold"
  [style.--color-rgb]="'var(--color-' + color + '-rgb, var(--bs-' + color + '-rgb))'"
  [style.--color-contrast]="'var(--color-' + color + '-contrast, var(--bs-' + color + '-contrast))'"
  [class]="[type, color]"
>
  @if (text) {
    @if (iconStart) {
      <icon-in-ellipse [matIcon]="iconStart.name" [bgColor]="iconStart.bgColor" />
    }

    <span class="text"> {{ text }} </span>

    @if (showCloseButton) {
      <ng-mat-icon name="close" [color]="type === 'filled' ? 'white' : color" class="cursor-pointer" (click)="close.emit()" />
    }
  } @else {
    <ng-content />
  }
</div>
