import { Component, Input } from '@angular/core';
import { ColorDirective } from '../../directives/color.directive';
import { AppColor } from '../../model/custom-types';

@Component({
  selector: 'no-results',
  standalone: true,
  imports: [ColorDirective],
  templateUrl: './no-results.component.html',
  styleUrl: './no-results.component.scss'
})
export class NoResultsComponent {
  @Input({ required: true }) message: string;
  @Input() classes: Array<string> = [];
  @Input() color: AppColor = 'dark'
}
