<div id="container">
  <div id="content" class="m-4">
    <div id="header">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">{{ usuario?.id ? "Editar" : "Novo" }} Usuário</h3>
        <button class="btn" (click)="dismiss()">
          <icon name="x" />
        </button>
      </div>
    </div>

    <div id="form" class="mt-4">
      <div class="d-flex justify-content-between gap-4">
        <div class="d-flex justify-content-between align-items-end">
          <div class="form-group d-flex flex-column gap-2">
            <small-label> Usuário Ativo </small-label>

            <div class="btn-group" role="group">
              <button type="button" class="btn" [class]="'btn-' + (usuario?.status === UsuarioStatus.ATIVO ? '' : 'outline-') + 'primary'" (click)="usuario.status = UsuarioStatus.ATIVO">Ativo</button>
              <button type="button" class="btn" [class]="'btn-' + (usuario?.status === UsuarioStatus.INATIVO ? '' : 'outline-') + 'primary'" (click)="usuario.status = UsuarioStatus.INATIVO">Inativo</button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mt-3 d-flex flex-column gap-2">
        <small-label> Nome <span>*</span> </small-label>
        <input type="text" class="form-control" placeholder="Insira o nome do usuário" [(ngModel)]="usuario.nome" />
      </div>

      <div class="row gap-2 w-100 mx-0 mt-3">
        <div class="form-group col px-0 d-flex flex-column gap-2">
          <small-label> Telefone <span>*</span> </small-label>
          <input type="text" class="form-control" placeholder="(00) 00000-0000" [(ngModel)]="usuario.telefone" [mask]="'(00)0000-0000||(00)00000-0000'" />
        </div>

        <div class="form-group col px-0 d-flex flex-column gap-2">
          <small-label> Email <span>*</span> </small-label>
          <input type="email" class="form-control" placeholder="Insira o email" [(ngModel)]="usuario.email" autocomplete="off" />
        </div>
      </div>

      @if (!usuario?.id) {
        <div class="row gap-2 w-100 mx-0 mt-3">
          <div class="form-group input-senha col px-0 d-flex flex-column gap-2">
            <small-label> Senha <span>*</span> </small-label>
            <input type="password" class="form-control" placeholder="Insira a senha" [(ngModel)]="senha" autocomplete="off" [type]="hidePassword ? 'password' : 'text'" />

            <ng-mat-icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="hidePassword ? 'visibility' : 'visibility_off'" color="dark" />
          </div>

          <div class="form-group input-senha col px-0 d-flex flex-column gap-2">
            <small-label> Confirmar Senha <span>*</span> </small-label>
            <input type="password" class="form-control" placeholder="Confirme a senha" [(ngModel)]="confirmaSenha" autocomplete="off" [type]="hidePassword ? 'password' : 'text'" />

            <ng-mat-icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="hidePassword ? 'visibility' : 'visibility_off'" color="dark" />
          </div>
        </div>
      }
    </div>

    <div id="buttons" class="d-flex justify-content-end mt-4 gap-4">
      @if (usuario?.id) {
        <button type="button" class="px-4 btn btn-danger" (click)="excluir()">Excluir Usuário</button>
      }

      <button type="button" class="px-4 btn btn-outline-primary" (click)="dismiss()">Cancelar</button>

      <button type="button" class="px-4 btn btn-primary" (click)="salvar()">Salvar Usuário</button>
    </div>
  </div>
</div>
