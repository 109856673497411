<div id="container" class="rounded-3 p-3">
  @if (periodo) {
    <section id="main">
      <section id="list">
        <categoria-list [categoriaSelecionada]="categoriaSelecionada" [periodo]="periodo" [operationInProgress$]="operationInProgress$" (addCategoria)="detalhesCategoria(null)" (selectCategoria)="detalhesCategoria($event)" />
      </section>

      @if (isDesktop) {
        <hr class="divider vertical" />

        <ng-container *ngTemplateOutlet="detailsCategoriaRef" />
      }

      <ng-template #detailsCategoriaRef>
        <section id="details">
          <detalhes-categoria
            [updateUrl]="updateUrl"
            [periodo]="periodo"
            [segment]="segment"
            [operationInProgress$]="operationInProgress$"
            [categoria]="categoriaSelecionada"
            (categoriaAdded)="categoriaList.updatePeriodo.emit(periodo)"
            (refreshCategoria)="detalhesCategoria(categoriaSelecionada)"
            (categoriaExcluida)="categoriaList.getCategorias()"
            (dismiss)="modalDetails?.close()"
          />
        </section>
      </ng-template>
    </section>
  } @else if (nenhumPeriodoCadastrado === null) {
    <operation-in-progress />
  } @else {
    <no-results message="Nenhum período cadastrado" />
  }
</div>
