import { Component, ViewChild } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { PeriodoService } from '../../../services/periodo.service';
import { BehaviorSubject } from 'rxjs';
import { PeriodoLancamentos } from '../../../model/periodo.model';
import { NoResultsComponent } from '../../../custom-components/no-results/no-results.component';
import { OperationInProgressComponent } from '../../../custom-components/operation-in-progress/operation-in-progress.component';
import { CategoriaListComponent } from './categoria-list/categoria-list.component';
import { Categoria } from '../../../model/categoria.model';
import { AsyncPipe, Location, NgTemplateOutlet } from '@angular/common';
import { defaultClassModal, updateUrl } from '../../../globals/utils';
import { ActivatedRoute } from '@angular/router';
import { CategoriaService } from '../../../services/categoria.service';
import { DetalhesCategoriaComponent, SegmentDetalhesCategoria } from './detalhes-categoria/detalhes-categoria.component';
import { ModalService } from '../../../services/modal.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PeriodoLancamentosSelecionado$ } from '../../../globals/globals';
import { ProdutoService } from '../../../services/produto.service';
import { DespesaService } from '../../../services/despesa.service';
import { TipoDespesa } from '../../../model/despesa.model';

@Component({
  selector: 'app-categorias',
  standalone: true,
  imports: [NoResultsComponent, NgTemplateOutlet, OperationInProgressComponent, CategoriaListComponent, DetalhesCategoriaComponent, AsyncPipe],
  templateUrl: './categorias.component.html',
  styleUrl: './categorias.component.scss'
})
export class CategoriasComponent extends BaseClass() {

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  periodo: PeriodoLancamentos;
  nenhumPeriodoCadastrado: boolean = null;

  @ViewChild(CategoriaListComponent) categoriaList: CategoriaListComponent;
  @ViewChild('detailsCategoriaRef') detailsCategoriaRef: DetalhesCategoriaComponent;

  categoriaSelecionada: Categoria;

  modalDetails: NgbModalRef;

  segment: SegmentDetalhesCategoria = 'margem';

  constructor(
    private periodoService: PeriodoService,
    private categoriaService: CategoriaService,
    private produtoService: ProdutoService,
    private despesaService: DespesaService,
    private modalService: ModalService,
    private _location: Location,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    if (!PeriodoLancamentosSelecionado$.value) this.checkPeriodos();
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams?.['segment']) this.segment = queryParams['segment'] as SegmentDetalhesCategoria;
  }

  ngAfterContentInit(): void {
    const categoriaId = this.route.snapshot?.queryParams?.['categoria'];

    const sub = PeriodoLancamentosSelecionado$.subscribe({
      next: (periodo) => {
        if (periodo) {
          this.periodo = periodo;
          this.categoriaList?.updatePeriodo?.next(periodo);
          if (categoriaId) {
            this.categoriaService.getCategoriaById(periodo.id, categoriaId).then((res) => {
              if (res) this.detalhesCategoria(res);
            });
          }
        }
      }
    });
    this.appendSubscription(sub);
  }

  private checkPeriodos() {
    this.periodoService.getPeriodos().then((res) => {
      if (res.errors) return;
      if (!res.data.length) {
        this.nenhumPeriodoCadastrado = true;
      }
    });
  }

  detalhesCategoria(categoria: Categoria = null) {
    this.updateUrl(categoria?.id, this.segment);

    this.categoriaSelecionada = categoria;

    if (this.categoriaSelecionada) {
      this.getQuantidadeProdutos();
      this.getSomatorioDespesasVenda();
    }

    if (this.isMobile) this.modalDetails = this.modalService.presentModal(this.detailsCategoriaRef, defaultClassModal(this));
  }

  private getQuantidadeProdutos() {
    const { id: idCategoria, idPeriodo, quantidadeProdutos } = this.categoriaSelecionada;
    if (idPeriodo && idCategoria && !quantidadeProdutos) {
      this.produtoService.getProdutosPeriodo(idPeriodo, { IdsCategorias: [idCategoria], PageSize: 1 }).then((res) => {
        if (res?.rowsCount > 0) this.categoriaSelecionada.quantidadeProdutos = res.rowsCount;
      });
    }
  }

  private getSomatorioDespesasVenda() {
    const { id: idCategoria, idPeriodo } = this.categoriaSelecionada;
    if (idPeriodo && idCategoria) {
      this.despesaService.getSomatorioDespesasPeriodo(idPeriodo, TipoDespesa.VENDA).then((res) => {
        if (res) this.categoriaSelecionada.somatorioDespesasVenda = res;
      });
    }
  }

  updateUrl(categoria: string = null, segment: SegmentDetalhesCategoria = null) {
    const params = Object.fromEntries(Object.entries({
      categoria,
      segment,
    }).filter(x => !!x[1]));
    updateUrl(this._location, params);
  }

}
