import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { Empresa } from '../model/empresa.model';
import { environment } from '../../environments/environment.development';
import { ApiConstraintGetEmpresas } from '../model/api.model';
import { StorageService } from './storage.service';
import { DefaultPageSize } from '../globals/globals';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends BaseHttpService {

  getEmpresas(constraints?: ApiConstraintGetEmpresas) {
    const storageService = new StorageService();
    const params = Object.fromEntries(Object.entries({
      Termo: constraints?.Termo || null,
      Page: constraints?.Page || 1,
      PageSize: constraints?.PageSize || storageService.get('PAGE_SIZE_LIST_EMPRESAS') || DefaultPageSize.empresas,
      FirstRow: constraints?.FirstRow || null,
    } as ApiConstraintGetEmpresas).filter(x => x[1]))
    return this.get<Empresa>(`${environment.api.endpoints.empresas}`, params);
  }

  getEmpresaById(id: string) {
    return this.get<Empresa>(`${environment.api.endpoints.empresas}/${id}`);
  }

  searchEmpresas(termo: string) {
    return this.get<Empresa>(`${environment.api.endpoints.empresas}`, { Termo: termo });
  }

  insertEmpresa(empresa: Partial<Empresa>) {
    return this.post<Empresa>(`${environment.api.endpoints.empresas}`, empresa);
  }

  updateEmpresa(empresa: Partial<Empresa>) {
    return this.put<Empresa>(`${environment.api.endpoints.empresas}/${empresa.id}`, empresa);
  }

  deleteEmpresa(id: string) {
    return this.delete<Empresa>(`${environment.api.endpoints.empresas}`, id);
  }

}
