<div>
  <div id="container">
    <div id="content" class="m-4 position-relative">
      <div id="header">
        <div id="title" class="d-flex justify-content-between align-items-center">
          <h3 color="titulo" class="fw-600">Importar Produtos</h3>
          <button class="btn" (click)="dismiss()">
            <ng-mat-icon name="close" color="black" />
          </button>
        </div>
      </div>

      <div id="body" class="py-3">
        <div class="d-flex flex-column gap-4">
          <div class="d-flex flex-column gap-3">
            <small-label> Baixar planilha modelo </small-label>

            <button class="btn btn-outline-info gap-3" (click)="downloadModelo()">
              <ng-mat-icon name="file_download" color="info" />
              <span> Download modelo </span>
            </button>
          </div>

          <div class="d-flex flex-column gap-3">
            <small-label> Importar arquivo </small-label>

            <div class="d-flex gap-3 align-items-center">
              <button class="btn btn-outline-info" (click)="selectFile()">
                <span class="d-flex align-items-center justify-content-center gap-2">
                  <ng-mat-icon name="file_upload" color="info" />
                  <span class="small nobold"> {{ file?.name ? "Substituir" : "Selecionar" }} </span>
                </span>
              </button>

              <span class="small text-body fst-italic"> {{ file?.name || "Nenhum arquivo selecionado" }} </span>
            </div>
          </div>

          <hr />

          <div class="d-flex flex-column gap-3">
            <small-label class="fw-bold" color="laranja"> Atenção! </small-label>

            <small-label color="laranja" class="fst-italic"> Ao importar um arquivo, todos os produtos existentes no período selecionado serão excluídos e substituídos pelos produtos do arquivo. </small-label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="buttons" class="position-absolute d-flex gap-2">
    <button class="btn btn-outline-info" (click)="dismiss()">Fechar</button>

    <button class="btn btn-info" (click)="confirmar()">Confirmar</button>
  </div>
</div>
