import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../globals/base-class';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { FormControl } from '@angular/forms';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';

@Component({
  selector: 'dropdown',
  standalone: true,
  imports: [NgbDropdownModule, NgClass, SmallLabelComponent, NgMatIconComponent],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent<T> extends BaseClass() {
  @Input() label: string = '';
  @Input() placeholder: string = 'Selecione...'
  @Input() options: Array<T>;
  @Input() control: FormControl<T>;
  @Input() displayField: keyof T;

  @Input() isDisabled: boolean = false;
  @Input() inline: boolean = false;

  @Input() classes: Array<string> = [];

  @Output() optionSelected: EventEmitter<T> = new EventEmitter();
}
