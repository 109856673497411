<div id="container" class="h-100" [ngClass]="{ 'p-3': !isDesktop, 'p-5 w-fit-content min-width-100': isDesktop }">
  <h5 class="fw-600">Seleção</h5>

  <div id="content" class="mt-3 mb-5 rounded-3">
    <div class="p-2 d-flex flex-column gap-2 h-100">
      <div id="header">
        <button type="button" class="btn btn-primary btn-sm" (click)="toggleOptionsPanel()">
          <ng-mat-icon [name]="showOptions ? 'expand_less' : 'expand_more'" />
          <span>{{ showOptions ? "Selecionar o que comparar" : "Exibir o que comparar" }} </span>
        </button>

        <div #labels id="labels" class="d-flex gap-2">
          <div class="list d-flex gap-2 user-select-none">
            <button type="button" class="btn clear" (click)="scrollLabels('left')">
              <ng-mat-icon color="danger" name="chevron_left" />
            </button>

            @for (stat of stats | keyvalue; track $index) {
              <div class="stat-label d-flex flex-column gap-1 py-3 cursor-pointer" [class.selected]="stat.value.selected" [style.--color-rgb]="stat.value.color" (click)="selectColumn(stat)">
                <span class="fw-600">{{ stat.value.label }}</span>
              </div>
            }

            <button type="button" class="btn clear" (click)="scrollLabels('right')">
              <ng-mat-icon color="danger" name="chevron_right" />
            </button>
          </div>
        </div>
      </div>

      <div id="body" class="w-100 h-100 overflow-hidden">
        @if ((isDesktop && showOptions) || !isDesktop) {
          <div id="options-panel" class="mt-4 h-100" [ngClass]="{ 'd-flex gap-3 position-relative': isDesktop, present: !isDesktop && showOptions }">
            <div id="select-periodos" class="h-100" [ngClass]="{ 'w-100 p-3': !isDesktop }">
              @if (!isDesktop) {
                <div id="title" class="d-flex justify-content-between align-items-center mb-4">
                  <h3 color="titulo" class="fw-600">Configurar colunas</h3>
                  <button class="btn" (click)="toggleOptionsPanel()">
                    <ng-mat-icon name="close" color="titulo" />
                  </button>
                </div>
              }

              <div id="tipos-periodo" class="d-flex align-items-center" [ngClass]="{ 'justify-content-around': !isDesktop, 'gap-3': isDesktop }">
                @for (tipo of tipos; track $index) {
                  <div class="form-check d-flex align-items-center gap-2">
                    <input #input class="form-check-input" [id]="'tipo-periodo-' + $index" type="checkbox" [(ngModel)]="tipo.checked" [disabled]="!tipos[($index + 1) % 2].checked" (change)="selectTipos()" />
                    <label class="form-check-label user-select-none" [for]="'tipo-periodo-' + $index"> {{ tipo.value }} </label>
                  </div>
                }
              </div>

              <div id="periodos" class="d-flex flex-column gap-2 mt-3 pb-1" [class.pe-1]="isDesktop">
                @for (periodo of periodos$ | async; track $index) {
                  <card-with-icon [showFooter]="false" [borderColor]="isDesktop ? 'info' : 'primary'" [fill]="periodo._selected" (clickBody)="selectPeriodo(periodo)">
                    <div class="user-select-none">
                      <span [color]="periodo._selected ? 'white' : 'info'" class="fw-bold">{{ periodo.simulacao ? "S" : "P" }} - </span>
                      <span [color]="periodo._selected ? 'white' : 'info'" class="fw-normal"> {{ periodo.nome }} </span>
                    </div>
                  </card-with-icon>
                }

                @if (periodos$.value?.length == 0) {
                  <no-results message="Nenhum dado para exibir" />
                }
              </div>

              @if (!isDesktop) {
                <button id="confirm" type="button" class="btn btn-info mt-3" (click)="toggleOptionsPanel()">Salvar</button>
              }
            </div>

            @if (loading) {
              <operation-in-progress id="loading-periodos" message="Carregando dados" />
            }
          </div>
        }

        <div id="chart" class="position-relative h-100">
          @if (periodos$.value == null) {
            <operation-in-progress />
          } @else if (periodos$.value?.length == 0) {
            <no-results message="Nenhum dado para exibir" />
          } @else {
            <apx-chart
              class="position-absolute h-100 w-100"
              [dataLabels]="chartOptions.dataLabels"
              [chart]="chartOptions?.chart"
              [series]="chartOptions?.series"
              [plotOptions]="chartOptions?.plotOptions"
              [xaxis]="chartOptions?.xaxis"
              [yaxis]="chartOptions?.yaxis"
              [noData]="chartOptions?.noData"
            />
          }
        </div>
      </div>
    </div>
  </div>
</div>
