<div id="container">
  <div class="m-4">
    <div id="header" class="mb-5">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">Ponto de Equilíbrio</h3>
        <button class="btn" (click)="dismiss()">
          <icon name="x" />
        </button>
      </div>

      <!-- <div id="subtitle">
        <p class="fw-500" color="cinza">Aqui vem as informações explicando o que é o termo em questão, links com chamadas para vídeos explicativos, artigos, etc.</p>
      </div> -->
    </div>

    <div id="content">
      <div class="mb-5">
        <dropdown
          [isDisabled]="!periodos?.length || operationInProgress"
          [inline]="true"
          label="Período de lançamentos"
          [placeholder]="periodos?.length === 0 ? 'Nenhum período cadastrado' : 'Selecione um período'"
          [options]="periodos"
          [control]="controlPeriodo"
          displayField="nome"
          (optionSelected)="selectPeriodo($event)"
        />
      </div>

      <div class="mb-5">
        <card-with-icon color="white" [title]="pontoEquilibrio.title" [icon]="pontoEquilibrio.icon" [iconBgColor]="pontoEquilibrio.iconBgColor" [showForwardIcon]="false" [showFooter]="false">
          @if (pontoEquilibrio.value === undefined) {
            <small-label class="fst-italic" color="cinza">
              <span class="spinner-border spinner-border-sm me-2"></span>
              <span> Carregando... </span>
            </small-label>
          } @else if (pontoEquilibrio.value === "error") {
            <small-label class="fst-italic" color="danger"> Erro ao carregar </small-label>
          } @else {
            @if (!pontoEquilibrio.value) {
              -
            } @else {
              @if (pontoEquilibrio.pipe === "percent") {
                {{ pontoEquilibrio.value | percent: "1.0-2" }}
              } @else if (pontoEquilibrio.pipe === "currency") {
                {{ pontoEquilibrio.value | currency }}
              } @else {
                <span class="text-pre"> {{ pontoEquilibrio.value }} </span>
              }
            }
          }
        </card-with-icon>
      </div>
    </div>

    <div id="footer" class="absolute">
      <div class="d-flex align-items-center justify-content-end">
        <button class="btn btn-outline" color="roxo" (click)="dismiss()">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>
