<div id="container" class="h-100" [class.master]="(session | async).usuario.master">
  <toolbar class="w-100" [currentPage]="currentPage" [usuario]="(session | async).usuario" />

  @if ((!isDesktop && mainMenu.opened) || isDesktop) {
    <sidenav class="w-100 h-100" [menu]="mainMenu" [usuario]="(session | async).usuario" />
  }

  @if (!isDesktop) {
    <tabbar class="w-100" [menu]="mainMenu" [usuario]="(session | async).usuario" />
  }

  <div id="content" class="w-100">
    <router-outlet />
  </div>
</div>
