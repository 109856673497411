import { Pipe, PipeTransform } from '@angular/core';
import { Produto, TipoPrecificacaoEnum } from '../model/produto.model';
import { AppColor, PrecificacaoColor, PrecificacaoIcon } from '../model/custom-types';
import { MaterialIcon } from 'material-icons';

@Pipe({
  name: 'precificacaoProdutoFace',
  standalone: true
})
export class PrecificacaoProdutoFacePipe implements PipeTransform {

  transform(produto: Produto): TPrecificacaoProdutoFace {
    if (!produto?.precificacao) return null;
    return this.getState(produto.precificacao);
  }

  private getState(precificacao: TipoPrecificacaoEnum): TPrecificacaoProdutoFace {
    return { icon: PrecificacaoIcon[precificacao], color: PrecificacaoColor[precificacao] };
  }

}

type TPrecificacaoProdutoFace = {
  icon: MaterialIcon;
  color: AppColor;
};
