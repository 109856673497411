<div id="card-with-icon" class="d-flex">
  <div id="card" class="d-flex flex-column rounded-3 w-100" [style]="styles$ | async" [class.animated]="animated" [class.fill]="fill">
    <div id="body" class="d-flex p-3 cursor-pointer" (click)="input?.control ? null : clickBody.emit()">
      @if (icon || customIcon) {
        <section id="icon" class="ps-2 pe-4 pb-2">
          @if (icon) {
            <ng-mat-icon [name]="icon" [color]="iconColor" [bgColor]="iconBgColor" />
          } @else if (customIcon) {
            <custom-icon [color]="iconBgColor" [name]="customIcon" />
          }
        </section>
      }

      <section id="content" class="d-flex flex-column gap-2 justify-content-center">
        @if (title) {
          <div id="header" class="d-flex align-items-center justify-content-between" (click)="input?.control ? clickBody.emit() : null">
            <h6 class="mb-0 fw-500">{{ title }}</h6>
            @if (showForwardIcon) {
              <ng-mat-icon name="chevron_right" color="dark" />
            }
          </div>
        }

        <div id="message">
          @if (message) {
            <p>{{ message }}</p>
          } @else if (input?.control) {
            <input [type]="input?.type || 'text'" class="form-control" [placeholder]="input?.placeholder || ''" [formControl]="input.control" />
          } @else {
            <p>
              <ng-content />
            </p>
          }
        </div>
      </section>
    </div>

    @if (note) {
      <note class="px-4" [color]="noteColor" [text]="note" />
    }

    @if (showFooter) {
      <!-- <div id="footer" class="d-flex justify-content-end">
        <button class="btn btn-primary clear fw-500" (click)="clickFooterButton.emit()">O que é?</button>
      </div> -->
    }
  </div>
</div>
