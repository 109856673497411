<div id="container" class="d-flex justify-content-center h-100">
  @if (isDesktop) {
    <section id="banner">
      <div id="bg" class="h-100">
        <div id="content" class="d-flex flex-column gap-5 align-items-center justify-content-center h-100">
          <img src="assets/images/login-fg.svg" alt="" />
          <div class="text">
            <h1 class="fw-700">Inteligência de negócios e precificação</h1>
            <h6 class="fw-400">Precifique do jeito certo e alavanque seu negócio</h6>
          </div>
        </div>
      </div>
    </section>
  }

  <section class="form-user gap-3 d-flex flex-column">
    <logo-header title="Faça seu Login" subtitle="Inteligência de negócios e precificação" />

    <form class="d-flex gap-3 flex-column" [formGroup]="form">
      <div>
        <div class="form-group mb-3">
          <label class="fw-600 mb-1" for="email"> Login </label>
          <input #inputEmail type="email" class="form-control" id="email" placeholder="seuemail@email.com" formControlName="email" />
        </div>

        <div class="form-group mb-1">
          <label class="fw-600 mb-1" for="senha"> Senha </label>
          <input #inputPassword [type]="hidePassword ? 'password' : 'text'" class="form-control" id="senha" placeholder="******" formControlName="senha" />

          <ng-mat-icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="hidePassword ? 'visibility' : 'visibility_off'" color="dark" />
        </div>

        <div class="form-check d-flex align-items-center gap-3 mt-3">
          <input type="checkbox" id="manter-conectado" class="form-check-input my-0" formControlName="manterConectado" />
          <label class="fw-400 form-check-label cursor-pointer no-selection" for="manter-conectado"> Mantenha me conectado </label>
        </div>
      </div>

      <div class="d-grid">
        <button [disabled]="form.disabled" type="button" id="submit" (click)="login()" class="btn btn-primary">
          <span> Login </span>
          @if (fazendoLogin) {
            <div class="spinner-border ms-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          }
        </button>
      </div>

      <a [ariaDisabled]="form.disabled" id="esqueci-senha" class="link-info fw-500" (click)="forgotPassword()"> Esqueci minha senha </a>

      <!-- <button [disabled]="form.disabled" type="button" id="create-senha" (click)="createAccount()" class="btn btn-dark mt-5">
        <span> Criar Conta </span>
      </button> -->

      <a type="button" id="create-senha" class="btn btn-dark mt-5" [href]="linkHotmart" target="_blank">
        <span> Criar Conta </span>
      </a>
    </form>
  </section>
</div>
