<div id="container" class="d-flex flex-column" [class.h-100]="operationInProgress">
  @if (operationInProgress) {
    <operation-in-progress [absolute]="true" message="Carregando dados do período..." />
  } @else {
    <stepper
      #Stepper
      [steps]="steps"
      [drawColor]="(eSimulacao$ | async) ? 'laranja-forte' : 'verde'"
      [showDeleteButton]="!!periodo?.id"
      [currentStep]="stepNumber"
      (onStepChange)="stepChanged($event)"
      (delete)="deletePeriodo()"
      (finished)="save()"
      (cancel)="cancel()"
      (childEvent)="updatePeriodo($event.data, $event.previousIndex, $event.periodoACopiar, $event.file)"
      finishButtonText="Finalizar"
    >
      <ng-container *ngComponentOutlet="currentStep.component; inputs: { periodo, tipoBase, Stepper }" />
    </stepper>
  }
</div>
