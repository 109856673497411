import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormsModule } from '@angular/forms';
import { CurrencyPipe, NgClass } from '@angular/common';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../../../custom-components/icon/icon.component';
import { ColorDirective } from '../../../../../directives/color.directive';
import { DropdownComponent } from '../../../../../custom-components/dropdown/dropdown.component';
import { BaseClass } from '../../../../../globals/base-class';
import { Produto, StatusProdutoEnum } from '../../../../../model/produto.model';
import { Categoria, Categorias } from '../../../../../model/categoria.model';
import { ProdutoService } from '../../../../../services/produto.service';
import { ToastService } from '../../../../../services/toast.service';
import { ModalService } from '../../../../../services/modal.service';
import { CategoriaService } from '../../../../../services/categoria.service';
import { BadgeComponent } from '../../../../../custom-components/badge/badge.component';
import { ApiResponseError } from '../../../../../model/api.model';
import { DelayShowTooltip, PeriodoLancamentosSelecionado$ } from '../../../../../globals/globals';
import { TagsInputDirective } from '../../../../../directives/tags-input.directive';

@Component({
  selector: 'app-novo-produto-overlay',
  standalone: true,
  imports: [
    SmallLabelComponent,
    IconComponent,
    ColorDirective,
    FormsModule,
    DropdownComponent,
    CurrencyPipe,
    ColorDirective,
    BadgeComponent,
    TagsInputDirective,
    NgClass,
  ],
  templateUrl: './novo-produto-overlay.component.html',
  styleUrl: './novo-produto-overlay.component.scss'
})
export class NovoProdutoOverlayComponent extends BaseClass() {

  delayShowTooltip = DelayShowTooltip;

  categorias: Categorias;

  selectedCategoria: FormControl = new FormControl(null);
  tags: Array<string> = [];

  produto: Partial<Produto> = {
    status: StatusProdutoEnum.ATIVO,
    nome: null,
    categoria: null,
    margemLucro: null,
    unidadeMedida: null,
    precoCusto: null,
    precoVenda: null,
    precoIdeal: null,
  }

  ProdutoStatus = StatusProdutoEnum;

  constructor(
    @Inject('data') public data: { produto: Produto, periodo: string },
    private activeModal: NgbActiveModal,
    private produtoService: ProdutoService,
    private categoriaService: CategoriaService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.getCategorias();

    if (this.data?.produto) {
      Object.assign(this.produto, {
        ...this.data.produto,
        margemLucro: this.data.produto.margemLucro,
      });

      this.tags = this.data.produto.tags || [];
    }
  }

  async getCategorias() {
    try {
      const res = await this.categoriaService.getCategoriasPeriodo(PeriodoLancamentosSelecionado$.value?.id, { PageSize: 1000 });
      if (res.data) {
        this.categorias = res.data;
        if (this.data.produto?.idCategoria) this.selectCategoria(this.categorias?.find(x => x.id === this.data.produto.idCategoria));
      }
    } catch (err) {
      this.toastService.show({ body: 'Ocorreu um erro ao buscar as categorias, tente novamente mais tarde', color: 'danger' });
      console.log(err);
    }
  }

  selectCategoria(categoria: Categoria) {
    this.selectedCategoria.setValue(categoria);
    this.produto.margemLucro = categoria?.margemLucro || null;
  }

  private presentErrorMessage(message: string) {
    this.modalService.presentAlert('Erro ao salvar produto', message);
  }

  salvar() {
    const { status, nome, margemLucro, unidadeMedida, quantidade, precoCusto, precoVenda, precoIdeal } = this.produto;
    if (!nome) return this.presentErrorMessage('O nome do produto é obrigatório');
    if (!Number.isFinite(precoCusto)) return this.presentErrorMessage('O preço de custo é obrigatório');
    if (!Number.isFinite(precoVenda)) return this.presentErrorMessage('O preço de venda é obrigatório');
    if (!Number.isFinite(quantidade)) return this.presentErrorMessage('A quantidade é obrigatória');

    const toSave: Partial<Produto> = {
      status,
      nome: nome || null,
      idCategoria: this.selectedCategoria.value?.id || null,
      margemLucro: margemLucro || null,
      unidadeMedida: unidadeMedida || null,
      quantidade,
      precoCusto,
      precoVenda,
      precoIdeal,
      tags: this.tags?.map(x => x.trim()) || null,
      id: this.data?.produto?.id || null,
    }

    const loading = this.modalService.presentLoading("Salvando produto, aguarde...", true);

    const periodoId = this.data?.periodo || PeriodoLancamentosSelecionado$.value?.id;

    const promise = this.data?.produto ? this.produtoService.updateProdutoPeriodo(periodoId, toSave) : this.produtoService.insertProdutoPeriodo(periodoId, toSave);

    promise.then((res) => {
      if (!res.success) return this.presentToastErrorSave();

      this.toastService.show({ body: `Produto salvo com sucesso`, color: 'success' });
      this.dismiss('saved');
    }).catch((err) => {
      console.log(err);
      const apiError: ApiResponseError = err?.error;
      if (apiError) {
        const message = (apiError.error?.map(x => x.message) || apiError?.validations?.map(x => x.message)).join('\n');
        return this.modalService.presentAlert('Erro ao salvar produto', message || 'Ocorreu um erro ao salvar o produto, tente novamente mais tarde');
      }

      this.presentToastErrorSave()
    }).finally(() => {
      loading.dismiss();
    })
  }

  margemIncrement() {
    if (!this.produto.margemLucro) return this.produto.margemLucro = 1;
    if (this.produto.margemLucro + 1 >= 100) return this.produto.margemLucro = 100;
    this.produto.margemLucro += 1;
  }

  margemDecrement() {
    if (!this.produto.margemLucro) return this.produto.margemLucro = 0;
    if (this.produto.margemLucro - 1 <= 0) return this.produto.margemLucro = 0;
    this.produto.margemLucro -= 1;
  }

  removeTag(index: number) {
    this.tags.splice(index, 1);
  }

  private presentToastErrorSave() {
    this.toastService.show({ body: 'Ocorreu um erro ao salvar o produto, tente novamente mais tarde', color: 'danger' });
  }

  excluir() {
    const confirm = this.modalService.presentConfirm('Excluir produto', 'Deseja realmente excluir este produto?');

    const sub = confirm.closed.subscribe({
      next: (res: boolean) => {
        if (res) {
          const loading = this.modalService.presentLoading("Excluindo produto, aguarde...", true);

          this.produtoService.deleteProdutoPeriodo(this.data?.periodo || PeriodoLancamentosSelecionado$.value?.id, this.data?.produto?.id).then((res) => {
            if (!res.success) return this.toastService.show({ body: 'Ocorreu um erro ao excluir o produto, tente novamente mais tarde', color: 'danger' });

            this.toastService.show({ body: `Produto excluído com sucesso`, color: 'success' });
            this.dismiss('deleted');
          }).catch((err) => {
            console.log(err);
            this.toastService.show({ body: 'Ocorreu um erro ao excluir o produto, tente novamente mais tarde', color: 'danger' });
          }).finally(() => {
            loading.dismiss();
          })
        }
      }
    });

    this.appendSubscription(sub);
  }

  dismiss(reason: string = null) {
    this.activeModal.dismiss(reason);
  }
}
