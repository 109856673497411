import { Component, Input } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorDirective } from '../../directives/color.directive';
import { AppColor, RGBColor } from '../../model/custom-types';

type ProgressBarConfigSingle = {
  label: string;
  value: number;
  showValue: boolean;
  color: RGBColor | AppColor;
  total?: number;
}

type ProgressBarConfigStacked = {
  values: ProgressBarConfigSingle[];
}

type ProgressBarType = 'single' | 'stacked';

export type ProgressBarConfig<T extends ProgressBarType = 'single'> = (T extends 'single' ? ProgressBarConfigSingle : ProgressBarConfigStacked);

@Component({
  selector: 'progress-bar',
  standalone: true,
  imports: [NgbProgressbarModule, ColorDirective],
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent extends BaseClass() {

  @Input({ required: true }) config: ProgressBarConfig<ProgressBarType>;

  configSingle: ProgressBarConfigSingle;
  configStacked: ProgressBarConfigStacked;

  ngOnInit() {
    if (this.config) {
      if ('label' in this.config) this.configSingle = this.config as ProgressBarConfigSingle;
      else this.configStacked = this.config as ProgressBarConfigStacked;
    }
  }

}
