import { SiglaEstado, StatusAtivoEnum } from "./custom-types";

export type Empresa = {
  id: string;
  tipo: TipoPessoa;
  cpfCnpj: string;
  nome: string;
  logradouro: string;
  bairro: string;
  cidade: string;
  estado: SiglaEstado;
  status: StatusAtivoEnum;

  nomeResponsavel: string;
  email: string;
  telefone: string;
  senha?: string;
}
export type Empresas = Array<Empresa>;

export enum TipoPessoa {
  PF = 1,
  PJ = 2,
}
