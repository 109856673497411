import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SuporteComponent } from './suporte/suporte.component';
import { AnalisarCompararComponent } from './analisar-comparar/analisar-comparar.component';
import { PeriodosComponent } from './periodos-wrapper/periodos/periodos.component';
import { PeriodosWrapperComponent } from './periodos-wrapper/periodos-wrapper.component';
import { ProdutosWrapperComponent } from './produtos-wrapper/produtos-wrapper.component';
import { CategoriasComponent } from './produtos-wrapper/categorias/categorias.component';
import { ProdutosComponent } from './produtos-wrapper/produtos/produtos.component';
import { NovoPeriodoComponent } from './periodos-wrapper/periodos/novo-periodo/novo-periodo.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { UsuariosComponent } from './usuarios/usuarios.component';

export const HomeRoutes: Routes = [
  { path: 'dashboard', component: DashboardComponent, },
  {
    path: 'produtos',
    component: ProdutosWrapperComponent,
    children: [
      { path: 'list', component: ProdutosComponent },
      { path: 'categorias', component: CategoriasComponent },
      { path: '', redirectTo: 'list', pathMatch: 'full' },
    ],
  },
  { path: 'analisar', component: AnalisarCompararComponent, },
  {
    path: 'periodos',
    component: PeriodosWrapperComponent,
    children: [
      { path: 'novo', component: NovoPeriodoComponent },
      { path: '', component: PeriodosComponent },
    ]
  },
  {
    path: 'simulador',
    component: PeriodosWrapperComponent,
    children: [
      { path: 'novo', component: NovoPeriodoComponent },
      { path: '', component: PeriodosComponent },
    ]
  },
  { path: 'empresas', component: EmpresasComponent, },
  { path: 'usuarios', component: UsuariosComponent, },
  { path: 'suporte', component: SuporteComponent, },
  // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
];
