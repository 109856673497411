import { Component, Input } from '@angular/core';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';
import { MaterialIcon } from 'material-icons';

@Component({
  selector: 'ng-mat-icon',
  standalone: true,
  imports: [ColorDirective],
  templateUrl: './ng-mat-icon.component.html',
  styleUrl: './ng-mat-icon.component.scss'
})
export class NgMatIconComponent {
  @Input({ required: true }) name: MaterialIcon;
  @Input() outlined: boolean = true;
  @Input() color: AppColor = 'white';
  @Input() bgColor: AppColor = null;
  @Input() classes: string = '';
}
