import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective } from 'ngx-mask';
import { IbgeService } from '../../services/ibge.service';
import { Usuario } from '../../model/usuario.model';
import { ToastService } from '../../services/toast.service';
import { Empresa, TipoPessoa } from '../../model/empresa.model';
import { BaseClass } from '../../globals/base-class';
import { LogoHeaderComponent } from '../../custom-components/logo-header/logo-header.component';
import { ModalService } from '../../services/modal.service';
import { EmpresaService } from '../../services/empresa.service';
import { CidadeIbge, EstadoIbge } from '../../model/custom-types';

type PickedFieldsUsuario = Pick<Usuario, 'email' | 'telefone'>;
type PickedFieldsEmpresa = Pick<Empresa, 'nome' | 'tipo' | 'cpfCnpj' | 'nomeResponsavel' | 'estado' | 'cidade' | 'bairro' | 'logradouro'>;

type TForm = {
  [key in keyof (PickedFieldsEmpresa & PickedFieldsUsuario)]: FormControl;
};
type TFormValue = PickedFieldsEmpresa & PickedFieldsUsuario;

@Component({
  selector: 'app-criar-conta',
  standalone: true,
  imports: [ReactiveFormsModule, NgxMaskDirective, LogoHeaderComponent],
  templateUrl: './criar-conta.component.html',
  styleUrl: './criar-conta.component.scss'
})
export class CriarContaComponent extends BaseClass() {

  estados: Array<EstadoIbge>;
  cidades: Array<CidadeIbge>;

  form: FormGroup<TForm> = new FormGroup({
    cpfCnpj: new FormControl(''),
    nome: new FormControl(''),
    email: new FormControl(''),
    estado: new FormControl(''),
    cidade: new FormControl(''),
    bairro: new FormControl(''),
    logradouro: new FormControl(''),
    nomeResponsavel: new FormControl(''),
    telefone: new FormControl(''),
    tipo: new FormControl(TipoPessoa.PJ),
  });

  PF = TipoPessoa.PF;
  PJ = TipoPessoa.PJ;

  constructor(
    private empresaService: EmpresaService,
    private ibgeService: IbgeService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.getEstados();

    this.form.controls.estado.valueChanges.subscribe({
      next: (v) => {
        if (!v) {
          this.form.controls.cidade.setValue('');
          this.form.controls.cidade.disable();
          return;
        }

        this.getCidadesPorEstado().then(() => this.form.controls.cidade.enable());
      }
    });
  }

  confirm() {
    const formValue: TFormValue = this.form.getRawValue();

    const error = this.checkErrors();
    if (error) return this.showToastErro(error);

    const empresa: Partial<Usuario & Empresa> = {
      ...formValue,
      status: 1,
    };

    const { nomeResponsavel, ...usuario } = empresa;

    const loading = this.modalService.presentLoading('Criando conta...');

    this.form.disable();

    this.empresaService.insertEmpresa(formValue.tipo === TipoPessoa.PF ? usuario : empresa).then((res) => {
      if (res.errors && res.validations?.length) return this.modalService.presentAlert('Erro ao criar conta', res.validations.map(v => v.message).join('\n'));

      this.modalService.presentAlert('Conta criada com sucesso!', 'Foi enviado um email com instruções para acessar sua conta, verifique sua caixa de entrada.');
      this.activeModal.close();
    }).catch((err) => {
      console.log(err);
      this.showToastErro(err?.error?.message || 'Ocorreu um erro ao tentar criar a conta');
    }).finally(() => {
      this.form.enable();
      loading.close();
    });
  }

  private checkErrors() {
    const formValue: TFormValue = this.form.getRawValue();

    if (!formValue.tipo)
      return 'Você deve informar o tipo de pessoa';

    if (
      this.form.controls.cpfCnpj.invalid ||
      !formValue.cpfCnpj ||
      (formValue.cpfCnpj.length < 11 && formValue.tipo === TipoPessoa.PF) || (formValue.cpfCnpj.length < 14 && formValue.tipo === TipoPessoa.PJ)
    )
      return `${formValue.tipo === TipoPessoa.PF ? 'CPF' : 'CNPJ'} inválido`;

    if (!formValue.nome || formValue.nome.length < 3)
      return `Informe o nome da ${formValue.tipo === TipoPessoa.PF ? 'pessoa' : 'empresa'}`;

    if (formValue.tipo === TipoPessoa.PJ && (!formValue.nomeResponsavel || formValue.nomeResponsavel.length < 3))
      return `Nome do responsável deve ter no mínimo 3 caracteres`;

    if (this.form.controls.email.invalid || !formValue.email)
      return 'Informe um email válido';

    if (this.form.controls.telefone.invalid || !formValue.telefone || formValue.telefone.length < 10)
      return 'Informe um número de telefone válido';

    if (!formValue.estado)
      return 'Informe o estado';

    if (!formValue.cidade)
      return 'Informe a cidade';

    if (!formValue.bairro)
      return 'Informe o bairro';

    if (!formValue.logradouro)
      return 'Informe o logradouro';

    return null;

  }

  private showToastErro(message: string) {
    this.toastService.show({ body: message, color: 'warning' });
  }

  async getEstados() {
    this.form.controls.estado.disable();
    this.estados = await this.ibgeService.getEstadosBrasil();
    this.form.controls.cidade.setValue('');
    this.form.controls.cidade.disable();
    this.form.controls.estado.enable();
  }

  async getCidadesPorEstado() {
    this.form.controls.cidade.disable();
    this.form.controls.cidade.setValue('');
    const estado = this.form.controls.estado.value;
    this.cidades = await this.ibgeService.getCidadesPorEstado(estado);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
