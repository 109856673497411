<div id="container">
  @if (periodo && showActions) {
    <div id="header" class="mb-4 mt-1">
      <button id="btn-novo" type="button" class="btn btn-info gap-3" (click)="addEditProduto()">
        <custom-icon name="package-white" />
        <span> Novo Produto </span>
      </button>

      @if (isDesktop) {
        <button type="button" class="btn btn-outline-info gap-3" (click)="exportar()">
          <ng-mat-icon color="info" name="file_download" />
          <span> Exportar </span>
        </button>

        <button type="button" class="btn btn-outline-info gap-3" (click)="importar()">
          <ng-mat-icon color="info" name="file_upload" />
          <span> Importar </span>
        </button>
      }

      <div id="searchbox" class="input-group input-icon-end position-relative input-icon-end">
        <input
          type="text"
          class="form-control lh-2 pe-5"
          id="search-input"
          placeholder="Busque pelo nome do produto"
          [debounce]="500"
          [operationObs$]="operationInProgress$"
          [enterOnly]="false"
          (onChange)="inputOnSearch()"
          [disabled]="!produtos || (operationInProgress$ | async)"
          [(ngModel)]="searchTerm"
        />
        <ng-mat-icon class="cursor-pointer" slot="end" name="search" color="info" />
      </div>

      <button id="btn-filtros" type="button" class="btn" [class.btn-info]="isDesktop" [class.btn-outline-info]="!isDesktop" (click)="selecionarFiltros()" ngbTooltip="Filtros" placement="top" [openDelay]="DelayShowTooltip">
        <ng-mat-icon name="filter_alt" [color]="isDesktop ? 'white' : 'info'" />
      </button>

      @if (!isDesktop) {
        <button id="btn-options" type="button" class="btn btn-outline-info" (click)="optionsMobile()">
          <ng-mat-icon name="more_vert" [color]="isDesktop ? 'white' : 'info'" />
        </button>
      }

      @if (isDesktop) {
        <button type="button" class="btn btn-info" (click)="selecionarColunas()" ngbTooltip="Colunas" placement="top" [openDelay]="DelayShowTooltip">
          <ng-mat-icon name="tune" class="rotate-90" />
        </button>
      }
    </div>
  }

  @if (produtos) {
    <table-produto-list
      [columns]="[displayedColumns, columnsActions].flat()"
      [produtos]="produtos"
      [paginatorOpts]="paginatorOpts"
      [periodo]="periodo"
      [operationInProgress$]="operationInProgress$"
      [ordering]="ordering"
      (ativoToggleChange)="ativoToggleChange($event.item, $event.input)"
      (addEditProduto)="addEditProduto($event)"
      (detalhesProduto)="informacoesAdicionaisProduto($event)"
      (updatePageSize)="updatePageSize($event)"
      (navigatePage)="navigatePage($event)"
      (checkedChanged)="produtoChecked($event)"
      (order)="order($event)"
    />
  } @else {
    @if (error) {
      <no-results message="Ocorreu um erro ao buscar os produtos. Tente novamente." />
    } @else {
      <operation-in-progress />
    }
  }
</div>
