<div id="container" class="h-100" [ngClass]="{ 'p-5': isDesktop, 'p-4': !isDesktop }">
  <div id="content" class="h-100 position-relative rounded-3 p-4">
    <div class="h-100 d-flex gap-3 d-flex text-center flex-column align-items-center justify-content-center">
      <h1 class="fw-600" color="verde">Tem alguma dúvida ou problema?</h1>
      <h3>Entre em contato conosco!</h3>

      <div class="d-grid gap-3">
        <card-with-icon [animated]="true" borderColor="titulo" [showFooter]="false" (clickBody)="sendMail()">
          <div class="d-flex flex-column gap-3 align-items-center m-2 px-4">
            <ng-mat-icon name="email" color="titulo" />
            <span> Email </span>
          </div>
        </card-with-icon>

        <card-with-icon [animated]="true" borderColor="titulo" [showFooter]="false" (clickBody)="sendWhatsapp()">
          <div class="d-flex flex-column gap-3 align-items-center m-2 px-4">
            <icon name="logo-whatsapp" />
            <span> Whatsapp </span>
          </div>
        </card-with-icon>
      </div>
    </div>
  </div>
</div>
