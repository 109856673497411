import { Component, Inject, ViewChild } from '@angular/core';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../../../custom-components/dropdown/dropdown.component';
import { CurrencyPipe } from '@angular/common';
import { BadgeComponent } from '../../../custom-components/badge/badge.component';
import { BaseClass } from '../../../globals/base-class';
import { NgbActiveModal, NgbTypeahead, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { EmpresaService } from '../../../services/empresa.service';
import { ToastService } from '../../../services/toast.service';
import { ModalService } from '../../../services/modal.service';
import { Empresa, TipoPessoa } from '../../../model/empresa.model';
import { ApiResponseError } from '../../../model/api.model';
import { TipoPessoaPipe } from '../../../pipes/tipo-pessoa.pipe';
import { NgxMaskDirective } from 'ngx-mask';
import { IbgeService } from '../../../services/ibge.service';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { emailIsValid, replaceSpecialChars, stringIncludesSubstring } from '../../../globals/utils';
import { CidadeIbge, EstadoIbge, SiglaEstado, StatusAtivoEnum } from '../../../model/custom-types';
import { minLengthPassword } from '../../../globals/globals';
import { CnpjRfService } from '../../../services/cnpj-rf.service';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';

@Component({
  selector: 'app-nova-empresa-overlay',
  standalone: true,
  imports: [
    SmallLabelComponent,
    IconComponent,
    ColorDirective,
    FormsModule,
    DropdownComponent,
    CurrencyPipe,
    ColorDirective,
    BadgeComponent,
    TipoPessoaPipe,
    NgxMaskDirective,
    NgbTypeaheadModule,
    NgMatIconComponent,
  ],
  templateUrl: './nova-empresa-overlay.component.html',
  styleUrl: './nova-empresa-overlay.component.scss'
})
export class NovaEmpresaOverlayComponent extends BaseClass() {

  @ViewChild('instanceEstado', { static: true }) instanceEstado: NgbTypeahead;
  @ViewChild('instanceCidade', { static: true }) instanceCidade: NgbTypeahead;

  controlEstado: EstadoIbge;
  controlCidade: CidadeIbge;

  focusEstado$ = new Subject<string>();
  focusCidade$ = new Subject<string>();
  clickEstado$ = new Subject<string>();
  clickCidade$ = new Subject<string>();

  searchEstado: OperatorFunction<string, readonly EstadoIbge[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickEstado$.pipe(filter(() => !this.instanceEstado.isPopupOpen()));

    return merge(debouncedText$, this.focusEstado$, clicksWithClosedPopup$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => term === '' ? this.estados : this.estados.filter((v) => stringIncludesSubstring(v.nome, term)))
    );
  };

  searchCidade: OperatorFunction<string, readonly CidadeIbge[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickCidade$.pipe(filter(() => !this.instanceCidade.isPopupOpen()));

    return merge(debouncedText$, this.focusCidade$, clicksWithClosedPopup$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => term === '' ? this.cidades : this.cidades.filter((v) => stringIncludesSubstring(v.nome, term)))
    );
  };

  formatterNgbTypeahead = (x: CidadeIbge | EstadoIbge) => x.nome;

  empresa: Partial<Empresa> = {
    bairro: null,
    cidade: null,
    cpfCnpj: null,
    email: null,
    estado: null,
    nome: null,
    id: null,
    logradouro: null,
    nomeResponsavel: null,
    status: StatusAtivoEnum.ATIVO,
    telefone: null,
    tipo: TipoPessoa.PJ,
  }

  senha: string;
  confirmaSenha: string;
  hidePassword: boolean = true;

  EmpresaStatus = StatusAtivoEnum;
  TipoPessoa = TipoPessoa;

  estados: Array<EstadoIbge>;
  cidades: Array<CidadeIbge> = [];

  constructor(
    @Inject('data') public data: { empresa: Empresa },
    private activeModal: NgbActiveModal,
    private empresaService: EmpresaService,
    private toastService: ToastService,
    private modalService: ModalService,
    private ibgeService: IbgeService,
    private cnpjRfService: CnpjRfService,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  async initialize() {
    if (this.data?.empresa) this.empresa = this.data.empresa;
    await this.getEstados();
    if (this.empresa.estado) {
      this.controlEstado = this.estados.find(x => x.sigla === this.empresa.estado);
      await this.getCidades(this.empresa.estado);
      if (this.empresa.cidade) this.controlCidade = this.cidades.find(x => replaceSpecialChars(x.nome)?.toUpperCase() === replaceSpecialChars(this.empresa.cidade).toUpperCase());
    }
  }

  private formError(empresa: Partial<Empresa>) {
    if (!empresa.nome) return 'Nome da empresa é obrigatório';
    if (empresa.tipo === TipoPessoa.PJ && empresa.cpfCnpj?.length !== 14) return 'CNPJ é obrigatório e deve ter 14 dígitos';
    if (empresa.tipo === TipoPessoa.PF && empresa.cpfCnpj?.length !== 11) return 'CPF é obrigatório e deve ter 11 dígitos';
    if (!empresa.estado) return 'Estado é obrigatório';
    if (!empresa.cidade) return 'Cidade é obrigatória';
    if (!empresa.bairro) return 'Bairro é obrigatório';
    if (!empresa.logradouro) return 'Logradouro é obrigatório';

    if (this.data?.empresa?.id) return null;
    // as verificações abaixo só são necessárias para novos registros

    if (!emailIsValid(empresa.email)) return 'O email informado é inválido';
    if (empresa.telefone?.length > 0 && empresa.telefone.length < 10) return 'O telefone informado é inválido';
    if (empresa.tipo === TipoPessoa.PJ && !empresa.nomeResponsavel) return 'Nome do responsável é obrigatório';
    if (!this.senha) return 'Senha é obrigatória';
    if (!this.confirmaSenha) return 'Confirmação de senha é obrigatória';
    if (this.senha !== this.confirmaSenha) return 'As senhas não conferem';
    if (this.senha.length < minLengthPassword) return `A senha deve ter no mínimo ${minLengthPassword} caracteres`;

    return null;
  }

  salvar() {
    const { status, nome, bairro, cidade, cpfCnpj, email, estado, logradouro, nomeResponsavel, telefone, tipo } = this.empresa;

    const toSave: Partial<Empresa> = {
      status,
      nome: nome || null,
      bairro: bairro || null,
      cidade: cidade || this.controlCidade?.nome || null,
      cpfCnpj: cpfCnpj || null,
      estado: estado || this.controlEstado?.sigla || null,
      logradouro: logradouro || null,
      tipo,

      telefone: telefone || null,
      nomeResponsavel: nomeResponsavel || null,
      email: email || null,
    }

    const { telefone: tel, nomeResponsavel: responsavel, email: mail, ...toUpdate } = toSave;

    const error = this.formError(toSave);
    if (error) return this.toastService.show({ body: error, color: 'danger' });

    const loading = this.modalService.presentLoading("Salvando empresa, aguarde...", true);

    const promise = this.data?.empresa ? this.empresaService.updateEmpresa({ id: this.data.empresa.id, ...toUpdate }) : this.empresaService.insertEmpresa({ ...toSave, senha: this.senha });

    promise.then((res) => {
      if (!res.success) {
        let message = res.error?.map(x => x.message).join('\n') || res.errors?.validations?.map(x => x.message).join('\n');
        if (message) return this.modalService.presentAlert('Erro ao salvar empresa', message);
        return this.toastErrorSave();
      }

      this.toastService.show({ body: `Empresa salvo com sucesso`, color: 'success' });
      this.dismiss('saved');
    }).catch((err) => {
      console.log(err);
      const apiError: ApiResponseError = err?.error;
      if (apiError) {
        const message = (apiError.error?.map(x => x.message) || apiError?.validations?.map(x => x.message)).join('\n');
        return this.modalService.presentAlert('Erro ao salvar empresa', message || 'Ocorreu um erro ao salvar o empresa, tente novamente mais tarde');
      }

      this.toastErrorSave()
    }).finally(() => {
      loading.dismiss();
    });
  }

  excluir() {
    const confirm = this.modalService.presentConfirm('Excluir empresa', 'Deseja realmente excluir esta empresa?');

    const sub = confirm.closed.subscribe({
      next: (res: boolean) => {
        if (res) {
          const loading = this.modalService.presentLoading("Excluindo empresa, aguarde...", true);

          this.empresaService.deleteEmpresa(this.data.empresa.id).then((res) => {
            if (!res.success) {
              let message = res.error?.map(x => x.message).join('\n') || res.errors?.validations?.map(x => x.message).join('\n');
              if (message) return this.modalService.presentAlert('Erro ao excluir empresa', message);
              return this.toastService.show({ body: 'Ocorreu um erro ao excluir a empresa, tente novamente mais tarde', color: 'danger' });
            }

            this.toastService.show({ body: `Empresa excluída com sucesso`, color: 'success' });
            this.dismiss('deleted');
          }).catch((err) => {
            console.log(err);
            const apiError: ApiResponseError = err?.error;
            if (apiError) {
              const message = (apiError.error?.map(x => x.message) || apiError?.validations?.map(x => x.message)).join('\n');
              return this.modalService.presentAlert('Erro ao excluir empresa', message || 'Ocorreu um erro ao excluir a empresa, tente novamente mais tarde');
            }

            this.toastService.show({ body: 'Ocorreu um erro ao excluir a empresa, tente novamente mais tarde', color: 'danger' });
          }).finally(() => {
            loading.dismiss();
          });
        }
      }
    })

    this.appendSubscription(sub);
  }

  focusOutCpfCnpj() {
    if (this.empresa.tipo === TipoPessoa.PJ && this.empresa.cpfCnpj?.length === 14) {
      this.cnpjRfService.getDetailsCnpjPromise(this.empresa.cpfCnpj).then(async (res) => {
        if (res.status === 'ERROR') return this.toastService.show({ body: 'CNPJ não encontrado', color: 'danger' });
        this.empresa.nome = res.nome;
        this.empresa.bairro = res.bairro;
        this.empresa.cidade = res.municipio;
        this.empresa.estado = res.uf;
        this.empresa.logradouro = res.logradouro;
        this.empresa.telefone = res.telefone;
        this.empresa.email = res.email;

        if (!this.estados) await this.getEstados();
        this.controlEstado = this.estados.find(x => x.sigla === this.empresa.estado);

        if (!this.cidades?.length && this.empresa.estado) await this.getCidades(this.empresa.estado);
        this.controlCidade = this.cidades.find(x => replaceSpecialChars(x.nome)?.toUpperCase() === replaceSpecialChars(this.empresa.cidade).toUpperCase());
      }).catch((err) => {
        console.log(err);
        this.toastService.show({ body: 'Ocorreu um erro ao buscar os dados do CNPJ, tente novamente mais tarde', color: 'danger' });
      });
    }
  }

  estadoChanged(event: EstadoIbge) {
    if (!event?.sigla) return;
    this.empresa.estado = event.sigla;
    this.empresa.cidade = null;
    this.controlCidade = null;
    this.cidades = null;
    this.getCidades(event.sigla);
  }

  cidadeChanged(event: CidadeIbge) {
    if (!event?.nome) return;
    this.empresa.cidade = event?.nome;
  }

  async getEstados() {
    this.estados = await this.ibgeService.getEstadosBrasil();
  }

  async getCidades(estado: SiglaEstado) {
    this.cidades = await this.ibgeService.getCidadesPorEstado(estado);
  }

  private toastErrorSave() {
    this.toastService.show({ body: 'Ocorreu um erro ao salvar a empresa, tente novamente mais tarde', color: 'danger' });
  }

  dismiss(reason: string = null) {
    this.activeModal.dismiss(reason);
  }
}
