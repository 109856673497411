import { Component, ViewChild } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ToastService } from '../../../../services/toast.service';
import { AsyncPipe, Location, NgClass, NgComponentOutlet } from '@angular/common';
import { PeriodoLancamentos } from '../../../../model/periodo.model';
import { importarPlanilhaProdutos, updateUrl } from '../../../../globals/utils';
import { ConfiguracaoPeriodoComponent } from './configuracao-periodo/configuracao-periodo.component';
import { ParametrosIniciaisPeriodoComponent } from './parametros-iniciais-periodo/parametros-iniciais-periodo.component';
import { ProdutosPeriodoComponent } from './produtos-periodo/produtos-periodo.component';
import { ConferirDadosNovoPeriodoComponent } from './conferir-dados-novo-periodo/conferir-dados-novo-periodo.component';
import { Step, StepChangedEvent, StepperComponent } from '../../../../custom-components/stepper/stepper.component';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { PeriodoService } from '../../../../services/periodo.service';
import { ModalService } from '../../../../services/modal.service';
import { RefreshPeriodosNotifier$, PeriodoLancamentosSelecionado$, session$ } from '../../../../globals/globals';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ProdutoService } from '../../../../services/produto.service';
import { OptionNovaBaseValue } from '../periodos.component';
import { eSimulacao$ } from '../../periodos-wrapper.component';
import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'app-novo-periodo',
  standalone: true,
  imports: [RouterLink, NgClass, NgComponentOutlet, StepperComponent, OperationInProgressComponent, NgbDropdownModule, AsyncPipe],
  templateUrl: './novo-periodo.component.html',
  styleUrl: './novo-periodo.component.scss'
})
export class NovoPeriodoComponent extends BaseClass() {

  @ViewChild("Stepper") Stepper: StepperComponent<PeriodoLancamentos>;

  steps: Array<Step> = [
    { component: ConfiguracaoPeriodoComponent, title: 'Configuração', data: null, status: 'active' },
    { component: ParametrosIniciaisPeriodoComponent, title: 'Parâmetros Iniciais', data: null, status: 'pending' },
    { component: ProdutosPeriodoComponent, title: 'Produtos', data: null, status: 'pending' },
    { component: ConferirDadosNovoPeriodoComponent, title: 'Conferência dos dados', data: null, status: 'pending' },
  ];

  tipoBase: OptionNovaBaseValue;

  file: File;

  currentStep: Step;

  periodo: PeriodoLancamentos = null;
  stepNumber: number = 1;

  operationInProgress = true;

  eSimulacao$ = eSimulacao$;

  currentPeriodo$ = PeriodoLancamentosSelecionado$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private toastService: ToastService,
    private periodoService: PeriodoService,
    private produtoService: ProdutoService,
    private modalService: ModalService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.tipoBase = this.route.snapshot?.queryParams?.['tipoBase'];
    this.stepNumber = +this.route.snapshot?.queryParams?.['step'];
    const periodoId = this.route.snapshot?.queryParams?.['id'];

    if (!Object.values(OptionNovaBaseValue).includes(this.tipoBase)) {
      this.presentToastError('Tipo de base inválido');
      this.navigateList();
      return;
    }

    if (periodoId) return this.getPeriodo(periodoId);
    this.operationInProgress = false;
  }

  private getPeriodo(id: string) {
    this.operationInProgress = true;
    this.periodoService.getPeriodo(id).then((res) => {
      if (res.errors) return;
      if (res) this.periodo = res;
    }).catch((err) => {
      this.presentToastError('Erro ao buscar período');
      this.navigateList();
    }).finally(() => {
      this.operationInProgress = false;
    });
  }

  updatePeriodo(periodo: PeriodoLancamentos, previousIndex: number, periodoACopiar?: PeriodoLancamentos, file?: File) {
    periodo.idEmpresa = session$.value.empresa;

    switch (this.tipoBase) {
      case OptionNovaBaseValue.Editar:
      case OptionNovaBaseValue.Manualmente:
        this.upsertPeriodo(periodo, previousIndex);
        break;

      case OptionNovaBaseValue.Copiar:
        if (periodo?.id) this.upsertPeriodo(periodo, previousIndex);
        else this.copiarPeriodo(periodo, periodoACopiar, previousIndex);
        break;

      case OptionNovaBaseValue.Importar:
        if (periodo?.id) this.upsertPeriodo(periodo, previousIndex);
        this.importarPlaniha(periodo, previousIndex, file);
        break;
    }
  }

  private periodoSalvo(periodo: PeriodoLancamentos) {
    this.notifyPeriodoSelecionado(periodo.id);
    this.toastService.show({ body: 'Período salvo!', color: 'success' });
    this.periodo = periodo;
    this.updateUrl(periodo.id);
  }

  private async upsertPeriodo(periodo: PeriodoLancamentos, previousIndex: number, isImporting: boolean = false) {
    const loading = this.modalService.presentLoading(`${periodo.id ? 'Atualizando' : 'Criando'} período`, true);

    try {
      const promise = periodo.id ? this.periodoService.updatePeriodo({ ...periodo, simulacao: !!this.eSimulacao$.value }) : this.periodoService.createPeriodo({ ...periodo, simulacao: !!this.eSimulacao$.value });

      const res = await promise;

      if (res.errors) {
        this.presentToastError('Erro ao salvar período');
        this.Stepper.activateStep(previousIndex);
        throw new Error('Erro ao salvar período');
      }

      if (!isImporting) this.periodoSalvo(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
      this.presentToastError('Erro ao salvar período');
      this.Stepper.activateStep(previousIndex);
      return false;
    } finally {
      loading.dismiss();
    }
  }

  private copiarPeriodo(periodoNovo: Pick<PeriodoLancamentos, 'dataFim' | 'dataInicio' | 'nome' | 'simulacao'>, periodoACopiar: PeriodoLancamentos, previousIndex: number) {
    const loading = this.modalService.presentLoading('Copiando período', true);

    this.periodoService.copiarPeriodo({ ...periodoNovo, nome: periodoNovo.nome, simulacao: !!this.eSimulacao$.value }, periodoACopiar.id).then((res) => {
      if (res.errors) {
        this.presentToastError('Erro ao copiar período');
        this.Stepper.activateStep(previousIndex);
        return;
      }
      this.updateUrl(res.data.id);
      RefreshPeriodosNotifier$.emit();
      this.toastService.show({ body: 'Período copiado', color: 'success' });
      const { idPeriodoASerCopiado, ...rest } = res.data;
      this.periodo = rest as PeriodoLancamentos;
    }).catch((err) => {
      console.log(err);
      this.presentToastError('Erro ao copiar período');
      this.Stepper.activateStep(previousIndex);
    }).finally(() => {
      loading.dismiss();
    })
  }

  private async importarPlaniha(periodo: PeriodoLancamentos, previousIndex: number, file: File) {
    if (!file) return;

    const saved = await this.upsertPeriodo(periodo, previousIndex, true);
    if (!saved) return;

    const resImport = await importarPlanilhaProdutos(file, this.produtoService, this.periodo.id, this.toastService, this.modalService);
    // if (!resImport) this.Stepper.activateStep(previousIndex);
    if (!resImport) {
      await this.periodoService.deletePeriodo(saved.id);
      this.cancel();
      return;
    }

    this.periodoSalvo(saved);
  }

  private updateUrl(id: string, partial: { [key: string]: string | number | boolean } = {}) {
    const params = {
      tipoBase: this.tipoBase,
      step: this.Stepper?.currentStep,
      id,
      simulador: !!this.eSimulacao$?.value,
      ...partial,
    }
    updateUrl(this._location, Object.fromEntries(Object.entries(params).filter(x => !!x[1])));
  }

  private presentToastError(body: string) {
    this.toastService.show({ body, color: 'danger' });
  }

  stepChanged(stepChangeEv: StepChangedEvent) {
    this.currentStep = this.steps[stepChangeEv.newIndex - 1];
    this.updateUrl(this.periodo?.id || null, { ...this.route.snapshot.queryParams, step: stepChangeEv.newIndex })
  }

  save() {
    this.Stepper.lock = true;
    this.navigateToDashboard();
  }

  private navigateList() {
    this.router.navigate(['home', this.eSimulacao$?.value ? 'simulador' : 'periodos'], { replaceUrl: true });
  }

  private navigateToDashboard() {
    this.storageService.set('CURRENT_PERIODO_ID', this.periodo?.id);
    RefreshPeriodosNotifier$.emit();
    this.router.navigate(['home', 'dashboard'], { replaceUrl: true });
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route, queryParams: { tipoBase: this.tipoBase } });
  }

  deletePeriodo() {
    const confirm = this.modalService.presentConfirm("Deseja realmente excluir o período?", "Todos os dados de produtos, despesas e categorias serão excluidos juntamente.", true);
    confirm.closed.subscribe({
      next: (confirmed) => {
        if (confirmed) {
          const loading = this.modalService.presentLoading('Excluindo período', true);

          this.periodoService.deletePeriodo(this.periodo.id).then((res) => {
            const errors = [...(res?.errors?.validations?.map(x => x.message) || []), ...(res.error?.map(x => x.message) || [])];
            if (errors?.length > 0) {
              this.modalService.presentAlert('Erro ao excluir período', errors.join('\n'));
              return;
            }

            this.toastService.show({ body: 'Período excluído', color: 'success' });
            this.storageService.remove('CURRENT_PERIODO_ID')
            RefreshPeriodosNotifier$.emit(false);
            this.navigateList();
          }).catch((err) => {
            console.log(err);
            this.presentToastError('Erro ao excluir período');
          }).finally(() => {
            loading.dismiss();
          });
        }
      }
    });
  }

  private notifyPeriodoSelecionado(id: string) {
    this.storageService.set('CURRENT_PERIODO_ID', id);
    RefreshPeriodosNotifier$.emit();
  }
}
