<div id="container" class="mb-4">
  <div id="produto-list">
    @if (!produtos) {
      @if (operationInProgress$ | async) {
        <operation-in-progress />
      } @else {
        <small-label color="danger" class="fst-italic justify-content-center my-5 py-5"> Erro ao carregar produtos </small-label>
      }
    } @else {
      <produto-list [periodo]="periodo" (operationInProgress$)="operationInProgress$.next($event)" />
    }
  </div>
</div>
