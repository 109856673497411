import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrencyPipe, NgClass, PercentPipe } from '@angular/common';
import { IconInEllipseComponent } from '../../../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { PrecificacaoProdutoFacePipe } from '../../../../../pipes/precificacao-produto-face.pipe';
import { BaseClass } from '../../../../../globals/base-class';
import { Produto } from '../../../../../model/produto.model';
import { ProdutoLocalList } from '../../../../../custom-components/table-produto-list/table-produto-list.component';

@Component({
  selector: 'detalhes-produto-accordion-body',
  standalone: true,
  imports: [CurrencyPipe, PercentPipe, IconInEllipseComponent, PrecificacaoProdutoFacePipe, NgClass],
  templateUrl: './detalhes-produto-accordion-body.component.html',
  styleUrl: './detalhes-produto-accordion-body.component.scss'
})
export class DetalhesProdutoAccordionBodyComponent extends BaseClass() {
  @Input() produto: ProdutoLocalList;
  @Input() ativoToggleChange: EventEmitter<{ item: Produto, input: HTMLInputElement }>;

  @Output() detalhesProduto: EventEmitter<void> = new EventEmitter<void>();
  @Output() editar: EventEmitter<void> = new EventEmitter<void>();
}
