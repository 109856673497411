import { Component } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { EmpresaService } from '../../services/empresa.service';
import { PaginatorComponent, PaginatorOpts } from '../../custom-components/paginator/paginator.component';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { Empresa } from '../../model/empresa.model';
import { ModalService } from '../../services/modal.service';
import { defaultClassModal } from '../../globals/utils';
import { ApiConstraintGetEmpresas } from '../../model/api.model';
import { StorageService } from '../../services/storage.service';
import { ToastService } from '../../services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { DebounceDirective } from '../../directives/debounce.directive';
import { AsyncPipe, NgClass } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { defaultDebounceInput } from '../../globals/globals';
import { FormsModule } from '@angular/forms';
import { ColumnTableEmpresaList, EmpresaLocalList, TableEmpresaListComponent } from '../../custom-components/table-empresa-list/table-empresa-list.component';
import { NovaEmpresaOverlayComponent } from './nova-empresa-overlay/nova-empresa-overlay.component';
import { StatusAtivoEnum } from '../../model/custom-types';

@Component({
  selector: 'app-empresas',
  standalone: true,
  imports: [
    PaginatorComponent,
    OperationInProgressComponent,
    NgMatIconComponent,
    DebounceDirective,
    AsyncPipe,
    NgbTooltipModule,
    FormsModule,
    NgClass,
    TableEmpresaListComponent,
  ],
  templateUrl: './empresas.component.html',
  styleUrl: './empresas.component.scss'
})
export class EmpresasComponent extends BaseClass() {

  empresas: Array<EmpresaLocalList>;
  empresasSelecionados: Array<EmpresaLocalList> = [];

  paginatorOpts: PaginatorOpts;

  searchTerm: string;

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  displayedColumns: Array<ColumnTableEmpresaList>;
  columnsActions: Array<ColumnTableEmpresaList> = ['status', 'edit'];

  defaultDebounceInput = defaultDebounceInput;

  constructor(
    private empresaService: EmpresaService,
    private modalService: ModalService,
    private storageService: StorageService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    const a: Array<ColumnTableEmpresaList> = this.storageService.get('DISPLAY_COLUMNS_EMPRESAS_LIST');
    this.displayedColumns = (a?.length > 0 ? a : ['nome', 'tipo', 'cpfCnpj', 'logradouro', 'bairro', 'cidade', 'estado'])

    this.getEmpresas();
  }

  async getEmpresas() {
    try {
      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_EMPRESAS')
      const constraints: ApiConstraintGetEmpresas = {
        Page: this.paginatorOpts?.page,
        PageSize,
        Termo: this.searchTerm,
      };

      const empresasRes = await this.empresaService.getEmpresas(constraints);

      if (empresasRes.data == null) {
        let messages: string = empresasRes.error?.map(err => err.message).join('\n') || empresasRes.errors?.validations?.map(err => err.message).join('\n');
        if (messages) return this.modalService.presentAlert("Erro", messages);
        return this.toastService.show({ body: 'Erro ao buscar empresas', color: 'danger' });
      }

      const { page, pages, pageSize, rowsCount, data } = empresasRes;
      this.empresas = data.map((empresa) => {
        return {
          ...empresa,
          _statusToggle: empresa.status === StatusAtivoEnum.ATIVO,
          _checked: false,
        };
      });
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      this.toastService.show({ body: 'Erro ao buscar empresas', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  ativoToggleChange(empresa: EmpresaLocalList, toggle: HTMLInputElement) {
    const { _statusToggle, ...toSave } = empresa;

    const loading = this.modalService.presentLoading("Salvando empresa, aguarde...", true);

    toSave.status = toggle.checked ? 1 : 2;

    this.empresaService.updateEmpresa(toSave).then((res) => {
      if (!res.success) {
        let messages: string = res.error?.map(err => err.message).join('\n') || res.errors?.validations?.map(err => err.message).join('\n');
        if (messages) return this.modalService.presentAlert("Erro", messages);
        return this.toastService.show({ body: 'Erro ao salvar empresa', color: 'danger' });
      }
      this.empresas.find(p => p.id === empresa.id).status = toSave.status;

      this.toastService.show({ body: `Empresa salva com sucesso`, color: 'success' });
    }).catch((err) => {
      console.log(err);
      this.toastService.show({ body: 'Erro ao salvar empresa', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    })
  }

  empresaChecked(ev: { empresa: EmpresaLocalList, checked: boolean }) {
    this.empresas.find(p => p.id === ev.empresa.id)._checked = ev.checked;
    if (ev.checked) this.empresasSelecionados.push(ev.empresa);
    else this.empresasSelecionados = this.empresasSelecionados.filter(p => p.id !== ev.empresa.id);
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getEmpresas();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_EMPRESAS', size);
    this.getEmpresas();
  }

  addEditEmpresa(empresa: Empresa = null) {
    const modal = this.modalService.presentModal(NovaEmpresaOverlayComponent, defaultClassModal(this), { empresa: empresa ? this.empresas?.find(e => e.id === empresa.id) : null }, { windowClass: 'blur-backdrop', });
    modal.dismissed.subscribe({
      next: (res) => {
        if (res === 'saved') this.getEmpresas();
      }
    });
  }

  inputOnSearch() {
    this.getEmpresas();
  }

}
