import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToastComponent } from './custom-components/toast/toast.component';
import { initializeSession } from './globals/globals';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  ngOnInit(): void {
    initializeSession(null);

    // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // this.toggleDarkTheme(prefersDark.matches);
  }

  // toggleDarkTheme(shouldAdd: boolean): void {
  //   document.body.classList.toggle('dark', shouldAdd);
  // }
}
