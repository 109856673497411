<div id="container" [class.position-relative]="nenhumPeriodoCadastrado">
  @if (nenhumPeriodoCadastrado) {
    <no-results message="Nenhum período cadastrado" />
  } @else {
    <div id="content" [ngClass]="{ 'gap-3': isDesktop }" class="p-3">
      <section id="cards-big" class="d-flex flex-column gap-3" [ngClass]="{ 'pb-3': !isDesktop }">
        <card-with-icon
          color="white"
          title="Lucro"
          icon="attach_money"
          iconBgColor="secondary"
          (clickBody)="dashboardData ? presentModal('lucro', { lucro: dashboardData.lucro, margemLucroCalculada: dashboardData.margemLucroCalculada, margemLucroDesejada: dashboardData.margemLucroDesejada }) : null"
        >
          <div class="d-flex gap-4" [ngClass]="{ 'flex-column': !isDesktop, 'align-items-center': isDesktop }">
            @if (!dashboardData) {
              @if (this.error) {
                <small-label class="fst-italic" color="danger"> Erro ao carregar </small-label>
              } @else if (dashboardData === null) {
                <small-label class="fst-italic" color="cinza"> {{ (currentPeriodo$ | async)?.id ? "N/D" : "Nenhum período selecionado" }} </small-label>
              } @else {
                <small-label class="fst-italic" color="cinza">
                  <span class="spinner-border spinner-border-sm me-2"></span>
                  <span> Carregando... </span>
                </small-label>
              }
            } @else {
              <label>
                <span color="secondary">{{ dashboardData.lucro | currency }}</span>
              </label>

              <div class="d-flex gap-3">
                <small-label>
                  <span color="secondary" class="fw-bold">{{ dashboardData.margemLucroCalculada / 100 | percent: "1.0-2" }}</span>
                  <span class="ms-1">Real</span>
                </small-label>
                <small-label>
                  <span color="titulo" class="fw-bold">{{ dashboardData.margemLucroDesejada / 100 | percent: "1.0-2" }}</span>
                  <span class="ms-1">Desejado</span>
                </small-label>
              </div>
            }
          </div>
        </card-with-icon>

        <card-with-icon
          color="white"
          icon="receipt"
          iconBgColor="azul"
          (clickBody)="dashboardData ? presentModal('faturamento', { faturamentoCalculado: dashboardData.faturamentoCalculado, faturamentoEstimado: dashboardData.faturamentoEstimado }) : null"
        >
          <div class="subcards d-flex gap-4" [ngClass]="{ 'flex-column': !isDesktop, 'align-items-center': isDesktop }">
            <div class="subcard">
              <div class="header d-flex gap-2 flex-column">
                <small-label>
                  <span color="cinza"> Faturamento calculado </span>
                </small-label>

                <label>
                  @if (!dashboardData) {
                    @if (error) {
                      <small-label class="fst-italic" color="danger"> Erro ao carregar </small-label>
                    } @else if (dashboardData === null) {
                      <small-label class="fst-italic" color="cinza"> N/D </small-label>
                    } @else {
                      <span class="spinner-border spinner-border-sm"></span>
                    }
                  } @else {
                    <span color="titulo"> {{ dashboardData.faturamentoCalculado > 0 ? (dashboardData.faturamentoCalculado | currency) : "-" }} </span>
                  }
                </label>
              </div>
            </div>

            <div class="position-relative subcard">
              @if (isDesktop) {
                <hr class="position-absolute h-100 divider vertical" />
              }

              <div class="header d-flex gap-2 flex-column" [class.ms-4]="isDesktop">
                <small-label>
                  <span color="cinza"> Faturamento estimado </span>
                  @if (isDesktop) {
                    <icon name="chevron-right" />
                  }
                </small-label>

                <label>
                  @if (!dashboardData) {
                    @if (error) {
                      <small-label class="fst-italic" color="danger"> Erro ao carregar </small-label>
                    } @else if (dashboardData === null) {
                      <small-label class="fst-italic" color="cinza"> N/D </small-label>
                    } @else {
                      <span class="spinner-border spinner-border-sm"></span>
                    }
                  } @else {
                    <span color="titulo"> {{ dashboardData.faturamentoEstimado > 0 ? (dashboardData.faturamentoEstimado | currency) : "-" }} </span>
                  }
                </label>
              </div>
            </div>
          </div>
        </card-with-icon>

        <card-with-icon color="white" [showForwardIcon]="false" [showFooter]="false">
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h4>Dinheiro deixado na mesa</h4>
          </div>

          @if (chartOptions.series === null) {
            <div>
              @if (error) {
                <small-label color="danger">
                  <span class="fst-italic py-4 w-100 text-center"> Erro ao carregar</span>
                </small-label>
              } @else {
                <no-results message="Nenhum dado encontrado" />
              }
            </div>
          } @else {
            <div class="gap-4" [ngClass]="{ 'd-grid grid-two-columns align-items-center': isDesktop, 'd-flex flex-column align-items-center gap-4': !isDesktop }">
              <div id="chart" class="mx-auto">
                @if (chartOptions.series) {
                  <apx-chart
                    [series]="chartOptions?.series"
                    [chart]="chartOptions?.chart"
                    [plotOptions]="chartOptions?.plotOptions"
                    [labels]="chartOptions?.labels"
                    [colors]="colorsChart"
                    [legend]="chartOptions?.legend"
                    [dataLabels]="chartOptions?.dataLabels"
                  />
                } @else {
                  <operation-in-progress [h100]="false" />
                }
              </div>

              <div id="percents" [class.w-100]="!isDesktop">
                @if (dashboardData?.dinheiroDeixadoNaMesa >= 0) {
                  <h5 class="fw-600 mb-4">{{ dashboardData?.dinheiroDeixadoNaMesa | currency }}</h5>
                  @for (item of dashboardData?.composicaoDinheiroDeixadoNaMesa; track $index) {
                    <div class="d-flex flex-column gap-3">
                      <small-label>
                        <span color="cinza"> {{ item.nome }}: </span>
                        <span class="ms-2 fw-bold"> {{ item.percentual / 100 | percent: "1.0-2" }} </span>
                      </small-label>
                      <ngb-progressbar class="mb-3" [color]="chartOptions?.colors[$index]" [value]="item.percentual" />
                    </div>
                  }
                } @else {
                  <operation-in-progress [h100]="false" />
                }
              </div>
            </div>
          }
        </card-with-icon>
      </section>

      <section id="cards-small" class="gap-3" [ngClass]="{ 'd-flex flex-column': !isDesktop }">
        @for (card of cardsSmall; track $index) {
          <card-small [card]="card" [periodo]="currentPeriodo$ | async" />
        }
      </section>
    </div>
  }
</div>
