export const environment = {
  production: false,
  linkHotmart: 'https://pay.hotmart.com/N42137990O',
  apiReceitaFederal: 'https://www.receitaws.com.br/v1/cnpj/',
  api: {
    url: 'https://api2.smartis.com.br/v1/',
    endpoints: {
      dashboard: 'dashboard',
      empresas: 'empresas',
      usuarios: 'usuarios',
      periodos: 'periodos',
      produtos: 'produtos',
      tokens: 'tokens',
      categorias: 'categorias',
      despesas: 'despesas',
      despesasVendaCategoria: 'despesasvenda',
    }
  }
};
