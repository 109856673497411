export type Despesa = {
  id: string;
  idPeriodo: string;
  nome: string;
  valor: number;
  tipo: TipoDespesa;
  tipoAplicacao: TipoAplicacaoDespesa;
}
export type Despesas = Array<Despesa>;

export type DespesaGrafico = {
  nome: string;
  valor: number;
  tipoAplicacao: TipoAplicacaoDespesa;
}

export enum TipoDespesa {
  VENDA = 1,
  FIXA = 2,
}

export enum TipoAplicacaoDespesa {
  PERCENTUAL = 2,
  VALOR = 1,
}

// export type TipoDespesa = "venda" | "fixa";

// export type TipoAplicacaoDespesa = "percentual" | "valorFixo";
