import { Component, Inject } from '@angular/core';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../../../custom-components/dropdown/dropdown.component';
import { CurrencyPipe } from '@angular/common';
import { BadgeComponent } from '../../../custom-components/badge/badge.component';
import { BaseClass } from '../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
import { ModalService } from '../../../services/modal.service';
import { ApiResponseError } from '../../../model/api.model';
import { TipoPessoaPipe } from '../../../pipes/tipo-pessoa.pipe';
import { NgxMaskDirective } from 'ngx-mask';
import { emailIsValid } from '../../../globals/utils';
import { CidadeIbge, EstadoIbge, StatusAtivoEnum } from '../../../model/custom-types';
import { minLengthPassword } from '../../../globals/globals';
import { NgMatIconComponent } from '../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { Usuario } from '../../../model/usuario.model';
import { UsuarioService } from '../../../services/usuario.service';

@Component({
  selector: 'app-novo-usuario-overlay',
  standalone: true,
  imports: [
    SmallLabelComponent,
    IconComponent,
    ColorDirective,
    FormsModule,
    DropdownComponent,
    CurrencyPipe,
    ColorDirective,
    BadgeComponent,
    TipoPessoaPipe,
    NgxMaskDirective,
    NgMatIconComponent,
  ],
  templateUrl: './novo-usuario-overlay.component.html',
  styleUrl: './novo-usuario-overlay.component.scss'
})
export class NovoUsuarioOverlayComponent extends BaseClass() {

  usuario: Partial<Usuario> = {
    email: null,
    nome: null,
    id: null,
    status: StatusAtivoEnum.ATIVO,
    telefone: null,
    senha: null,
  }

  senha: string;
  confirmaSenha: string;
  hidePassword: boolean = true;

  UsuarioStatus = StatusAtivoEnum;

  estados: Array<EstadoIbge>;
  cidades: Array<CidadeIbge> = [];



  constructor(
    @Inject('data') public data: { usuario: Usuario },
    private activeModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  async initialize() {
    if (this.data?.usuario) this.usuario = this.data.usuario;
  }

  private formError(usuario: Partial<Usuario>) {
    if (!usuario.nome) return 'Nome do usuário é obrigatório';
    if (usuario.telefone?.length > 0 && usuario.telefone.length < 10) return 'O telefone informado é inválido';

    if (this.usuario?.id) return null;

    if (!emailIsValid(usuario.email)) return 'O email informado é inválido';
    if (!this.senha) return 'Senha é obrigatória';
    if (!this.confirmaSenha) return 'Confirmação de senha é obrigatória';
    if (this.senha !== this.confirmaSenha) return 'As senhas não conferem';
    if (this.senha.length < minLengthPassword) return `A senha deve ter no mínimo ${minLengthPassword} caracteres`;
    return null;
  }

  salvar() {
    const { status, nome, email, telefone } = this.usuario;

    const toSave: Partial<Usuario> = {
      status,
      nome: nome || null,
      telefone: telefone || null,
      email: email || null,
      senha: this.senha,
    }

    const { senha: pass, ...toUpdate } = toSave;

    const error = this.formError(toSave);
    if (error) return this.toastService.show({ body: error, color: 'danger' });

    const loading = this.modalService.presentLoading("Salvando usuário, aguarde...", true);

    const promise = this.data?.usuario ? this.usuarioService.updateUser({ id: this.data.usuario.id, ...toUpdate }) : this.usuarioService.insertUsuario({ ...toSave, senha: this.senha });

    promise.then((res) => {
      if (!res.success) {
        let message = res.error?.map(x => x.message).join('\n') || res.errors?.validations?.map(x => x.message).join('\n');
        if (message) return this.modalService.presentAlert('Erro ao salvar usuário', message);
        return this.toastErrorSave();
      }

      this.toastService.show({ body: `Usuário salvo com sucesso`, color: 'success' });
      this.dismiss('saved');
    }).catch((err) => {
      console.log(err);
      const apiError: ApiResponseError = err?.error;
      if (apiError) {
        const message = (apiError.error?.map(x => x.message) || apiError?.validations?.map(x => x.message)).join('\n');
        return this.modalService.presentAlert('Erro ao salvar usuário', message || 'Ocorreu um erro ao salvar o usuário, tente novamente mais tarde');
      }

      this.toastErrorSave()
    }).finally(() => {
      loading.dismiss();
    });
  }

  excluir() {
    const confirm = this.modalService.presentConfirm('Excluir usuário', 'Deseja realmente excluir este usuário?');

    const sub = confirm.closed.subscribe({
      next: (res: boolean) => {
        if (res) {
          const loading = this.modalService.presentLoading("Excluindo usuário, aguarde...", true);

          this.usuarioService.deleteUser(this.data.usuario.id).then((res) => {
            if (!res.success) {
              let message = res.error?.map(x => x.message).join('\n') || res.errors?.validations?.map(x => x.message).join('\n');
              if (message) return this.modalService.presentAlert('Erro ao excluir usuário', message);
              return this.toastService.show({ body: 'Ocorreu um erro ao excluir o usuário, tente novamente mais tarde', color: 'danger' });
            }

            this.toastService.show({ body: `Usuário excluído com sucesso`, color: 'success' });
            this.activeModal.close('deleted');
          }).catch((err) => {
            console.log(err);
            const apiError: ApiResponseError = err?.error;
            if (apiError) {
              const message = (apiError.error?.map(x => x.message) || apiError?.validations?.map(x => x.message)).join('\n');
              return this.modalService.presentAlert('Erro ao excluir usuário', message || 'Ocorreu um erro ao excluir o usuário, tente novamente mais tarde');
            }

            this.toastService.show({ body: 'Ocorreu um erro ao excluir o usuário, tente novamente mais tarde', color: 'danger' });
          }).finally(() => {
            loading.dismiss();
          });
        }
      }
    })

    this.appendSubscription(sub);
  }

  private toastErrorSave() {
    this.toastService.show({ body: 'Ocorreu um erro ao salvar o usuário, tente novamente mais tarde', color: 'danger' });
  }

  dismiss(reason: string = null) {
    this.activeModal.dismiss(reason);
  }
}
