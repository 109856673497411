import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { Usuario, UsuarioSession } from '../model/usuario.model';
import { ApiConstraintGetUsuarios, ApiResponsePatch } from '../model/api.model';
import { DefaultPageSize, session$ } from '../globals/globals';
import { BaseHttpService } from './base-http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends BaseHttpService {

  getUsuarios(constraints?: ApiConstraintGetUsuarios) {
    const storageService = new StorageService();
    const params = Object.fromEntries(Object.entries({
      Termo: constraints?.Termo || null,
      Page: constraints?.Page || 1,
      PageSize: constraints?.PageSize || storageService.get('PAGE_SIZE_LIST_USUARIOS') || DefaultPageSize.usuarios,
      FirstRow: constraints?.FirstRow || null,
    } as ApiConstraintGetUsuarios).filter(x => x[1]))
    return this.get<Usuario>(`${environment.api.endpoints.usuarios}`, params);
  }

  getUserById(id: string) {
    return this.getById<Usuario>(`${environment.api.endpoints.usuarios}`, id);
  }

  insertUsuario(usuario: Partial<Usuario>) {
    return this.post<Usuario>(`${environment.api.endpoints.usuarios}`, usuario);
  }

  updateUser(usuario: Partial<Usuario>) {
    return this.put<Usuario>(`${environment.api.endpoints.usuarios}/${usuario.id}`, usuario);
  }

  deleteUser(id: string) {
    return this.delete<Usuario>(`${environment.api.endpoints.usuarios}`, id);
  }

  changePassword(senhaAtual: string, novaSenha: string) {
    const idUsuario = session$?.value?.usuario?.id;
    const body = {
      idUsuario,
      senhaAtual,
      novaSenha
    };
    return this.patch<typeof body, ApiResponsePatch<UsuarioSession & Pick<Usuario, 'telefone' | 'status'>>>(`${environment.api.endpoints.usuarios}/${idUsuario}/senha`, body);
  }
}
