import { Component, Inject } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PeriodoLancamentos, PeriodosLancamentos } from '../../../model/periodo.model';
import { CurrencyPipe, NgClass, PercentPipe } from '@angular/common';
import { CardWithIconComponent } from '../../../custom-components/card-with-icon/card-with-icon.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { DropdownComponent } from '../../../custom-components/dropdown/dropdown.component';
import { PeriodoService } from '../../../services/periodo.service';
import { ToastService } from '../../../services/toast.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StorageService } from '../../../services/storage.service';
import { DefaultPageSize, DelayShowTooltip, PaginatorPageSize, PeriodoLancamentosSelecionado$, RGBColors } from '../../../globals/globals';
import { ProgressBarComponent, ProgressBarConfig } from '../../../custom-components/progress-bar/progress-bar.component';
import { DespesaService } from '../../../services/despesa.service';
import { Despesa, Despesas, TipoAplicacaoDespesa, TipoDespesa } from '../../../model/despesa.model';
import { PaginatorComponent, PaginatorOpts } from '../../../custom-components/paginator/paginator.component';
import { ModalService } from '../../../services/modal.service';
import { ApiConstraintGetDespesas, ApiResponseError } from '../../../model/api.model';
import { cardsSmall } from '../dashboard.component';
import { DashboardService } from '../../../services/dashboard.service';
import { SmallCardDashboard } from '../modal-card-small-dashboard';

type TForm = {
  id: FormControl<string>,
  nome: FormControl<string>,
  tipo: FormControl<TipoDespesa>,
  tipoAplicacao: FormControl<TipoAplicacaoDespesa>,
  valor: FormControl<number>,
};

@Component({
  selector: 'app-despesas-venda-overlay',
  standalone: true,
  imports: [
    CardWithIconComponent,
    SmallLabelComponent,
    CurrencyPipe,
    PercentPipe,
    IconComponent,
    ColorDirective,
    DropdownComponent,
    FormsModule,
    ProgressBarComponent,
    PaginatorComponent,
    NgbTooltipModule,
    ReactiveFormsModule,
    NgClass,
  ],
  providers: [CurrencyPipe, PercentPipe,],
  templateUrl: './despesas-venda-overlay.component.html',
  styleUrl: './despesas-venda-overlay.component.scss',
})
export class DespesasVendaOverlayComponent extends BaseClass() {
  despesasVenda: SmallCardDashboard;

  periodo: PeriodoLancamentos;
  periodos: PeriodosLancamentos;

  controlPeriodo: FormControl = new FormControl();

  operationInProgress: boolean = true;

  progressBarConfig: ProgressBarConfig<'stacked'> = {
    values: null,
  };

  paginatorOpts: PaginatorOpts;

  despesas: Despesas;

  showForm: boolean = false;

  DelayShowTooltip = DelayShowTooltip;

  form: FormGroup<TForm> = new FormGroup({
    id: new FormControl<string>(null),
    nome: new FormControl<string>(null),
    tipoAplicacao: new FormControl<TipoAplicacaoDespesa>(TipoAplicacaoDespesa.VALOR),
    tipo: new FormControl<TipoDespesa>(TipoDespesa.VENDA),
    valor: new FormControl<number>(null),
  });

  TipoAplicacaoDespesa = TipoAplicacaoDespesa;

  constructor(
    @Inject('data') private data: { periodo: PeriodoLancamentos },
    private activeModal: NgbActiveModal,
    private periodoService: PeriodoService,
    private dashboardService: DashboardService,
    private toastService: ToastService,
    private storageService: StorageService,
    private despesaService: DespesaService,
    private modalService: ModalService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
  ) {
    super();

    this.despesasVenda = {
      ...cardsSmall().find(card => card.modal === 'despesas-venda'),
      pipe: null,
    };

    if (this.data) this.periodo = data?.periodo;
  }

  ngOnInit() {
    this.getPeriodos();

    if (!this.periodo?.id) {
      this.toastService.show({ body: 'Período não encontrado', color: 'danger' });
      this.dismiss();
    }

    this.getDespesas();
    this.getDadosGrafico();
  }

  getDadosGrafico() {
    this.despesaService.getDespesasGrafico(this.periodo.id, TipoDespesa.VENDA).then((res) => {
      if (!res) return this.toastService.show({ body: 'Erro ao buscar despesas', color: 'danger' });
      this.progressBarConfig.values = [];
      const total = res.reduce((acc, despesa) => acc + despesa.valor, 0);
      let indexColors = 0;
      res.forEach((despesa) => {
        if (indexColors >= RGBColors.length) indexColors = 0;
        const color = RGBColors[indexColors];
        const bar: ProgressBarConfig<'single'> = {
          value: despesa.valor / total * 100,
          label: (despesa.tipoAplicacao === TipoAplicacaoDespesa.PERCENTUAL ? this.percentPipe.transform(despesa.valor / 100) : this.currencyPipe.transform(despesa.valor)) + ' - ' + despesa.nome,
          showValue: false,
          color,
        }
        indexColors++;
        this.progressBarConfig.values.push(bar);
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  async getDespesas() {
    if (!this.periodo?.id) return this.toastService.show({ body: 'Período não encontrado', color: 'danger' });

    this.operationInProgress = true;

    try {
      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_DESPESAS') || PaginatorPageSize.slice(-1)[0];
      const constraints: ApiConstraintGetDespesas = {
        Page: this.paginatorOpts?.page,
        PageSize,
        Tipo: TipoDespesa.VENDA,
      };

      const despesasPeriodo = await this.despesaService.getDespesasPeriodo(this.periodo.id, constraints);

      if (despesasPeriodo.errors)
        return this.toastService.show({ body: 'Erro ao buscar despesas', color: 'danger' });

      this.dashboardService.getDashboardData(this.periodo.id).then((dashboardData) => {
        this.despesasVenda = {
          ...cardsSmall().find(card => card.modal === 'despesas-venda'),
          pipe: null,
          value: `${this.currencyPipe.transform(dashboardData.totalDespesasVendas) || this.currencyPipe.transform(0)}  (${this.percentPipe.transform(dashboardData.percentualTotalDespesasVendas / 100, '1.0-2')})`,
        }
      });

      const { page, pages, pageSize, rowsCount, data } = despesasPeriodo;
      this.despesas = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      this.toastService.show({ body: 'Erro ao buscar despesas', color: 'danger' });
    } finally {
      this.operationInProgress = false;
    }
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getDespesas();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_DESPESAS', size);
    this.getDespesas();
  }

  async getPeriodos() {
    try {
      const res = await this.periodoService.getPeriodos({ Simulacao: false, PageSize: DefaultPageSize.periodos })
      if (res.data) {
        this.periodos = res.data;
        this.controlPeriodo.setValue(this.periodo);
      }
    } catch (error) {
      console.log(error);
      this.toastService.show({ body: 'Erro ao buscar os períodos', color: 'danger' });
      this.dismiss();
    } finally {
      this.operationInProgress = false;
    }
  }

  selectPeriodo(periodo: PeriodoLancamentos) {
    if (!periodo) return;
    this.controlPeriodo.setValue(periodo);
    this.periodo = periodo;
    this.getPeriodos();
    this.getDespesas();
    this.getDadosGrafico();
  }

  async saveForm() {
    const formValue = this.form.value;

    if (!formValue.nome) return this.toastService.show({ body: 'Nome da despesa é obrigatório', color: 'danger' });
    //if (!formValue.valor) return this.toastService.show({ body: 'Valor da despesa é obrigatório', color: 'danger' });
    if (formValue.valor == null || formValue.valor < 0) return this.toastService.show({ body: 'Valor da despesa deve ser maior ou igual a 0', color: 'danger' });
    if (formValue.tipoAplicacao === TipoAplicacaoDespesa.PERCENTUAL && formValue.valor > 100) return this.toastService.show({ body: 'Valor da despesa deve ser menor ou igual a 100', color: 'danger' });

    const despesa: Despesa = {
      id: formValue.id || null,
      idPeriodo: this.periodo.id,
      nome: formValue.nome,
      tipo: formValue.tipo,
      valor: formValue.valor,
      tipoAplicacao: formValue.tipoAplicacao,
    };

    const loading = this.modalService.presentLoading('Salvando despesa...');
    this.operationInProgress = true;
    try {
      await (despesa.id ? this.despesaService.updateDespesaPeriodo(this.periodo.id, despesa) : this.despesaService.insertDespesaPeriodo(this.periodo.id, despesa));
      this.toastService.show({ body: 'Despesa salva com sucesso', color: 'success' });
      this.getDespesas();
      this.getDadosGrafico();
      PeriodoLancamentosSelecionado$.next(this.periodo); // forçar atualização da dashboad
      this.toggleFormVisibility();
    } catch (error) {
      console.error(error);
      const messages = (error as ApiResponseError<'validation'>).error?.validations?.map((v) => v.message);
      if (messages?.length > 0)
        this.modalService.presentAlert('Erro ao salvar a despesa', messages.join('\n'));
      else
        this.toastService.show({ body: 'Erro ao salvar a despesa', color: 'danger' });
    } finally {
      this.operationInProgress = false;
      loading.close();
    }
  }

  excluirDespesa() {
    const confirm = this.modalService.presentConfirm('Excluir despesa', 'Deseja realmente excluir a despesa ' + this.form.value.nome + '?', null, 'rosa');
    const sub = confirm.closed.subscribe({
      next: async (res) => {
        if (res) {
          this.operationInProgress = true;
          const loading = this.modalService.presentLoading('Excluindo despesa...');
          try {
            await this.despesaService.deleteDespesaPeriodo(this.periodo.id, this.form.value.id);
            this.toastService.show({ body: 'Despesa excluída com sucesso', color: 'success' });
            this.getDespesas();
            this.getDadosGrafico();
            PeriodoLancamentosSelecionado$.next(this.periodo); // forçar atualização da dashboad
            this.toggleFormVisibility();
          } catch (error) {
            console.error(error);
            this.toastService.show({ body: 'Erro ao excluir a despesa', color: 'danger' });
          } finally {
            this.operationInProgress = false;
            loading.close();
          }
        }
      }
    })
    this.appendSubscription(sub);
  }

  toggleFormVisibility(despesa: Despesa = null) {
    if (despesa) {
      this.form.patchValue({
        id: despesa.id,
        nome: despesa.nome,
        tipo: despesa.tipo,
        tipoAplicacao: despesa.tipoAplicacao,
        valor: despesa.valor,
      });

      this.showForm = true;
    } else {
      this.form.reset({
        nome: null,
        tipo: TipoDespesa.VENDA,
        tipoAplicacao: TipoAplicacaoDespesa.VALOR,
        valor: null,
      });

      this.showForm = !this.showForm;
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}

