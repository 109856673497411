<div id="header" class="d-flex gap-2 flex-column">
  <div id="logo">
    <img class="logo" src="assets/logo-sm.png" alt="Smartis Logo" />
  </div>
  <h1 id="title" class="fw-700">{{ title }}</h1>
  @if (subtitle) {
    <h6 id="subtitle" class="fw-400" [innerHTML]="subtitle"></h6>
  }
</div>

<hr />
