<div id="container" [style.--gridTemplateColumns]="gridTemplateColumns" [style.--gridTemplateAreas]="'&quot;' + gridTemplateAreas + '&quot;'">
  <div id="list" class="rounded-4 overflow-x-auto max-width-100">
    @if (isDesktop) {
      <div id="header" class="list-row w-fit-content min-width-100" [class.rounded-top-4]="isDesktop" [class.operation-in-progress]="operationInProgress$ | async">
        @if (columns.includes("checkbox")) {
          <div class="cell checkbox">
            <input type="checkbox" class="form-check-input" />
          </div>
        }
        @if (columns.includes("nome")) {
          <div class="cell nome allow-ordering" [class.ordering]="ordering.OrderBy === 'nome'" [ngClass]="ordering.OrderBy === 'nome' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''" (click)="orderBy('nome')">
            <label>Nome</label>
          </div>
        }
        @if (columns.includes("uni")) {
          <div
            class="cell unidade allow-ordering"
            [class.ordering]="ordering.OrderBy === 'unidademedida'"
            [ngClass]="ordering.OrderBy === 'unidademedida' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('unidademedida')"
          >
            <label>Unidade</label>
          </div>
        }
        @if (columns.includes("qtd")) {
          <div
            class="cell qtd allow-ordering"
            [class.ordering]="ordering.OrderBy === 'quantidade'"
            [ngClass]="ordering.OrderBy === 'quantidade' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('quantidade')"
          >
            <label>Quant.</label>
          </div>
        }
        @if (columns.includes("custo")) {
          <div
            class="cell preco-custo allow-ordering"
            [class.ordering]="ordering.OrderBy === 'precocusto'"
            [ngClass]="ordering.OrderBy === 'precocusto' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('precocusto')"
          >
            <label>R$ Custo</label>
          </div>
        }
        @if (columns.includes("venda")) {
          <div
            class="cell preco-venda allow-ordering"
            [class.ordering]="ordering.OrderBy === 'precovenda'"
            [ngClass]="ordering.OrderBy === 'precovenda' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('precovenda')"
          >
            <label>R$ Venda</label>
          </div>
        }
        @if (columns.includes("rateio")) {
          <div
            class="cell rateio allow-ordering"
            [class.ordering]="ordering.OrderBy === 'PercentualRateioCusto'"
            [ngClass]="ordering.OrderBy === 'PercentualRateioCusto' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('PercentualRateioCusto')"
          >
            <label>Rateio</label>
          </div>
        }     
        @if (columns.includes("df")) {
          <div
            class="cell df allow-ordering"
            [class.ordering]="ordering.OrderBy === 'DespesasFixas'"
            [ngClass]="ordering.OrderBy === 'DespesasFixas' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('DespesasFixas')"
          >
            <label>DF</label>
          </div>
        }
        @if (columns.includes("calc")) {
          <div
            class="cell calc allow-ordering"
            [class.ordering]="ordering.OrderBy === 'PrecoCalculadoSmartis'"
            [ngClass]="ordering.OrderBy === 'PrecoCalculadoSmartis' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('PrecoCalculadoSmartis')"
          >
            <label>Preço Ideal</label>
          </div>
        }        
        
        @if (columns.includes("mc")) {
          <div
            class="cell mc allow-ordering"
            [class.ordering]="ordering.OrderBy === 'PercentualMargemContribuicao'"
            [ngClass]="ordering.OrderBy === 'PercentualMargemContribuicao' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('PercentualMargemContribuicao')"
          >
            <label>MC</label>
          </div>
        }
        @if (columns.includes("faturamentoTotal")) {
          <div
            class="cell resultado allow-ordering"
            [class.ordering]="ordering.OrderBy === 'faturamentoAtual'"
            [ngClass]="ordering.OrderBy === 'faturamentoAtual' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('faturamentoAtual')"
          >
            <label>Fat. Total</label>
          </div>
        }
          @if (columns.includes("lucroAtual")) {
            <div
              class="cell resultado allow-ordering"
              [class.ordering]="ordering.OrderBy === 'lucroAtual'"
              [ngClass]="ordering.OrderBy === 'lucroAtual' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
              (click)="orderBy('lucroAtual')"
            >
              <label>Lucro Unit.</label>
            </div>
          }
          @if (columns.includes("lucroTotal")) {
            <div
              class="cell variacao allow-ordering"
              [class.ordering]="ordering.OrderBy === 'LucroTotalAtual'"
              [ngClass]="ordering.OrderBy === 'LucroTotalAtual' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
              (click)="orderBy('LucroTotalAtual')"
            >
              <label>Lucro Total</label>
            </div>
          }
        @if (columns.includes("despesasV")) {
          <div
            class="cell despesas-vendas allow-ordering"
            [class.ordering]="ordering.OrderBy === 'percentualDespesasVenda'"
            [ngClass]="ordering.OrderBy === 'percentualDespesasVenda' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('percentualDespesasVenda')"
          >
            <label>Despesas de Vendas</label>
          </div>
        }
        @if (columns.includes("margem")) {
          <div
            class="cell margem-lucro allow-ordering"
            [class.ordering]="ordering.OrderBy === 'percentualLucroAtual'"
            [ngClass]="ordering.OrderBy === 'percentualLucroAtual' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('percentualLucroAtual')"
          >
            <label>Margem</label>
          </div>
        }
        @if (columns.includes("categoria")) {
          <div
            class="cell categoria allow-ordering"
            [class.ordering]="ordering.OrderBy === 'nomeCategoria'"
            [ngClass]="ordering.OrderBy === 'nomeCategoria' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('nomeCategoria')"
          >
            <label>Categoria</label>
          </div>
        }
        @if (columns.includes("tags")) {
          <div class="cell tags">
            <label>Tags</label>
          </div>
        }
        @if (columns.includes("precificacao")) {
          <div
            class="cell precificacao allow-ordering"
            [class.ordering]="ordering.OrderBy === 'precificacao'"
            [ngClass]="ordering.OrderBy === 'precificacao' ? (ordering.OrderAsc ? 'crescente' : 'decrescente') : ''"
            (click)="orderBy('precificacao')"
          >
            <label>Precificação</label>
          </div>
        }

        @if (columns.includes("status") || columns.includes("edit") || columns.includes("expand")) {
          <div class="cell actions">
            <label>Ações</label>
          </div>
        }
      </div>
    }

    <div class="list-content" ngbAccordion [closeOthers]="true">
      @for (produto of produtos; track $index) {
        <div ngbAccordionItem #accordionItem="ngbAccordionItem" class="rounded-4">
          <h2 ngbAccordionHeader>
            <div class="list-row w-fit-content min-width-100" [ngClass]="{ 'sem-precificacao': !(produto | precificacaoProdutoFace), 'rounded-4': !isDesktop }">
              @if (isDesktop && columns.includes("checkbox")) {
                <div class="cell checkbox">
                  <input #check type="checkbox" class="form-check-input" (change)="checkedChanged.emit({ produto, checked: check.checked })" />
                </div>
              }
              @if (columns.includes("nome")) {
                <div class="cell nome fw-bold" [class.pb-0]="!isDesktop">
                  <label [color]="!isDesktop ? 'primary' : accordionItem.collapsed ? 'titulo' : null" [ngClass]="{ 'd-flex gap-3 align-items-center justify-content-between w-100': !isDesktop }">
                    <span class="cursor-pointer" (click)="$event.preventDefault(); detalhesProduto.emit(produto)"> {{ produto.nome }} </span>
                    @if (!isDesktop) {
                      <div class="d-flex align-items-end">
                        <button class="btn p-0 me-0 btn clear" color="info" ngbAccordionButton></button>
                      </div>
                    }
                  </label>
                </div>
              }
              @if (isDesktop) {
                @if (columns.includes("uni")) {
                  <div class="cell unidade">
                    <label>
                      @if (!isDesktop) {
                        <span class="fw-bold"> Unidade </span>
                      }
                      <span> {{ produto.unidadeMedida }}</span>
                    </label>
                  </div>
                }
                @if (columns.includes("qtd")) {
                  <div class="cell qtd">
                    <label>
                      @if (!isDesktop) {
                        <span class="fw-bold"> Quantidade </span>
                      }
                      <span> {{ produto.quantidade }}</span>
                    </label>
                  </div>
                }
                @if (columns.includes("custo")) {
                  <div class="cell preco-custo">
                    <label>
                      @if (!isDesktop) {
                        <span class="fw-bold"> R$ Custo </span>
                      }
                      <span> {{ produto.precoCusto | currency }}</span>
                    </label>
                  </div>
                }
              }

              @if (isDesktop || accordionItem.collapsed) {
                @if (columns.includes("venda")) {
                  <div class="cell preco-venda" [class.pt-1]="!isDesktop">
                    <label>
                      @if (!isDesktop) {
                        <span class="fw-bold"> R$ Venda </span>
                      }
                      <span> {{ produto.precoVenda | currency }} </span>
                    </label>
                  </div>
                }

                @if (columns.includes("margem")) {
                  <div class="cell margem-lucro" [class.pt-1]="!isDesktop">
                    <label>
                      @if (!isDesktop) {
                        <span class="fw-bold"> Margem </span>
                      }
                      <span> {{ (produto.percentualLucroAtual | percent: "1.0-2") || "-" }}</span>
                    </label>
                  </div>
                }
              }

              @if (isDesktop && columns.includes("rateio")) {
                <div class="cell rateio">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> Rateio: </span>
                    }
                    <span> {{ produto.percentualRateioCusto | percent: "1.0-2" }}</span>
                  </div>
                </div>
              }

              @if (isDesktop && columns.includes("df")) {
                <div class="cell df">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> DF: </span>
                    }
                    <span> {{ produto.despesasFixas | currency }}</span>
                  </div>
                </div>
              }

              @if (isDesktop && columns.includes("calc")) {
                <div class="cell calc">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> Preço Ideal: </span>
                    }
                    <span> {{ produto.precoCalculadoSmartis | currency }}</span>
                  </div>
                </div>
              }

              

              @if (isDesktop && columns.includes("mc")) {
                <div class="cell mc">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> MC: </span>
                    }
                    <span> {{ produto.percentualMargemContribuicao | percent: "1.0-2" }}</span>
                  </div>
                </div>
              }

              @if (isDesktop && columns.includes("faturamentoTotal")) {
                <div class="cell resultado">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> Fat. Total: </span>
                    }
                    <span> {{ produto.faturamentoAtual | currency }}</span>
                  </div>
                </div>
              }

              @if (isDesktop && columns.includes("lucroAtual")) {
                <div class="cell resultado">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> Lucro Unit.: </span>
                    }
                    <span> {{ produto.lucroAtual | currency }}</span>
                  </div>
                </div>
              }

              @if (isDesktop && columns.includes("lucroTotal")) {
                <div class="cell resultado">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> Lucro Total: </span>
                    }
                    <span> {{ produto.lucroTotalAtual | currency }}</span>
                  </div>
                </div>
              }

              @if (isDesktop && columns.includes("despesasV")) {
                <div class="cell despesas-vendas">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> Despesas de Vendas: </span>
                    }
                    <span> {{ produto.despesasVenda | currency }}</span>
                  </div>
                </div>
              }

              @if (isDesktop && columns.includes("tags")) {
                <div class="cell tags px-1">
                  <div class="d-flex align-items-center gap-2 flex-wrap">
                    @if (!isDesktop) {
                      <span class="fw-bold"> Tags: </span>
                    }
                    @if (!produto?.tags?.length) {
                      <span> - </span>
                    }
                    @for (tag of produto?.tags; track $index) {
                      <span class="badge rounded-2"> {{ tag }} </span>
                    }
                  </div>
                </div>
              }

              @if (isDesktop && columns.includes("categoria")) {
                <div class="cell categoria">
                  <div class="d-flex align-items-center gap-2">
                    @if (!isDesktop) {
                      <span class="fw-bold"> Categoria: </span>
                    }

                    @if (produto.categoria) {
                      @if (produto.categoria.icone) {
                        <icon-in-ellipse [matIcon]="produto.categoria?.icone" [matIconOutlined]="false" [bgColor]="produto.categoria?.cor" />
                      }
                      <span> {{ produto.categoria?.nome }}</span>
                    } @else {
                      <span> - </span>
                    }
                  </div>
                </div>
              }

              @if (columns.includes("precificacao") && (produto | precificacaoProdutoFace)) {
                <div class="cell precificacao">
                  <icon-in-ellipse [matIcon]="(produto | precificacaoProdutoFace)?.icon" [color]="(produto | precificacaoProdutoFace)?.color" />
                </div>
              }

              @if (isDesktop && (columns.includes("status") || columns.includes("edit") || columns.includes("expand"))) {
                <div class="cell actions d-flex gap-2">
                  @if (columns.includes("status")) {
                    <div class="form-check form-switch">
                      <input #toggle class="form-check-input" type="checkbox" role="switch" (change)="ativoToggleChange.emit({ item: produto, input: toggle })" [checked]="produto._statusToggle" />
                    </div>
                  }

                  @if (columns.includes("edit")) {
                    <button class="btn" (click)="addEditProduto.emit(produto)">
                      <ng-mat-icon name="edit" color="titulo" />
                    </button>
                  }

                  @if (columns.includes("expand")) {
                    <button class="btn w-fit-content" ngbAccordionButton></button>
                  }
                </div>
              }
              @if (!isDesktop && accordionItem.collapsed) {
                <div class="cell preco-sugerido" [class.pt-1]="!isDesktop">
                  <label>
                    @if (!isDesktop) {
                      <span class="fw-bold"> R$ Sugerido </span>
                    }
                    <span> {{ produto.precoVenda + 10 | currency }}</span>
                  </label>
                </div>
              }

              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <detalhes-produto-accordion-body [produto]="produto" (detalhesProduto)="detalhesProduto.emit(produto)" (editar)="addEditProduto.emit(produto)" />
                  </ng-template>
                </div>
              </div>
            </div>
          </h2>
        </div>
      }

      @if (operationInProgress$ | async) {
        <div class="loading">
          <operation-in-progress [absolute]="true" />
        </div>
      }

      @if (produtos?.length === 0) {
        <div class="list-row no-results w-fit-content">
          <div class="cell justify-content-center" [class.rounded-4]="!isDesktop">
            <label> Nenhum produto encontrado </label>
          </div>
        </div>
      }
    </div>
  </div>

  <paginator
    [page]="paginatorOpts?.page"
    [pages]="paginatorOpts.pages"
    [pageSize]="paginatorOpts.pageSize"
    [rowsCount]="paginatorOpts.rowsCount"
    [isDisabled]="(operationInProgress$ | async) || !produtos?.length"
    (updatePageSize)="_updatePageSize($event)"
    (navigatePage)="_navigatePage($event)"
  />
</div>
