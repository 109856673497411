import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'custom-icon',
  standalone: true,
  imports: [NgClass],
  templateUrl: './custom-icon.component.html',
  styleUrl: './custom-icon.component.scss'
})
export class CustomIconComponent {
  @Input({ required: true }) name: string;
  @Input() size100: boolean;
  @Input() class: string = '';
}
