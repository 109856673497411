import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, CurrencyPipe, NgClass, PercentPipe } from '@angular/common';
import { OperationInProgressComponent } from '../operation-in-progress/operation-in-progress.component';
import { ColorDirective } from '../../directives/color.directive';
import { IconInEllipseComponent } from '../icon-in-ellipse/icon-in-ellipse.component';
import { IconComponent } from '../icon/icon.component';
import { PaginatorComponent, PaginatorOpts } from '../paginator/paginator.component';
import { StorageService } from '../../services/storage.service';
import { FormsModule } from '@angular/forms';
import { NgxMaskPipe } from 'ngx-mask';
import { Usuario } from '../../model/usuario.model';
import { StatusAtivoEnum } from '../../model/custom-types';
import { aGreaterThenB } from '../../globals/utils';

@Component({
  selector: 'table-usuario-list',
  standalone: true,
  imports: [
    NgClass,
    OperationInProgressComponent,
    AsyncPipe,
    ColorDirective,
    CurrencyPipe,
    PercentPipe,
    IconInEllipseComponent,
    IconComponent,
    PaginatorComponent,
    FormsModule,
    NgxMaskPipe,
  ],
  templateUrl: './table-usuario-list.component.html',
  styleUrl: './table-usuario-list.component.scss'
})
export class TableUsuarioListComponent extends BaseClass() {

  @Input({ required: true }) usuarios: Array<UsuarioLocalList>;
  @Input({ required: true }) operationInProgress$: BehaviorSubject<boolean>;
  @Input({ required: true }) paginatorOpts: PaginatorOpts;

  @Input() columns: Array<ColumnTableUsuarioList>;

  @Output() ativoToggleChange: EventEmitter<{ usuario: UsuarioLocalList, input: HTMLInputElement }> = new EventEmitter();
  @Output() addEditUsuario: EventEmitter<UsuarioLocalList> = new EventEmitter();
  @Output() updatePageSize: EventEmitter<number> = new EventEmitter();
  @Output() navigatePage: EventEmitter<number> = new EventEmitter();

  @Output() checkedChanged: EventEmitter<{ usuario: UsuarioLocalList, checked: boolean }> = new EventEmitter();

  COLUMNS_WIDTH = ["33px", "auto", "250px", "250px", "60px", "90px",];
  COLUMNS_AREAS: Array<ColumnTableUsuarioList | 'actions'> = ['checkbox', 'nome', 'email', 'telefone', 'status', 'actions'];

  gridTemplateColumns = this.COLUMNS_WIDTH.join(' ');
  gridTemplateAreas = this.COLUMNS_AREAS.join(' ');

  ordering: Ordering = {
    by: 'nome',
    direction: 'up',
  };

  constructor(
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnChanges() {
    if (this.usuarios.length) this.setUsuarios();

    const displayColumns = this.COLUMNS_AREAS.filter(x => x === 'actions' ? this.columns.filter(y => y === 'status' || y === 'edit').length >= 1 : this.columns.includes(x));
    this.gridTemplateAreas = displayColumns.join(' ');
    this.gridTemplateColumns = displayColumns.map(c => this.COLUMNS_WIDTH[this.COLUMNS_AREAS.indexOf(c)]).join(' ');
  }

  ngOnInit() {
    this.setUsuarios();
  }

  private async setUsuarios() {
    this.usuarios = this.usuarios?.map(p => ({
      ...p,
      _statusToggle: p.status === StatusAtivoEnum.ATIVO,
    }));
  }

  orderBy(orderBy: OrderBy) {
    this.ordering.direction = this.ordering.by === orderBy ? this.ordering.direction === 'down' ? 'up' : 'down' : 'up';
    this.ordering.by = orderBy;
    this.order();
  }

  private order() {
    const ordered = this.usuarios.sort((e1, e2) => {
      switch (this.ordering.by) {
        case 'nome':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.nome, e2.nome);
          return aGreaterThenB(e2.nome, e1.nome);

        case 'email':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.email, e2.email);
          return aGreaterThenB(e2.email, e1.email);

        case 'telefone':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.telefone, e2.telefone);
          return aGreaterThenB(e2.telefone, e1.telefone);
      }
    }).map(p => ({ ...p, _statusToggle: p.status === StatusAtivoEnum.ATIVO }));

    this.usuarios = ordered;
  }

  _navigatePage(page: number) {
    this.navigatePage.emit(page);
  }

  _updatePageSize(size: number) {
    this.updatePageSize.emit(size);
    this.storageService.set('PAGE_SIZE_LIST_USUARIOS', size);
  }

}

export type UsuarioLocalList = Usuario & {
  _statusToggle: boolean,
  _checked: boolean,
};

export type OrderBy = 'nome' | 'email' | 'telefone';
type OrderingDirection = 'up' | 'down';
export type Ordering = {
  by: OrderBy;
  direction: OrderingDirection;
};

export type ColumnTableUsuarioList = 'checkbox' | 'nome' | 'email' | 'telefone' | 'status' | 'edit';

export const ColumnsTableUsuarioListToDisplay: { [key in ColumnTableUsuarioList]: string } = {
  checkbox: null,

  nome: 'Nome',
  email: 'E-mail',
  telefone: 'Telefone',

  status: null,
  edit: null,
};
