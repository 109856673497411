import { Component } from '@angular/core';
import { NgMatIconComponent } from '../../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { BaseClass } from '../../../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { downloadModeloImportacaoProdutos, selectPlanilhaImportacaoProdutos } from '../../../../../globals/utils';
import { ToastService } from '../../../../../services/toast.service';
import { ColorDirective } from '../../../../../directives/color.directive';

@Component({
  selector: 'app-importar-produtos-overlay',
  standalone: true,
  imports: [
    NgMatIconComponent,
    SmallLabelComponent,
    ColorDirective,
  ],
  templateUrl: './importar-produtos-overlay.component.html',
  styleUrl: './importar-produtos-overlay.component.scss'
})
export class ImportarProdutosOverlayComponent extends BaseClass() {

  downloadModelo = downloadModeloImportacaoProdutos;
  file: File;

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
  ) {
    super();
  }

  async selectFile() {
    const file = await selectPlanilhaImportacaoProdutos();
    if (!file) return;
    this.file = file;
  }

  confirmar() {
    if (!this.file) return this.toastService.show({ body: 'Selecione um arquivo para importar', color: 'warning' });
    this.activeModal.close({ file: this.file });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
