<nav class="navbar bg-body-tertiary h-100" [class.master]="usuario.master">
  <div class="container p-0" [ngClass]="{ 'd-flex flex-column align-items-start': !isDesktop }">
    <div class="navbar-brand d-flex align-items-center gap-4" [class.px-4]="!isDesktop">
      <a href="/">
        <img class="logo" [src]="isDesktop ? 'assets/logo-sm.png' : 'assets/logo-pequena.png'" alt="Smartis Logo" height="24" />
      </a>

      <span id="title" class="fw-600"> {{ currentPage?.title }} </span>
    </div>

    @if (!usuario.master) {
      <dropdown
        [isDisabled]="!periodos?.length"
        [inline]="true"
        [label]="isDesktop ? 'Período/Simulação' : null"
        [placeholder]="periodos?.length === 0 ? 'Nenhum período cadastrado' : 'Selecione um período'"
        [options]="periodos"
        [control]="control"
        displayField="nomeDropdown"
        (optionSelected)="selectPeriodo($event)"
      />
    }
  </div>
</nav>
