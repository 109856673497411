<div id="container">
  <div id="content" class="m-4">
    <div id="header">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">{{ produto?.id ? "Editar Produto" : "Novo Produto" }}</h3>
        <button class="btn" (click)="dismiss()">
          <icon name="x" />
        </button>
      </div>

      <!-- <div id="subtitle">
        <p class="fw-500" color="cinza">Aqui vem as informações explicando o que é o termo em questão, links com chamadas para vídeos explicativos, artigos, etc.</p>
      </div> -->
    </div>

    <div id="form" class="mt-4">
      <div class="d-flex justify-content-between align-items-end">
        <div class="form-group d-flex flex-column gap-2">
          <small-label> Produto Ativo </small-label>

          <div class="btn-group" role="group">
            <button type="button" class="btn" [class]="'btn-' + (produto?.status === ProdutoStatus.ATIVO ? '' : 'outline-') + 'info'" (click)="produto.status = ProdutoStatus.ATIVO">Ativo</button>
            <button type="button" class="btn" [class]="'btn-' + (produto?.status === ProdutoStatus.INATIVO ? '' : 'outline-') + 'info'" (click)="produto.status = ProdutoStatus.INATIVO">Inativo</button>
          </div>
        </div>

        <!-- <div>
          <button class="btn btn-outline-info gap-3">
            <icon name="copy-info" />
            <span> Copiar de um existente </span>
          </button>
        </div> -->
      </div>

      <div class="form-group mt-3 d-flex flex-column gap-2">
        <small-label> Nome <span>*</span> </small-label>
        <input type="text" class="form-control" id="nome" placeholder="Insira o nome do produto" [(ngModel)]="produto.nome" />
      </div>

      <div class="d-flex justify-content-between gap-4 align-items-end mt-3">
        <div class="form-group w-100">
          <dropdown label="Categoria" [options]="categorias" displayField="nome" [control]="selectedCategoria" (optionSelected)="selectCategoria($event)" [isDisabled]="!categorias" [classes]="['fw-normal']" />
        </div>

        <div class="form-group d-flex flex-column gap-2" id="margem">
          <small-label> Margem de lucro </small-label>
          <div class="d-flex align-items-center gap-2">
            <button [disabled]="produto.margemLucro <= 0" type="button" class="round-btn-icon btn btn-info" (click)="margemDecrement()">
              <icon name="minus-white" />
            </button>
            <input type="number" class="form-control" id="margem" placeholder="100%" [(ngModel)]="produto.margemLucro" [max]="100" [min]="0" />
            <button [disabled]="produto.margemLucro >= 100" type="button" class="round-btn-icon btn btn-info" (click)="margemIncrement()">
              <icon name="plus-white" />
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between gap-5 align-items-end mt-3">
        <div class="form-group d-flex flex-column gap-2">
          <small-label> Unidade de medida </small-label>
          <input type="text" class="form-control" id="medida" placeholder="Digite a unidade" [(ngModel)]="produto.unidadeMedida" />
        </div>

        <div class="form-group d-flex flex-column gap-2">
          <small-label> Quantidade </small-label>
          <input type="number" class="form-control" id="qtd" placeholder="Insira a quantidade" [(ngModel)]="produto.quantidade" />
        </div>
      </div>

      <div class="d-flex justify-content-between gap-5 align-items-end mt-3">
        <div class="input-with-tag d-flex flex-column gap-2">
          <small-label> Preço de custo </small-label>
          <div class="input-group">
            <label class="input-group-text fw-bold" color="neutro">R$</label>
            <input type="number" class="form-control text-center" placeholder="999.99" [(ngModel)]="produto.precoCusto" />
          </div>
        </div>

        <div class="input-with-tag d-flex flex-column gap-2">
          <small-label> Preço de venda </small-label>
          <div class="input-group">
            <label class="input-group-text fw-bold" [color]="produto.precoVenda < produto.precoIdeal ? 'danger' : 'neutro'">R$</label>
            <input type="number" class="form-control text-center" placeholder="999.99" [(ngModel)]="produto.precoVenda" />
          </div>
        </div>

        <div class="d-flex flex-column gap-2">
          <small-label> Preço ideal calculado </small-label>
          <label class="fw-bold"> {{ (produto.precoIdeal | currency) || "R$ -" }} </label>
        </div>
      </div>

      <div class="d-flex gap-2 flex-wrap align-items-end mt-3">
        <div class="form-group d-flex flex-column gap-2">
          <small-label> Tags </small-label>
          <input type="text" class="form-control" id="tag" placeholder="Insira uma nova tag" [TagsInput]="tags" />
        </div>

        @if (tags?.length > 0) {
          <section id="tags-list" class="d-flex gap-2 flex-basis-100 flex-wrap">
            @for (tag of tags; track $index) {
              <badge [text]="tag" color="info" [showCloseButton]="true" (close)="removeTag($index)" />
            }
          </section>
        }
      </div>
    </div>

    <div id="buttons" class="d-flex justify-content-end mt-4" [ngClass]="{ 'gap-4': isDesktop, 'flex-column gap-2': !isDesktop }">
      @if (produto?.id) {
        <button type="button" class="px-4 btn btn-danger" (click)="excluir()">Excluir</button>
      }

      <button type="button" class="px-4 btn btn-outline-info" (click)="dismiss()">Cancelar</button>

      <button type="button" class="px-4 btn btn-info" (click)="salvar()">Salvar Produto</button>
    </div>
  </div>
</div>
