import { Component, Inject } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PeriodoLancamentos, PeriodosLancamentos } from '../../../model/periodo.model';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { CardWithIconComponent } from '../../../custom-components/card-with-icon/card-with-icon.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { DropdownComponent } from '../../../custom-components/dropdown/dropdown.component';
import { PeriodoService } from '../../../services/periodo.service';
import { ToastService } from '../../../services/toast.service';
import { FormControl, FormsModule } from '@angular/forms';
import { DefaultPageSize } from '../../../globals/globals';
import { DashboardService } from '../../../services/dashboard.service';
import { cardsSmall } from '../dashboard.component';
import { SmallCardDashboard } from '../modal-card-small-dashboard';

@Component({
  selector: 'app-margem-contribuicao-overlay',
  standalone: true,
  imports: [CardWithIconComponent, SmallLabelComponent, CurrencyPipe, PercentPipe, IconComponent, ColorDirective, DropdownComponent, FormsModule],
  providers: [CurrencyPipe, PercentPipe],
  templateUrl: './margem-contribuicao-overlay.component.html',
  styleUrl: './margem-contribuicao-overlay.component.scss'
})
export class MargemContribuicaoOverlayComponent extends BaseClass() {
  margemContribuicao: SmallCardDashboard;

  periodo: PeriodoLancamentos;
  periodos: PeriodosLancamentos;

  controlPeriodo: FormControl = new FormControl();

  operationInProgress: boolean = true;

  constructor(
    @Inject('data') private data: Record<string, any>,
    private activeModal: NgbActiveModal,
    private periodoService: PeriodoService,
    private dashboardService: DashboardService,
    private toastService: ToastService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
  ) {
    super();

    if (this.data) {
      this.periodo = data['periodo'];
      this.margemContribuicao = data['data'];
      this.margemContribuicao.pipe = cardsSmall().find((c) => c.modal === 'margem-contribuicao').pipe;
    }
  }

  ngOnInit() {
    this.getPeriodos();

    this.controlPeriodo.setValue(this.periodo);
    this.getDadosMargemContribuicao();
  }

  async getPeriodos() {
    try {
      const res = await this.periodoService.getPeriodos({ Simulacao: false, PageSize: DefaultPageSize.periodos })
      if (res.data) {
        this.periodos = res.data;
        this.controlPeriodo.setValue(this.periodo);
      }
    } catch (error) {
      console.log(error);
      this.toastService.show({ body: 'Erro ao buscar os períodos', color: 'danger' });
      this.dismiss();
    } finally {
      this.operationInProgress = false;
    }
  }

  selectPeriodo(periodo: PeriodoLancamentos) {
    if (!periodo) return;
    this.controlPeriodo.setValue(periodo);
    this.periodo = periodo;
    this.getDadosMargemContribuicao();
  }

  getDadosMargemContribuicao() {
    this.operationInProgress = true;
    this.dashboardService.getDashboardData(this.periodo.id).then((res) => {
      if (res) {
        const { margemContribuicao, percentualMargemContribuicao } = res;
        this.margemContribuicao = {
          ...this.margemContribuicao,
          value: `${margemContribuicao > 0 ? this.currencyPipe.transform(margemContribuicao) : '-'}  (${this.percentPipe.transform(percentualMargemContribuicao / 100, '1.0-2')})`,
          pipe: null,
        };
      }
    }).finally(() => {
      this.operationInProgress = false;
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}

