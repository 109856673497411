import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Segment, SegmentsComponent } from '../../custom-components/segments/segments.component';
import { BaseClass } from '../../globals/base-class';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-produtos-wrapper',
  standalone: true,
  imports: [RouterModule, SegmentsComponent, NgClass],
  templateUrl: './produtos-wrapper.component.html',
})
export class ProdutosWrapperComponent extends BaseClass() {
  segments: Array<Segment> = [
    { path: ['/home', 'produtos', 'list'], title: 'Produtos' },
    { path: ['/home', 'produtos', 'categorias'], title: 'Categorias' },
  ];

  activeSegment: Segment;
}
