<div id="container" [style.--gridTemplateColumns]="gridTemplateColumns" [style.--gridTemplateAreas]="'&quot;' + gridTemplateAreas + '&quot;'">
  <div id="list" class="rounded-4 max-width-100 overflow-x-auto">
    @if (isDesktop) {
      <div id="header" class="list-row" [class.rounded-top-4]="isDesktop" [class.operation-in-progress]="operationInProgress$ | async">
        @if (columns.includes("checkbox")) {
          <div class="cell checkbox">
            <input type="checkbox" class="form-check-input" />
          </div>
        }
        @if (columns.includes("nome")) {
          <div class="cell nome allow-ordering" [class.ordering]="ordering.by === 'nome'" [ngClass]="ordering.by === 'nome' ? ordering.direction : ''" (click)="orderBy('nome')">
            <label>Nome</label>
          </div>
        }
        @if (columns.includes("email")) {
          <div class="cell email allow-ordering" [class.ordering]="ordering.by === 'email'" [ngClass]="ordering.by === 'email' ? ordering.direction : ''" (click)="orderBy('email')">
            <label>Email</label>
          </div>
        }
        @if (columns.includes("telefone")) {
          <div class="cell telefone allow-ordering" [class.ordering]="ordering.by === 'telefone'" [ngClass]="ordering.by === 'telefone' ? ordering.direction : ''" (click)="orderBy('telefone')">
            <label>Telefone</label>
          </div>
        }
        @if (columns.includes("status") || columns.includes("edit")) {
          <div class="cell actions">
            <label>Ações</label>
          </div>
        }
      </div>
    }

    <div class="list-content">
      @for (usuario of usuarios; track $index) {
        <div class="rounded-4 item-list">
          <h2>
            <div class="list-row py-2" [class.rounded-4]="!isDesktop">
              @if (isDesktop && columns.includes("checkbox")) {
                <div class="cell checkbox">
                  <input #check type="checkbox" class="form-check-input" (change)="checkedChanged.emit({ usuario, checked: check.checked })" />
                </div>
              }
              @if (columns.includes("nome")) {
                <div class="cell nome fw-bold">
                  <label [color]="!isDesktop ? 'primary' : 'titulo'" [ngClass]="{ 'd-flex gap-3 align-items-center justify-content-between w-100': !isDesktop }">
                    <span class="cursor-pointer" (click)="addEditUsuario.emit(usuario)"> {{ usuario.nome }} </span>
                  </label>
                </div>
              }

              @if (columns.includes("email")) {
                <div class="cell email">
                  <label>
                    <span> {{ usuario.email }}</span>
                  </label>
                </div>
              }

              @if (columns.includes("telefone")) {
                <div class="cell telefone">
                  <label>
                    <span> {{ (usuario.telefone | mask: "(00) 0000-0000||(00) 00000-0000") || "-" }} </span>
                  </label>
                </div>
              }

              @if (columns.includes("status") || columns.includes("edit")) {
                <div class="cell actions d-flex gap-2">
                  @if (columns.includes("status")) {
                    <div class="form-check form-switch">
                      <input #checkbox class="form-check-input" type="checkbox" role="switch" (change)="ativoToggleChange.emit({ usuario, input: checkbox })" [checked]="usuario._statusToggle" />
                    </div>
                  }

                  @if (columns.includes("edit")) {
                    <button class="btn" (click)="addEditUsuario.emit(usuario)">
                      <icon name="edit" />
                    </button>
                  }
                </div>
              }
            </div>
          </h2>
        </div>
      }

      @if (operationInProgress$ | async) {
        @if (usuarios?.length) {
          <div class="loading">
            <operation-in-progress [absolute]="true" />
          </div>
        } @else {
          <div class="list-row no-results">
            <div class="cell justify-content-center" [class.rounded-4]="!isDesktop">
              <label> Carregando usuários... </label>
            </div>
          </div>
        }
      }

      @if (usuarios?.length === 0) {
        <div class="list-row no-results">
          <div class="cell justify-content-center" [class.rounded-4]="!isDesktop">
            <label> Nenhum usuário encontrado </label>
          </div>
        </div>
      }
    </div>
  </div>

  <paginator
    [page]="paginatorOpts?.page"
    [pages]="paginatorOpts.pages"
    [pageSize]="paginatorOpts.pageSize"
    [rowsCount]="paginatorOpts.rowsCount"
    [isDisabled]="(operationInProgress$ | async) || !usuarios?.length"
    (updatePageSize)="_updatePageSize($event)"
    (navigatePage)="_navigatePage($event)"
  />
</div>
