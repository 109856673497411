import { Pipe, PipeTransform } from '@angular/core';
import { TipoPessoa } from '../model/empresa.model';

@Pipe({
  name: 'tipoPessoa',
  standalone: true
})
export class TipoPessoaPipe implements PipeTransform {

  transform(value: TipoPessoa, abbreviate: boolean = true): string {
    if (!value) return null;
    if (abbreviate) return value === TipoPessoa.PF ? "PF" : "PJ";
    return value === TipoPessoa.PF ? "Pessoa Física" : "Pessoa Jurídica";
  }

}
