<nav class="navbar navbar-expand bg-body-tertiary py-0 h-100 rounded-top-4 align-items-center">
  <div class="container-fluid h-100 px-3" [ngClass]="{ 'justify-content-center gap-4': !isDesktop }">
    @for (page of pages; track $index) {
      <a
        [color]="route.isActive ? 'verde' : 'cinza'"
        class="nav-link gap-1 fw-600 d-flex align-items-center flex-column"
        routerLinkActive="active"
        [routerLink]="page.path"
        [queryParams]="page.queryParams || {}"
        [routerLinkActiveOptions]="routerLinkActiveOptions"
        #route="routerLinkActive"
      >
        <icon [name]="route.isActive ? page.icon + '-green' : page.icon" />
        <span [class.text-secondary]="route.isActive"> {{ page.title }} </span>
      </a>
    }

    <a color="cinza" class="nav-link gap-1 fw-600 d-flex align-items-center flex-column" (click)="menu.toggle()">
      <icon name="align-right" />
      <span> Mais </span>
    </a>
  </div>
</nav>
