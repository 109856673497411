<div class="d-flex align-items-end justify-content-between mt-4">
  @if (showPageSizeSelector) {
    <dropdown [inline]="true" [label]="isDesktop ? (showLabels ? 'Mostrar' : '') : ''" placeholder="" [options]="pageSizes" [control]="pageSizeControl" (optionSelected)="pageSizeChanged($event)" [isDisabled]="isDisabled" />
  } @else {
    <div></div>
  }

  <div class="d-flex align-items-center gap-3">
    @if (isDesktop && showLabels) {
      <small-label> Linhas </small-label>
    }
    <ngb-pagination
      [color]="color"
      [ellipses]="true"
      [maxSize]="isDesktop ? 2 : 1"
      [rotate]="true"
      size="small"
      [boundaryLinks]="false"
      [disabled]="isDisabled"
      [(page)]="page"
      [pageSize]="showPageSizeSelector ? pageSize : rowsCount > 10 ? 10 : rowsCount"
      [collectionSize]="rowsCount"
      (pageChange)="navigatePage.emit($event)"
    />
  </div>
</div>
