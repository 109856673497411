import { Component, Input } from '@angular/core';
import { Menu } from '../../../model/menu.model';
import { getAppPages, PageAppPath, PagesApp, routerLinkActiveOptions } from '../app-pages';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';
import { BaseClass } from '../../../globals/base-class';
import { ColorDirective } from '../../../directives/color.directive';
import { Usuario } from '../../../model/usuario.model';

@Component({
  selector: 'tabbar',
  standalone: true,
  imports: [IconComponent, RouterLink, RouterLinkActive, NgClass, ColorDirective],
  templateUrl: './tabbar.component.html',
  styleUrl: './tabbar.component.scss'
})
export class TabbarComponent extends BaseClass() {

  @Input({ required: true }) usuario: Partial<Usuario>;

  private pagesToShow: Array<PageAppPath>;
  pages: PagesApp;

  routerLinkActiveOptions = routerLinkActiveOptions;

  @Input() menu: Menu;

  ngOnInit() {
    this.pagesToShow = this.usuario.master ? ['/home/empresas', '/home/suporte'] : ['/home/dashboard', '/home/produtos', '/home/periodos'];
    this.pages = getAppPages(this.usuario).filter(page => this.pagesToShow.includes(page.path));
  }

}
