import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../environments/environment.development';
import { PeriodoLancamentos } from '../model/periodo.model';
import { DefaultPageSize, session$ } from '../globals/globals';
import { ApiConstraintGetPeriodos } from '../model/api.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PeriodoService extends BaseHttpService {

  getPeriodos(constraints: ApiConstraintGetPeriodos = {}) {
    const storageService = new StorageService();
    constraints.PageSize = constraints?.PageSize || storageService.get('PAGE_SIZE_LIST_PERIODOS') || DefaultPageSize.periodos;
    constraints.Page = constraints?.Page || 1;

    // const params = Object.fromEntries(Object.entries({
    //   Termo: constraints?.Termo || null,
    //   Page: constraints?.Page || 1,
    //   PageSize: constraints?.PageSize || storageService.get('PAGE_SIZE_LIST_PERIODOS') || DefaultPageSize.periodos,
    //   FirstRow: constraints?.FirstRow || null,
    //   Simulacao: constraints?.Simulacao,
    // } as ApiConstraintGetPeriodos).filter(x => x[1]))
    return this.get<PeriodoLancamentos>(environment.api.endpoints.periodos, constraints);
  }

  getPeriodo(id: string) {
    return this.getById<PeriodoLancamentos>(environment.api.endpoints.periodos, id);
  }

  createPeriodo(periodo: PeriodoLancamentos) {
    return this.post<PeriodoLancamentos>(environment.api.endpoints.periodos, periodo);
  }

  updatePeriodo(periodo: PeriodoLancamentos) {
    const { id, ...data } = periodo;
    return this.put<PeriodoLancamentos>(`${environment.api.endpoints.periodos}/${id}`, data);
  }

  deletePeriodo(id: string) {
    return this.delete<PeriodoLancamentos>(environment.api.endpoints.periodos, id);
  }

  copiarPeriodo(periodoConfig: Pick<PeriodoLancamentos, 'dataFim' | 'dataInicio' | 'nome' | 'simulacao'>, idPeriodoASerCopiado: string) {
    const body: Partial<PeriodoLancamentos> & { idPeriodoASerCopiado: string } = {
      idEmpresa: session$.value.empresa,
      nome: periodoConfig.nome,
      dataInicio: periodoConfig.dataInicio,
      dataFim: periodoConfig.dataFim,
      simulacao: periodoConfig.simulacao,
      idPeriodoASerCopiado,
    };
    return this.post<Partial<PeriodoLancamentos> & { idPeriodoASerCopiado: string }>(`${environment.api.endpoints.periodos}/copia`, body);
  }
}
