import { DespesasFixasOverlayComponent } from './despesas-fixas-overlay/despesas-fixas-overlay.component';
import { DespesasVendaOverlayComponent } from './despesas-venda-overlay/despesas-venda-overlay.component';
import { FaturamentoOverlayComponent } from './faturamento-overlay/faturamento-overlay.component';
import { LucroOverlayComponent } from './lucro-overlay/lucro-overlay.component';
import { ModalService, ModalType } from '../../services/modal.service';
import { CustoVariavelOverlayComponent } from './custo-variavel-overlay/custo-variavel-overlay.component';
import { MargemContribuicaoOverlayComponent } from './margem-contribuicao-overlay/margem-contribuicao-overlay.component';
import { PontoEquilibrioOverlayComponent } from './ponto-equilibrio-overlay/ponto-equilibrio-overlay.component';
import { PeriodoLancamentos } from '../../model/periodo.model';
import { Component } from '@angular/core';
import { MaterialIcon } from 'material-icons';
import { AppColor } from '../../model/custom-types';

const OVERLAYS = [DespesasFixasOverlayComponent, DespesasVendaOverlayComponent, FaturamentoOverlayComponent, LucroOverlayComponent, CustoVariavelOverlayComponent, MargemContribuicaoOverlayComponent, PontoEquilibrioOverlayComponent];

@Component({
  selector: 'app-modal-card-small-dashboard',
  imports: [OVERLAYS],
  standalone: true,
  template: '',
  host: { 'id': 'modal-card-small-dashboard' },
})
export class ModalCardSmallDashboard {

  async presentModalCardSmall(
    modalService: ModalService,
    type: TypeModalDashboard,
    data: TDataModalCardSmallDashboard,
    modalType: ModalType,
    currentPeriodo: PeriodoLancamentos,
  ) {
    const modal = modalService.presentModal(this.getComponent(type), modalType, { periodo: currentPeriodo, data }, {
      size: (['despesas-fixas', 'despesas-venda'] as Array<TypeModalDashboard>).includes(type) ? 'lg' : '',
    });

    return modal;
  }

  private getComponent(type: TypeModalDashboard) {
    switch (type) {
      case 'despesas-fixas': return DespesasFixasOverlayComponent;
      case 'despesas-venda': return DespesasVendaOverlayComponent;
      case 'faturamento': return FaturamentoOverlayComponent;
      case 'lucro': return LucroOverlayComponent;
      case 'custo-variavel': return CustoVariavelOverlayComponent;
      case 'margem-contribuicao': return MargemContribuicaoOverlayComponent;
      case 'ponto-equilibrio': return PontoEquilibrioOverlayComponent;
    }
  }
}

export type TDataModalCardSmallDashboard = SmallCardDashboard | { lucro: number, margemLucroCalculada: number, margemLucroDesejada: number } | { faturamentoCalculado: number, faturamentoEstimado: number };
export type TypeModalDashboard = 'despesas-fixas' | 'despesas-venda' | 'faturamento' | 'lucro' | 'custo-variavel' | 'margem-contribuicao' | 'ponto-equilibrio';
export type SmallCardDashboard = {
  icon?: MaterialIcon;
  customIcon?: string;
  iconBgColor: AppColor;
  title: string;
  pipe: 'currency' | 'percent';
  value?: number | string;
  modal?: TypeModalDashboard;
};
