<div>
  <card-with-icon
    color="white"
    [title]="card.title"
    [icon]="card.icon"
    [customIcon]="card.customIcon"
    [iconBgColor]="card.iconBgColor"
    (clickBody)="card.modal ? presentModalCardSmall(modalService, card.modal, card, modalType(this), periodo) : null"
    [showForwardIcon]="!!card.modal"
  >
    @if (card.value === undefined) {
      <small-label class="fst-italic" color="cinza">
        <span class="spinner-border spinner-border-sm me-2"></span>
        <span> Carregando... </span>
      </small-label>
    } @else if (card.value === "error") {
      <small-label class="fst-italic" color="danger"> Erro ao carregar </small-label>
    } @else {
      @if (+card.value === 0) {
        {{ 0 | currency }}
      } @else {
        @if (card.pipe === "percent") {
          {{ card.value | percent: "1.0-2" }}
        } @else if (card.pipe === "currency") {
          {{ card.value | currency }}
        } @else {
          <span class="text-pre"> {{ card.value }} </span>
        }
      }
    }
  </card-with-icon>
</div>
