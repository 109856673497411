import { Component, ElementRef, Input } from '@angular/core';
import { PeriodoLancamentos, PeriodosLancamentos } from '../../../../../model/periodo.model';
import { BaseClass } from '../../../../../globals/base-class';
import { ColorDirective } from '../../../../../directives/color.directive';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskPipe, NgxMaskService } from 'ngx-mask';
import { IconComponent } from '../../../../../custom-components/icon/icon.component';
import { StepperComponent } from '../../../../../custom-components/stepper/stepper.component';
import { format, parse } from 'date-fns';
import { PeriodoService } from '../../../../../services/periodo.service';
import { ToastService } from '../../../../../services/toast.service';
import { DropdownComponent } from '../../../../../custom-components/dropdown/dropdown.component';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { PaginatorPageSize } from '../../../../../globals/globals';
import { OptionNovaBaseValue } from '../../periodos.component';
import { eSimulacao$ } from '../../../periodos-wrapper.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { ApiConstraintGetPeriodos } from '../../../../../model/api.model';
import { NgMatIconComponent } from '../../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { dateDDMMYYYYIsInvalid, downloadModeloImportacaoProdutos, selectPlanilhaImportacaoProdutos } from '../../../../../globals/utils';
import { NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

type TForm = {
  nome: FormControl<string>;
  dataInicio: FormControl<string>;
  dataFim: FormControl<string>;
  file: FormControl<File>;
};

@Component({
  selector: 'app-configuracao-periodo',
  standalone: true,
  imports: [
    ColorDirective,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    DropdownComponent,
    SmallLabelComponent,
    AsyncPipe,
    NgClass,
    NgMatIconComponent,
    NgxMaskPipe,
    NgbDatepickerModule,
  ],
  providers: [],
  templateUrl: './configuracao-periodo.component.html',
  styleUrl: './configuracao-periodo.component.scss',
})
export class ConfiguracaoPeriodoComponent extends BaseClass() {

  @Input() periodo: PeriodoLancamentos;
  @Input() tipoBase: OptionNovaBaseValue;
  @Input() Stepper: StepperComponent<PeriodoLancamentos>;

  eSimulacao$ = eSimulacao$;

  form: FormGroup<TForm> = new FormGroup({
    nome: new FormControl('', [Validators.required, Validators.minLength(3)]),
    dataInicio: new FormControl(null),
    dataFim: new FormControl(null),
    file: new FormControl(null),
  });

  periodoCopiaControl: FormControl = new FormControl({ value: '', disabled: true });

  periodosLancamento: PeriodosLancamentos;

  downloadModelo = downloadModeloImportacaoProdutos;

  dataFimMin: NgbDateStruct = null;
  dataInicioMax: NgbDateStruct = null;

  constructor(
    private periodoService: PeriodoService,
    private toastService: ToastService,
    private maskService: NgxMaskService,
  ) {
    super();
  }

  ngOnInit() {
    this.Stepper.lock = true;

    this.getPeriodos();
    this.setSubsctiptions();

    if (this.periodo) {
      this.form.patchValue({
        nome: this.periodo.nome,
        dataInicio: this.periodo.dataInicio ? format(parse(this.periodo.dataInicio, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '',
        dataFim: this.periodo.dataFim ? format(parse(this.periodo.dataFim, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '',
      });
    }
  }

  private setSubsctiptions() {
    const sub = this.form.valueChanges.subscribe({
      next: () => {
        this.Stepper.lock = !!this.errorStep();
      }
    });
    this.appendSubscription(sub);

    const sub2 = this.Stepper.onStepChangeBlocked.pipe().subscribe({
      next: (stepEv) => {
        if (stepEv.currentIndex) {
          const error = this.errorStep();
          if (error) this.presentToastError(error);
        }
      }
    });
    this.appendSubscription(sub2);

    const sub3 = this.Stepper.onStepChange.pipe().subscribe({
      next: (ev) => {
        const { file, ...formValue } = this.form.value;
        const newValue: PeriodoLancamentos = {
          ...this.periodo,
          ...formValue,
          dataInicio: formValue.dataInicio ? format(parse(formValue.dataInicio, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : null,
          dataFim: formValue.dataFim ? format(parse(formValue.dataFim, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : null
        };

        // só dispara o evento se os campos do step atual tenham sido alterados
        if (
          this.periodo?.nome !== newValue.nome ||
          this.periodo?.dataInicio !== newValue.dataInicio ||
          this.periodo?.dataFim !== newValue.dataFim ||
          this.form.dirty
        )
          this.Stepper.childEvent.emit({
            data: newValue,
            periodoACopiar: this.periodoCopiaControl.value,
            previousIndex: ev.currentIndex,
            file: this.form.controls?.file?.value,
          });
      }
    });
    this.appendSubscription(sub3);

    const sub4 = this.form.controls.dataInicio.valueChanges.subscribe({
      next: (value) => {
        if (!value || dateDDMMYYYYIsInvalid(value)) return;

        const date = parse(value, 'dd/MM/yyyy', new Date());

        this.dataFimMin = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
      }
    });
    this.appendSubscription(sub4);

    const sub5 = this.form.controls.dataFim.valueChanges.subscribe({
      next: (value) => {
        if (!value || dateDDMMYYYYIsInvalid(value)) return;

        const date = parse(value, 'dd/MM/yyyy', new Date());

        this.dataInicioMax = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
      }
    });
    this.appendSubscription(sub5);
  }

  private errorStep() {
    const formValue: Partial<PeriodoLancamentos> = this.form.value;
    if (formValue?.nome?.length < 3) return 'Nome do período deve ter no mínimo 3 caracteres';
    if (formValue.dataInicio && formValue.dataFim && new Date(formValue.dataInicio) > new Date(formValue.dataFim)) return 'Data de início não pode ser maior que a data de fim';
    if (this.tipoBase === OptionNovaBaseValue.Importar && !this.form.controls?.file?.value) return 'Selecione um arquivo para importar';
    if (this.tipoBase === OptionNovaBaseValue.Copiar && !this.periodoCopiaControl.value?.id) return 'Selecione um período para copiar';
    return null;
  }

  private presentToastError(body: string) {
    this.toastService.show({ body, color: 'danger' });
  }

  periodoLancamentoSelecionado(periodo: PeriodoLancamentos) {
    this.periodoCopiaControl.setValue(periodo);

    this.form.patchValue({
      nome: periodo.nome ? `Cópia de ${periodo.nome}` : '',
      dataInicio: periodo.dataInicio ? format(parse(periodo.dataInicio, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '',
      dataFim: periodo.dataFim ? format(parse(periodo.dataFim, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '',
    }, { emitEvent: true });

    this.form.markAsDirty();
  }

  async getPeriodos() {
    const constraints: ApiConstraintGetPeriodos = {
      PageSize: PaginatorPageSize.slice(-1)[0]
    };
    const res = await this.periodoService.getPeriodos(constraints);
    if (res.errors) return;
    this.periodosLancamento = res.data || [];
    this.periodosLancamento = this.periodosLancamento.map(p => ({ ...p, nomeDropdown: `${p.simulacao ? 'S': 'P'} - ${p.nome}` }));

    this.periodoCopiaControl.enable();
  }

  async selectFile() {
    const file = await selectPlanilhaImportacaoProdutos();
    if (!file) return;
    this.form.controls.file.setValue(file);
  }

  inputOnDate(control: FormControl<string>, event: Event) {
    const controlValue = control.value;
    if (!controlValue) return;
    const maskedValue = this.maskService.applyMask(controlValue, "00/00/0000");
    (event.target as any).value = maskedValue;
  }

  focusOutDate(control: FormControl<string>, data: 'inicio' | 'fim') {
    const controlValue = control.value;
    if (!controlValue) return;
    const dateIsInvalid = dateDDMMYYYYIsInvalid(controlValue);
    if (dateIsInvalid?.dateInvalid) {
      control.setValue(null);
      this.toastService.show({ body: `Data ${data === 'inicio' ? 'início' : 'fim'} inválida`, color: 'danger' });
      return;
    }
  }
}
