<div class="d-contents">
  <div id="container">
    <div id="content" class="m-4 position-relative">
      <div id="header">
        <div id="title" class="d-flex justify-content-between align-items-center">
          <h3 color="titulo" class="fw-600">Configurar Colunas</h3>
          <button class="btn" (click)="dismiss()">
            <ng-mat-icon name="close" />
          </button>
        </div>
      </div>

      <div id="body" class="py-3">
        <div class="d-flex flex-column gap-4">
          <div class="d-flex flex-column gap-3">
            <small-label>
              <div class="d-flex justify-content-between w-100">
                <span> Selecione quais colunas vão ser exibidas </span>
                <span class="fw-bold"> {{ selectedColumns.length }} / {{ colunas.length }} </span>
              </div>
            </small-label>

            <div class="list-cards d-grid column-gap-4 row-gap-2">
              @for (coluna of colunas; track $index) {
                <card-with-icon
                  borderColor="info"
                  [showFooter]="false"
                  (clickBody)="selectColumn(coluna)"
                  [fill]="selectedColumns.includes(coluna.column)"
                  [textColor]="selectedColumns.includes(coluna.column) ? 'white' : 'info'"
                  [class.cursor-not-allowed]="coluna.locked"
                >
                  <div class="d-flex gap-1 justify-content-between">
                    <span> {{ coluna.label }} </span>
                    @if (selectedColumns.includes(coluna.column)) {
                      <ng-mat-icon name="done" color="white" />
                    }
                  </div>
                </card-with-icon>
              }
            </div>

            <div id="button" class="d-flex justify-content-end mt-5 gap-3">
              <button class="btn btn-outline-info" (click)="dismiss()">Fechar</button>
              <button class="btn btn-info" (click)="confirm()">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
