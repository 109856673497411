import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppColor } from '../../model/custom-types';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { IconInEllipseComponent } from '../icon-in-ellipse/icon-in-ellipse.component';
import { MaterialIcon } from 'material-icons';

@Component({
  selector: 'badge',
  standalone: true,
  imports: [NgMatIconComponent, IconInEllipseComponent],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss'
})
export class BadgeComponent {
  @Input() text: string;
  @Input() color: AppColor = 'primary';
  @Input() showCloseButton: boolean = false;
  @Input() type: TBadge;

  @Input() iconStart: { name: MaterialIcon, bgColor: string };

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
}

export type TBadge = 'outline' | 'filled' | 'clear';
