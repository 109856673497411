<div id="container">
  <div id="list" [ngClass]="{ 'd-flex flex-wrap gap-5': isDesktop }">
    @if (!isDesktop) {
      <div class="item px-1">
        <div class="d-flex gap-2">
          <label class="label"> Ativo </label>
          <label class="value">
            <div class="justify-content-start form-check form-switch">
              <input #toggle class="form-check-input" type="checkbox" role="switch" (change)="ativoToggleChange.emit({ item: produto, input: toggle })" [checked]="produto._statusToggle" />
            </div>
          </label>
        </div>
      </div>
    }

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Nome </label>
        <label class="value"> {{ produto.nome }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Unidade </label>
        <label class="value"> {{ produto.unidadeMedida }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Quantidade </label>
        <label class="value"> {{ produto.quantidade ?? "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> R$ Custo </label>
        <label class="value"> {{ (produto.precoCusto | currency) || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> R$ Venda </label>
        <label class="value"> {{ (produto.precoVenda | currency) || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Margem </label>
        <label class="value"> {{ (produto.margemLucro | percent: "1.0-2") || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Lucro Unitário (R$) </label>
        <label class="value"> {{ (produto.lucroAtual | currency) || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Lucro Total (R$) </label>
        <label class="value"> {{ (produto.lucroTotalAtual | currency) || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> PR (%) </label>
        <label class="value"> {{ (produto.percentualParticipacaoReceita | percent: "1.0-2") || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> MC (%) </label>
        <label class="value"> {{ (produto.percentualMargemContribuicao / 100 | percent: "1.0-2") || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Resultado </label>
        <label class="value"> {{ (produto.faturamentoAtual | currency) || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Despesas de venda </label>
        <label class="value"> {{ (produto.percentualDespesasVenda / 100 | percent: "1.0-2") || "-" }} </label>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Categoria </label>
        <div class="d-flex align-items-center gap-3 value">
          <label class="d-flex gap-2 align-items-center">
            @if (!produto.categoria) {
              <span> - </span>
            } @else {
              @if (produto.categoria.icone) {
                <icon-in-ellipse [matIcon]="produto.categoria?.icone" [matIconOutlined]="false" [bgColor]="produto.categoria?.cor" />
              }
              <span> {{ produto.categoria?.nome }}</span>
            }
          </label>
        </div>
      </div>
    </div>

    <div class="item px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Precificação </label>
        <div class="d-flex align-items-center gap-3 value">
          <label class="d-flex gap-2 align-items-center">
            @if (!produto.precificacao) {
              <span> - </span>
            } @else {
              <icon-in-ellipse [matIcon]="(produto | precificacaoProdutoFace)?.icon" [color]="(produto | precificacaoProdutoFace)?.color" />
            }
          </label>
        </div>
      </div>
    </div>

    <div class="item tags px-1">
      <div class="d-flex gap-2" [ngClass]="{ 'flex-column': isDesktop }">
        <label class="label"> Tags </label>
        <div class="d-flex align-items-center gap-3 value">
          <label class="d-flex gap-2 align-items-center">
            @if (!produto?.tags?.length) {
              <span> - </span>
            }
            @for (tag of produto?.tags; track $index) {
              <span class="badge rounded-2"> {{ tag }} </span>
            }
          </label>
        </div>
      </div>
    </div>

    @if (!isDesktop) {
      <div class="item px-1">
        <div class="d-flex align-items-center gap-3 value w-100">
          <button type="button" class="btn btn-outline-info" (click)="detalhesProduto.emit()">
            <span> Ver detalhes </span>
          </button>

          <button type="button" class="btn btn-info" (click)="editar.emit()">
            <span> Editar </span>
          </button>
        </div>
      </div>
    }
  </div>
</div>
