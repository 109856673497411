<div class="d-flex justify-content-center">
  <!--
    BS seta o foco para o primeiro elemento interagível no modal, em dispositivos móveis isso faz com que a tela "suba" pra cima por causa do teclado.
    Esse input invisível previne esse comportamento e, como não é visível, não afeta a interface.
  -->
  <input [style.display]="'none'" />

  <section class="form-user gap-3 d-flex flex-column py-4 mx-5">
    <logo-header title="Esqueci minha senha" subtitle="Digite seu email para recuperar sua senha" />

    @if (!jaTemCodido) {
      <form class="d-flex flex-column gap-3" [formGroup]="formRequestCode">
        <div>
          <div class="form-group mb-3">
            <label class="fw-600 mb-1" for="email"> Login </label>
            <input type="email" class="form-control" id="email" placeholder="seuemail@email.com" formControlName="email" />
          </div>
        </div>

        <div class="row gap-3 w-100 mx-0">
          <button [disabled]="formRequestCode.disabled" type="button" (click)="dismiss()" class="btn col">
            <span> Cancelar </span>
          </button>

          <button [disabled]="formRequestCode.disabled" type="button" (click)="requestCode()" class="btn btn-primary col">
            <span> Enviar email </span>
          </button>
        </div>

        <small-label class="mt-5 pt-2">
          <span> Já tem um código? </span>
          <a class="link fw-bold ps-1" color="info" (click)="jaTemCodido = true"> Clique aqui </a>
        </small-label>
      </form>
    } @else {
      <!-- formChangePassword -->
      <form class="d-flex flex-column gap-3" [formGroup]="formChangePassword">
        <div>
          <div class="form-group mb-3">
            <label class="fw-600 mb-1" for="email"> Email </label>
            <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email" />
          </div>

          <div class="form-group mb-3">
            <label class="fw-600 mb-1" for="email"> Código </label>
            <input type="text" class="form-control" id="code" placeholder="Código" formControlName="code" />
          </div>

          <div class="form-group mb-3">
            <label class="fw-600 mb-1" for="password"> Nova senha </label>
            <input type="password" class="form-control" id="password" placeholder="Nova senha" formControlName="password" />
          </div>

          <div class="form-group mb-3">
            <label class="fw-600 mb-1" for="password"> Confirmar nova senha </label>
            <input type="password" class="form-control" id="confirmPassword" placeholder="Confirmar nova senha" formControlName="confirmPassword" />
          </div>

          <div class="row gap-3 w-100 mx-0">
            <button [disabled]="formChangePassword.disabled" type="button" (click)="dismiss()" class="btn col">
              <span> Cancelar </span>
            </button>

            <button [disabled]="formChangePassword.disabled" type="button" (click)="changePassword()" class="btn btn-primary col">
              <span> Alterar senha </span>
            </button>
          </div>
        </div>

        <small-label class="mt-5 pt-2">
          <span> Não recebeu o código? </span>
          <a class="link fw-bold ps-1" color="info" (click)="jaTemCodido = false"> Solicitar novamente </a>
        </small-label>
      </form>
    }
  </section>
</div>
