import { Component, Input, SimpleChange } from '@angular/core';
import { PeriodoLancamentos } from '../../../../../model/periodo.model';
import { StepperComponent } from '../../../../../custom-components/stepper/stepper.component';
import { ColorDirective } from '../../../../../directives/color.directive';
import { NoteComponent } from '../../../../../custom-components/note/note.component';
import { BaseClass } from '../../../../../globals/base-class';
import { CardWithIconComponent, InputCardWithIcon } from '../../../../../custom-components/card-with-icon/card-with-icon.component';
import { FormControl } from '@angular/forms';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { ToastService } from '../../../../../services/toast.service';
import { Observer } from 'rxjs';
import { OptionNovaBaseValue } from '../../periodos.component';
import { eSimulacao$ } from '../../../periodos-wrapper.component';
import { defaultClassModal } from '../../../../../globals/utils';
import { cardsSmall } from '../../../../dashboard/dashboard.component';
import { CardSmallComponent } from '../../../../dashboard/card-small/card-small.component';
import { PeriodoService } from '../../../../../services/periodo.service';
import { DashboardService } from '../../../../../services/dashboard.service';
import { ModalCardSmallDashboard, TDataModalCardSmallDashboard, TypeModalDashboard } from '../../../../dashboard/modal-card-small-dashboard';
import { ModalService } from '../../../../../services/modal.service';

@Component({
  selector: 'app-parametros-iniciais-periodo',
  standalone: true,
  imports: [
    ColorDirective,
    NoteComponent,
    CardWithIconComponent,
    CurrencyPipe,
    AsyncPipe,
    CardSmallComponent,
  ],
  templateUrl: './parametros-iniciais-periodo.component.html',
  styleUrl: './parametros-iniciais-periodo.component.scss'
})
export class ParametrosIniciaisPeriodoComponent extends BaseClass(ModalCardSmallDashboard) {
  @Input() periodo: PeriodoLancamentos;
  @Input() tipoBase: OptionNovaBaseValue;
  @Input() Stepper: StepperComponent<PeriodoLancamentos>;

  inputMargemLucro: InputCardWithIcon = {
    placeholder: 'Insira um valor',
    type: 'number',
    control: new FormControl()
  };

  inputFaturamentoEstimado: InputCardWithIcon = {
    placeholder: 'Insira um valor',
    type: 'number',
    control: new FormControl()
  };

  eSimulacao$ = eSimulacao$;

  cardsSmall = cardsSmall().filter(x => x.modal === 'despesas-venda' || x.modal === 'despesas-fixas');

  initialValueControlsChecked: boolean = false;

  constructor(
    private toastService: ToastService,
    private periodoService: PeriodoService,
    private dashboardService: DashboardService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.setSubscriptions();

    if (this.periodo) this.setControls();
  }

  ngOnChanges(res: { periodo: SimpleChange }) {
    if (this.periodo && (!this.inputMargemLucro.control.value || !this.inputFaturamentoEstimado.control.value) && !this.initialValueControlsChecked)
      this.setControls();

    if (res.periodo?.previousValue === null && res.periodo.currentValue && this.cardsSmall?.some(x => x.value === undefined))
      this.getSomatorioDespesas();
  }

  private async updateValues() {
    this.periodo = await this.periodoService.getPeriodo(this.periodo.id);
    if (this.periodo) this.setControls();
  }

  private setControls() {
    this.inputMargemLucro.control.patchValue(this.periodo.margemLucroDesejada);
    this.inputFaturamentoEstimado.control.patchValue(this.periodo.faturamentoEstimado);

    this.Stepper.lock = !!this.errorStep();
    this.getSomatorioDespesas();
  }

  async getSomatorioDespesas() {
    try {
      const dashboardData = await this.dashboardService.getDashboardData(this.periodo.id);

      this.cardsSmall.find(card => card.modal === 'despesas-venda').value = dashboardData.totalDespesasVendas;
      this.cardsSmall.find(card => card.modal === 'despesas-fixas').value = dashboardData.totalDespesasFixas;
    } catch (error) {
      console.error(error);
      this.cardsSmall.forEach(card => card.value = null);
    }
  }

  private setSubscriptions() {
    const observer: Partial<Observer<void>> = {
      next: () => this.Stepper.lock = !!this.errorStep()
    }
    const sub = this.inputMargemLucro.control.valueChanges.subscribe(observer);
    this.appendSubscription(sub);
    const sub2 = this.inputFaturamentoEstimado.control.valueChanges.subscribe(observer);
    this.appendSubscription(sub2);

    const sub3 = this.Stepper.onStepChangeBlocked.pipe().subscribe({
      next: (stepEv) => {
        if (stepEv.currentIndex) {
          const error = this.errorStep();
          if (error) this.presentToastError(error);
        }
      }
    });
    this.appendSubscription(sub3);

    const sub4 = this.Stepper.onStepChange.pipe().subscribe({
      next: (ev) => {
        const margemLucroDesejada = +this.inputMargemLucro.control.value;
        const faturamentoEstimado = +this.inputFaturamentoEstimado.control.value;

        const newValue: PeriodoLancamentos = {
          ...this.periodo,
          margemLucroDesejada,
          faturamentoEstimado,
        };

        // só dispara o evento caso os campos do step atual tenham sido alterados
        if (
          this.periodo.margemLucroDesejada !== margemLucroDesejada ||
          this.periodo.faturamentoEstimado !== faturamentoEstimado
        )
          this.Stepper.childEvent.emit({ data: newValue, previousIndex: ev.currentIndex });
      }
    });
    this.appendSubscription(sub4);
  }

  private errorStep() {
    const margemLucro = this.inputMargemLucro.control.value;
    const faturamentoEstimado = this.inputFaturamentoEstimado.control.value;
    if (!margemLucro || margemLucro < 0 || margemLucro > 100) return 'Margem de lucro deve ser um valor entre 0 e 100';
    if (!faturamentoEstimado || faturamentoEstimado < 0) return 'Faturamento estimado deve ser um valor positivo';
    return null;
  }

  async presentModal(type: TypeModalDashboard, data: TDataModalCardSmallDashboard) {
    const modal = await this.presentModalCardSmall(this.modalService, type, data, defaultClassModal(this), this.periodo)
    const sub = modal.dismissed.subscribe(() => this.updateValues());
    this.appendSubscription(sub);
  }

  private presentToastError(body: string) {
    this.toastService.show({ body, color: 'danger' });
  }
}
