import { Component, Input } from '@angular/core';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../../../custom-components/icon/icon.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorDirective } from '../../../../../directives/color.directive';
import { BaseClass } from '../../../../../globals/base-class';

@Component({
  selector: 'margem-lucro-categoria',
  standalone: true,
  imports: [SmallLabelComponent, IconComponent, FormsModule, ColorDirective, ReactiveFormsModule],
  templateUrl: './margem-lucro-categoria.component.html',
  styleUrl: './margem-lucro-categoria.component.scss'
})
export class MargemLucroCategoriaComponent extends BaseClass() {

  @Input() control: FormControl<number> = new FormControl(null);

  increaseMargemLucro() {
    const value = this.control.value;
    if (value < 100) this.control.setValue(value + 1);
  }

  decreaseMargemLucro() {
    const value = this.control.value;
    if (value > 0) this.control.setValue(value - 1);
  }

}
