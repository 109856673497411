import { Component } from '@angular/core';
import { CardWithIconComponent } from '../../custom-components/card-with-icon/card-with-icon.component';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { IconComponent } from '../../custom-components/icon/icon.component';
import { ColorDirective } from '../../directives/color.directive';
import { Contato } from '../../globals/globals';
import { BaseClass } from '../../globals/base-class';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-suporte',
  standalone: true,
  imports: [
    CardWithIconComponent,
    IconComponent,
    NgMatIconComponent,
    ColorDirective,
    NgClass,
  ],
  templateUrl: './suporte.component.html',
  styleUrl: './suporte.component.scss'
})
export class SuporteComponent extends BaseClass() {

  sendMail() {
    window.open(`mailto:${Contato.emailSuporte}`, '_blank');
  }

  sendWhatsapp() {
    window.open(`https://wa.me/${Contato.whatsappSuporte}`, '_blank');
  }

}
