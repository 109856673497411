import { Injectable } from '@angular/core';
import { AppColor } from '../model/custom-types';
import { toastDelay } from '../globals/globals';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: ToastInfo[] = [];

  show(opts: ToastInfo): ToastInfo {
    const toast = {
      header: opts.header,
      body: opts.body,
      delay: opts.delay || toastDelay,
      color: opts.color,
      alignHorizontal: opts.alignHorizontal || 'right',
      alignVertical: opts.alignVertical || 'top',
    };

    this.toasts.push(toast);
    return toast;
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t != toast);
  }
}

export type ToastInfo = {
  header?: string;
  body: string;
  delay?: number;
  alignHorizontal?: ToastAlignHorizontal;
  alignVertical?: ToastAlignVertical;
  color?: AppColor;
}

export type ToastAlignVertical = 'top' | 'center' | 'bottom';
export type ToastAlignHorizontal = 'left' | 'center' | 'right';
