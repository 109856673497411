<div id="container">
  <div #stepperEl id="stepper">
    <ul class="nav nav-tabs py-3 gap-0 justify-content-center" [style.--draw-color-rgb]="'var(--color-' + drawColor + '-rgb)'">
      @for (step of steps; track $index) {
        <li class="nav-item" [class]="step.status" (click)="currentStep === $index + 1 ? null : activateStep($index)">
          <div class="d-flex flex-column align-items-center text-center gap-2">
            <span class="index d-flex align-items-center justify-content-center fw-bolder">
              @if (step.status !== "completed") {
                <span> {{ $index + 1 }} </span>
              } @else {
                <icon name="check-bold-white" />
              }
            </span>
            <span class="fw-700"> {{ step.title }} </span>
          </div>
        </li>
      }
    </ul>
  </div>

  <div id="content" class="p-3 bg-white rounded-4 my-2" [ngClass]="{ 'mx-5': isDesktop, 'mx-4': !isDesktop }">
    <ng-content [data]="data" />

    <div class="gap-2" [ngClass]="{ 'justify-content-end d-flex': isDesktop, 'd-grid grid-auto-columns': !isDesktop }">
      @if (showDeleteButton) {
        <button #buttonprev class="btn btn-outline me-auto" color="danger" (click)="delete.emit()">Excluir</button>
      }

      <button #buttonprev class="btn btn-outline" [color]="drawColor" (click)="previous()">{{ currentStep === 1 ? cancelButtonText : previousButtonText }}</button>
      <button #buttonnext class="btn" [color]="drawColor" (click)="next()">{{ currentStep === steps?.length ? finishButtonText : nextButtonText }}</button>
    </div>
  </div>
</div>
