import { Component, Input } from '@angular/core';

@Component({
  selector: 'small-label',
  standalone: true,
  imports: [],
  templateUrl: './small-label.component.html',
  styleUrl: './small-label.component.scss'
})
export class SmallLabelComponent {
  @Input() class: string = null;
}
