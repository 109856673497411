import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CidadeIbge, EstadoIbge } from '../model/custom-types';

@Injectable({
  providedIn: 'root'
})
export class IbgeService {

  constructor(private http: HttpClient) {}

  getEstadosBrasil(): Promise<Array<EstadoIbge>> {
    return lastValueFrom(this.http.get<Array<EstadoIbge>>('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderby=nome'));
  }

  getCidadesPorEstado(estado: number | string): Promise<Array<CidadeIbge>> {
    return lastValueFrom(this.http.get<Array<CidadeIbge>>(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios?orderby=nome`));
  }
}
