<div id="container" class="mb-4">
  @if (tipoBase !== "manualmente" && tipoBase !== "editar") {
    <section id="from-existing" class="d-flex flex-column">
      @if (tipoBase === "copiar") {
        <div id="dropdown-periodo" class="mb-3 w-fit-content max-width-100">
          <dropdown
            [label]="'Selecione de qual período ou simulação deseja copiar a base'"
            [placeholder]="'Selecione de qual período ou simulação deseja copiar a base'"
            [options]="periodosLancamento"
            [inline]="false"
            displayField="nomeDropdown"
            [control]="periodoCopiaControl"
            (optionSelected)="periodoLancamentoSelecionado($event)"
          />
        </div>
      } @else {
        <div id="import-file">
          <label for="import-file" class="form-label"> Importe o arquivo para copiar a base </label>

          <div class="d-flex gap-2 justify-content-between" [class.align-items-center]="isDesktop" [class.flex-column]="!isDesktop">
            <div class="d-flex gap-3 align-items-center">
              <button class="btn btn-outline px-3 py-5" [color]="(eSimulacao$ | async) ? 'laranja-forte' : 'verde'" (click)="selectFile()">
                <span class="d-flex flex-column align-items-center justify-content-center gap-2">
                  <icon [name]="(eSimulacao$ | async) ? 'upload-laranja-forte' : 'upload'" />
                  <span class="small nobold"> {{ form.controls.file?.value?.name ? "Substituir" : "Selecionar" }} </span>
                </span>
              </button>

              <span class="small text-body fst-italic"> {{ form.controls.file?.value?.name || "Nenhum arquivo selecionado" }} </span>
            </div>

            <button class="btn btn-outline gap-2" [class.flex-grow-1]="isMobile" [color]="(eSimulacao$ | async) ? 'laranja-forte' : 'verde'" (click)="downloadModelo()">
              <ng-mat-icon name="download" [color]="(eSimulacao$ | async) ? 'laranja-forte' : 'verde'" />
              <span class="small nobold"> Baixar modelo </span>
            </button>
          </div>
        </div>
      }

      <hr class="divider my-3" />
    </section>
  }

  <section id="form-container">
    <div id="form" class="d-flex gap-3" [ngClass]="{ 'flex-column': !isDesktop }" [formGroup]="form">
      <div class="flex-grow-1" [class.mb-3]="isDesktop">
        <label for="nome-periodo" class="form-label"> Nome {{ (eSimulacao$ | async) ? "da simulação" : "do período" }} </label>
        <div class="input-group">
          <input type="text" class="form-control" id="nome-periodo" [placeholder]="'Insira um nome para o ' + ((eSimulacao$ | async) ? 'simulação' : 'período')" formControlName="nome" />
        </div>
      </div>

      <div class="input-with-tag" [class.mb-3]="isDesktop">
        <label for="periodo-inicio" class="form-label"> Início do período </label>
        <div class="input-group">
          <ng-mat-icon
            class="input-group-text p-0 rounded-start-3 overflow-hidden"
            [classes]="'px-2 rounded-start'"
            id="periodo-inicio"
            name="calendar_today"
            color="white"
            [bgColor]="(eSimulacao$ | async) ? 'laranja-forte' : 'verde'"
          />
          <input
            class="form-control date text-center"
            placeholder="30/12/2024"
            formControlName="dataInicio"
            [maxDate]="dataInicioMax"
            ngbDatepicker
            #dpInicio="ngbDatepicker"
            (input)="inputOnDate(form.controls.dataInicio, $event)"
            (blur)="focusOutDate(form.controls.dataInicio, 'inicio')"
          />

          <button class="btn btn-outline" color="neutro" (click)="dpInicio.toggle()" type="button">
            <ng-mat-icon name="edit_calendar" [color]="(eSimulacao$ | async) ? 'laranja-forte' : 'verde'" />
          </button>
        </div>
      </div>

      <div class="input-with-tag" [class.mb-3]="isDesktop">
        <label for="periodo-fim" class="form-label"> Fim do período </label>
        <div class="input-group">
          <ng-mat-icon
            class="input-group-text p-0 rounded-start-3 overflow-hidden"
            [classes]="'px-2 rounded-start'"
            id="periodo-fim"
            name="calendar_today"
            color="white"
            [bgColor]="(eSimulacao$ | async) ? 'laranja-forte' : 'verde'"
          />
          <input
            class="form-control date text-center"
            placeholder="30/12/2024"
            formControlName="dataFim"
            [minDate]="dataFimMin"
            ngbDatepicker
            #dpFim="ngbDatepicker"
            (input)="inputOnDate(form.controls.dataFim, $event)"
            (blur)="focusOutDate(form.controls.dataFim, 'fim')"
          />

          <button class="btn btn-outline" color="neutro" (click)="dpFim.toggle()" type="button">
            <ng-mat-icon name="edit_calendar" [color]="(eSimulacao$ | async) ? 'laranja-forte' : 'verde'" />
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
