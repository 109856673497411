import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { AppColor, RGBColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoteComponent } from '../note/note.component';
import { stringIsRGBColor } from '../../globals/utils';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { CustomIconComponent } from '../custom-icon/custom-icon.component';
import { MaterialIcon } from 'material-icons';

@Component({
  selector: 'card-with-icon',
  standalone: true,
  imports: [
    NgMatIconComponent,
    ColorDirective,
    FormsModule,
    ReactiveFormsModule,
    NoteComponent,
    AsyncPipe,
    CustomIconComponent,
  ],
  templateUrl: './card-with-icon.component.html',
  styleUrl: './card-with-icon.component.scss'
})
export class CardWithIconComponent extends BaseClass() {
  @Input() icon: MaterialIcon;
  @Input() customIcon: string;
  @Input() iconBgColor: AppColor;
  @Input() iconColor: AppColor = 'white';
  @Input() title: string;
  @Input() message: string;
  @Input() input: InputCardWithIcon;
  @Input() note: string;
  @Input() noteColor: AppColor;
  @Input() showForwardIcon: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() borderColor: RGBColor | AppColor;
  @Input() textColor: AppColor | RGBColor;
  @Input() animated: boolean = false;
  @Input() fill: boolean = false;

  @Output() clickBody: EventEmitter<void> = new EventEmitter();
  @Output() clickFooterButton: EventEmitter<void> = new EventEmitter();

  styles$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  ngOnChanges() {
    let styles = '';
    if (stringIsRGBColor(this.borderColor)) styles += `--custom-border-color: rgb(${this.borderColor})`;
    else styles += `--custom-border-color: var(--bs-${this.borderColor}, var(--color-${this.borderColor}))`;

    if (stringIsRGBColor(this.textColor)) styles += `; --custom-text-color: rgb(${this.textColor})`;
    else styles += `; --custom-text-color: var(--bs-${this.textColor}, var(--color-${this.textColor}))`;

    this.styles$.next(styles);
  }
}

export type InputCardWithIcon = {
  placeholder: string;
  type: 'text' | 'number';
  control: FormControl;
};
