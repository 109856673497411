import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { session$ } from '../globals/globals';
import { lastValueFrom } from 'rxjs';
import { ApiRequestQueryParams, ApiResponseDelete, ApiResponseGetMultiple, ApiResponseGetUnique, ApiResponsePatch, ApiResponsePost, ApiResponsePut } from '../model/api.model';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  private url = environment.api.url;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private router: Router,
  ) {}

  protected getById<T>(endpoint: string, id: string, params: ApiRequestQueryParams = null): Promise<ApiResponseGetUnique<T>> {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.get<T>(this.url + endpoint + `/${id}`, { headers, params }));
  }

  protected async get<T>(endpoint: string, params: ApiRequestQueryParams = {}): Promise<ApiResponseGetMultiple<T>> {
    const headers = this.setHeaders();
    try {
      return await lastValueFrom(this.httpClient.get<ApiResponseGetMultiple<T>>(this.url + endpoint, { headers, params }));
    } catch (err) {
      const error = err as HttpErrorResponse;
      if (error.status === 401) {
        this.authService.logout();
        this.router.navigate(['/login'], { queryParams: { redirectTo: this.router.url }, replaceUrl: true });
      }
      return {
        data: null,
        error: [{ errorCode: error.status, message: error.message }],
        status: error.status,
      };
    }
  }

  protected post<T>(endpoint: string, body: Partial<T>): Promise<ApiResponsePost<T>> {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.post<T>(this.url + endpoint, body, { headers }));
  }

  protected put<T>(endpoint: string, body: Omit<Partial<T>, 'id'>): Promise<ApiResponsePut<T>> {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.put<ApiResponsePut<T>>(this.url + endpoint, body, { headers }));
  }

  protected patch<Req, Res>(endpoint: string, body: Req): Promise<ApiResponsePatch<Res>> {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.patch<ApiResponsePatch<Res>>(this.url + endpoint, body, { headers }));
  }

  protected delete<T>(endpoint: string, id: string): Promise<ApiResponseDelete<T>> {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.delete<ApiResponseDelete<T>>(this.url + endpoint + `/${id}`, { headers }));
  }

  private setHeaders(): HttpHeaders {
    return new HttpHeaders({ 'Authorization': `Bearer ${session$.value?.accessToken}` })
  }
}
