import { Component } from "@angular/core";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { BehaviorSubject } from "rxjs";

export const eSimulacao$: BehaviorSubject<boolean> = new BehaviorSubject(true);

@Component({
  selector: 'app-periodos-wrapper',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './periodos-wrapper.component.html',
})
export class PeriodosWrapperComponent {

  constructor(private route: ActivatedRoute) {
    eSimulacao$.next(this.route.snapshot?.queryParams?.['simulador'] === 'true' || this.route.snapshot.routeConfig?.path === 'simulador');
  }

}
