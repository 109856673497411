<div id="container">
  <div class="m-4">
    <div id="header" class="mb-5">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">Lucro</h3>
        <button class="btn" (click)="dismiss()">
          <icon name="x" />
        </button>
      </div>

      <!-- <div id="subtitle">
        <p class="fw-500" color="cinza">Aqui vem as informações explicando o que é o termo em questão, links com chamadas para vídeos explicativos, artigos, etc.</p>
      </div> -->
    </div>

    <div id="content">
      <div class="mb-5">
        <dropdown
          [isDisabled]="!periodos?.length || operationInProgress"
          [inline]="true"
          label="Período de lançamentos"
          [placeholder]="periodos?.length === 0 ? 'Nenhum período cadastrado' : 'Selecione um período'"
          [options]="periodos"
          [control]="controlPeriodo"
          displayField="nome"
          (optionSelected)="selectPeriodo($event)"
        />
      </div>

      <div class="mb-5">
        <card-with-icon color="white" title="Lucro" icon="attach_money" iconBgColor="secondary" [showForwardIcon]="false" [showFooter]="false">
          <div class="d-flex gap-4" [ngClass]="{ 'flex-column': !isDesktop, 'align-items-center': isDesktop }">
            @if (dadosLucro) {
              <label>
                <span color="secondary">{{ dadosLucro.lucro | currency }}</span>
              </label>

              <div class="d-flex gap-3">
                <small-label>
                  <span color="secondary" class="fw-bold">{{ dadosLucro.margemLucroCalculada / 100 | percent: "1.0-2" }}</span>
                  <span class="ms-1">Real</span>
                </small-label>
                <small-label>
                  <span color="titulo" class="fw-bold">{{ dadosLucro.margemLucroDesejada / 100 | percent: "1.0-2" }}</span>
                  <span class="ms-1">Desejado</span>
                </small-label>
              </div>
            } @else {
              <small-label class="fst-italic" color="cinza">
                <span class="spinner-border spinner-border-sm me-2"></span>
                <span> Carregando... </span>
              </small-label>
            }
          </div>
        </card-with-icon>
      </div>

      @if (dadosLucro) {
        <div id="form">
          <div class="d-flex" [ngClass]="{ 'gap-5 align-items-start': isDesktop, 'gap-3 justify-content-between': !isDesktop }">
            <div class="cell d-flex flex-column gap-2">
              <small-label>
                <span color="cinza" class="fw-600"> Percentual Desejado </span>
              </small-label>

              <label color="dark" class="fw-bold"> {{ dadosLucro.margemLucroDesejada > 0 ? (dadosLucro.margemLucroDesejada / 100 | percent: "1.0-2") : "-" }} </label>
            </div>

            @if (showForm || !isDesktop) {
              <div class="form-group cell d-flex flex-column gap-2 w-fit-content" id="margem">
                <small-label>
                  <span color="cinza" class="fw-600"> % de lucro desejado </span>
                </small-label>
                <div id="lucro-form-group" class="d-flex align-items-center gap-2">
                  <button type="button" class="round-btn-icon btn" color="verde" (click)="decreaseMargemLucroDesejado()">
                    <icon name="minus-white" />
                  </button>
                  <input type="number" class="form-control lh-2" id="margem" placeholder="10%" [(ngModel)]="novoLucroEstimado" [min]="0" [max]="100" />
                  <button type="button" class="round-btn-icon btn" color="verde" (click)="increaseMargemLucroDesejado()">
                    <icon name="plus-white" />
                  </button>
                </div>
              </div>

              @if (isDesktop) {
                <div class="cell d-flex gap-2 flex-column">
                  <small-label>&nbsp;</small-label>
                  <div class="buttons d-flex gap-2 align-items-center justify-content-center">
                    <button class="btn" color="verde" (click)="save()">
                      <icon name="check-white" />
                    </button>
                    <button class="btn btn-outline" color="verde" (click)="showForm = false">
                      <icon name="x-verde" />
                    </button>
                  </div>
                </div>
              }
            } @else {
              @if (isDesktop) {
                <div class="cell d-flex gap-2 flex-column">
                  <small-label>&nbsp;</small-label>
                  <div class="buttons d-flex gap-2 align-items-center justify-content-center">
                    <button class="btn btn-outline" color="verde" (click)="showForm = true">
                      <icon name="edit-verde" />
                    </button>
                  </div>
                </div>
              }
            }
          </div>
        </div>
      }
    </div>

    <div id="footer" class="mt-5">
      <div class="d-flex align-items-center justify-content-end gap-3" [ngClass]="{ 'mt-6 me-3 mb-3 position-absolute bottom-0 end-0': !isDesktop }">
        <button class="btn btn-outline" color="verde" (click)="dismiss()">
          <span>Fechar</span>
        </button>

        @if (!isDesktop) {
          <button class="btn" color="verde" (click)="save()">
            <span>Salvar</span>
          </button>
        }
      </div>
    </div>
  </div>
</div>
