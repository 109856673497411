import { Component, Input } from '@angular/core';
import { ColorDirective } from '../../directives/color.directive';
import { IconComponent } from '../icon/icon.component';
import { AppColor } from '../../model/custom-types';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { MaterialIcon } from 'material-icons';

@Component({
  selector: 'icon-in-ellipse',
  standalone: true,
  imports: [IconComponent, NgMatIconComponent, ColorDirective],
  templateUrl: './icon-in-ellipse.component.html',
  styleUrl: './icon-in-ellipse.component.scss'
})
export class IconInEllipseComponent {

  @Input() color: AppColor;
  @Input() bgColor: string;
  @Input() icon: string;
  @Input() matIcon: MaterialIcon;
  @Input() matIconOutlined: boolean = true;
  @Input() class: string = '';
  /** se true o ícone se ajusta ao tamanho do pai */
  @Input() fitParent: boolean = false;

}
