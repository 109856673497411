import { Component, Input } from '@angular/core';
import { CardWithIconComponent } from '../../../custom-components/card-with-icon/card-with-icon.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { ModalService, ModalType } from '../../../services/modal.service';
import { PeriodoLancamentos } from '../../../model/periodo.model';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { defaultClassModal } from '../../../globals/utils';
import { BaseClass } from '../../../globals/base-class';
import { ColorDirective } from '../../../directives/color.directive';
import { ModalCardSmallDashboard, SmallCardDashboard } from '../modal-card-small-dashboard';

@Component({
  selector: 'card-small',
  standalone: true,
  imports: [
    CardWithIconComponent,
    SmallLabelComponent,
    PercentPipe,
    CurrencyPipe,
    ColorDirective,
  ],
  templateUrl: './card-small.component.html',
  styleUrl: './card-small.component.scss'
})
export class CardSmallComponent extends BaseClass(ModalCardSmallDashboard) {
  @Input({ required: true }) card: SmallCardDashboard;
  @Input({ required: true }) periodo: PeriodoLancamentos;

  modalType = defaultClassModal;

  constructor(
    protected modalService: ModalService,
  ) {
    super();
  }
}
