import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { Produto, StatusProdutoEnum } from '../../model/produto.model';
import { Categoria, Categorias } from '../../model/categoria.model';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, CurrencyPipe, NgClass, PercentPipe } from '@angular/common';
import { OperationInProgressComponent } from '../operation-in-progress/operation-in-progress.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorDirective } from '../../directives/color.directive';
import { IconInEllipseComponent } from '../icon-in-ellipse/icon-in-ellipse.component';
import { PrecificacaoProdutoFacePipe } from '../../pipes/precificacao-produto-face.pipe';
import { DetalhesProdutoAccordionBodyComponent } from '../../home/produtos-wrapper/produtos/produto-list/detalhes-produto-accordion-body/detalhes-produto-accordion-body.component';
import { PaginatorComponent, PaginatorOpts } from '../paginator/paginator.component';
import { StorageService } from '../../services/storage.service';
import { CategoriaService } from '../../services/categoria.service';
import { FormsModule } from '@angular/forms';
import { PaginatorPageSize, PeriodoLancamentosSelecionado$ } from '../../globals/globals';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { DirectionOrderingProdutoAsc, OrderProdutoBy } from '../../model/custom-types';
import { PeriodoLancamentos } from '../../model/periodo.model';

@Component({
  selector: 'table-produto-list',
  standalone: true,
  imports: [
    NgClass,
    OperationInProgressComponent,
    AsyncPipe,
    NgbAccordionModule,
    ColorDirective,
    CurrencyPipe,
    PercentPipe,
    IconInEllipseComponent,
    PrecificacaoProdutoFacePipe,
    DetalhesProdutoAccordionBodyComponent,
    PaginatorComponent,
    FormsModule,
    NgMatIconComponent,
  ],
  templateUrl: './table-produto-list.component.html',
  styleUrl: './table-produto-list.component.scss'
})
export class TableProdutoListComponent extends BaseClass() {

  @Input({ required: true }) produtos: Array<ProdutoLocalList>;
  @Input({ required: true }) operationInProgress$: BehaviorSubject<boolean>;
  @Input({ required: true }) paginatorOpts: PaginatorOpts;
  @Input({ required: true }) periodo: PeriodoLancamentos;

  @Input() columns: Array<ColumnTableProdutoList>;
  @Input() ordering: Ordering = {
    OrderBy: 'nome',
    OrderAsc: true,
  }

  @Output() ativoToggleChange: EventEmitter<{ item: ProdutoLocalList, input: HTMLInputElement }> = new EventEmitter();
  @Output() addEditProduto: EventEmitter<ProdutoLocalList> = new EventEmitter();
  @Output() detalhesProduto: EventEmitter<ProdutoLocalList> = new EventEmitter();
  @Output() updatePageSize: EventEmitter<number> = new EventEmitter();
  @Output() navigatePage: EventEmitter<number> = new EventEmitter();

  @Output() checkedChanged: EventEmitter<{ produto: ProdutoLocalList, checked: boolean }> = new EventEmitter();

  @Output() order: EventEmitter<Ordering> = new EventEmitter();

  COLUMNS_WIDTH = ["33px", "auto", "100px", "90px", "100px", "100px", "100px", "100px", "100px", "100px", "100px", "130px", "100px", "100px", "100px", "100px", "150px", "115px", "150px"];
  COLUMNS_AREAS: Array<ColumnTableProdutoList | 'actions'> = ['checkbox', 'nome', 'margem', 'uni', 'qtd', 'custo', 'venda', 'rateio', 'df', 'calc', 'mc', 'faturamentoTotal', 'lucroAtual', 'lucroTotal', 'despesasV', 'categoria', 'tags', 'precificacao', 'actions'];

  gridTemplateColumns = this.COLUMNS_WIDTH.join(' ');
  gridTemplateAreas = this.COLUMNS_AREAS.join(' ');

  gettingCategorias: boolean = false;
  categorias: Categorias;

  constructor(
    private storageService: StorageService,
    private categoriaService: CategoriaService,
  ) {
    super();
  }

  ngOnChanges() {
    if (this.produtos.length) this.setProdutos();

    const displayColumns = this.COLUMNS_AREAS.filter(x => x === 'actions' ? this.columns.filter(y => y === 'status' || y === 'edit' || y === 'expand').length >= 1 : this.columns.includes(x));
    this.gridTemplateAreas = displayColumns.join(' ');
    this.gridTemplateColumns = displayColumns.map(c => this.COLUMNS_WIDTH[this.COLUMNS_AREAS.indexOf(c)]).join(' ');
  }

  ngOnInit() {
    this.setProdutos();
  }

  private async setProdutos() {
    if (!this.categorias && !this.gettingCategorias && this.columns?.includes('categoria')) await this.getCategorias();

    this.produtos = this.produtos?.map(p => ({
      ...p,
      _statusToggle: p.status === StatusProdutoEnum.ATIVO,
      //categoria: this.categorias?.find(c => c.id === p.idCategoria),
    }));
  }

  // TODO: remover quando a API estiver retornando a categoria do produto
  private async getCategorias() {
    if (!PeriodoLancamentosSelecionado$.value || this.categorias) return;
    this.gettingCategorias = true;

    try {
      this.categorias = (await this.categoriaService.getCategoriasPeriodo(PeriodoLancamentosSelecionado$.value.id, { PageSize: 1000 })).data;
    } catch (error) {
      console.error(error);
    }
  }

  orderBy(orderBy: OrderProdutoBy) {
    this.ordering.OrderAsc = this.ordering.OrderBy === orderBy ? !!!this.ordering.OrderAsc : true;
    this.ordering.OrderBy = orderBy;
    this.order.emit(this.ordering);
  }

  _navigatePage(page: number) {
    this.navigatePage.emit(page);
  }

  _updatePageSize(size: number) {
    this.updatePageSize.emit(size);
    this.storageService.set('PAGE_SIZE_LIST_PRODUTOS', size);
  }

}

export type ProdutoLocalList = Produto & {
  _statusToggle: boolean,
  _checked?: boolean,
};
export type CategoriaLocalList = { [key: string]: Categoria };

export type Ordering = {
  OrderBy: OrderProdutoBy;
  OrderAsc: DirectionOrderingProdutoAsc;
};

export type ColumnTableProdutoList =
  'checkbox' |
  'nome' |
  'uni' |
  'qtd' |
  'custo' |
  'venda' |
  'margem' |
  'rateio' |
  'df' |
  'calc' |
  'mc' |
  'faturamentoTotal' |
  'lucroAtual' |
  'lucroTotal' |
  'despesasV' |
  'tags' |
  'categoria' |
  'precificacao' |
  'status' |
  'edit' |
  'expand';

export const ColumnsTableProdutoListToDisplay: { [key in ColumnTableProdutoList]: string } = {
  checkbox: null,
  nome: 'Nome',
  uni: 'Unidade',
  qtd: 'Quantidade',
  custo: 'R$ Custo',
  venda: 'R$ Venda',
  margem: 'Margem',
  categoria: 'Categoria',
  precificacao: 'Precificação',
  calc: 'Preço Ideal',
  rateio: 'Rateio',
  df: 'DF',
  mc: 'MC',
  faturamentoTotal: 'Fat. Total',
  lucroAtual: 'Lucro Unitário',
  lucroTotal: 'Lucro Total',
  despesasV: 'Despesas de Vendas',
  tags: 'Tags',

  status: null,
  edit: null,
  expand: null,
};
