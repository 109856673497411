<div class="d-flex flex-column gap-3 p-4">
  <div class="option d-flex gap-2 py-2 align-items-center" (click)="select('exportar')">
    <ng-mat-icon name="file_download" color="info" />
    <span class="fw-600"> Exportar arquivo com os produtos </span>
  </div>

  <div class="option d-flex gap-2 py-2 align-items-center" (click)="select('importar')">
    <ng-mat-icon name="file_upload" color="info" />
    <span class="fw-600"> Importar arquivo com os produtos </span>
  </div>

  <div class="option d-flex gap-2 py-2 align-items-center" (click)="select('tags')">
    <ng-mat-icon name="local_offer" color="info" />
    <span class="fw-600"> Tags </span>
  </div>

  <div class="option d-flex gap-2 py-2 align-items-center" (click)="select('colunas')">
    <ng-mat-icon name="tune" color="info" class="rotate-90" />
    <span class="fw-600"> Configurar colunas </span>
  </div>

  <button type="button" class="btn btn-outline-info" (click)="dismiss()">Fechar</button>
</div>
