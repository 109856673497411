<ul class="nav gap-4" [class.nowrap]="nowrap">
  @for (segment of segments; track $index) {
    <li
      class="nav-item"
      [routerLink]="isNavigation ? segment.path : null"
      routerLinkActive="active"
      #route="routerLinkActive"
      [class.active]="activeSegment?.title === segment.title"
      (click)="isNavigation ? null : selectSegment(segment)"
    >
      <a class="nav-link p-0">{{ segment.title }}</a>
    </li>
  }
</ul>
