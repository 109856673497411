import { Component } from '@angular/core';
import { BaseClass } from '../../../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconComponent } from '../../../../../custom-components/icon/icon.component';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { CardWithIconComponent } from '../../../../../custom-components/card-with-icon/card-with-icon.component';
import { NgMatIconComponent } from '../../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ColorDirective } from '../../../../../directives/color.directive';
import { TExportacaoProduto } from '../../../../../model/custom-types';
import { MaterialIcon } from 'material-icons';

@Component({
  selector: 'app-exportar-produtos-overlay',
  standalone: true,
  imports: [
    IconComponent,
    NgMatIconComponent,
    SmallLabelComponent,
    CardWithIconComponent,
    ColorDirective,
  ],
  templateUrl: './exportar-produtos-overlay.component.html',
  styleUrl: './exportar-produtos-overlay.component.scss'
})
export class ExportarProdutosOverlayComponent extends BaseClass() {

  options: Array<{ label: string, matIcon: MaterialIcon, value: TExportacaoProduto }> = [
    { label: 'Excel', matIcon: 'table_chart', value: 'xlsx' },
    { label: 'PDF', matIcon: 'description', value: 'pdf' },
  ];

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  optionSelected(option: TExportacaoProduto, comFiltros: boolean = false) {
    this.activeModal.close({ option, comFiltros });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}

