import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { defaultDebounceInput } from '../globals/globals';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Directive({
  selector: 'input[debounce]',
  standalone: true
})
export class DebounceDirective {

  /**
   * tempo de espera para acionar o evento
   */
  @Input() debounce: number | string = defaultDebounceInput;
  /**
   * subject que controla a operação em andamento. É utilizado para aguardar o fim da operação para focar no input. Também é utilizado para desabilitar o input durante a operação.
   */
  @Input() operationObs$: BehaviorSubject<boolean>;
  /**
   * tamanho mínimo para acionar o evento
   */
  @Input() minLen: number;
  /**
   * se true, o evento só será acionado ao pressionar Enter
   */
  @Input() enterOnly: boolean;

  @Output() onChange = new EventEmitter<HTMLInputElement>();

  private timer: number;

  constructor(private el: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit() {
    this.el.nativeElement.addEventListener('keyup', this._debounce);
  }

  private _debounce = (ev: KeyboardEvent) => {
    clearTimeout(this.timer);
    if (this.minLen && this.el.nativeElement.value.length < this.minLen && ev.key !== 'Backspace') return;
    if (this.enterOnly && ev.key !== 'Enter' && ev.key !== 'Backspace') return;

    this.timer = setTimeout(() => {
      this.operationObs$?.next(true);
      this.el.nativeElement.disabled = true;
      if (this.operationObs$) this.setFocusAfterOperationEnd();
      this.onChange.emit(this.el.nativeElement);
    }, ev.key === 'Enter' ? 0 : (+this.debounce || defaultDebounceInput)) as unknown as number;
  }

  private async setFocusAfterOperationEnd() {
    await firstValueFrom(this.operationObs$).then(() => {
      setTimeout(() => this.el.nativeElement.focus(), +this.debounce);
    });
  }
}
