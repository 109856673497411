import { Component, Input } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { PageApp } from '../app-pages';
import { PeriodoLancamentos } from '../../../model/periodo.model';
import { PeriodoService } from '../../../services/periodo.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { StorageService } from '../../../services/storage.service';
import { DropdownComponent } from '../../../custom-components/dropdown/dropdown.component';
import { FormControl } from '@angular/forms';
import { ApiConstraintGetPeriodos } from '../../../model/api.model';
import { RefreshPeriodosNotifier$, PeriodoLancamentosSelecionado$, defaultAmountPeriodosDropdownToolbar } from '../../../globals/globals';
import { Usuario } from '../../../model/usuario.model';

@Component({
  selector: 'toolbar',
  standalone: true,
  imports: [NgbDropdownModule, NgClass, DatePipe, AsyncPipe, IconComponent, DropdownComponent],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent extends BaseClass() {

  @Input() currentPage: PageApp;
  @Input() usuario: Partial<Usuario>;
  periodos: Array<PeriodoLancamentos & { nomeDropdown: string }>;

  control: FormControl = new FormControl();

  constructor(
    private periodoService: PeriodoService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  private initialize() {
    if (this.usuario.master) return;
    this.getPeriodos();

    RefreshPeriodosNotifier$.subscribe((updateDropdownOnly) => this.getPeriodos(updateDropdownOnly));
  }

  async getPeriodos(updateDropdownOnly: boolean = false) {
    const constraints: ApiConstraintGetPeriodos = {
      PageSize: defaultAmountPeriodosDropdownToolbar,
    }

    try {
      const periodosSimulacoes = await this.periodoService.getPeriodos({ ...constraints });
      this.periodos = periodosSimulacoes.data.map(p => ({ ...p, nomeDropdown: `${p.simulacao ? 'S': 'P'} - ${p.nome}` }))


      if (updateDropdownOnly) return;

      const periodoStorage = this.storageService.get('CURRENT_PERIODO_ID');
      let currentPeriodo = periodoStorage ? this.periodos?.find(p => p?.id === periodoStorage) : null;
      this.control.setValue(currentPeriodo);
      if (!currentPeriodo) {
        this.storageService.remove('CURRENT_PERIODO_ID');
        currentPeriodo = this.periodos[0];
      }

      this.selectPeriodo(currentPeriodo);
    } catch (error) {
      console.error(error);
    }
  }

  selectPeriodo(periodo: PeriodoLancamentos) {
    if (!periodo) return;
    PeriodoLancamentosSelecionado$.next(periodo);
    this.control.setValue(periodo);
    this.storageService.set('CURRENT_PERIODO_ID', periodo.id);
  }
}
