import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseClass } from '../../../../globals/base-class';
import { PeriodoLancamentos } from '../../../../model/periodo.model';
import { BehaviorSubject } from 'rxjs';
import { IconComponent } from '../../../../custom-components/icon/icon.component';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NgClass, PercentPipe } from '@angular/common';
import { Categoria, Categorias, StatusCategoriaEnum } from '../../../../model/categoria.model';
import { NoResultsComponent } from '../../../../custom-components/no-results/no-results.component';
import { CategoriaService } from '../../../../services/categoria.service';
import { ToastService } from '../../../../services/toast.service';
import { ColorDirective } from '../../../../directives/color.directive';
import { PaginatorComponent, PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../../services/storage.service';
import { ModalService } from '../../../../services/modal.service';
import { CardWithIconComponent } from '../../../../custom-components/card-with-icon/card-with-icon.component';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { IconInEllipseComponent } from '../../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ProdutoService } from '../../../../services/produto.service';
import { DebounceDirective } from '../../../../directives/debounce.directive';
import { ApiConstraintGetCategorias } from '../../../../model/api.model';
import { FormsModule } from '@angular/forms';
import { MaterialIcon } from 'material-icons';

@Component({
  selector: 'categoria-list',
  standalone: true,
  imports: [
    IconComponent,
    OperationInProgressComponent,
    NoResultsComponent,
    ColorDirective,
    PercentPipe,
    PaginatorComponent,
    CardWithIconComponent,
    SmallLabelComponent,
    IconInEllipseComponent,
    NgClass,
    NgMatIconComponent,
    DebounceDirective,
    FormsModule,
  ],
  templateUrl: './categoria-list.component.html',
  styleUrls: ['./categoria-list.component.scss', '../categorias-section-common.scss']
})
export class CategoriaListComponent extends BaseClass() {
  @Input({ required: true }) periodo: PeriodoLancamentos;
  @Input({ required: true }) operationInProgress$: BehaviorSubject<boolean>;
  @Input({ required: true }) categoriaSelecionada: Categoria;

  @Output() addCategoria: EventEmitter<void> = new EventEmitter();
  @Output() selectCategoria: EventEmitter<Categoria> = new EventEmitter();

  updatePeriodo: EventEmitter<PeriodoLancamentos> = new EventEmitter();
  categorias: Categorias;
  paginatorOpts: PaginatorOpts;

  errorMessage: string;

  iconPlaceholder: MaterialIcon = 'receipt';

  CategoriaStatus = StatusCategoriaEnum

  searchTerm: string;

  constructor(
    private categoriaService: CategoriaService,
    private produtoService: ProdutoService,
    private toastService: ToastService,
    private storageService: StorageService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    const sub = this.updatePeriodo.pipe().subscribe({
      next: (periodo) => {
        if (periodo) {
          this.periodo = periodo;
          this.getCategorias();
        }
      }
    });
    this.appendSubscription(sub);

    this.getCategorias();
  }

  async getCategorias() {
    if (!this.periodo?.id) return this.toastService.show({ body: 'Período não encontrado', color: 'danger' });

    this.categorias = null;
    this.operationInProgress$.next(true);

    try {
      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_CATEGORIAS')
      const constraints: ApiConstraintGetCategorias = {
        Page: this.paginatorOpts?.page,
        PageSize,
        Termo: this.searchTerm,
      };
      const res = await this.categoriaService.getCategoriasPeriodo(this.periodo.id, constraints);
      if (res.errors) return this.toastService.show({ body: 'Erro ao buscar categorias', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data } = res;
      this.categorias = data
      if (!this.categorias?.map(c => c.id).includes(this.categoriaSelecionada?.id)) this.categoriaSelecionada = null;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      this.errorMessage = 'Erro ao buscar categorias';
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  ativoToggleChange(categoria: Categoria, toggle: HTMLInputElement) {
    const { ...toSave } = categoria;

    const loading = this.modalService.presentLoading("Salvando categoria, aguarde...", true);

    const periodoId = this.periodo?.id;

    toSave.status = toggle.checked ? 1 : 2;

    this.categoriaService.updateCategoriaPeriodo(periodoId, toSave).then((res) => {
      if (!res.success) return this.presentToastErrorSave();

      this.toastService.show({ body: `Categoria salvo com sucesso`, color: 'success' });
      categoria = res.data;
      this.selectCategoria.emit(categoria);
    }).catch((err) => {
      console.log(err);
      this.presentToastErrorSave()
    }).finally(() => {
      loading.dismiss();
    })
  }

  inputOnSearch() {
    this.getCategorias();
  }

  private presentToastErrorSave() {
    this.toastService.show({ body: 'Ocorreu um erro ao salvar a categoria, tente novamente mais tarde', color: 'danger' });
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_CATEGORIAS', size);
    this.getCategorias();
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getCategorias();
  }

}
