import { Component } from '@angular/core';
import { NgMatIconComponent } from '../../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { BaseClass } from '../../../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-options-mobile-lista-produtos',
  standalone: true,
  imports: [
    NgMatIconComponent,
  ],
  templateUrl: './options-mobile-lista-produtos.component.html',
  styleUrl: './options-mobile-lista-produtos.component.scss'
})
export class OptionsMobileListaProdutosComponent extends BaseClass() {

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  select(option: 'exportar' | 'importar' | 'tags' | 'colunas') {
    this.activeModal.close(option);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
