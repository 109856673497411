import { Categoria } from "./categoria.model";

export type Produto = {
  id: string;
  idPeriodo: string;
  nome: string;
  idCategoria: string;
  categoria?: Categoria;
  unidadeMedida: string;
  precoCusto: number;
  precoVenda: number;
  quantidade: number;
  custoTotal: number;
  margemLucro: number;
  precoIdeal: number;
  status: StatusProdutoEnum;
  tags: Array<string>;

  faturamentoAtual?: number;
  percentualRateioCusto?: number;
  percentualParticipacaoReceita?: number;
  despesasFixas?: number;
  percentualDespesasVenda?: number;
  despesasVenda?: number;
  precoCalculadoSmartis?: number;
  precoCalculadoZeroPercentualLucro?: number;
  lucroAtual?: number;
  lucroTotalAtual?: number;
  percentualLucroAtual?: number;
  lucroEstimado?: number;
  totalLucroEstimado?: number;
  percentualLucroEstimado?: number;
  margemContribuicao?: number;
  percentualMargemContribuicao?: number;
  quantidadePontoEquilibrio?: number;
  valorPontoEquilibrio?: number;
  faturamentoPossivel?: number;
  melhorPreco?: number;
  lucroPossivel?: number;
  lucroTotalPossivel?: number;
  precificacao?: TipoPrecificacaoEnum;
  percentualPrecoCustoPrecoIdeal: number;
  custoTotalPeriodo: number;
  faturamentoTotalPeriodo: number;
  percentualLucroPossivel: number;
  totalDespesasFixas: number;
  percentualDespesasFixasPrecoIdeal: number;
  percentualDespesasVendaPossivel: number;
  percentualDespesasVendaPrecoIdeal: number;
  despesasVendaIdeal: number;
  totalDespesasVenda: number;
  totalDespesasVendaIdeal: number;
  percentualPrecoCalculadoSmartisFaturamentoIdeal: number;
  lucroTotalPeriodo: number;
  valorPontoEquilibrioPeriodo: number;
  percentualLucroPossivelPrecoIdeal: number;
}
export type Produtos = Array<Produto>;

export enum StatusProdutoEnum {
  ATIVO = 1,
  INATIVO = 2,
}

export enum TipoPrecificacaoEnum {
  IDEAL = "ideal",
  CRITICA = "critica",
  DEFASADA = "defasada",
}
