<!--
  BS seta o foco para o primeiro elemento interagível no modal, em dispositivos móveis isso faz com que a tela "suba" pra cima por causa do teclado.
  Esse input invisível previne esse comportamento e, como não é visível, não afeta a interface.
-->
<input [style.display]="'none'" />

@if (operationInProgress) {
  <operation-in-progress [absolute]="true" />
}

<div class="d-flex justify-content-center">
  <section class="form-user gap-3 d-flex flex-column py-4 mx-5">
    <logo-header title="Criar nova senha" subtitle="Alterar senha para o email <span class='fw-bolder'> {{ user?.email }} </span>" />

    <form class="d-flex flex-column gap-3" [formGroup]="form">
      <div>
        <div class="form-group mb-3">
          <label class="fw-600 mb-1" for="email"> Senha Atual </label>
          <input #input [type]="hidePassword ? 'password' : 'text'" formControlName="currentPassword" class="form-control" id="currentPassword" placeholder="Digite sua senha atual" />

          <icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="'eye' + (hidePassword ? '' : '-off')" />
        </div>

        <div class="form-group mb-3">
          <label class="fw-600 mb-1" for="email"> Nova senha </label>
          <input #input [type]="hidePassword ? 'password' : 'text'" formControlName="newPassword" class="form-control" id="newPassword" placeholder="Digite sua nova senha" />

          <icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="'eye' + (hidePassword ? '' : '-off')" />
        </div>

        <div class="form-group mb-3">
          <label class="fw-600 mb-1" for="email"> Confirmar senha </label>
          <input #input [type]="hidePassword ? 'password' : 'text'" formControlName="confirmPassword" class="form-control" id="confirmPassword" placeholder="Confirme sua nova senha" />

          <icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="'eye' + (hidePassword ? '' : '-off')" />
        </div>
      </div>

      <div class="row gap-3 w-100 mx-0">
        <button [disabled]="form.disabled" type="button" (click)="close()" class="btn col">
          <span> Cancelar </span>
        </button>

        <button [disabled]="form.disabled" type="button" (click)="confirm()" class="btn btn-primary col">
          <span> Confirmar </span>
        </button>
      </div>
    </form>
  </section>
</div>
