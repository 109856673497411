import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Categoria, DespesaVendaCategoria, DespesasVendaCategoria } from '../../../../../model/categoria.model';
import { BaseClass } from '../../../../../globals/base-class';
import { BehaviorSubject } from 'rxjs';
import { PaginatorComponent } from '../../../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../../../services/storage.service';
import { aGreaterThenB } from '../../../../../globals/utils';
import { AsyncPipe, CurrencyPipe, NgClass, PercentPipe } from '@angular/common';
import { ColorDirective } from '../../../../../directives/color.directive';
import { OperationInProgressComponent } from '../../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NgMatIconComponent } from '../../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '../../../../../services/modal.service';
import { ToastService } from '../../../../../services/toast.service';
import { CategoriaService } from '../../../../../services/categoria.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DelayShowTooltip } from '../../../../../globals/globals';
import { TipoAplicacaoDespesa } from '../../../../../model/despesa.model';

type TForm = {
  // nome: FormControl<string>;
  valor: FormControl<number>;
}

@Component({
  selector: 'despesas-venda-categoria',
  standalone: true,
  imports: [
    NgClass,
    PercentPipe,
    CurrencyPipe,
    AsyncPipe,
    ColorDirective,
    OperationInProgressComponent,
    PaginatorComponent,
    NgMatIconComponent,
    ReactiveFormsModule,
    NgbTooltipModule,
  ],
  templateUrl: './despesas-venda-categoria.component.html',
  styleUrl: './despesas-venda-categoria.component.scss'
})
export class DespesasVendaCategoriaComponent extends BaseClass() {

  @Input({ required: true }) categoria: Categoria;
  @Input({ required: true }) despesasVenda: DespesasVendaCategoria;
  @Input({ required: true }) operationInProgress$: BehaviorSubject<boolean>;
  @Input() columns: Array<ColumnTableDespesaVendaCategoria> = ['nome', 'valor', 'edit'];

  @Output() updatePageSize: EventEmitter<number> = new EventEmitter();
  @Output() navigatePage: EventEmitter<number> = new EventEmitter();
  @Output() refresh: EventEmitter<Categoria> = new EventEmitter();

  COLUMNS_WIDTH = ["auto", "130px", "130px"];
  COLUMNS_AREAS: Array<ColumnTableDespesaVendaCategoria | 'actions'> = ['nome', 'valor', 'actions'];

  gridTemplateColumns = this.COLUMNS_WIDTH.join(' ');
  gridTemplateAreas = this.COLUMNS_AREAS.join(' ');

  ordering: Ordering = {
    by: 'nome',
    direction: 'up',
  };

  despesaForm: FormGroup<TForm>;
  indexForm: number;

  DelayShowTooltip = DelayShowTooltip;
  TipoAplicacaoDespesa = TipoAplicacaoDespesa;

  constructor(
    private storageService: StorageService,
    private modalService: ModalService,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
  ) {
    super();
  }

  ngOnChanges() {
    const displayColumns = this.COLUMNS_AREAS.filter(x => x === 'actions' ? this.columns.filter(y => y === 'edit').length >= 1 : this.columns.includes(x));
    this.gridTemplateAreas = displayColumns.join(' ');
    this.gridTemplateColumns = displayColumns.map(c => this.COLUMNS_WIDTH[this.COLUMNS_AREAS.indexOf(c)]).join(' ');
  }

  orderBy(orderBy: OrderDepesaVendaCategoriaBy) {
    this.ordering.direction = this.ordering.by === orderBy ? this.ordering.direction === 'down' ? 'up' : 'down' : 'up';
    this.ordering.by = orderBy;
    this.order();
  }

  private order() {
    const ordered = this.despesasVenda.sort((x1, x2) => {
      switch (this.ordering.by) {
        case 'nome':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(x1?.nomeDespesa, x2.nomeDespesa)
          return aGreaterThenB(x2?.nomeDespesa, x1.nomeDespesa);

        case 'valor':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(x1.valor, x2.valor);
          return aGreaterThenB(x2.valor, x1.valor);
      }
    });

    this.despesasVenda = ordered;
  }

  cancelEditDespesaVenda() {
    this.despesaForm = null;
    this.indexForm = null;
  }

  editDespesaVenda(despesa: DespesaVendaCategoria) {
    if (!(this.despesaForm?.pristine === false))
      return this.setForm(despesa, this.despesasVenda.indexOf(despesa));

    const confirm = this.modalService.presentConfirm('Atenção', 'Deseja descartar as alterações feitas?', true);
    confirm.closed.subscribe({
      next: (res: boolean) => {
        if (res) this.setForm(despesa, this.despesasVenda.indexOf(despesa));
      }
    });
  }

  saveDespesaVenda(despesa: DespesaVendaCategoria) {
    if (this.despesaForm.pristine) return this.toastService.show({ body: 'Nenhuma alteração foi feita', color: 'warning' });
    // if (this.despesaForm.controls.nome.invalid) return this.toastService.show({ body: 'Nome da despesa inválido', color: 'danger' });
    if (this.despesaForm.controls.valor.invalid) return this.toastService.show({ body: 'Valor da despesa inválido', color: 'danger' });

    const loading = this.modalService.presentLoading('Salvando despesa...', true);

    const newValue: DespesaVendaCategoria = {
      ...despesa,
      ...this.despesaForm.value
    };
    this.despesaForm.disable();

    this.categoriaService.updateDespesasVendaCategoria(this.categoria.idPeriodo, this.categoria.id, newValue).then((res) => {
      if (!res.success) return this.toastService.show({ body: 'Erro ao salvar despesa', color: 'danger' });

      this.toastService.show({ body: 'Despesa salva com sucesso', color: 'success' });
      this.despesasVenda[this.indexForm] = newValue;
      this.refresh.emit(this.categoria);
      this.cancelEditDespesaVenda();
    }).catch((err) => {
      console.log(err);
      this.toastService.show({ body: 'Erro ao salvar despesa', color: 'danger' });
      this.despesaForm.enable();
    }).finally(() => {
      loading.dismiss();
    });
  }

  private setForm(despesa: DespesaVendaCategoria, index: number) {
    this.despesaForm = new FormGroup({
      // nome: new FormControl(despesa.nomeDespesa || null),
      valor: new FormControl(despesa.valor || null),
    });
    this.indexForm = index;
  }

  _navigatePage(page: number) {
    this.navigatePage.emit(page);
  }

  _updatePageSize(size: number) {
    this.updatePageSize.emit(size);
    this.storageService.set('PAGE_SIZE_LIST_DESPESAS', size);
  }
}


export type OrderDepesaVendaCategoriaBy = 'nome' | 'valor';
type OrderingDirection = 'up' | 'down';
export type Ordering = {
  by: OrderDepesaVendaCategoriaBy;
  direction: OrderingDirection;
};

export type ColumnTableDespesaVendaCategoria = 'nome' | 'valor' | 'edit';

export const ColumnsTableDespesasVendaCategoriaToDisplay: { [key in ColumnTableDespesaVendaCategoria]: string } = {
  nome: 'Despesa',
  valor: 'Valor',
  edit: null,
};

