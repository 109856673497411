<div id="container" [ngClass]="{ 'p-5': isDesktop, 'p-3': !isDesktop }">
  <div id="content" class="rounded-3 p-4">
    <div id="header" class="mb-4 mt-1">
      <button id="btn-nova" type="button" class="btn btn-primary gap-3" (click)="addEditUsuario()">
        <ng-mat-icon name="add_business" color="white" />
        <span> Novo Usuário </span>
      </button>

      <div id="searchbox" class="input-group input-icon-end position-relative input-icon-end">
        <input
          type="text"
          class="form-control lh-2 pe-5"
          id="search-input"
          placeholder="Busque pelo nome do usuário"
          [debounce]="defaultDebounceInput"
          [operationObs$]="operationInProgress$"
          [enterOnly]="true"
          (onChange)="inputOnSearch()"
          [disabled]="!usuarios || (operationInProgress$ | async)"
          [(ngModel)]="searchTerm"
        />
        <ng-mat-icon placement="top" class="cursor-pointer" slot="end" name="search" color="primary" />
      </div>
    </div>

    @if (usuarios) {
      <table-usuario-list
        [columns]="[displayedColumns, columnsActions].flat()"
        [usuarios]="usuarios"
        [paginatorOpts]="paginatorOpts"
        [operationInProgress$]="operationInProgress$"
        (ativoToggleChange)="ativoToggleChange($event.usuario, $event.input)"
        (addEditUsuario)="addEditUsuario($event)"
        (updatePageSize)="updatePageSize($event)"
        (navigatePage)="navigatePage($event)"
        (checkedChanged)="usuarioChecked($event)"
      />
    } @else {
      <operation-in-progress />
    }
  </div>
</div>
