<div id="content">
  <div class="modal-header">
    <h5 class="modal-title fw-bolder">{{ header }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body text-pre-line">
    <p>{{ message }}</p>
  </div>
  <div class="d-flex gap-1 modal-footer px-2 py-0">
    <button type="button" [color]="buttonsColor" class="btn btn-outline" (click)="close(false)">{{ noText }}</button>
    <button type="button" [color]="buttonsColor" class="btn" (click)="close(true)">{{ yesText }}</button>
  </div>
</div>
