<div id="container" [style.--gridTemplateColumns]="gridTemplateColumns" [style.--gridTemplateAreas]="'&quot;' + gridTemplateAreas + '&quot;'">
  <div id="list" class="rounded-4">
    @if (isDesktop) {
      <div id="header" class="list-row" [class.rounded-top-4]="isDesktop" [class.operation-in-progress]="operationInProgress$ | async">
        @if (columns.includes("nome")) {
          <div class="cell nome allow-ordering" [class.ordering]="ordering.by === 'nome'" [ngClass]="ordering.by === 'nome' ? ordering.direction : ''" (click)="orderBy('nome')">
            <label>Nome</label>
          </div>
        }
        @if (columns.includes("valor")) {
          <div class="cell valor allow-ordering" [class.ordering]="ordering.by === 'valor'" [ngClass]="ordering.by === 'valor' ? ordering.direction : ''" (click)="orderBy('valor')">
            <label>Valor</label>
          </div>
        }
        @if (columns.includes("edit")) {
          <div class="cell actions">
            <label>Ações</label>
          </div>
        }
      </div>
    }

    <div class="list-content">
      @for (despesa of despesasVenda; track $index) {
        <div class="rounded-4">
          <div class="list-row" [class.py-2]="!isDesktop" [class.rounded-4]="!isDesktop" [class.px-2]="!isDesktop" [class.inputs-visible]="despesaForm && indexForm === $index">
            @if (columns.includes("nome")) {
              <div class="cell nome fw-bold">
                <!-- @if (despesaForm && indexForm === $index) {
                  <div [formGroup]="despesaForm" class="d-contents">
                    <input type="text" class="form-control" placeholder="Nome da despesa" formControlName="nome" />
                  </div>
                } @else { -->
                <label [color]="!isDesktop ? 'primary' : 'titulo'" [ngClass]="{ 'd-flex gap-3 align-items-center justify-content-between w-100': !isDesktop }">
                  <span class="cursor-pointer"> {{ despesa.nomeDespesa }} </span>
                </label>
                <!-- } -->
              </div>
            }

            @if (columns.includes("valor")) {
              <div class="cell valor">
                @if (despesaForm && indexForm === $index) {
                  <div [formGroup]="despesaForm" class="d-contents">
                    <input type="number" class="form-control" placeholder="Valor da despesa" formControlName="valor" />
                  </div>
                } @else {
                  <label>
                    @if (despesa.tipoAplicacao === TipoAplicacaoDespesa.PERCENTUAL) {
                      <span> {{ despesa.valor / 100 | percent: "1.0-2" }} </span>
                    } @else {
                      <span> {{ despesa.valor | currency }} </span>
                    }
                  </label>
                }
              </div>
            }

            @if (columns.includes("edit")) {
              <div class="cell actions d-flex gap-2">
                @if (columns.includes("edit")) {
                  @if (!despesaForm || indexForm !== $index) {
                    <button class="btn" (click)="editDespesaVenda(despesa)" placement="top" ngbTooltip="Editar despesa" [openDelay]="DelayShowTooltip">
                      <ng-mat-icon name="edit" color="titulo" />
                    </button>
                  } @else {
                    <button type="button" class="btn btn-outline" (click)="cancelEditDespesaVenda()" color="roxo" placement="top" ngbTooltip="Cancelar edição" [openDelay]="DelayShowTooltip">
                      <ng-mat-icon name="close" color="roxo" />
                    </button>

                    <button type="button" class="btn" (click)="saveDespesaVenda(despesa)" color="roxo" placement="top" ngbTooltip="Salvar despesa" [openDelay]="DelayShowTooltip">
                      <ng-mat-icon name="save" color="white" />
                    </button>
                  }
                }
              </div>
            }
          </div>
        </div>
      }

      @if (operationInProgress$ | async) {
        @if (despesasVenda?.length) {
          <div class="loading">
            <operation-in-progress [absolute]="true" />
          </div>
        } @else {
          <div class="list-row no-results">
            <div class="cell justify-content-center" [class.rounded-4]="!isDesktop">
              <label> Carregando despesas... </label>
            </div>
          </div>
        }
      }

      @if (despesasVenda?.length === 0) {
        <div class="list-row no-results">
          <div class="cell justify-content-center" [class.rounded-4]="!isDesktop">
            <label> Nenhum resultado encontrado. </label>
          </div>
        </div>
      }
    </div>
  </div>

  <!-- <paginator
    [page]="paginatorOpts?.page"
    [pages]="paginatorOpts.pages"
    [pageSize]="paginatorOpts.pageSize"
    [rowsCount]="paginatorOpts.rowsCount"
    [isDisabled]="(operationInProgress$ | async) || !despesasVenda?.length"
    (updatePageSize)="_updatePageSize($event)"
    (navigatePage)="_navigatePage($event)"
  /> -->
</div>
