import { transitionDuration } from "../globals/globals";

export class Menu {
  opened: boolean = false
  closing: boolean = false

  toggle() {
    this.opened = !this.opened;
  }

  close() {
    this.closing = true;

    setTimeout(() => {
      this.toggle();
      this.closing = false;
      this.opened = false;
    }, transitionDuration);
  }

  open() {
    this.opened = true;
  }
}
