import { Component, Inject } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PeriodoLancamentos, PeriodosLancamentos } from '../../../model/periodo.model';
import { CurrencyPipe, NgClass } from '@angular/common';
import { CardWithIconComponent } from '../../../custom-components/card-with-icon/card-with-icon.component';
import { SmallLabelComponent } from '../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { ColorDirective } from '../../../directives/color.directive';
import { DropdownComponent } from '../../../custom-components/dropdown/dropdown.component';
import { PeriodoService } from '../../../services/periodo.service';
import { ToastService } from '../../../services/toast.service';
import { FormControl, FormsModule } from '@angular/forms';
import { DefaultPageSize } from '../../../globals/globals';
import { ModalService } from '../../../services/modal.service';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'app-faturamento-overlay',
  standalone: true,
  imports: [CardWithIconComponent, SmallLabelComponent, CurrencyPipe, IconComponent, ColorDirective, DropdownComponent, FormsModule, NgClass],
  templateUrl: './faturamento-overlay.component.html',
  styleUrl: './faturamento-overlay.component.scss'
})
export class FaturamentoOverlayComponent extends BaseClass() {

  dadosFaturamento: {
    faturamentoCalculado: number,
    faturamentoEstimado: number
  };

  periodo: PeriodoLancamentos;
  periodos: PeriodosLancamentos;

  controlPeriodo: FormControl = new FormControl();

  showForm: boolean = false;

  novoFaturamentoEstimado: number;

  operationInProgress: boolean = true;

  constructor(
    @Inject('data') private data: Record<string, any>,
    private activeModal: NgbActiveModal,
    private periodoService: PeriodoService,
    private toastService: ToastService,
    private modalService: ModalService,
    private dashboardService: DashboardService,
  ) {
    super();

    if (this.data) {
      this.periodo = data['periodo'];
      this.dadosFaturamento = data['data'];
    }
  }

  ngOnInit() {
    this.getPeriodos();
    if (this.periodo) this.getDadosFaturamento();
  }

  async getPeriodos() {
    try {
      const res = await this.periodoService.getPeriodos({ Simulacao: false, PageSize: DefaultPageSize.periodos })
      if (res.data) {
        this.periodos = res.data;
        this.controlPeriodo.setValue(this.periodo);
      }
    } catch (error) {
      console.log(error);
      this.toastService.show({ body: 'Erro ao buscar os períodos', color: 'danger' });
      this.dismiss();
    } finally {
      this.operationInProgress = false;
    }
  }

  selectPeriodo(periodo: PeriodoLancamentos) {
    if (!periodo) return;
    this.controlPeriodo.setValue(periodo);
    this.periodo = periodo;
    this.getDadosFaturamento();
  }

  async getDadosFaturamento() {
    this.operationInProgress = true;
    try {
      const [periodo, dashboardData] = await Promise.all([
        this.periodoService.getPeriodo(this.periodo.id),
        this.dashboardService.getDashboardData(this.periodo.id)
      ]);

      if (periodo && dashboardData) {
        this.periodo = periodo;
        this.dadosFaturamento = {
          faturamentoCalculado: dashboardData.faturamentoCalculado,
          faturamentoEstimado: dashboardData.faturamentoEstimado
        }
      }
    } catch (error) {
      console.log(error);
      this.toastService.show({ body: 'Erro ao buscar dados de faturamento', color: 'danger' });
      this.dismiss();
    } finally {
      this.operationInProgress = false;
    }
  }

  save() {
    const loading = this.modalService.presentLoading('Salvando...');
    const periodo: PeriodoLancamentos = { ...this.periodo, faturamentoEstimado: this.novoFaturamentoEstimado };
    this.periodoService.updatePeriodo(periodo).then((res) => {
      if (res) {
        this.toastService.show({ body: 'Faturamento estimado salvo com sucesso', color: 'success' });
        this.periodo = periodo;
        this.dadosFaturamento.faturamentoEstimado = this.novoFaturamentoEstimado;
        this.showForm = false;
      }
    }).catch((err) => {
      console.log(err);
      this.toastService.show({ body: 'Erro ao salvar faturamento estimado', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
