@for (toast of toastService.toasts; track toast) {
  <ngb-toast [color]="toast.color" [header]="toast.header" [autohide]="true" [delay]="toast.delay || 4000" (hidden)="toastService.remove(toast)" [ngClass]="['v-' + toast.alignVertical, 'h-' + toast.alignHorizontal]">
    <div class="d-flex">
      {{ toast.body }}
      @if (!toast.header) {
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      }
    </div>
  </ngb-toast>
}
