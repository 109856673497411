import { Directive, HostListener, Input } from '@angular/core';

export const TagsInputSeparatorsDefault = [',', 'Enter'];

@Directive({
  selector: 'input[TagsInput]',
  standalone: true
})
export class TagsInputDirective {

  /**
   * Deve ser o array que receberá os valores inseridos
   */
  @Input({ required: true }) TagsInput: Array<string> = [];
  /**
   * deve ser um valor aceitável para `KeyboarEvent.key`
   */
  @Input() separators: Array<string> = TagsInputSeparatorsDefault;

  @HostListener('keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (!this.separators.includes(event.key)) return;
    event.preventDefault();
    this.eventsInputTagsProduto(event.target as HTMLInputElement);
  }

  @HostListener('focusout', ['$event'])
  onFocusLost(event: FocusEvent) {
    this.eventsInputTagsProduto(event.target as HTMLInputElement);
  }

  eventsInputTagsProduto(inputEl: HTMLInputElement) {
    if (!inputEl.value) return;
    const inputValue = inputEl.value.trim();
    inputEl.value = '';
    if (!this.TagsInput.includes(inputValue)) this.TagsInput.push(inputValue);
  }

}
