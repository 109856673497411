import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexPlotOptions, ApexYAxis, ApexLegend, ApexDataLabels } from 'ng-apexcharts';
import { TipoPrecificacaoEnum } from './produto.model';
import { MaterialIcon } from 'material-icons';

export type HexColor = `#${string}`;
export type RGBColor = `${number},${number},${number}`;

export type AppColor =
  'primary' |
  'secondary' |
  'success' |
  'info' |
  'warning' |
  'danger' |
  'light' |
  'dark' |
  'cinza' |
  'neutro' |
  'verde' |
  'titulo' |
  'azul' |
  'black' |
  'white' |
  'rosa' |
  'apoio' |
  'vermelho' |
  'laranja' |
  'roxo' |
  'laranja-forte';

export type StorageKey =
  'CURRENT_PERIODO_ID' |
  'PAGE_SIZE_LIST_PRODUTOS' |
  'PAGE_SIZE_LIST_EMPRESAS' |
  'PAGE_SIZE_LIST_USUARIOS' |
  'PAGE_SIZE_LIST_CATEGORIAS' |
  'PAGE_SIZE_LIST_PERIODOS' |
  'PAGE_SIZE_LIST_DESPESAS' |
  'DISPLAY_COLUMNS_PRODUTOS_LIST' |
  'DISPLAY_COLUMNS_EMPRESAS_LIST' |
  'DISPLAY_COLUMNS_USUARIOS_LIST';

export type OmitId<T> = Omit<T, 'id'>;

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: Array<string>;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  colors: Array<AppColor>;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
};

export type TExportacaoProduto = 'xlsx' | 'pdf';

export const PrecificacaoIcon: { [key in TipoPrecificacaoEnum]: MaterialIcon } = {
  ideal: 'sentiment_satisfied',
  critica: 'sentiment_dissatisfied',
  defasada: 'sentiment_neutral',
};

export const PrecificacaoColor: { [key in TipoPrecificacaoEnum]: AppColor } = {
  ideal: 'secondary',
  critica: 'vermelho',
  defasada: 'laranja',
};

export type DirectionOrderingProdutoAsc = boolean;
export type OrderProdutoBy =
  'nome' |
  'unidademedida' |
  'quantidade' |
  'precocusto' |
  'precovenda' |
  'percentualLucroAtual' |
  'idcategoria' |
  'nomeCategoria' |
  'precificacao' |
  'PercentualRateioCusto' |
  'DespesasFixas' |
  'PrecoCalculadoSmartis' |
  'LucroAtual' |
  'LucroTotalAtual' |
  'PercentualMargemContribuicao' |
  'faturamentoAtual' |
  'lucroAtual'|
  'percentualDespesasVenda';

export declare type SiglaEstado = 'AC' | 'AL' | 'AP' | 'AM' | 'BA' | 'CE' | 'DF' | 'ES' | 'GO' | 'MA' | 'MT' | 'MS' | 'MG' | 'PA' | 'PB' | 'PR' | 'PE' | 'PI' | 'RJ' | 'RN' | 'RS' | 'RO' | 'RR' | 'SC' | 'SP' | 'SE' | 'TO';

export type EstadoIbge = {
  id?: number,
  sigla?: SiglaEstado,
  nome?: string,
  cidades?: Array<CidadeIbge>;
  regiao?: {
    id?: number,
    sigla?: string,
    nome?: string
  };
}

export type CidadeIbge = {
  id: number,
  nome: string,
  microrregiao: {
    id: number,
    nome: string,
    mesorregiao: {
      id: number,
      nome: string,
      UF: {
        id: number,
        sigla: string,
        nome: string,
        regiao: {
          id: number,
          sigla: string,
          nome: string
        }
      }
    }
  };
  regiao_imediata: {
    id: number,
    nome: string,
    regiao_intermediaria: {
      id: number,
      nome: string,
      UF: {
        id: number,
        sigla: string,
        nome: string,
        regiao: {
          id: number,
          sigla: string,
          nome: string
        }
      }
    }
  };
}

export type RegiaoIbge = {
  id: number;
  sigla: string;
  nome: string;
}

export enum StatusAtivoEnum {
  ATIVO = 1,
  INATIVO = 2,
}
