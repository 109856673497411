<div class="form-group d-flex flex-column gap-2" id="margem" [class.w-fit-content]="isDesktop" [class.w-100]="!isDesktop">
  <small-label>
    <span class="fw-bold"> Margem de lucro </span>
  </small-label>
  <div class="d-flex align-items-center gap-2">
    <button type="button" class="round-btn-icon btn" color="roxo" (click)="decreaseMargemLucro()">
      <icon name="minus-white" />
    </button>
    @if (control) {
      <input [class.flex-grow-1]="!isDesktop" type="number" class="form-control lh-2" id="margem" placeholder="10%" [formControl]="control" [min]="0" [max]="100" />
    } @else {
      <input [class.flex-grow-1]="!isDesktop" type="number" class="form-control lh-2" id="margem" placeholder="10%" />
    }
    <button type="button" class="round-btn-icon btn" color="roxo" (click)="increaseMargemLucro()">
      <icon name="plus-white" />
    </button>
  </div>
</div>
