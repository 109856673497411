import { Component, Inject, ViewChild } from '@angular/core';
import { ColorDirective } from '../../../../../directives/color.directive';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { NgMatIconComponent } from '../../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { BaseClass } from '../../../../../globals/base-class';
import { NgbActiveModal, NgbTypeahead, NgbTypeaheadModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { BadgeComponent } from '../../../../../custom-components/badge/badge.component';
import { CategoriaService } from '../../../../../services/categoria.service';
import { Categoria, Categorias } from '../../../../../model/categoria.model';
import { PaginatorPageSize, PeriodoLancamentosSelecionado$ } from '../../../../../globals/globals';
import { TagsInputDirective } from '../../../../../directives/tags-input.directive';
import { StatusProdutoEnum, TipoPrecificacaoEnum } from '../../../../../model/produto.model';
import { KeyValuePipe } from '@angular/common';
import { MaterialIcon } from 'material-icons';
import { IconInEllipseComponent } from '../../../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { AppColor } from '../../../../../model/custom-types';
import { MatSliderModule } from '@angular/material/slider';
import { ApiConstraintGetProdutos } from '../../../../../model/api.model';
import { FormsModule } from '@angular/forms';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs';
import { ToastService } from '../../../../../services/toast.service';

@Component({
  selector: 'app-selecionar-filtros-lista-produtos',
  standalone: true,
  imports: [
    ColorDirective,
    NgMatIconComponent,
    IconInEllipseComponent,
    SmallLabelComponent,
    BadgeComponent,
    TagsInputDirective,
    KeyValuePipe,
    MatSliderModule,
    FormsModule,
    NgbTypeaheadModule,
  ],
  templateUrl: './selecionar-filtros-lista-produtos.component.html',
  styleUrl: './selecionar-filtros-lista-produtos.component.scss'
})
export class SelecionarFiltrosListaProdutosComponent extends BaseClass() {

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  categorias: Categorias;
  selectedCategorias: Categorias = [];

  tags: Array<string> = [];
  unidadesMedida: Array<string> = [];

  margemLucro: { min: number, max: number } = { min: 0, max: 100 };

  StatusesProduto = StatusProdutoEnum;

  status: {
    [key: string]: {
      value: boolean,
      label: string,
    }
  } = {
      [StatusProdutoEnum.ATIVO]: {
        value: false,
        label: 'Ativo',
      },
      [StatusProdutoEnum.INATIVO]: {
        value: false,
        label: 'Inativo',
      },
    };

  precificacao: {
    [key: string]: {
      value: boolean,
      label: string,
      icon: MaterialIcon,
      color: AppColor,
    }
  } = {
      [TipoPrecificacaoEnum.CRITICA]: {
        value: false,
        label: 'Crítica',
        icon: 'sentiment_dissatisfied',
        color: 'danger',
      },
      [TipoPrecificacaoEnum.IDEAL]: {
        value: false,
        label: 'Ideal',
        icon: 'sentiment_satisfied',
        color: 'success',
      },
      [TipoPrecificacaoEnum.DEFASADA]: {
        value: false,
        label: 'Defasada',
        icon: 'sentiment_neutral',
        color: 'laranja',
      },
    };

  categoriaSearch: string;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    @Inject('data') private data: { filtros: FiltrosProdutoList },
    private activeModal: NgbActiveModal,
    private categoriaService: CategoriaService,
    private toastService: ToastService,
  ) {
    super();

    if (data?.filtros) {
      const { UnidadesMedidas, MargemLucroMinima, MargemLucroMaxima, Precificacao, StatusProduto, Tags } = data?.filtros;
      if (UnidadesMedidas) this.unidadesMedida = UnidadesMedidas;
      if (MargemLucroMinima) this.margemLucro.min = MargemLucroMinima;
      if (MargemLucroMaxima) this.margemLucro.max = MargemLucroMaxima;
      if (Precificacao) Precificacao.forEach(x => this.precificacao[x].value = true);
      if (StatusProduto) StatusProduto.forEach(x => this.status[x].value = true);
      if (Tags) this.tags = Tags;
    }
  }

  selectedCategoriaInputChanged(ev: NgbTypeaheadSelectItemEvent<Categoria>, input: HTMLInputElement) {
    if (this.selectedCategorias?.map(x => x.id).includes(ev.item?.id)) return this.toastService.show({ body: 'Categoria já adicionada', color: 'warning' });
    ev.preventDefault();
    this.selectedCategorias = [...this.selectedCategorias, ev.item];
    this.categoriaSearch = '';
    input.value = '';
  }

  categoriaTypeaheadFormatter = (x: Categoria) => x.nome;

  search: OperatorFunction<string, readonly Categoria[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === '' ? this.categorias : this.categorias.filter((v) => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1)),
      ),
    );
  };
  async ngOnInit() {
    this.getCategorias();
  }

  async getCategorias() {
    try {
      const res = await this.categoriaService.getCategoriasPeriodo(PeriodoLancamentosSelecionado$.value.id, { PageSize: 1000 });
      this.categorias = res.data;
      if (this.data?.filtros?.IdsCategorias) this.selectedCategorias = this.categorias.filter(x => this.data?.filtros?.IdsCategorias.includes(x.id));
    } catch (e) {
      console.error('error', e);
    }
  }

  removeCategoria(categoria: Categoria) {
    this.selectedCategorias = this.selectedCategorias.filter(x => x.id !== categoria.id);
  }

  removeTag(index: number) {
    this.tags.splice(index, 1);
  }

  removeUnidadeMedida(index: number) {
    this.unidadesMedida.splice(index, 1);
  }

  clear() {
    this.unidadesMedida = [];
    this.selectedCategorias = [];
    this.margemLucro = { min: 0, max: 100 };
    this.tags = [];
    Object.keys(this.precificacao).forEach(x => this.precificacao[x].value = false);
    Object.keys(this.status).forEach(x => this.status[x].value = false);
  }

  confirm() {
    const categoriasIds = this.selectedCategorias?.map(x => x.id);
    const includeMargemLucro = this.margemLucro.min !== 0 || this.margemLucro.max !== 100;

    const filtros: FiltrosProdutoList = {
      UnidadesMedidas: this.unidadesMedida,
      IdsCategorias: categoriasIds?.length ? categoriasIds : null,
      MargemLucroMinima: includeMargemLucro ? this.margemLucro.min : null,
      MargemLucroMaxima: includeMargemLucro ? this.margemLucro.max : null,
      Precificacao: Object.keys(this.precificacao).filter(x => this.precificacao[x].value) as Array<TipoPrecificacaoEnum>,
      StatusProduto: [StatusProdutoEnum.ATIVO, StatusProdutoEnum.INATIVO].filter(x => this.status[x].value),
      Tags: this.tags,
    };

    this.activeModal.close(Object.fromEntries(Object.entries(filtros).filter(([_, v]) => typeof v === 'number' ? v != null : v?.length > 0)));
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  displayLabelDualKnobWith(value: number) {
    return `${value}%`;
  }

  unsorted = (): number => 0;
}

export type FiltrosProdutoList = Pick<ApiConstraintGetProdutos, 'UnidadesMedidas' | 'IdsCategorias' | 'MargemLucroMinima' | 'MargemLucroMaxima' | 'Precificacao' | 'StatusProduto' | 'Tags'>;
