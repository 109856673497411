import { Component, ViewChild } from '@angular/core';
import { ProdutoListComponent } from './produto-list/produto-list.component';
import { BehaviorSubject } from 'rxjs';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { OperationInProgressComponent } from '../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../../custom-components/no-results/no-results.component';
import { BaseClass } from '../../../globals/base-class';
import { PeriodoLancamentos } from '../../../model/periodo.model';
import { PeriodoService } from '../../../services/periodo.service';
import { PeriodoLancamentosSelecionado$ } from '../../../globals/globals';

@Component({
  selector: 'app-produtos',
  standalone: true,
  imports: [ProdutoListComponent, IconComponent, OperationInProgressComponent, NoResultsComponent],
  templateUrl: './produtos.component.html',
  styleUrl: './produtos.component.scss'
})
export class ProdutosComponent extends BaseClass() {

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  periodo: PeriodoLancamentos;
  nenhumPeriodoCadastrado: boolean = null;

  @ViewChild(ProdutoListComponent) produtoList: ProdutoListComponent;

  constructor(
    private periodoService: PeriodoService,
  ) {
    super();
  }

  ngOnInit() {
    if (!PeriodoLancamentosSelecionado$.value) this.checkPeriodos();

    const sub = PeriodoLancamentosSelecionado$.subscribe({
      next: (periodo) => {
        if (periodo) {
          this.periodo = periodo;
          this.produtoList?.updatePeriodo?.next(periodo);
        }
      }
    });
    this.appendSubscription(sub);
  }

  private checkPeriodos() {
    this.periodoService.getPeriodos().then((res) => {
      if (res.errors) return;
      if (!res.data.length) {
        this.nenhumPeriodoCadastrado = true;
      }
    });
  }
}
