import { Component } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { UsuarioService } from '../../services/usuario.service';
import { PaginatorComponent, PaginatorOpts } from '../../custom-components/paginator/paginator.component';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { ModalService } from '../../services/modal.service';
import { defaultClassModal } from '../../globals/utils';
import { ApiConstraintGetUsuarios } from '../../model/api.model';
import { StorageService } from '../../services/storage.service';
import { ToastService } from '../../services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { DebounceDirective } from '../../directives/debounce.directive';
import { AsyncPipe, NgClass } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { defaultDebounceInput, session$ } from '../../globals/globals';
import { FormsModule } from '@angular/forms';
import { ColumnTableUsuarioList, TableUsuarioListComponent, UsuarioLocalList } from '../../custom-components/table-usuario-list/table-usuario-list.component';
import { Usuario } from '../../model/usuario.model';
import { NovoUsuarioOverlayComponent } from './novo-usuario-overlay/novo-usuario-overlay.component';
import { StatusAtivoEnum } from '../../model/custom-types';

@Component({
  selector: 'app-usuarios',
  standalone: true,
  imports: [
    PaginatorComponent,
    OperationInProgressComponent,
    NgMatIconComponent,
    DebounceDirective,
    AsyncPipe,
    NgbTooltipModule,
    FormsModule,
    NgClass,
    TableUsuarioListComponent,
  ],
  templateUrl: './usuarios.component.html',
  styleUrl: './usuarios.component.scss'
})
export class UsuariosComponent extends BaseClass() {

  usuarios: Array<UsuarioLocalList>;
  usuariosSelecionados: Array<UsuarioLocalList> = [];

  paginatorOpts: PaginatorOpts;

  searchTerm: string;

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true)

  defaultDebounceInput = defaultDebounceInput;

  displayedColumns: Array<ColumnTableUsuarioList>;
  columnsActions: Array<ColumnTableUsuarioList> = ['status', 'edit'];

  constructor(
    private usuarioService: UsuarioService,
    private modalService: ModalService,
    private storageService: StorageService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    const a: Array<ColumnTableUsuarioList> = this.storageService.get('DISPLAY_COLUMNS_USUARIOS_LIST');
    this.displayedColumns = (a?.length > 0 ? a : ['nome', 'email', 'telefone', 'status'] as Array<ColumnTableUsuarioList>);

    this.getUsuarios();
  }

  async getUsuarios() {
    try {
      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_USUARIOS')
      const constraints: ApiConstraintGetUsuarios = {
        Page: this.paginatorOpts?.page,
        PageSize,
        Termo: this.searchTerm,
        // IdEmpresa: 'c95570d8-5bf8-45e0-a9f3-8b58b9723079',
      };

      const usuariosRes = await this.usuarioService.getUsuarios(constraints);
      // const usu = await this.usuarioService.getUserById('4cc56cc3-95a5-415a-8f3e-04fee256b595');
      // const usu = await this.usuarioService.getUserById(session$.value.usuario.id);
      if (usuariosRes.data == null) {
        let messages: string = usuariosRes.error?.map(err => err.message).join('\n') || usuariosRes.errors?.validations?.map(err => err.message).join('\n');
        if (messages) return this.modalService.presentAlert("Erro", messages);
        return this.toastService.show({ body: 'Erro ao buscar usuários', color: 'danger' });
      }

      const { page, pages, pageSize, rowsCount, data } = usuariosRes;
      this.usuarios = data?.map((usuario) => {
        return {
          ...usuario,
          _statusToggle: usuario?.status === StatusAtivoEnum.ATIVO,
          _checked: false,
        };
      });
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      this.toastService.show({ body: 'Erro ao buscar usuários', color: 'danger' });
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  ativoToggleChange(usuario: UsuarioLocalList, toggle: HTMLInputElement) {
    const { _statusToggle, ...toSave } = usuario;

    const loading = this.modalService.presentLoading("Salvando usuário, aguarde...", true);

    toSave.status = toggle.checked ? 1 : 2;

    this.usuarioService.updateUser(toSave).then((res) => {
      if (!res.success) {
        let messages: string = res.error?.map(err => err.message).join('\n') || res.errors?.validations?.map(err => err.message).join('\n');
        if (messages) return this.modalService.presentAlert("Erro", messages);
        return this.toastService.show({ body: 'Erro ao salvar usuário', color: 'danger' });
      }
      this.usuarios.find(p => p.id === usuario.id).status = toSave.status;

      this.toastService.show({ body: `Usuário salvo com sucesso`, color: 'success' });
    }).catch((err) => {
      console.log(err);
      this.toastService.show({ body: 'Erro ao salvar usuário', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    })
  }

  usuarioChecked(ev: { usuario: UsuarioLocalList, checked: boolean }) {
    this.usuarios.find(p => p.id === ev.usuario.id)._checked = ev.checked;
    if (ev.checked) this.usuariosSelecionados.push(ev.usuario);
    else this.usuariosSelecionados = this.usuariosSelecionados.filter(p => p.id !== ev.usuario.id);
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getUsuarios();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_USUARIOS', size);
    this.getUsuarios();
  }

  addEditUsuario(usuario: Usuario = null) {
    const modal = this.modalService.presentModal(NovoUsuarioOverlayComponent, defaultClassModal(this), { usuario: usuario ? this.usuarios?.find(e => e.id === usuario.id) : null }, { windowClass: 'blur-backdrop', });
    modal.dismissed.subscribe({
      next: (res) => {
        if (res === 'saved' || res === 'deleted') this.getUsuarios()
      }
    });
  }

  inputOnSearch() {
    this.getUsuarios();
  }

}
