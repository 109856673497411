import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { inject } from '@angular/core';
import { Session } from '../model/usuario.model';
import { initializePeriodoLancamentosSelecionado, sessionTimeout } from '../globals/globals';
import { UsuarioService } from '../services/usuario.service';
import { ToastService } from '../services/toast.service';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = async (route, state) => {

  try {
    const storageService = inject(StorageService);
    const authService = inject(AuthService);
    const usuarioService = inject(UsuarioService);
    const toastService = inject(ToastService);

    const session: Session = storageService.getSession();
    const router = inject(Router);

    const sessionExpired: boolean = !session?.keepConnected && ((session?.timestamp + sessionTimeout) < Date.now());

    if (!session || sessionExpired) {
      redirectLogin(router, authService, storageService, state.url);
      return false;
    }

    await checkSessionAPI(authService, usuarioService, storageService, toastService, session, router, state);

    initializePeriodoLancamentosSelecionado();

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const checkSessionAPI = async (
  authService: AuthService,
  usuarioService: UsuarioService,
  storageService: StorageService,
  toastService: ToastService,
  session: Session,
  router: Router,
  state: RouterStateSnapshot,
) => {
  try {
    const user = await usuarioService.getUserById(session.usuario?.id);

    if (!user || user.error || user.errors) {
      redirectLogin(router, authService, storageService, state.url);
      toastService.show({ body: 'Sessão expirada', color: 'danger' });
    }

    if (session.usuario && session.usuario.master === undefined) {
      user
      session.usuario.master = user.master;
      storageService.setSession(session);
    }
  } catch (error) {
    redirectLogin(router, authService, storageService, state.url);
  }
}

const redirectLogin = (router: Router, authService: AuthService, storageService: StorageService, redirectTo: string = null) => {
  authService.logout();
  router.navigate(['/login'], { replaceUrl: true, queryParams: { redirectTo } });
}
