import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../environments/environment.development';
import { Produto } from '../model/produto.model';
import { ApiConstraintGetProdutos } from '../model/api.model';
import { DefaultPageSize } from '../globals/globals';
import { StorageService } from './storage.service';
import { TExportacaoProduto } from '../model/custom-types';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService extends BaseHttpService {

  getProdutosPeriodo(periodo: string, constraints?: ApiConstraintGetProdutos) {
    const storageService = new StorageService();

    const { Termo, Page, PageSize, Tags, ...rest } = constraints || {};

    const params = Object.fromEntries(Object.entries({
      Termo: constraints?.Termo?.trim() || null,
      Page: constraints?.Page || 1,
      PageSize: constraints?.PageSize || storageService.get('PAGE_SIZE_LIST_PRODUTOS') || DefaultPageSize.produtos,
      Tags: constraints?.Tags?.map(x => x.trim()) || null,
      ...rest,
    } as ApiConstraintGetProdutos).filter(x => x[1] != undefined))
    return this.get<Produto>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.produtos}`, params);
  }

  getProdutoPeriodoById(periodo: string, id: string) {
    return this.get<Produto>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.produtos}/${id}`);
  }

  searchProdutosPeriodo(periodo: string, termo: string) {
    return this.get<Produto>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.produtos}`, { Termo: termo });
  }

  insertProdutoPeriodo(periodo: string, produto: Partial<Produto>) {
    return this.post<Produto>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.produtos}`, produto);
  }

  updateProdutoPeriodo(periodo: string, produto: Partial<Produto>) {
    return this.put<Produto>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.produtos}/${produto.id}`, produto);
  }

  importarProdutosPeriodoArquivo(periodo: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post(`${environment.api.endpoints.periodos}/${periodo}/produtos/importacao`, formData);
  }

  exportarProdutosPeriodo(idPeriodo: string, formato: TExportacaoProduto, constraints: ApiConstraintGetProdutos) {
    const { Termo, Page, PageSize, Tags, ...rest } = constraints || {};

    const params = Object.fromEntries(Object.entries({
      formato,
      Termo: constraints?.Termo?.trim() || null,
      Tags: constraints?.Tags?.map(x => x.trim()) || null,
      ...rest,
    } as ApiConstraintGetProdutos).filter(x => x[1]))

    return this.post(`${environment.api.endpoints.periodos}/${idPeriodo}/produtos/exportacao`, params);
  }

  deleteProdutoPeriodo(periodo: string, id: string) {
    return this.delete<Produto>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.produtos}`, id);
  }

}
