<div class="d-contents">
  <div id="container">
    <div id="content" class="m-4 position-relative">
      <div id="header">
        <div id="title" class="d-flex justify-content-between align-items-center">
          <h3 color="titulo" class="fw-600">Filtros</h3>
          <button class="btn" (click)="dismiss()">
            <ng-mat-icon name="close" />
          </button>
        </div>
      </div>

      <div id="body" class="py-3">
        <div class="d-flex flex-column gap-4">
          <div class="w-100 d-flex flex-column gap-2">
            <small-label> Unidades </small-label>

            <div class="input-group input-icon-end position-relative input-icon-end">
              <input type="text" class="form-control lh-2" placeholder="Busque pela Unidade" [TagsInput]="unidadesMedida" />
              <ng-mat-icon placement="top" slot="end" name="search" color="info" />
            </div>

            <div class="d-flex gap-2">
              @for (unidade of unidadesMedida; track $index) {
                <badge [text]="unidade" [showCloseButton]="true" (close)="removeUnidadeMedida($index)" />
              }
            </div>
          </div>

          <hr class="divider" />

          <div class="w-100 d-flex flex-column gap-2">
            <small-label> Categoria </small-label>

            <div class="input-group input-icon-end position-relative input-icon-end">
              <input
                #ref
                #instance="ngbTypeahead"
                [disabled]="!categorias || categorias.length === 0"
                id="typeahead-focus"
                type="text"
                class="form-control"
                placeholder="Busque pela Categoria"
                (selectItem)="selectedCategoriaInputChanged($event, ref)"
                [(ngModel)]="categoriaSearch"
                [ngbTypeahead]="search"
                (focus)="focus$.next($any($event).target.value)"
                (click)="click$.next($any($event).target.value)"
                [inputFormatter]="categoriaTypeaheadFormatter"
                [resultFormatter]="categoriaTypeaheadFormatter"
              />

              <ng-mat-icon placement="top" slot="end" name="search" color="info" />
            </div>

            @if (selectedCategorias?.length > 0) {
              <div class="d-flex gap-2">
                @for (categoria of selectedCategorias; track $index) {
                  <badge type="clear" color="dark" class="user-select-nome">
                    <div class="d-flex gap-3 align-items-center">
                      <icon-in-ellipse [matIcon]="categoria.icone" [bgColor]="categoria.cor" />
                      <span class="fw-100" color="cinza"> {{ categoria.nome }} </span>
                      <ng-mat-icon name="close" color="black" class="cursor-pointer" (click)="removeCategoria(categoria)" />
                    </div>
                  </badge>
                }
              </div>
            }
          </div>

          <hr class="divider" />

          <div class="w-100 d-flex flex-column gap-2">
            <small-label> Margem de Lucro </small-label>

            <mat-slider [min]="0" [max]="100" color="primary" [disableRipple]="true" [discrete]="true" [displayWith]="displayLabelDualKnobWith" [step]="1" class="thumb-label-always-visible">
              <input [(ngModel)]="margemLucro.min" matSliderStartThumb />
              <input [(ngModel)]="margemLucro.max" matSliderEndThumb />
            </mat-slider>
          </div>

          <hr class="divider" />

          <div class="w-100 d-flex flex-column gap-2">
            <small-label> Precificação </small-label>

            <div class="d-flex gap-2">
              @for (_precificacao of precificacao | keyvalue: unsorted; track $index) {
                <badge class="cursor-pointer user-select-nome" [type]="precificacao[_precificacao.key].value ? 'filled' : null">
                  <div class="d-flex gap-2 align-items-center" (click)="precificacao[_precificacao.key].value = true">
                    <icon-in-ellipse [matIcon]="_precificacao.value.icon" [color]="_precificacao.value.color" />
                    <span> {{ _precificacao.value.label }} </span>
                  </div>
                  @if (precificacao[_precificacao.key].value) {
                    <ng-mat-icon name="close" [color]="precificacao[_precificacao.key].value ? 'white' : 'primary'" (click)="precificacao[_precificacao.key].value = false" />
                  }
                </badge>
              }
            </div>
          </div>

          <div class="w-100 d-flex flex-column gap-2">
            <small-label> Status do produto </small-label>

            <div class="d-flex gap-2">
              @for (_status of status | keyvalue: unsorted; track $index) {
                <badge class="cursor-pointer user-select-nome" [type]="status[_status.key].value ? 'filled' : null">
                  <span (click)="status[_status.key].value = true"> {{ _status.value.label }} </span>
                  @if (status[_status.key].value) {
                    <ng-mat-icon name="close" [color]="status[_status.key].value ? 'white' : 'primary'" (click)="status[_status.key].value = false" />
                  }
                </badge>
              }
            </div>
          </div>

          <hr class="divider" />

          <div class="w-100 d-flex flex-column gap-2">
            <small-label> Tags </small-label>

            <div class="input-group input-icon-end position-relative input-icon-end">
              <input type="text" class="form-control lh-2" placeholder="Busque pela Tag" [TagsInput]="tags" />
              <ng-mat-icon placement="top" slot="end" name="search" color="info" />
            </div>

            <div class="d-flex gap-2">
              @for (tag of tags; track $index) {
                <badge [text]="tag" [showCloseButton]="true" (close)="removeTag($index)" />
              }
            </div>
          </div>

          <div id="button" class="d-flex justify-content-end mt-5 gap-3">
            <button class="btn btn-outline-danger" (click)="clear()">Limpar</button>
            <button class="btn btn-outline-primary" (click)="dismiss()">Fechar</button>
            <button class="btn btn-primary" (click)="confirm()">Aplicar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
