<div id="container" class="mb-4">
  <div id="faturamento">
    <h1 class="title mb-4">Faturamento e Margem de Lucro</h1>

    @if (!inputFaturamentoEstimado.control?.value) {
      <note
        color="azul"
        text="Para a realização dos cálculos, é preciso informar o Faturamento Estimado. Os cálculos serão realizados com base nesse valor estimado e posteriormente o faturamento será atualizado para o Faturamento Calculado."
      />
    }

    <div class="cards mt-4 pt-2 gap-4">
      <card-with-icon
        title="Margem de Lucro Desejado"
        icon="attach_money"
        iconBgColor="secondary"
        [input]="inputMargemLucro"        
      />

      <card-with-icon
        title="Faturamento Estimado"
        icon="receipt"
        iconBgColor="azul"
        [input]="inputFaturamentoEstimado"
      />
    </div>
  </div>

  <div id="despesas" class="mt-5 mb-5">
    <h1 class="title">Despesas e saídas</h1>

    <h3 class="subtitle">Clique nos cards e informe os valores das suas despesas.</h3>

    <div class="cards mt-4 pt-2 gap-4">
      @for (card of cardsSmall; track $index) {
        <card-small [card]="card" [periodo]="periodo" />
      }
    </div>
  </div>
</div>
