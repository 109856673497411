import { AsyncPipe, KeyValue, KeyValuePipe, NgClass } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { NgMatIconComponent } from '../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { RGBColors } from '../../globals/globals';
import { ApexAxisChartSeries, ApexOptions, ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { CardWithIconComponent } from '../../custom-components/card-with-icon/card-with-icon.component';
import { FormsModule } from '@angular/forms';
import { ColorDirective } from '../../directives/color.directive';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../custom-components/no-results/no-results.component';
import { DashboardService } from '../../services/dashboard.service';
import { PeriodoAnaliseComparativa } from '../../model/dashboard.model';
import { BehaviorSubject } from 'rxjs';

type ComparableStats = Pick<PeriodoAnaliseComparativa, 'faturamentoEstimado' | 'faturamentoCalculado' | 'lucroDesejado' | 'lucro' | 'margemContribuicao' | 'pontoEquilibrio'>;

const SCROLL_AMOUNT = 250;

@Component({
  selector: 'app-analisar-comparar',
  standalone: true,
  imports: [
    NgClass,
    NgMatIconComponent,
    KeyValuePipe,
    NgApexchartsModule,
    CardWithIconComponent,
    FormsModule,
    ColorDirective,
    OperationInProgressComponent,
    NoResultsComponent,
    NgMatIconComponent,
    AsyncPipe,
  ],
  templateUrl: './analisar-comparar.component.html',
  styleUrl: './analisar-comparar.component.scss'
})
export class AnalisarCompararComponent extends BaseClass() {

  @ViewChild('labels', { read: ElementRef }) labels: ElementRef<HTMLDivElement>;
  @ViewChild('apx-chart', { read: ElementRef }) chart: ElementRef<ChartComponent>;

  loading: boolean = false;
  showOptions: boolean = this.isDesktop;
  periodos: Array<PeriodoAnaliseComparativaLocal> = [];
  periodos$: BehaviorSubject<Array<PeriodoAnaliseComparativaLocal>> = new BehaviorSubject(null);

  tipos: Array<TipoPeriodo> = [
    { name: 'periodo', value: 'Períodos', checked: true, },
    { name: 'simulacao', value: 'Simulações', checked: false, },
  ];

  stats: Stats = {
    faturamentoCalculado: { label: 'Faturamento Calculado', values: [], color: RGBColors[0], selected: true, },
    faturamentoEstimado: { label: 'Faturamento Estimado', values: [], color: RGBColors[1], selected: true, },
    lucroDesejado: { label: 'Margem de Lucro Desejada', values: [], color: RGBColors[2], selected: true, },
    lucro: { label: 'Margem de Lucro Calculada', values: [], color: RGBColors[3], selected: true, },
    margemContribuicao: { label: 'Margem de Contribuição', values: [], color: RGBColors[4], selected: true, },
    pontoEquilibrio: { label: 'Ponto de Equilíbrio', values: [], color: RGBColors[5], selected: true, },
  };

  chartOptions: ApexOptions = {
    series: [] as ApexAxisChartSeries,
    chart: {
      type: 'bar',
      height: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      forceNiceScale: true,
      labels: {
        formatter: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      }
    },
    noData: {
      text: 'Selecione o que comparar'
    }
  };

  constructor(
    private dashboardService: DashboardService,
  ) {
    super();
  }

  ngOnInit() {
    this.getAnaliseComparativa();
  }

  async getAnaliseComparativa() {
    this.loading = true;

    const periodos = await this.dashboardService.analiseComparativa();
    this.periodos = periodos.map(p => ({ ...p, _selected: true }));
    this.displayPeriodos();

    this.loading = false;
  }

  private displayPeriodos() {
    this.periodos$.next(this.periodos?.filter((p) => {
      const eSimulacao = p.simulacao;
      const simulacoesChecked = this.tipos.find(t => t.checked && t.name === 'simulacao');
      const periodosChecked = this.tipos.find(t => t.checked && t.name === 'periodo');

      return (eSimulacao && simulacoesChecked) || (!eSimulacao && periodosChecked);
    }));

    this.updateChart();
  }

  toggleOptionsPanel() {
    this.showOptions = !this.showOptions;
    this.chart?.nativeElement?.updateSeries(this.chartOptions.series);
  }

  selectTipos() {
    this.displayPeriodos();
  }

  selectColumn(stat: KeyValue<string, Stat>) {
    stat.value.selected = !stat.value.selected
    this.updateChart();
  }

  selectPeriodo(periodoAnalise: PeriodoAnaliseComparativaLocal) {
    periodoAnalise._selected = !periodoAnalise._selected;
    this.updateChart();
  }

  private updateChart() {
    const selectedPeriodos = this.periodos$.value.filter(p => p._selected);

    this.chartOptions.xaxis = {
      categories: selectedPeriodos.map(p => p.nome || p.idPeriodo),
      overwriteCategories: selectedPeriodos.map(p => p.nome || p.idPeriodo),
    }

    this.chartOptions.series = Object.entries(this.stats).filter(x => x[1].selected).map((x) => ({
      name: x[1].label,
      data: selectedPeriodos.map(p => (p[x[0] as keyof ComparableStats] as number) || 0),
      color: `rgba(${x[1].color}, .8)`,
    }));
  }

  scrollLabels(direction: 'left' | 'right') {
    this.labels.nativeElement.scrollBy({
      behavior: 'smooth',
      left: SCROLL_AMOUNT * (direction === 'left' ? -1 : 1),
    })
  }

}

type PeriodoAnaliseComparativaLocal = PeriodoAnaliseComparativa & { _selected: boolean };
type Stats = {
  [key in keyof ComparableStats]: Stat
}
type Stat = {
  label: string,
  values: Array<number>,
  color: string,
  selected: boolean,
}
type TipoPeriodo = {
  name: 'simulacao' | 'periodo',
  value: string,
  checked: boolean,
}
