import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { BehaviorSubject } from 'rxjs';
import { aGreaterThenB } from '../../globals/utils';
import { AsyncPipe, CurrencyPipe, NgClass, PercentPipe } from '@angular/common';
import { OperationInProgressComponent } from '../operation-in-progress/operation-in-progress.component';
import { ColorDirective } from '../../directives/color.directive';
import { IconInEllipseComponent } from '../icon-in-ellipse/icon-in-ellipse.component';
import { IconComponent } from '../icon/icon.component';
import { PaginatorComponent, PaginatorOpts } from '../paginator/paginator.component';
import { StorageService } from '../../services/storage.service';
import { FormsModule } from '@angular/forms';
import { Empresa } from '../../model/empresa.model';
import { NgxMaskPipe } from 'ngx-mask';
import { TipoPessoaPipe } from '../../pipes/tipo-pessoa.pipe';
import { StatusAtivoEnum } from '../../model/custom-types';

@Component({
  selector: 'table-empresa-list',
  standalone: true,
  imports: [
    NgClass,
    OperationInProgressComponent,
    AsyncPipe,
    ColorDirective,
    CurrencyPipe,
    PercentPipe,
    IconInEllipseComponent,
    IconComponent,
    PaginatorComponent,
    FormsModule,
    NgxMaskPipe,
    TipoPessoaPipe,
  ],
  templateUrl: './table-empresa-list.component.html',
  styleUrl: './table-empresa-list.component.scss'
})
export class TableEmpresaListComponent extends BaseClass() {

  @Input({ required: true }) empresas: Array<EmpresaLocalList>;
  @Input({ required: true }) operationInProgress$: BehaviorSubject<boolean>;
  @Input({ required: true }) paginatorOpts: PaginatorOpts;

  @Input() columns: Array<ColumnTableEmpresaList>;

  @Output() ativoToggleChange: EventEmitter<{ empresa: EmpresaLocalList, input: HTMLInputElement }> = new EventEmitter();
  @Output() addEditEmpresa: EventEmitter<EmpresaLocalList> = new EventEmitter();
  @Output() updatePageSize: EventEmitter<number> = new EventEmitter();
  @Output() navigatePage: EventEmitter<number> = new EventEmitter();

  @Output() checkedChanged: EventEmitter<{ empresa: EmpresaLocalList, checked: boolean }> = new EventEmitter();

  // COLUMNS_WIDTH = ["33px", "auto", "80px", "120px", "100px", "100px", "100px", "150px", "115px", "150px"];
  COLUMNS_WIDTH = ["33px", "auto", "80px", "140px", "260px", "140px", "160px", "60px", "90px",];
  COLUMNS_AREAS: Array<ColumnTableEmpresaList | 'actions'> = ['checkbox', 'nome', 'tipo', 'cpfCnpj', 'logradouro', 'bairro', 'cidade', 'estado', 'actions'];

  gridTemplateColumns = this.COLUMNS_WIDTH.join(' ');
  gridTemplateAreas = this.COLUMNS_AREAS.join(' ');

  ordering: Ordering = {
    by: 'nome',
    direction: 'up',
  };

  constructor(private storageService: StorageService) {
    super();
  }

  ngOnChanges() {
    if (this.empresas.length) this.setEmpresas();

    const displayColumns = this.COLUMNS_AREAS.filter(x => x === 'actions' ? this.columns.filter(y => y === 'status' || y === 'edit').length >= 1 : this.columns.includes(x));
    this.gridTemplateAreas = displayColumns.join(' ');
    this.gridTemplateColumns = displayColumns.map(c => this.COLUMNS_WIDTH[this.COLUMNS_AREAS.indexOf(c)]).join(' ');
  }

  ngOnInit() {
    this.setEmpresas();
  }

  private async setEmpresas() {
    this.empresas = this.empresas?.map(p => ({
      ...p,
      _statusToggle: p.status === StatusAtivoEnum.ATIVO,
    }));
  }

  orderBy(orderBy: OrderBy) {
    this.ordering.direction = this.ordering.by === orderBy ? this.ordering.direction === 'down' ? 'up' : 'down' : 'up';
    this.ordering.by = orderBy;
    this.order();
  }

  private order() {
    const ordered = this.empresas.sort((e1, e2) => {
      switch (this.ordering.by) {
        case 'nome':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.nome, e2.nome);
          return aGreaterThenB(e2.nome, e1.nome);

        case 'cpfCnpj':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.cpfCnpj, e2.cpfCnpj);
          return aGreaterThenB(e2.cpfCnpj, e1.cpfCnpj);

        case 'tipo':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.tipo, e2.tipo);
          return aGreaterThenB(e2.tipo, e1.tipo);

        case 'logradouro':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.logradouro, e2.logradouro);
          return aGreaterThenB(e2.logradouro, e1.logradouro);

        case 'bairro':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.bairro, e2.bairro);
          return aGreaterThenB(e2.bairro, e1.bairro);

        case 'cidade':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.cidade, e2.cidade);
          return aGreaterThenB(e2.cidade, e1.cidade);

        case 'estado':
          if (this.ordering.direction === 'up')
            return aGreaterThenB(e1.estado, e2.estado);
          return aGreaterThenB(e2.estado, e1.estado);
      }
    }).map(p => ({ ...p, _statusToggle: p.status === StatusAtivoEnum.ATIVO }));

    this.empresas = ordered;
  }

  _navigatePage(page: number) {
    this.navigatePage.emit(page);
  }

  _updatePageSize(size: number) {
    this.updatePageSize.emit(size);
    this.storageService.set('PAGE_SIZE_LIST_EMPRESAS', size);
  }

}

export type EmpresaLocalList = Empresa & {
  _statusToggle: boolean,
  _checked: boolean,
};

export type OrderBy = 'nome' | 'tipo' | 'cpfCnpj' | 'logradouro' | 'bairro' | 'cidade' | 'estado';
type OrderingDirection = 'up' | 'down';
export type Ordering = {
  by: OrderBy;
  direction: OrderingDirection;
};

export type ColumnTableEmpresaList = 'checkbox' | 'nome' | 'tipo' | 'cpfCnpj' | 'logradouro' | 'bairro' | 'cidade' | 'estado' | 'status' | 'edit';

export const ColumnsTableEmpresaListToDisplay: { [key in ColumnTableEmpresaList]: string } = {
  checkbox: null,
  nome: 'Nome',
  tipo: 'Tipo',
  bairro: 'Bairro',
  cidade: 'Cidade',
  cpfCnpj: 'CPF/CNPJ',
  estado: 'Estado',
  logradouro: 'Logradouro',

  status: null,
  edit: null,
};
