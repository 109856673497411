<div id="container" class="h-100 w-100" [class.close-menu]="menu.closing">
  <div id="backdrop" (click)="closeMenu()" [ngClass]="{ 'h-100 w-100': !isDesktop }"></div>

  <nav id="menu" class="nav flex-column h-100" [ngClass]="{ 'pt-5 ps-3 gap-3': !isDesktop }">
    @if (!isDesktop) {
      <div class="nav-link d-flex align-items-end">
        <img [src]="usuario?.imagem || 'https://ui-avatars.com/api/?name=' + usuario?.nome" class="avatar" />
        <p color="dark" class="mb-0 ms-3 d-flex flex-column" [ngClass]="{ 'mt-3': isDesktop }">
          <span class="fw-600"> {{ usuario?.nome }} </span>
          <span class="small"> {{ usuario?.email }} </span>
        </p>
      </div>
    }

    @for (page of pages; track $index) {
      <a
        color="titulo"
        [ngClass]="{ 'flex-column text-center mx-auto p-0 my-3': isDesktop, 'gap-3 fw-600': !isDesktop }"
        class="nav-link d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="page.path.split('/')"
        [queryParams]="page.queryParams || {}"
        [routerLinkActiveOptions]="routerLinkActiveOptions"
        #route="routerLinkActive"
        (isActiveChange)="isActiveChange($event)"
      >
        <icon [name]="route.isActive ? page.icon + '-green' : page.icon" />
        <span [ngClass]="{ 'text-secondary fw-bold': route.isActive, 'ms-2': !isDesktop }"> {{ page.title }} </span>
      </a>
    }

    <a class="nav-link d-flex text-center flex-grow-1" [ngClass]="{ 'flex-column justify-content-end mx-auto px-0': isDesktop, 'align-items-end mb-4 gap-3': !isDesktop }">
      @if (isDesktop) {
        <img [src]="usuario?.imagem || 'https://ui-avatars.com/api/?name=' + usuario?.nome" class="avatar" [ngbPopover]="popContent" placement="end" />

        <div class="d-flex flex-column gap-3">
          <ng-template #popContent>
            <div>
              <p color="dark" class="mb-0 ms-3 d-flex flex-column">
                <span class="fw-600" style="color: var(--bs-cinza, var(--color-cinza));"> {{ usuario?.nomeEmpresa }} </span>
              </p>
              <div class="d-flex align-items-center gap-3 mt-3">
                <p color="dark" class="mb-0 ms-3 d-flex flex-column">
                  <span class="fw-600"> {{ usuario?.nome }} </span>
                  <span class="small"> {{ usuario?.email }} </span>
                </p>
              </div>

              <div class="d-flex gap-2 mt-3 align-items-center justify-content-between">
                <div class="d-flex gap-2 align-items-center cursor-pointer" (click)="changePassword()">
                  <icon name="unlock" />
                  <span [color]="!isMobile ? 'primary' : 'dark'" class="cursor-pointer fw-600"> Alterar Senha </span>
                </div>

                <!-- <div class="d-flex gap-2 align-items-center cursor-pointer" (click)="logout()">
                  <icon name="log-out" />
                  <span [color]="!isMobile ? 'primary' : 'dark'" class="cursor-pointer fw-600"> Sair </span>
                </div> -->
              </div>
            </div>
          </ng-template>
        </div>
      } @else {
        <icon name="log-out" />
      }
      <span (click)="logout()" [color]="!isMobile ? 'primary' : 'dark'" class="cursor-pointer fw-600" [ngClass]="{ 'mt-3': isDesktop }"> Sair </span>
    </a>
  </nav>
</div>
