import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { Usuario } from '../model/usuario.model';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ApiResponsePost, ApiResponsePut, ApiResponseToken } from '../model/api.model';
import { StorageService } from './storage.service';
import { PeriodoLancamentosSelecionado$, session$ } from '../globals/globals';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private endpoints = environment.api.endpoints;
  private url = environment.api.url;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {}

  login(email: string, senha: string) {
    return lastValueFrom(this.http.post<ApiResponseToken>(this.url + this.endpoints.tokens, { email, senha }));
  }

  solicitaCodigo(email: string) {
    return lastValueFrom(this.http.post<ApiResponsePost<null>>(`${this.url}${this.endpoints.usuarios}/codigorecuperacaosenha`, { email }));
  }

  redefinirSenha(email: string, codigoRecuperacao: string, novaSenha: string) {
    return lastValueFrom(this.http.put<ApiResponsePut<null>>(`${this.url}${this.endpoints.usuarios}/redefinirsenha`, { email, codigoRecuperacao, novaSenha }));
  }

  logout() {
    this.storageService.removeSession();
    // this.storageService.remove("CURRENT_PERIODO_ID");
    session$?.complete();
    PeriodoLancamentosSelecionado$?.complete();
  }

}
