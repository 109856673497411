<div>
  <div id="container">
    <div id="content" class="m-4 position-relative">
      <div id="header">
        <div id="title" class="d-flex justify-content-between align-items-center">
          <h3 color="titulo" class="fw-600">Exportar</h3>
          <button class="btn" (click)="dismiss()">
            <icon name="x" />
          </button>
        </div>
      </div>

      <div id="body" class="py-3">
        <div class="d-flex flex-column gap-4">
          <div class="d-flex flex-column gap-3">
            <small-label> Exportar com os filtros aplicados </small-label>

            <div class="list-cards d-flex gap-3">
              @for (option of options; track $index) {
                <card-with-icon [animated]="true" borderColor="info" [showFooter]="false" (clickBody)="optionSelected(option.value, true)">
                  <div class="d-flex flex-column gap-1 align-items-center">
                    <ng-mat-icon [name]="option.matIcon" color="info" />
                    <span color="info"> {{ option.label }} </span>
                  </div>
                </card-with-icon>
              }
            </div>
          </div>

          <div class="d-flex flex-column gap-3">
            <small-label> Exportar toda a lista sem filtros </small-label>

            <div class="list-cards d-flex gap-3">
              @for (option of options; track $index) {
                <card-with-icon [animated]="true" borderColor="info" [showFooter]="false" (clickBody)="optionSelected(option.value, false)">
                  <div class="d-flex flex-column gap-1 align-items-center">
                    <ng-mat-icon [name]="option.matIcon" color="info" />
                    <span color="info"> {{ option.label }} </span>
                  </div>
                </card-with-icon>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="btn btn-outline-info position-absolute" (click)="dismiss()">Fechar</button>
</div>
