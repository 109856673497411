<!--
  BS seta o foco para o primeiro elemento interagível no modal, em dispositivos móveis isso faz com que a tela "suba" pra cima por causa do teclado.
  Esse input invisível previne esse comportamento e, como não é visível, não afeta a interface.
-->
<input [style.display]="'none'" />

<div class="d-flex justify-content-center">
  <section class="form-user d-flex flex-column py-4 mx-5">
    <logo-header title="Criar Conta" />

    <form class="d-flex flex-column gap-1" [formGroup]="form">
      <div class="d-flex flex-column gap-2">
        <div class="row gap-2 w-100 mx-0">
          <div class="form-group d-flex flex-column gap-0 p-0 col">
            <!-- <small-label> Produto Ativo </small-label> -->
            <label class="fw-600 mb-1"> Tipo Pessoa </label>

            <div class="btn-group" role="group">
              <button type="button" class="btn" [class]="'btn-' + (form.controls.tipo?.value === PF ? '' : 'outline-') + 'dark'" (click)="form.controls.tipo.setValue(PF)">Física</button>
              <button type="button" class="btn" [class]="'btn-' + (form.controls.tipo?.value === PJ ? '' : 'outline-') + 'dark'" (click)="form.controls.tipo.setValue(PJ)">Jurídica</button>
              <!-- <button type="button" class="btn" [class]="'btn-' + (produto?.status === 0 ? '' : 'outline-') + 'info'" (click)="produto.status = 0">Inativo</button> -->
            </div>
          </div>

          <!-- <div class="form-group flex-column col px-0">
            <label class="fw-600 mb-1"> Tipo Pessoa </label>

            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-dark">
                <input value="PF" type="radio" name="tipo" autocomplete="off" formControlName="tipo" />
                <span> Física </span>
              </label>
              <label class="btn btn-dark">
                <input value="PJ" type="radio" name="tipo" autocomplete="off" formControlName="tipo" />
                <span> Jurídica </span>
              </label>
            </div>
          </div> -->

          <div class="form-group col px-0">
            <label class="fw-600 mb-1" for="cpf-cnpj"> {{ form.controls.tipo.value === PF ? "CPF" : "CNPJ" }} </label>
            <input
              type="text"
              class="form-control"
              id="cpf-cnpj"
              [placeholder]="form.controls.tipo.value === PF ? '000.000.000-00' : '00.000.000/0000-00'"
              formControlName="cpfCnpj"
              [mask]="form.controls.tipo.value === PF ? '000.000.000-00' : '00.000.000/0000-00'"
            />
          </div>
        </div>

        <div class="form-group">
          <label class="fw-600 mb-1" for="nome"> Nome </label>
          <input type="text" class="form-control" id="nome" placeholder="Seu Nome" formControlName="nome" />
        </div>

        @if (form.controls.tipo.value === PJ) {
          <div class="form-group">
            <label class="fw-600 mb-1" for="responsavel"> Responsável </label>
            <input type="text" class="form-control" id="responsavel" placeholder="Responsável" formControlName="nomeResponsavel" />
          </div>
        }

        <div class="form-group">
          <label class="fw-600 mb-1" for="email"> Email </label>
          <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email" />
        </div>

        <div class="form-group">
          <label class="fw-600 mb-1" for="telefone"> Telefone </label>
          <input type="text" class="form-control" id="telefone" placeholder="(00) 00000-0000" formControlName="telefone" mask="(00)0000-0000||(00)00000-0000" />
        </div>

        <div class="row gap-2 w-100 mx-0">
          <div class="form-group col px-0">
            <label class="fw-600 mb-1" for="estado"> Estado </label>

            <select class="form-select" id="estado" formControlName="estado">
              <option value="" selected>Selecione o estado</option>
              @for (estado of estados; track $index) {
                <option [value]="estado.sigla">{{ estado.nome }}</option>
              }
            </select>
          </div>

          <div class="form-group col px-0">
            <label class="fw-600 mb-1" for="cidade"> Cidade </label>

            <select class="form-select" id="cidade" formControlName="cidade">
              <option value="" selected>Selecione a cidade</option>
              @for (cidade of cidades; track $index) {
                <option [value]="cidade.nome">{{ cidade.nome }}</option>
              }
            </select>
          </div>
        </div>

        <div class="form-group">
          <label class="fw-600 mb-1" for="bairro"> Bairro </label>
          <input type="text" class="form-control" id="bairro" placeholder="Informe o bairro" formControlName="bairro" />
        </div>

        <div class="form-group">
          <label class="fw-600 mb-1" for="logradouro"> Logradouro </label>
          <input type="text" class="form-control" id="logradouro" placeholder="Rua, Avenida, etc" formControlName="logradouro" />
        </div>
      </div>

      <div class="row gap-2 w-100 mx-0 mt-2">
        <button [disabled]="form.disabled" type="button" (click)="dismiss()" class="text-nowrap btn btn-outline-primary col">
          <span> Cancelar </span>
        </button>

        <button [disabled]="form.disabled" type="button" id="submit" (click)="confirm()" class="text-nowrap btn btn-primary col">
          <span> Finalizar Cadastro </span>
        </button>
      </div>
    </form>
  </section>
</div>
