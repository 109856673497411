import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../environments/environment.development';
import { PeriodoAnaliseComparativa, DashboardData } from '../model/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseHttpService {

  getDashboardData(idPeriodo: string) {
    return this.getById<DashboardData>(`${environment.api.endpoints.periodos}`, `${idPeriodo}/dashboard`);
  }

  analiseComparativa() {
    return this.get<PeriodoAnaliseComparativa>(`${environment.api.endpoints.periodos}/analisecomparativa`) as unknown as Promise<Array<PeriodoAnaliseComparativa>>;
  }

}
