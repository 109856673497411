import { Component, Input } from '@angular/core';
import { PeriodoLancamentos } from '../../../../../model/periodo.model';
import { StepperComponent } from '../../../../../custom-components/stepper/stepper.component';
import { Produtos } from '../../../../../model/produto.model';
import { Categoria } from '../../../../../model/categoria.model';
import { BaseClass } from '../../../../../globals/base-class';
import { IconComponent } from '../../../../../custom-components/icon/icon.component';
import { FormControl } from '@angular/forms';
import { ProdutoService } from '../../../../../services/produto.service';
import { OperationInProgressComponent } from '../../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { ModalService } from '../../../../../services/modal.service';
import { defaultClassModal } from '../../../../../globals/utils';
import { PaginatorOpts } from '../../../../../custom-components/paginator/paginator.component';
import { ToastService } from '../../../../../services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { ProdutoListComponent } from '../../../../produtos-wrapper/produtos/produto-list/produto-list.component';
import { NovoProdutoOverlayComponent } from '../../../../produtos-wrapper/produtos/overlays/novo-produto-overlay/novo-produto-overlay.component';
import { OptionNovaBaseValue } from '../../periodos.component';
import { AsyncPipe } from '@angular/common';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../../../../directives/color.directive';

@Component({
  selector: 'app-produtos-periodo',
  standalone: true,
  imports: [
    IconComponent,
    ProdutoListComponent,
    OperationInProgressComponent,
    SmallLabelComponent,
    AsyncPipe,
    ColorDirective,
  ],
  templateUrl: './produtos-periodo.component.html',
  styleUrl: './produtos-periodo.component.scss'
})
export class ProdutosPeriodoComponent extends BaseClass() {
  @Input() periodo: PeriodoLancamentos;
  @Input() tipoBase: OptionNovaBaseValue;
  @Input() Stepper: StepperComponent<PeriodoLancamentos>;

  paginatorSettings: PaginatorOpts;
  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  selectedCategoria: FormControl<Categoria> = new FormControl();

  produtos: Produtos;

  constructor(
    private produtoService: ProdutoService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    const sub = this.operationInProgress$.subscribe({
      next: (value) => this.Stepper.lock = value
    });
    this.appendSubscription(sub);

    this.Stepper.lock = true;

    if (this.periodo?.id) this.getProdutosPeriodo();
    else {
      this.produtos = [];
      this.Stepper.lock = false;
    }
  }

  selectCategoria(categoria: Categoria) {
    if (categoria) this.selectedCategoria.setValue(categoria);
  }

  async getProdutosPeriodo() {
    this.operationInProgress$.next(true);

    try {
      const constraints = {
        Page: this.paginatorSettings?.page,
        PageSize: this.paginatorSettings?.pageSize,
      };
      const res = await this.produtoService.getProdutosPeriodo(this.periodo.id, constraints);
      if (res.errors) return this.toastService.show({ body: 'Erro ao buscar produtos', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data } = res;
      this.produtos = data;
      this.paginatorSettings = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.log(error);
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  novoProduto() {
    const modal = this.modalService.presentModal(NovoProdutoOverlayComponent, defaultClassModal(this), { periodo: this.periodo?.id }, { windowClass: 'blur-backdrop', });
    modal.dismissed.subscribe({
      next: (res) => {
        if (res === 'saved' || res === 'deleted') this.getProdutosPeriodo()
      }
    });
  }
}
