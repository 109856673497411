import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColorDirective } from '../../directives/color.directive';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { BaseClass } from '../../globals/base-class';
import { iconesCategoriaSlice, RGBColors } from '../../globals/globals';
import { MatIcons } from '../../globals/mat-icons-names';
import { ToastService } from '../../services/toast.service';
import { RGBColor } from '../../model/custom-types';
import { NgMatIconComponent } from '../ng-mat-icon/ng-mat-icon.component';
import { MaterialIcon } from 'material-icons';

@Component({
  selector: 'app-select-icon-color-categoria',
  standalone: true,
  imports: [NgMatIconComponent, ColorDirective, SmallLabelComponent],
  templateUrl: './select-icon-color-categoria.component.html',
  styleUrl: './select-icon-color-categoria.component.scss'
})
export class SelectIconColorCategoriaComponent extends BaseClass() {

  selectedColor: RGBColor = null;
  selectedIcon: MaterialIcon = null;

  cores = RGBColors.slice(0, 30);

  iconesSliced = iconesCategoriaSlice();
  icones = MatIcons;

  showAll: boolean = false;

  constructor(
    @Inject('data') private data: Record<string, any> = null,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
  ) {
    super();

    if (this.data) {
      this.selectedColor = this.data['cor'] || null;
      this.selectedIcon = this.data['icone'] || null;
    }
  }

  selectColor(color: RGBColor) {
    this.selectedColor = color;
  }

  selectIcon(icon: MaterialIcon) {
    this.selectedIcon = icon;
  }

  save() {
    if (!this.selectedColor || !this.selectedIcon)
      return this.toastService.show({ body: 'Você deve selecionar um ícone e uma cor', color: 'danger' });

    this.activeModal.close({ cor: this.selectedColor, icone: this.selectedIcon });
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
