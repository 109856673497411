import { Component, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseClass } from '../../globals/base-class';
import { AsyncPipe, CurrencyPipe, JsonPipe, NgClass, PercentPipe } from '@angular/common';
import { CardWithIconComponent } from '../../custom-components/card-with-icon/card-with-icon.component';
import { SmallLabelComponent } from '../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../directives/color.directive';
import { IconComponent } from '../../custom-components/icon/icon.component';
import { Produtos } from '../../model/produto.model';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { OperationInProgressComponent } from '../../custom-components/operation-in-progress/operation-in-progress.component';
import { DashboardData } from '../../model/dashboard.model';
import { ToastService } from '../../services/toast.service';
import { deepCopy, defaultClassModal } from '../../globals/utils';
import { DashboardService } from '../../services/dashboard.service';
import { PeriodoLancamentos } from '../../model/periodo.model';
import { PeriodoLancamentosSelecionado$, RGBColors, session$ } from '../../globals/globals';
import { NoResultsComponent } from '../../custom-components/no-results/no-results.component';
import { CardSmallComponent } from './card-small/card-small.component';
import { ModalCardSmallDashboard, SmallCardDashboard, TDataModalCardSmallDashboard, TypeModalDashboard } from './modal-card-small-dashboard';
import { ModalService } from '../../services/modal.service';
import { ChartOptions } from '../../model/custom-types';

export const cardsSmall = (): Array<SmallCardDashboard> => {
  const cards: Array<SmallCardDashboard> = [
    { customIcon: 'despesas-de-venda-white', iconBgColor: 'rosa', title: 'Despesas de Venda', pipe: 'currency', modal: 'despesas-venda' },
    { icon: 'percent', iconBgColor: 'warning', title: 'Margem de contribuição', pipe: null, modal: 'margem-contribuicao' },
    { customIcon: 'despesas-fixas-white', iconBgColor: 'vermelho', title: 'Despesas Fixas', pipe: 'currency', modal: 'despesas-fixas' },
    { icon: 'balance', iconBgColor: 'roxo', title: 'Ponto de Equilibrio', pipe: 'currency', modal: 'ponto-equilibrio' },
    { customIcon: 'custo-variavel-white', iconBgColor: 'laranja', title: 'Custo Variável', pipe: 'currency', modal: 'custo-variavel' },
  ];

  return deepCopy(cards);
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    RouterModule,
    AsyncPipe,
    JsonPipe,
    CurrencyPipe,
    PercentPipe,
    CardWithIconComponent,
    SmallLabelComponent,
    ColorDirective,
    IconComponent,
    NgClass,
    NgbProgressbar,
    NgApexchartsModule,
    OperationInProgressComponent,
    NoResultsComponent,
    CardSmallComponent,
  ],
  providers: [CurrencyPipe, PercentPipe],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent extends BaseClass(ModalCardSmallDashboard) {

  @ViewChild("chart") chart: ChartComponent;
  chartOptions: Partial<ChartOptions> = {
    chart: {
      type: "donut",
    },
    colors: ['azul', 'titulo', 'verde'],
    legend: { show: false },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          }
        },
      },
    },
  };
  colorsChart: Array<string> = this.chartOptions.colors.map(c => `var(--color-${c})`);

  session = session$;

  currentPeriodo$ = PeriodoLancamentosSelecionado$;

  produtosPeriodo: Produtos;

  RGBColors = RGBColors;

  cardsSmall = cardsSmall();

  dashboardData: DashboardData;

  nenhumPeriodoCadastrado: boolean = null;

  error: boolean = false;

  constructor(
    private dashboardService: DashboardService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    if (this.session.value.usuario.master) return;

    try {
      PeriodoLancamentosSelecionado$.subscribe((p) => {
        if (!p) {
          if (p === null) this.nenhumPeriodoCadastrado = true;
          this.cardsSmall.forEach((_, i) => {
            this.cardsSmall[i].value = 'N/D';
            this.cardsSmall[i].pipe = null;
          });

          this.dashboardData = null;

          this.chartOptions.series = null;

          return;
        }

        this.cardsSmall.forEach(c => c.value = undefined);
        this.dashboardData = undefined;
        this.getData(p)
      });
    } catch (error) {
    }
  }

  private async getData(periodo: PeriodoLancamentos) {
    this.error = false;
    this.chartOptions.series = null;

    try {

      const dashboardData = await this.dashboardService.getDashboardData(periodo.id);

      if (!dashboardData.errors) {
        this.dashboardData = dashboardData;

        [
          [dashboardData.totalDespesasVendas, dashboardData.percentualTotalDespesasVendas],
          [dashboardData.margemContribuicao, dashboardData.percentualMargemContribuicao],
          [dashboardData.totalDespesasFixas, dashboardData.percentualDespesasFixas],
          [dashboardData.pontoEquilibrio, dashboardData.percentualPontoEquilibrio],
          [dashboardData.custoVariavel, dashboardData.percentualCustoVariavel],
        ].forEach((v, i) => {
          const [valor, porcentagem] = v;

          Object.assign(this.cardsSmall[i], {
            value: `${this.currencyPipe.transform(Math.max(0, valor)) || this.currencyPipe.transform(0)}  (${this.percentPipe.transform(porcentagem / 100, '1.0-2')})`,
            pipe: null,
          });
        });

        if (dashboardData.composicaoDinheiroDeixadoNaMesa?.map(x => x.percentual)?.some(x => x > 0)) {
          this.chartOptions.series = dashboardData.composicaoDinheiroDeixadoNaMesa.map(c => c.percentual);
          this.chartOptions.labels = dashboardData.composicaoDinheiroDeixadoNaMesa.map(c => c.nome);
        } else {
          this.chartOptions.series = null;
          this.chartOptions.labels = null;
        }
      } else {
        this.cardsSmall.forEach(c => c.value = 'error');
      }
    } catch (err) {
      console.log(err);
      this.error = true;
      this.cardsSmall.forEach(c => c.value = 'error');
      this.toastService.show({ body: 'Erro ao buscar dados do dashboard', color: 'danger' });
    }
  }

  presentModal(type: TypeModalDashboard, data: TDataModalCardSmallDashboard) {
    this.presentModalCardSmall(this.modalService, type, data, defaultClassModal(this), this.currentPeriodo$.value)
  }
}
