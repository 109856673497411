<div id="container" class="p-2 d-flex flex-column gap-4">
  <h5 id="title" class="fw-500">Lista de categorias</h5>

  <div id="content" class="rounded-3 p-3">
    <div class="input-group input-icon-end position-relative input-icon-end">
      <input type="text" class="form-control lh-2" id="search-input" placeholder="Busque pela categoria" debounce (onChange)="inputOnSearch()" [disabled]="!categorias" [(ngModel)]="searchTerm" />
      <ng-mat-icon class="cursor-pointer" slot="end" name="search" color="roxo" />
    </div>

    <button [disabled]="operationInProgress$.value" type="button" class="btn btn-neutro btn-large gap-4 d-flex w-100 justify-content-start my-3 p2 rounded-4" (click)="addCategoria.emit()">
      <icon class="d-flex align-items-center justify-content-center" name="plus-black" />
      <span class="fw-500"> Nova Categoria </span>
    </button>

    <div class="list">
      @if (!categorias) {
        @if (errorMessage) {
          <no-results color="cinza" [classes]="['fst-italic', 'fw-200']" [message]="errorMessage" />
        } @else {
          <operation-in-progress />
        }
      } @else if (categorias.length === 0) {
        <no-results message="Nenhuma categoria cadastrada" />
      } @else {
        <div class="list-categorias d-flex flex-column gap-3">
          @for (categoria of categorias; track $index) {
            <div class="card px-2 py-3 gap-3" [class.selected]="categoriaSelecionada?.id === categoria.id">
              <div class="form-check form-switch" [ngClass]="{ 'd-flex flex-column p-0 gap-1': isDesktop }">
                @if (isDesktop) {
                  <label for="toggle-status"> Ativo </label>
                } @else {
                  <label color="roxo" class="fw-bold"> {{ categoria.nome }} </label>
                  <icon name="edit-roxo" class="cursor-pointer" (click)="selectCategoria.emit(categoria)" />
                }
                <input
                  [disabled]="operationInProgress$.value"
                  color="roxo"
                  #toggle
                  class="form-check-input mx-auto lh-2"
                  type="checkbox"
                  role="switch"
                  (change)="ativoToggleChange(categoria, toggle)"
                  name="toggle-status"
                  [checked]="categoria.status === CategoriaStatus.ATIVO"
                />
              </div>

              <div class="card-content d-flex flex-column gap-2" [class.cursor-pointer]="!operationInProgress$.value" (click)="operationInProgress$.value || isMobile ? null : selectCategoria.emit(categoria)">
                <div class="d-flex align-items-center justify-content-between">
                  @if (isDesktop) {
                    <label [color]="isMobile ? 'roxo' : 'cinza'" [ngClass]="{ 'fw-bold': isMobile }"> {{ categoria.nome }} </label>
                  }

                  @if (isDesktop) {
                    <section id="icon">
                      <icon name="chevron-right-black" />
                    </section>
                  }
                </div>

                <div class="card-message d-flex" [ngClass]="{ 'gap-4': isDesktop, 'flex-column gap-2': isMobile }">
                  <div>
                    <span [class.fw-bold]="isDesktop"> {{ categoria.margemLucro / 100 | percent }} </span>
                    <span color="cinza" [class.fw-600]="!isDesktop">Margem de lucro</span>
                  </div>

                  <div>
                    <span [class.fw-bold]="isDesktop"> {{ categoria.quantidadeProdutos ?? "N/D" }} </span>
                    <span color="cinza" [class.fw-600]="!isDesktop">Produtos</span>
                  </div>

                  @if (isMobile) {
                    <div>
                      <icon-in-ellipse class="h-100" [matIcon]="categoria.icone ? categoria.icone : iconPlaceholder" [bgColor]="categoria.cor ? categoria.cor : null" [fitParent]="false" />
                      <label color="cinza" [class.fw-600]="!isDesktop"> Cor e ícone </label>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>

        @if (paginatorOpts) {
          <paginator
            [isDisabled]="operationInProgress$.value"
            color="roxo"
            [showLabels]="false"
            [page]="paginatorOpts?.page"
            [pages]="paginatorOpts.pages"
            [pageSize]="paginatorOpts.pageSize"
            [rowsCount]="paginatorOpts.rowsCount"
            [isDisabled]="operationInProgress$.value || !categorias?.length"
            (updatePageSize)="updatePageSize($event)"
            (navigatePage)="navigatePage($event)"
          />
        }
      }
    </div>
  </div>
</div>
