<div id="container">
  <div id="content" class="m-4">
    <div id="header">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">{{ empresa?.id ? "Editar Empresa" : "Nova Empresa" }}</h3>
        <button class="btn" (click)="dismiss()">
          <icon name="x" />
        </button>
      </div>

      <!-- <div id="subtitle">
        <p class="fw-500" color="cinza">Aqui vem as informações explicando o que é o termo em questão, links com chamadas para vídeos explicativos, artigos, etc.</p>
      </div> -->
    </div>

    <div id="form" class="mt-4">
      <div class="d-flex justify-content-between gap-4">
        <div class="d-flex justify-content-between align-items-end">
          <div class="form-group d-flex flex-column gap-2">
            <small-label> Empresa Ativa </small-label>

            <div class="btn-group" role="group">
              <button type="button" class="btn" [class]="'btn-' + (empresa?.status === EmpresaStatus.ATIVO ? '' : 'outline-') + 'primary'" (click)="empresa.status = EmpresaStatus.ATIVO">Ativo</button>
              <button type="button" class="btn" [class]="'btn-' + (empresa?.status === EmpresaStatus.INATIVO ? '' : 'outline-') + 'primary'" (click)="empresa.status = EmpresaStatus.INATIVO">Inativo</button>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-end">
          <div class="form-group d-flex flex-column gap-2">
            <small-label> Tipo </small-label>

            <div class="btn-group" role="group">
              <button type="button" class="btn" [class]="'btn-' + (empresa?.tipo === TipoPessoa.PF ? '' : 'outline-') + 'primary'" (click)="empresa.tipo = TipoPessoa.PF">{{ TipoPessoa.PF | tipoPessoa: false }}</button>
              <button type="button" class="btn" [class]="'btn-' + (empresa?.tipo === TipoPessoa.PJ ? '' : 'outline-') + 'primary'" (click)="empresa.tipo = TipoPessoa.PJ">{{ TipoPessoa.PJ | tipoPessoa: false }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mt-3 d-flex flex-column gap-2">
        <small-label> CNPJ/CPF <span>*</span> </small-label>
        <input type="text" class="form-control" placeholder="Insira o CNPJ/CPF" [(ngModel)]="empresa.cpfCnpj" [mask]="'CPF_CNPJ'" (blur)="focusOutCpfCnpj()" />
      </div>

      <div class="form-group mt-3 d-flex flex-column gap-2">
        <small-label> Nome <span>*</span> </small-label>
        <input type="text" class="form-control" placeholder="Insira o nome da empresa" [(ngModel)]="empresa.nome" />
      </div>

      @if (!empresa?.id) {
        <div class="form-group mt-3 d-flex flex-column gap-2">
          <small-label> Responsável <span>*</span> </small-label>
          <input type="text" class="form-control" placeholder="Insira o nome do responsável" [(ngModel)]="empresa.nomeResponsavel" />
        </div>
      }

      <div class="row gap-2 w-100 mx-0 mt-3">
        <div class="form-group col px-0 d-flex flex-column gap-2">
          <small-label> Estado <span>*</span> </small-label>

          <input
            #instanceEstado="ngbTypeahead"
            type="text"
            class="form-control"
            [(ngModel)]="controlEstado"
            [ngbTypeahead]="searchEstado"
            [selectOnExact]="true"
            [editable]="false"
            (focus)="focusEstado$.next($any($event).target.value)"
            (click)="clickEstado$.next($any($event).target.value)"
            [inputFormatter]="formatterNgbTypeahead"
            [resultFormatter]="formatterNgbTypeahead"
            placeholder="Selecione o estado"
            (ngModelChange)="estadoChanged($event)"
            autocomplete="disabled"
          />
        </div>

        <div class="form-group col px-0 d-flex flex-column gap-2">
          <small-label> Cidade <span>*</span> </small-label>

          <input
            #instanceCidade="ngbTypeahead"
            [disabled]="!empresa.estado || !cidades"
            type="text"
            class="form-control"
            [(ngModel)]="controlCidade"
            [ngbTypeahead]="searchCidade"
            [selectOnExact]="true"
            [editable]="false"
            (focus)="focusCidade$.next($any($event).target.value)"
            (click)="clickCidade$.next($any($event).target.value)"
            [inputFormatter]="formatterNgbTypeahead"
            [resultFormatter]="formatterNgbTypeahead"
            placeholder="Selecione a cidade"
            autocomplete="disabled"
          />
        </div>
      </div>

      <div class="form-group mt-3 d-flex flex-column gap-2">
        <small-label> Bairro <span>*</span> </small-label>
        <input type="text" class="form-control" placeholder="Insira o bairro" [(ngModel)]="empresa.bairro" />
      </div>

      <div class="form-group mt-3 d-flex flex-column gap-2">
        <small-label> Logradouro <span>*</span> </small-label>
        <input type="text" class="form-control" placeholder="Insira o logradouro" [(ngModel)]="empresa.logradouro" />
      </div>

      @if (!empresa?.id) {
        <div class="row gap-2 w-100 mx-0 mt-3">
          <div class="form-group col px-0 d-flex flex-column gap-2">
            <small-label> Telefone <span>*</span> </small-label>
            <input type="text" class="form-control" placeholder="(00) 00000-0000" [(ngModel)]="empresa.telefone" [mask]="'(00)0000-0000||(00)00000-0000'" />
          </div>

          <div class="form-group col px-0 d-flex flex-column gap-2">
            <small-label> Email <span>*</span> </small-label>
            <input type="email" class="form-control" placeholder="Insira o email" [(ngModel)]="empresa.email" autocomplete="off" />
          </div>
        </div>

        <div class="row gap-2 w-100 mx-0 mt-3">
          <div class="form-group input-senha col px-0 d-flex flex-column gap-2">
            <small-label> Senha <span>*</span> </small-label>
            <input type="password" class="form-control" placeholder="Insira a senha" [(ngModel)]="senha" autocomplete="off" [type]="hidePassword ? 'password' : 'text'" />

            <ng-mat-icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="hidePassword ? 'visibility' : 'visibility_off'" color="dark" />
          </div>

          <div class="form-group input-senha col px-0 d-flex flex-column gap-2">
            <small-label> Confirmar Senha <span>*</span> </small-label>
            <input type="password" class="form-control" placeholder="Confirme a senha" [(ngModel)]="confirmaSenha" autocomplete="off" [type]="hidePassword ? 'password' : 'text'" />

            <ng-mat-icon (click)="hidePassword = !hidePassword" class="cursor-pointer" slot="end" [name]="hidePassword ? 'visibility' : 'visibility_off'" color="dark" />
          </div>
        </div>
      }
    </div>

    <div id="buttons" class="d-flex justify-content-end mt-4 gap-4">
      <!-- @if (empresa?.id) {
        <button type="button" class="px-4 btn btn-danger" (click)="excluir()">Excluir Empresa</button>
      } -->

      <button type="button" class="px-4 btn btn-outline-primary" (click)="dismiss()">Cancelar</button>

      <button type="button" class="px-4 btn btn-primary" (click)="salvar()">Salvar Empresa</button>
    </div>
  </div>
</div>
