import { Component } from '@angular/core';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { NoResultsComponent } from '../../../../custom-components/no-results/no-results.component';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { IconComponent } from '../../../../custom-components/icon/icon.component';
import { PeriodosLancamentos } from '../../../../model/periodo.model';
import { PeriodoService } from '../../../../services/periodo.service';
import { ToastService } from '../../../../services/toast.service';
import { BaseClass } from '../../../../globals/base-class';
import { RouterLink } from '@angular/router';
import { PaginatorComponent, PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../../services/storage.service';
import { ApiConstraintGetPeriodos } from '../../../../model/api.model';
import { BehaviorSubject } from 'rxjs';
import { eSimulacao$ } from '../../periodos-wrapper.component';
import { PeriodoLancamentosSelecionado$ } from '../../../../globals/globals';

@Component({
  selector: 'periodos-list',
  standalone: true,
  imports: [OperationInProgressComponent, NoResultsComponent, DatePipe, IconComponent, NgClass, RouterLink, PaginatorComponent, AsyncPipe],
  templateUrl: './periodos-list.component.html',
  styleUrls: ['./periodos-list.component.scss', '../common-periodos.scss']
})
export class PeriodosListComponent extends BaseClass() {
  PeriodoLancamentosSelecionado$ = PeriodoLancamentosSelecionado$;

  periodos: PeriodosLancamentos;

  paginatorOpts: PaginatorOpts;

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  eSimulacao$ = eSimulacao$;

  constructor(
    private periodoService: PeriodoService,
    private toastService: ToastService,
    private storageService: StorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.getPeriodos();
  }

  async getPeriodos() {
    this.operationInProgress$.next(true);

    try {
      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_PERIODOS')
      const constraints: ApiConstraintGetPeriodos = {
        Page: this.paginatorOpts?.page,
        PageSize,
        Simulacao: this.eSimulacao$.value ?? false,
      };
      const res = await this.periodoService.getPeriodos(constraints);
      if (res.errors) return this.presenteErrorToast();
      const { page, pages, pageSize, rowsCount, data } = res;
      this.periodos = data;
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
    } catch (error) {
      console.error(error);
      this.presenteErrorToast();
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getPeriodos();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PERIODOS', size);
    this.getPeriodos();
  }


  private presenteErrorToast() {
    return this.toastService.show({ body: 'Erro ao buscar os períodos', color: 'danger' });
  }

}
