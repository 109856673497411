<div id="content">
  <input [style.display]="'none'" />
  <div class="modal-header">
    <h5 class="modal-title fw-bolder">{{ header }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body text-pre-line">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer px-2 py-0">
    <button type="button" class="btn btn-dark" (click)="close()">Ok</button>
  </div>
</div>
