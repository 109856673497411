import { Component } from '@angular/core';
import { BaseClass } from '../../../globals/base-class';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { PeriodosListComponent } from './periodos-list/periodos-list.component';
import { AsyncPipe, Location } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { updateUrl } from '../../../globals/utils';
import { eSimulacao$ } from '../periodos-wrapper.component';

@Component({
  selector: 'app-periodos',
  standalone: true,
  imports: [IconComponent, PeriodosListComponent, RouterLink, AsyncPipe],
  templateUrl: './periodos.component.html',
  styleUrls: ['./periodos.component.scss', './common-periodos.scss']
})
export class PeriodosComponent extends BaseClass() {

  optionsNovaBase: Array<OptionNovaBase>;

  selectedOption: OptionNovaBaseValue;

  eSimulacao$ = eSimulacao$

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
  ) {
    super();
    this.selectedOption = this.route.snapshot?.queryParamMap?.get('tipoBase') as OptionNovaBaseValue || OptionNovaBaseValue.Copiar;

    this.optionsNovaBase = [
      {
        title: 'Copiar uma base existente',
        text: `Crie ${this.eSimulacao$.value ? 'uma simulação' : 'um período'} copiando uma base de produtos já existentes.`,
        value: OptionNovaBaseValue.Copiar,
      },
      {
        title: 'Importar um arquivo',
        text: `Importe uma base de produtos de um arquivo, para criar ${this.eSimulacao$.value ? 'uma simulação' : 'um período'}.`,
        value: OptionNovaBaseValue.Importar,
      },
      {
        title: 'Manualmente',
        text: `Crie ${this.eSimulacao$.value ? 'uma simulação' : 'um período'} com uma base vazia e adicione manualmente os produtos.`,
        value: OptionNovaBaseValue.Manualmente,
      }
    ];

    this.selectOption(this.optionsNovaBase.find(o => o.value === this.selectedOption));
  }

  selectOption(option: OptionNovaBase) {
    this.selectedOption = option?.value || OptionNovaBaseValue.Copiar;

    updateUrl(this._location, Object.fromEntries(Object.entries({
      tipoBase: option?.value || OptionNovaBaseValue.Copiar,
      simulador: !!this.eSimulacao$?.value,
    }).filter(x => !!x[1])));
  }
}

type OptionNovaBase = {
  title: string;
  text: string;
  value: OptionNovaBaseValue;
}

export enum OptionNovaBaseValue {
  Copiar = 'copiar',
  Importar = 'importar',
  Manualmente = 'manualmente',
  Editar = 'editar',
};
