<div id="container" class="p-2 d-flex flex-column gap-4" [class.p-4]="!isDesktop">
  <h5 id="title" class="fw-500" [ngClass]="{ 'd-flex justify-content-between align-items-center': !isDesktop }">
    <span> {{ categoria?.id ? "Editar" : "Adicionar" }} Categoria </span>

    @if (!isDesktop) {
      <button class="btn" (click)="dismiss.emit()">
        <ng-mat-icon name="close" color="black" />
      </button>
    }
  </h5>

  <div id="content" class="rounded-3 p-3 d-flex flex-column gap-5">
    <div class="d-flex gap-4" id="form" [class.flex-column]="!isDesktop">
      <div id="color-icon" class="d-flex flex-column gap-2 align-items-center cursor-pointer" [class.mb-3]="!isDesktop" (click)="operationInProgress$.value ? null : selectIconColor()">
        <small-label> Cor e ícone </small-label>
        <icon-in-ellipse
          class="h-100"
          [matIcon]="form.controls.icone.value ? form.controls.icone.value : iconPlaceholder"
          [matIconOutlined]="false"
          [bgColor]="form.controls.cor.value ? 'rgb(' + form.controls.cor.value + ')' : null"
          [fitParent]="true"
        />
      </div>

      <div class="form-group d-flex flex-column gap-2 flex-grow-1" [formGroup]="form">
        <small-label> Nome da categoria </small-label>
        <input type="text" class="form-control lh-2" id="nome" placeholder="Insira um nome para a categoria" formControlName="nome" />
      </div>

      <div class="form-group d-flex flex-column gap-2">
        <small-label> Status </small-label>

        <div class="btn-group" role="group">
          <button [disabled]="operationInProgress$.value" type="button" class="btn" [class.btn-outline]="form.controls.status?.value === CategoriaStatus.INATIVO" (click)="setStatus(CategoriaStatus.ATIVO)" color="roxo">
            Ativo
          </button>
          <button [disabled]="operationInProgress$.value" type="button" class="btn" [class.btn-outline]="form.controls.status?.value === CategoriaStatus.ATIVO" (click)="setStatus(CategoriaStatus.INATIVO)" color="roxo">
            Inativo
          </button>
        </div>
      </div>
    </div>

    @if (categoria) {
      <div id="detalhes-categoria" class="d-flex gap-2 flex-wrap">
        @if (isDesktop) {
          <div class="form-group d-flex flex-column gap-2 flex-grow-0 me-5 flex-shrink-1">
            <small-label> Margem de lucro da categoria </small-label>
            <span class="fw-bold"> {{ categoria.margemLucro == null ? "-" : (categoria.margemLucro / 100 | percent: "1.0-2") }} </span>
          </div>

          <div class="form-group d-flex flex-column gap-2 flex-grow-0 me-5 flex-shrink-1">
            <small-label> Despesas de venda da categoria </small-label>
            <span class="fw-bold"> {{ somatorioDespesasVenda == null ? "-" : (somatorioDespesasVenda / 100 | percent: "1.0-2") }} </span>
          </div>

          <div class="form-group d-flex flex-column gap-2 flex-grow-0 flex-shrink-1">
            <small-label> Produtos na categoria </small-label>
            <span class="fw-bold"> {{ categoria.quantidadeProdutos || "-" }} </span>
          </div>
        }

        <hr class="divider my-4 frex-grow-1" />

        <segments [segments]="segments" color="roxo" [isNavigation]="false" [activeSegment]="activeSegment" (onSegmentChange)="segmentChanged($event)" [nowrap]="true" />

        <div class="w-100 mt-4">
          @switch (activeSegment.path[0]) {
            @case ("margem") {
              <margem-lucro-categoria [control]="margemLucroControl" />
            }
            @case ("despesas") {
              <despesas-venda-categoria [operationInProgress$]="carregandoDespesas" [despesasVenda]="despesasVenda" [categoria]="categoria" (refresh)="refreshCategoria.emit($event)" />
            }
            @case ("produtos") {
              <produtos-categoria [categoria]="categoria" />
            }
          }
        </div>
      </div>
    }

    <div class="d-flex gap-2 justify-content-end">
      @if (categoria?.id) {
        <button [disabled]="operationInProgress$.value" type="button" class="btn btn-outline gap-3" (click)="deleteCategoria()" color="danger">
          <span> Excluir categoria </span>
        </button>
      }

      <button [disabled]="operationInProgress$.value" type="button" class="btn gap-3" (click)="save()" color="roxo">
        <span> Salvar categoria </span>
      </button>
    </div>
  </div>
</div>
