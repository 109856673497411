import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../environments/environment.development';
import { ApiConstraintGetDespesas } from '../model/api.model';
import { StorageService } from './storage.service';
import { DefaultPageSize } from '../globals/globals';
import { Despesa, DespesaGrafico, TipoDespesa } from '../model/despesa.model';

@Injectable({
  providedIn: 'root'
})
export class DespesaService extends BaseHttpService {

  getDespesasPeriodo(periodo: string, constraints?: ApiConstraintGetDespesas) {
    const storageService = new StorageService();
    const params = Object.fromEntries(Object.entries({
      Termo: constraints?.Termo || null,
      Page: constraints?.Page || 1,
      PageSize: constraints?.PageSize || storageService.get('PAGE_SIZE_LIST_DESPESAS') || DefaultPageSize.despesas,
      FirstRow: constraints?.FirstRow || null,
      Tipo: constraints?.Tipo || null,
    }).filter(x => x[1]))
    return this.get<Despesa>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.despesas}`, params);
  }

  getDespesaById(periodo: string, despesaId: string) {
    return this.getById<Despesa>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.despesas}`, despesaId);
  }

  getSomatorioDespesasPeriodo(periodo: string, tipo: TipoDespesa) {
    return this.getById<number>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.despesas}`, 'somatorio', { tipo });
  }

  getDespesasGrafico(periodo: string, tipo: TipoDespesa) {
    return this.get<DespesaGrafico>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.despesas}/grafico`, { tipo }) as Promise<Array<DespesaGrafico>>;
  }

  insertDespesaPeriodo(periodo: string, despesa: Partial<Despesa>) {
    return this.post<Despesa>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.despesas}`, despesa);
  }

  updateDespesaPeriodo(periodo: string, despesa: Partial<Despesa>) {
    return this.put<Despesa>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.despesas}/${despesa.id}`, despesa);
  }

  deleteDespesaPeriodo(periodo: string, despesaId: string) {
    return this.delete(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.despesas}`, despesaId);
  }

}
