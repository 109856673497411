<div id="container">
  <div class="m-4">
    <div id="header" class="mb-5">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">Despesas de Venda</h3>
        <button class="btn" (click)="dismiss()">
          <icon name="x" />
        </button>
      </div>

      <!-- <div id="subtitle">
        <p class="fw-500" color="cinza">Aqui vem as informações explicando o que é o termo em questão, links com chamadas para vídeos explicativos, artigos, etc.</p>
      </div> -->
    </div>

    <div id="content">
      <div class="d-flex align-items-center justify-content-between" [ngClass]="{ 'flex-column-reverse': !isDesktop }">
        <div class="mb-5" id="card-container" [class.w-100]="!isDesktop">
          <card-with-icon color="white" [title]="despesasVenda?.title" [icon]="despesasVenda?.icon" [iconBgColor]="despesasVenda?.iconBgColor" [showForwardIcon]="false" [showFooter]="false">
            @if (!despesasVenda?.value && despesasVenda?.value !== 0) {
              <small-label class="fst-italic" color="cinza">
                <span class="spinner-border spinner-border-sm me-2"></span>
                <span> Carregando... </span>
              </small-label>
            } @else if (despesasVenda?.value === "error") {
              <small-label class="fst-italic" color="danger"> Erro ao carregar </small-label>
            } @else {
              @if (despesasVenda?.pipe === "percent") {
                {{ despesasVenda?.value | percent: "1.0-2" }}
              } @else if (despesasVenda?.pipe === "currency") {
                {{ despesasVenda?.value | currency }}
              } @else {
                <span class="text-pre"> {{ despesasVenda?.value }} </span>
              }
            }
          </card-with-icon>
        </div>

        <div class="mb-5" [class.w-100]="!isDesktop">
          <dropdown
            [isDisabled]="!periodos?.length || operationInProgress"
            [inline]="isDesktop"
            label="Período de lançamentos"
            [placeholder]="periodos?.length === 0 ? 'Nenhum período cadastrado' : 'Selecione um período'"
            [options]="periodos"
            [control]="controlPeriodo"
            displayField="nome"
            (optionSelected)="selectPeriodo($event)"
          />
        </div>
      </div>

      <div class="mb-5">
        <div class="d-flex flex-column gap-3">
          <small-label [color]="'titulo'">
            <span class="fw-600"> Distribuição das despesas </span>
          </small-label>
          @if (progressBarConfig?.values?.length > 0) {
            <progress-bar [config]="progressBarConfig" />
          } @else {
            @if (despesas?.length === 0) {
              <small-label class="fst-italic" color="cinza"> Nenhuma despesa cadastrada </small-label>
            } @else {
              <small-label class="fst-italic" color="cinza">
                <span class="spinner-border spinner-border-sm me-2"></span>
                <span> Carregando... </span>
              </small-label>
            }
          }
        </div>
      </div>

      <div class="mb-5" id="lista-despesas">
        <div class="d-flex flex-column gap-3">
          <small-label [color]="'titulo'">
            <span class="fw-600"> Lista de Despesas </span>
          </small-label>

          @if (showForm) {
            <div id="form" class="my-2" [formGroup]="form">
              <div id="form-content" [ngClass]="{ 'd-flex gap-4 align-items-center': isDesktop }">
                <div class="nome cell form-group d-flex flex-column gap-2">
                  <small-label>
                    <span color="cinza" class="fw-500"> Nome da despesa </span>
                  </small-label>
                  <input type="text" class="form-control me-2 lh-2" id="nome" placeholder="Dê um nome para a despesa" formControlName="nome" />
                </div>

                <div class="tipo form-group d-flex flex-column gap-2">
                  <small-label> Tipo </small-label>

                  <div class="btn-group" role="group">
                    <button
                      type="button"
                      class="btn"
                      [class.btn-outline]="form.controls.tipoAplicacao?.value === TipoAplicacaoDespesa.PERCENTUAL"
                      color="rosa"
                      (click)="form.controls.tipoAplicacao?.setValue(TipoAplicacaoDespesa.VALOR)"
                    >
                      R$
                    </button>
                    <button
                      type="button"
                      class="btn"
                      [class.btn-outline]="form.controls.tipoAplicacao?.value === TipoAplicacaoDespesa.VALOR"
                      color="rosa"
                      (click)="form.controls.tipoAplicacao?.setValue(TipoAplicacaoDespesa.PERCENTUAL)"
                    >
                      %
                    </button>
                  </div>
                </div>

                <div class="valor cell form-group d-flex flex-column gap-2">
                  <small-label>
                    <span color="cinza" class="fw-500"> Valor da despesa </span>
                  </small-label>
                  <input type="number" class="form-control lh-2" id="nome" placeholder="Insira o novo valor" formControlName="valor" [min]="0" />
                </div>

                <div class="actions cell d-flex gap-2 flex-column">
                  <small-label>&nbsp;</small-label>
                  <div class="buttons d-flex gap-2 align-items-center justify-content-center" [class.flex-row-reverse]="!isDesktop">
                    <button class="btn" color="rosa" placement="top" ngbTooltip="Confirmar" [openDelay]="DelayShowTooltip" (click)="saveForm()" [class.flex-grow-1]="!isDesktop">
                      @if (isDesktop) {
                        <icon name="check-white" />
                      } @else {
                        <span> Confirmar </span>
                      }
                    </button>
                    <button class="btn btn-outline" color="rosa" placement="end" ngbTooltip="Cancelar" [openDelay]="DelayShowTooltip" (click)="toggleFormVisibility()" [class.flex-grow-1]="!isDesktop">
                      @if (isDesktop) {
                        <icon name="x-rosa" />
                      } @else {
                        <span> Cancelar </span>
                      }
                    </button>
                    @if (form.controls.id.value) {
                      <button class="btn gap-2" color="rosa" (click)="excluirDespesa()">
                        <icon name="trash-white" />
                        <span>Excluir</span>
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          <div class="list gap-3">
            @if (!showForm) {
              <button type="button" class="btn btn-neutro btn-large gap-4 d-flex w-100 justify-content-start p2 rounded-3" (click)="toggleFormVisibility()">
                <icon class="d-flex align-items-center justify-content-center" name="plus-white" />
                <span class="fw-500"> Nova Despesa </span>
              </button>
            }

            @for (despesa of despesas; track $index) {
              <card-with-icon [showFooter]="false" [showForwardIcon]="false">
                <div class="d-flex flex-column gap-2 justify-content-center">
                  <div class="d-flex justify-content-between align-items-center">
                    <small-label>
                      <span class="fw-600" color="cinza"> {{ despesa.nome }} </span>
                    </small-label>

                    <icon name="edit-rosa" (click)="toggleFormVisibility(despesa)" />
                  </div>
                  @if (despesasVenda?.value !== "error") {
                    {{ despesa.tipoAplicacao === TipoAplicacaoDespesa.VALOR ? (despesa.valor | currency) : (despesa.valor / 100 | percent: "1.0-2") }}
                  } @else {
                    <small-label class="fst-italic" color="danger"> Erro ao carregar </small-label>
                  }
                </div>
              </card-with-icon>
            }
          </div>
        </div>

        @if (paginatorOpts && despesas?.length > 0) {
          <paginator
            [page]="paginatorOpts?.page"
            [pages]="paginatorOpts.pages"
            [pageSize]="paginatorOpts.pageSize"
            [rowsCount]="paginatorOpts.rowsCount"
            [isDisabled]="operationInProgress || !despesas?.length"
            (updatePageSize)="updatePageSize($event)"
            (navigatePage)="navigatePage($event)"
          />
        }
      </div>
    </div>

    <div id="footer">
      <div class="d-flex align-items-center justify-content-end">
        <button class="btn btn-outline" color="rosa" (click)="dismiss()">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>
