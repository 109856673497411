import { Component, Input } from '@angular/core';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'note',
  standalone: true,
  imports: [ColorDirective, IconComponent],
  templateUrl: './note.component.html',
  styleUrl: './note.component.scss'
})
export class NoteComponent {
  @Input() text: string;
  @Input() color: AppColor = 'danger';
}
