<div id="container" class="mb-4">
  <div id="faturamento">
    <h1 class="title mb-4">Faturamento e Margem de Lucro</h1>

    <h3 class="subtitle mb-4">O <span class="fw-bold">Faturamento Estimado</span> foi atualizado para o <span class="fw-bold">Faturamento Calculado</span>, após a realização de todos os cálculos</h3>

    <div class="d-flex flex-wrap gap-4 mt-4 pt-2 mb-5">
      <div iv class="d-flex flex-column gap-4" [class.w-100]="!isDesktop">
        <card-with-icon
          color="white"
          title="Lucro"
          icon="attach_money"
          iconBgColor="secondary"
          (clickBody)="dashboardData ? presentModal('lucro', { lucro: dashboardData.lucro, margemLucroCalculada: dashboardData.margemLucroCalculada, margemLucroDesejada: dashboardData.margemLucroDesejada }) : null"
        >
          <div class="d-flex gap-4" [ngClass]="{ 'flex-column': !isDesktop, 'align-items-center': isDesktop }">
            @if (dashboardData) {
              <label>
                <span color="secondary">{{ dashboardData.lucro | currency }}</span>
              </label>

              <div class="d-flex gap-3">
                <small-label>
                  <span color="secondary" class="fw-bold">{{ dashboardData.margemLucroCalculada / 100 | percent: "1.0-2" }}</span>
                  <span class="ms-1">Real</span>
                </small-label>
                <small-label>
                  <span color="titulo" class="fw-bold">{{ dashboardData.margemLucroDesejada / 100 | percent: "1.0-2" }}</span>
                  <span class="ms-1">Desejado</span>
                </small-label>
              </div>
            } @else {
              @if (dashboardDataError) {
                <small-label color="danger">
                  <span class="fst-italic"> Erro ao carregar </span>
                </small-label>
              } @else {
                <small-label class="fst-italic" color="cinza">
                  <span class="spinner-border spinner-border-sm me-2"></span>
                  <span> Carregando... </span>
                </small-label>
              }
            }
          </div>
        </card-with-icon>

        <card-small [card]="cardPontoEquilibrio" [periodo]="periodo" />
      </div>

      <card-with-icon
        color="white"
        icon="receipt"
        [note]="dashboardData?.faturamentoCalculado > 0 ? 'O Faturamento foi atualizado após a realização de todos os cálculos' : null"
        noteColor="secondary"
        iconBgColor="azul"
        (clickBody)="dashboardData ? presentModal('faturamento', { faturamentoCalculado: dashboardData.faturamentoCalculado, faturamentoEstimado: dashboardData.faturamentoEstimado }) : null"
      >
        <div class="subcards d-flex gap-4" [ngClass]="{ 'flex-column': !isDesktop, 'align-items-center': isDesktop }">
          <div class="subcard">
            <div class="header d-flex gap-2 flex-column">
              <small-label>
                <span color="cinza"> Faturamento calculado </span>
              </small-label>

              <label>
                @if (dashboardData) {
                  <span color="titulo"> {{ dashboardData.faturamentoCalculado > 0 ? (dashboardData.faturamentoCalculado | currency) : "-" }} </span>
                } @else {
                  @if (dashboardDataError) {
                    <small-label color="danger">
                      <span class="fst-italic"> Erro ao carregar </span>
                    </small-label>
                  } @else {
                    <span class="spinner-border spinner-border-sm"></span>
                  }
                }
              </label>
            </div>
          </div>

          <div class="position-relative subcard">
            @if (isDesktop) {
              <hr class="position-absolute h-100 divider vertical" />
            }

            <div class="header d-flex gap-2 flex-column" [class.ms-4]="isDesktop">
              <small-label>
                <span color="cinza"> Faturamento estimado </span>
                @if (isDesktop) {
                  <icon name="chevron-right" />
                }
              </small-label>

              <label>
                @if (dashboardData) {
                  <span color="titulo"> {{ dashboardData.faturamentoEstimado > 0 ? (dashboardData.faturamentoEstimado | currency) : "-" }} </span>
                } @else {
                  @if (dashboardDataError) {
                    <small-label color="danger">
                      <span class="fst-italic"> Erro ao carregar </span>
                    </small-label>
                  } @else {
                    <span class="spinner-border spinner-border-sm"></span>
                  }
                }
              </label>
            </div>
          </div>
        </div>
      </card-with-icon>
    </div>
  </div>

  <div id="despesas" class="mt-5 mb-5">
    <h1 class="title">Despesas e saídas</h1>

    <h3 class="subtitle">Clique nos cards e informe os valores das suas despesas.</h3>

    <div class="cards mt-4 pt-2 gap-4">
      @for (card of cardsSmall; track $index) {
        <card-small [card]="card" [periodo]="periodo" />
      }
    </div>
  </div>
</div>
