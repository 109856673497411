<div id="container">
  <div id="content">
    @if (configSingle) {
      <ngb-progressbar [value]="configSingle.value" [ariaLabel]="configSingle.label" [showValue]="configSingle.showValue" [color]="configSingle.color" [max]="configSingle.total || null" />
    } @else {
      <ngb-progressbar-stacked>
        @for (bar of configStacked.values; track $index) {
          <ngb-progressbar [value]="bar.value" [ariaLabel]="bar.label" [showValue]="bar.showValue" [color]="bar.color" [max]="bar.total || null" />
        }
      </ngb-progressbar-stacked>
    }
  </div>
</div>
