import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, CurrencyPipe, Location, NgClass, PercentPipe } from '@angular/common';
import { IconInEllipseComponent } from '../../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DetalhesProdutoAccordionBodyComponent } from './detalhes-produto-accordion-body/detalhes-produto-accordion-body.component';
import { BehaviorSubject } from 'rxjs';
import { NovoProdutoOverlayComponent } from '../overlays/novo-produto-overlay/novo-produto-overlay.component';
import { FormsModule } from '@angular/forms';
import { PrecificacaoProdutoFacePipe } from '../../../../pipes/precificacao-produto-face.pipe';
import { ColorDirective } from '../../../../directives/color.directive';
import { PaginatorComponent, PaginatorOpts } from '../../../../custom-components/paginator/paginator.component';
import { OperationInProgressComponent } from '../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { BaseClass } from '../../../../globals/base-class';
import { PeriodoLancamentos } from '../../../../model/periodo.model';
import { StatusProdutoEnum } from '../../../../model/produto.model';
import { deepCopy, defaultClassModal, importarPlanilhaProdutos, updateUrl } from '../../../../globals/utils';
import { ModalService } from '../../../../services/modal.service';
import { ProdutoService } from '../../../../services/produto.service';
import { ToastService } from '../../../../services/toast.service';
import { StorageService } from '../../../../services/storage.service';
import {
  ColumnTableProdutoList,
  Ordering,
  ProdutoLocalList,
  TableProdutoListComponent,
} from '../../../../custom-components/table-produto-list/table-produto-list.component';
import { InformacoesAdicionaisProdutosComponent } from '../overlays/informacoes-adicionais-produtos/informacoes-adicionais-produtos.component';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ApiConstraintGetProdutos } from '../../../../model/api.model';
import { DebounceDirective } from '../../../../directives/debounce.directive';
import { DelayShowTooltip, PaginatorPageSize, PeriodoLancamentosSelecionado$ } from '../../../../globals/globals';
import { ExportarProdutosOverlayComponent } from '../overlays/exportar-produtos-overlay/exportar-produtos-overlay.component';
import { ImportarProdutosOverlayComponent } from '../overlays/importar-produtos-overlay/importar-produtos-overlay.component';
import { TExportacaoProduto } from '../../../../model/custom-types';
import { SelecionarColunasListaProdutosComponent } from '../overlays/selecionar-colunas-lista-produtos/selecionar-colunas-lista-produtos.component';
import { FiltrosProdutoList, SelecionarFiltrosListaProdutosComponent } from '../overlays/selecionar-filtros-lista-produtos/selecionar-filtros-lista-produtos.component';
import { OptionsMobileListaProdutosComponent } from '../overlays/options-mobile-lista-produtos/options-mobile-lista-produtos.component';
import { CustomIconComponent } from '../../../../custom-components/custom-icon/custom-icon.component';
import { ActivatedRoute } from '@angular/router';
import { NoResultsComponent } from "../../../../custom-components/no-results/no-results.component";

@Component({
  selector: 'produto-list',
  standalone: true,
  imports: [
    PercentPipe,
    CurrencyPipe,
    CustomIconComponent,
    NgMatIconComponent,
    IconInEllipseComponent,
    PrecificacaoProdutoFacePipe,
    NgClass,
    ColorDirective,
    NgbAccordionModule,
    DetalhesProdutoAccordionBodyComponent,
    PaginatorComponent,
    AsyncPipe,
    OperationInProgressComponent,
    FormsModule,
    TableProdutoListComponent,
    DebounceDirective,
    NgbTooltipModule,
    NoResultsComponent
  ],
  templateUrl: './produto-list.component.html',
  styleUrl: './produto-list.component.scss'
})
export class ProdutoListComponent extends BaseClass() {

  @Input() periodo: PeriodoLancamentos;
  @Input() displayedColumns: Array<ColumnTableProdutoList>;
  @Input() columnsActions: Array<ColumnTableProdutoList> = ['status', 'edit', 'expand'];
  @Input() showActions: boolean = true;
  @Input() IdsCategorias: Array<string>;

  @Output() operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  updatePeriodo: EventEmitter<PeriodoLancamentos> = new EventEmitter();
  produtos: Array<ProdutoLocalList>;
  paginatorOpts: PaginatorOpts;

  ordering: Ordering = {
    OrderBy: 'nome',
    OrderAsc: true,
  };

  DelayShowTooltip = DelayShowTooltip;

  produtosSelecionados: Array<ProdutoLocalList> = [];


  searchTerm: string;

  filtros: FiltrosProdutoList = {
  };

  error: boolean;

  constructor(
    private modalService: ModalService,
    private produtoService: ProdutoService,
    private toastService: ToastService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private _location: Location,
  ) {
    super();
  }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    const { Page, PageSize, OrderBy, OrderAsc } = queryParams;
    if (+Page) this.paginatorOpts = { ...this.paginatorOpts, page: +Page };
    if (+PageSize && PaginatorPageSize.includes(+PageSize)) this.paginatorOpts = { ...this.paginatorOpts, pageSize: +PageSize };
    if (OrderBy) this.ordering.OrderBy = OrderBy;
    if (OrderAsc) this.ordering.OrderAsc = OrderAsc === 'true';

    const a: Array<ColumnTableProdutoList> = this.storageService.get('DISPLAY_COLUMNS_PRODUTOS_LIST');
    if (!this.displayedColumns) this.displayedColumns = (a?.length > 0 ? a : ['nome', 'margem', 'uni', 'qtd', 'custo', 'venda', 'categoria', 'precificacao'])

    const sub = this.updatePeriodo.pipe().subscribe({
      next: (periodo) => {
        if (periodo) {
          this.periodo = periodo;
          this.getProdutosPeriodo();
        }
      }
    });
    this.appendSubscription(sub);

    this.getProdutosPeriodo();
  }

  async getProdutosPeriodo() {
    if (!this.periodo?.id) {
      this.operationInProgress$.next(false);
      return this.toastService.show({ body: 'Período não encontrado', color: 'danger' });
    }

    this.error = false;
    this.operationInProgress$.next(true);
    this.produtos = null;

    try {
      const PageSize = this.paginatorOpts?.pageSize || this.storageService.get('PAGE_SIZE_LIST_PRODUTOS')
      const constraints: ApiConstraintGetProdutos = {
        Page: this.paginatorOpts?.page,
        PageSize,
        Termo: this.searchTerm,
        OrderBy: this.ordering.OrderBy,
        OrderAsc: this.ordering.OrderAsc,
        IdsCategorias: this.IdsCategorias,
      };

      const produtosRes = await this.produtoService.getProdutosPeriodo(this.periodo.id, { ...constraints, ...this.filtros });

      if (produtosRes.errors) return this.toastService.show({ body: 'Erro ao buscar produtos', color: 'danger' });
      const { page, pages, pageSize, rowsCount, data } = produtosRes;
      this.produtos = data.map((produto) => {
        return {
          ...produto,
          _statusToggle: produto.status == StatusProdutoEnum.ATIVO ? true : false,
          _checked: false,
        };
      });
      this.paginatorOpts = { page, pages, pageSize, rowsCount };
      const queryParams = Object.fromEntries(this._location.path()?.split('?')?.[1]?.split('&')?.map((q) => q?.split('=')) || []);
      updateUrl(this._location, { ...queryParams, Page: page, PageSize: pageSize, OrderBy: this.ordering.OrderBy, OrderAsc: this.ordering.OrderAsc });
    } catch (error) {
      console.error(error);
      this.toastService.show({ body: 'Erro ao buscar produtos', color: 'danger' });
      this.error = true;
    } finally {
      this.operationInProgress$.next(false);
    }
  }

  navigatePage(page: number) {
    this.operationInProgress$.next(true);
    this.paginatorOpts.page = page;
    this.getProdutosPeriodo();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PRODUTOS', size);
    this.getProdutosPeriodo();
  }

  ativoToggleChange(produto: ProdutoLocalList, toggle: HTMLInputElement) {
    const { _statusToggle, ...toSave } = produto;

    const loading = this.modalService.presentLoading("Salvando produto, aguarde...", true);

    const periodoId = this.periodo?.id;

    toSave.status = toggle.checked ? 1 : 2;

    this.produtoService.updateProdutoPeriodo(periodoId, toSave).then((res) => {
      if (!res.success) return this.presentToastErrorSave();

      this.toastService.show({ body: `Produto salvo com sucesso`, color: 'success' });
    }).catch((err) => {
      console.log(err);
      this.presentToastErrorSave()
    }).finally(() => {
      loading.dismiss();
    })
  }

  produtoChecked(ev: { produto: ProdutoLocalList, checked: boolean }) {
    this.produtos.find(p => p.id === ev.produto.id)._checked = ev.checked;
    if (ev.checked) this.produtosSelecionados.push(ev.produto);
    else this.produtosSelecionados = this.produtosSelecionados.filter(p => p.id !== ev.produto.id);
  }

  private presentToastErrorSave() {
    this.toastService.show({ body: 'Ocorreu um erro ao salvar o produto, tente novamente mais tarde', color: 'danger' });
  }

  addEditProduto(produto: ProdutoLocalList = null) {
    const modal = this.modalService.presentModal(NovoProdutoOverlayComponent, defaultClassModal(this), { periodo: this.periodo?.id, produto: deepCopy(produto) }, { windowClass: 'blur-backdrop', });
    modal.dismissed.subscribe({
      next: (res) => {
        if (res === 'saved' || res === 'deleted') this.getProdutosPeriodo()
      }
    });
  }

  informacoesAdicionaisProduto(produto: ProdutoLocalList) {
    const modal = this.modalService.presentModal(InformacoesAdicionaisProdutosComponent, defaultClassModal(this), { periodo: this.periodo?.id, produto }, { windowClass: 'blur-backdrop w-700px' });
    modal.dismissed.subscribe({
      next: (res) => {
        if (res === 'saved') this.getProdutosPeriodo()
      }
    });
  }

  exportar() {
    const modal = this.modalService.presentModal(ExportarProdutosOverlayComponent, defaultClassModal(this), {}, { windowClass: 'w-400px' });
    modal.closed.subscribe({
      next: (modalRes: { option: TExportacaoProduto, comFiltros: boolean }) => {
        if (!modalRes) return;

        switch (modalRes.option) {
          case 'xlsx':
          case 'pdf':
            const loading = this.modalService.presentLoading('Exportando produtos', true);

            this.produtoService.exportarProdutosPeriodo(this.periodo.id, modalRes.option, modalRes.comFiltros ? { ...this.filtros, Termo: this.searchTerm } : null).then((res) => {
              if (res.success && res.data) {
                const base64 = res.data;
                const a = document.createElement('a');
                a.href = 'data:application/octet-stream;base64,' + base64;
                a.download = `produtos-${this.periodo.nome}.${modalRes.option}`;
                a.click();
              } else {
                this.toastService.show({ body: res.validations[0].message, color: 'warning' });
              }
            }).catch((err) => {
              console.log(err);
              if (err.error.validations[0]) {
                this.toastService.show({ body: err.error.validations[0].message, color: 'warning' });
              }
              else {
                this.erroExportacao();
              }
            }).finally(() => {
              loading.dismiss();
            });
            break;
        }
      }
    });
  }

  private erroExportacao() {
    this.toastService.show({ body: 'Erro ao exportar produtos', color: 'danger' });
  }

  importar() {
    const modal = this.modalService.presentModal(ImportarProdutosOverlayComponent, defaultClassModal(this), {}, { windowClass: 'w-400px' });
    modal.closed.subscribe({
      next: async (res: { file: File }) => {
        if (!res?.file) return;

        const resImport = await importarPlanilhaProdutos(res.file, this.produtoService, this.periodo.id, this.toastService, this.modalService);
        if (!resImport) return;

        this.getProdutosPeriodo();
      }
    });
  }

  duplicar() {
    this.toastService.show({ body: 'Em Breve!', color: 'info' })
  }

  selecionarTags() {
    this.toastService.show({ body: 'Em Breve!', color: 'info' })
  }

  selecionarFiltros() {
    const modal = this.modalService.presentModal(SelecionarFiltrosListaProdutosComponent, defaultClassModal(this), { filtros: this.filtros }, { windowClass: 'w-700px' });
    modal.closed.subscribe({
      next: (res: FiltrosProdutoList) => {
        if (!res) return;

        this.filtros = res;

        this.getProdutosPeriodo();
      }
    });
  }

  selecionarColunas() {
    const modal = this.modalService.presentModal(SelecionarColunasListaProdutosComponent, defaultClassModal(this), { displayedColumns: this.displayedColumns }, { windowClass: 'w-700px' });
    modal.closed.subscribe({
      next: (res: Array<ColumnTableProdutoList>) => {
        if (!res) return;
        this.displayedColumns = res;
        this.storageService.set('DISPLAY_COLUMNS_PRODUTOS_LIST', res);
      }
    });
  }

  inputOnSearch() {
    this.getProdutosPeriodo();
  }

  optionsMobile() {
    const modal = this.modalService.presentModal(OptionsMobileListaProdutosComponent, 'slide-bottom', {}, { windowClass: 'h-fit-content' });
    modal.closed.subscribe({
      next: (res) => {
        if (!res) return;
        switch (res) {
          case 'exportar':
            this.exportar();
            break;
          case 'importar':
            this.importar();
            break;
          case 'tags':
            this.selecionarTags();
            break;
          case 'colunas':
            this.selecionarColunas();
            break;
        }
      }
    });
  }

  order(ordering: Ordering) {
    this.ordering = ordering;
    this.operationInProgress$.next(true);
    this.getProdutosPeriodo();
  }
}
