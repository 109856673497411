/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { transitionDuration } from './app/globals/globals';

bootstrapApplication(AppComponent, appConfig).then(() => {
  document.documentElement.style.setProperty('--transition-duration', `${transitionDuration}ms`);
  document.documentElement.style.setProperty('--max-screen-size', `unset`);
  // document.documentElement.style.setProperty('--max-screen-size', `${maxScreenSize}px`);
}).catch((err) => {
  console.error(err)
});
