import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe, KeyValuePipe, NgClass, PercentPipe } from '@angular/common';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../../../custom-components/icon/icon.component';
import { ColorDirective } from '../../../../../directives/color.directive';
import { DropdownComponent } from '../../../../../custom-components/dropdown/dropdown.component';
import { BaseClass } from '../../../../../globals/base-class';
import { Produto } from '../../../../../model/produto.model';
import { ProdutoService } from '../../../../../services/produto.service';
import { ToastService } from '../../../../../services/toast.service';
import { ModalService } from '../../../../../services/modal.service';
import { BadgeComponent } from '../../../../../custom-components/badge/badge.component';
import { CardWithIconComponent } from '../../../../../custom-components/card-with-icon/card-with-icon.component';
import { IconInEllipseComponent } from '../../../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { ProgressBarComponent, ProgressBarConfig } from '../../../../../custom-components/progress-bar/progress-bar.component';
import { ApiResponseError } from '../../../../../model/api.model';
import { PeriodoLancamentosSelecionado$ } from '../../../../../globals/globals';
import { PrecificacaoProdutoFacePipe } from '../../../../../pipes/precificacao-produto-face.pipe';

type TProgressBar = 'faturamento' | 'lucro' | 'custo_variavel' | 'ponto_equilibrio'

@Component({
  selector: 'app-informacoes-adicionais-produtos',
  standalone: true,
  imports: [
    SmallLabelComponent,
    IconComponent,
    ColorDirective,
    FormsModule,
    DropdownComponent,
    CurrencyPipe,
    PercentPipe,
    IconInEllipseComponent,
    ColorDirective,
    BadgeComponent,
    CardWithIconComponent,
    NgClass,
    ProgressBarComponent,
    KeyValuePipe,
    PrecificacaoProdutoFacePipe,
  ],
  templateUrl: './informacoes-adicionais-produtos.component.html',
  styleUrl: './informacoes-adicionais-produtos.component.scss'
})
export class InformacoesAdicionaisProdutosComponent extends BaseClass() {

  progressBars: { [key in TProgressBar]: ProgressBarConfig<'single'> & { total: number } };

  produto: Produto;

  quantidade: number;
  precoCusto: number;
  precoVenda: number;

  constructor(
    @Inject('data') public data: { produto: Produto, periodo: string },
    private activeModal: NgbActiveModal,
    private produtoService: ProdutoService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.data?.produto) {
      this.produto = this.data.produto;

      this.quantidade = this.produto.quantidade;
      this.precoCusto = this.produto.precoCusto;
      this.precoVenda = this.produto.precoVenda;

      const total: number = (this.produto.precoVenda - this.produto.precoCusto) * this.produto.quantidade;
      this.progressBars = {
        custo_variavel:
          { color: 'azul', label: 'Custo Variável', showValue: false, value: this.produto.custoTotal, total: this.produto.custoTotalPeriodo, },
        faturamento:
          { color: 'azul', label: 'Faturamento', showValue: false, value: this.produto.faturamentoAtual, total: this.produto.faturamentoTotalPeriodo, },
        lucro:
          { color: 'azul', label: 'Lucro', showValue: false, value: this.produto.lucroTotalAtual, total: this.produto.lucroTotalPeriodo, },
        ponto_equilibrio:
          { color: 'azul', label: 'Ponto de Equilíbrio', showValue: false, value: this.produto.valorPontoEquilibrio, total: this.produto.valorPontoEquilibrioPeriodo, },
      }
    }
  }

  salvar() {
    const { quantidade, precoCusto, precoVenda } = this;

    const toSave: Partial<Produto> = {
      ...this.produto,
      quantidade,
      precoCusto,
      precoVenda,
    }

    const loading = this.modalService.presentLoading("Salvando produto, aguarde...", true);

    const periodoId = this.data?.periodo || PeriodoLancamentosSelecionado$.value?.id;

    this.produtoService.updateProdutoPeriodo(periodoId, toSave).then((res) => {
      if (!res.success) return this.toastService.show({ body: 'Ocorreu um erro ao salvar o produto, tente novamente mais tarde', color: 'danger' });
      console.log(res);
      this.toastService.show({ body: `Produto salvo com sucesso`, color: 'success' });
      this.produto = { ...this.produto, ...res.data };
      this.dismiss('saved');
    }).catch((err) => {
      console.log(err);
      const apiError: ApiResponseError = err?.error;
      if (apiError) {
        const message = (apiError.error?.map(x => x.message) || apiError?.validations?.map(x => x.message)).join('\n');
        return this.modalService.presentAlert('Erro ao salvar produto', message || 'Ocorreu um erro ao salvar o produto, tente novamente mais tarde');
      }
    }).finally(() => {
      loading.dismiss();
    });
  }

  dismiss(reason: string = null) {
    this.activeModal.dismiss(reason);    
  }
}
