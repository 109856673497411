import { Location } from "@angular/common";
import { ModalService, ModalType } from "../services/modal.service";
import { I_Responsive } from "./base-class";
import { HttpParams } from "@angular/common/http";
import { isValid, parse } from "date-fns";
import { AbstractControl, FormControl } from "@angular/forms";
import { HexColor, RGBColor } from "../model/custom-types";
import { AllowedExtensionsImportPeriodo } from "./globals";
import { ProdutoService } from "../services/produto.service";
import { ToastService } from "../services/toast.service";
import { ValidationError } from "../model/api.model";

/**
 * Atualiza a url com os parâmetros fornecidos
 * @param _location deve ser importado de @angular/common
 * @param params parâmetros a serem adicionados na url
 */
export const updateUrl = (_location: Location, params: Record<string, string | boolean | number> | HttpParams = {}) => {
  let _params: HttpParams;
  _params = (!(params instanceof HttpParams)) ? new HttpParams().appendAll(params) : params;
  _location.replaceState(location.pathname, _params.toString());
}

/**
 * Retorna a classe modal padrão de acordo com o tamanho da tela
 * @param responsive objeto que contém informações sobre o tamanho da tela
 * @returns classe modal padrão
 */
export const defaultClassModal = (responsive: I_Responsive): ModalType => {
  if (responsive.isDesktop) return "slide-right";
  if (!responsive.isDesktop) return "slide-bottom";
  return null;
}

/**
 * verifica se uma data no formato dd/MM/yyyy é válida
 * @param date data no formato dd/MM/yyyy ou um AbstractControl/FormControl(também formatado como dd/MM/yyyy)
 * @returns null se a data for válida, { 'dateInvalid': true } se a data for inválida
 */
export const dateDDMMYYYYIsInvalid = (date: string | AbstractControl | FormControl) => {
  const parsed = parse(typeof date === 'string' ? date : date.value, "dd/MM/yyyy", new Date());
  const valid = isValid(parsed)
  return valid ? null : { 'dateInvalid': true };
}

export const aGreaterThenB = <T extends number | string>(a: T, b: T): 1 | -1 | 0 => {
  if (typeof a === 'string' && typeof b === 'string') {
    if (a?.toUpperCase() === b?.toUpperCase()) return 0;
    if (!a && b) return -1;
    if (a && !b) return 1;
    return a?.toUpperCase() > b?.toUpperCase() ? 1 : -1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    if (a === b) return 0;
    return a > b ? 1 : -1;
  }

  return 0;
}

/**
 * substitui caracteres especiais por caracteres comuns
 */
export const replaceSpecialChars = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Verifica se uma string inclui um substring, ignorando acentos e case sensitive
 */
export const stringIncludesSubstring = (str: string, substr: string): boolean => {
  if (!str || !substr) return false;
  const haystack = replaceSpecialChars(str).toLowerCase();
  const needle = replaceSpecialChars(substr).toLowerCase();
  return haystack.includes(needle);
}

/**
 * Converte uma string no formato 'r,g,b' para sua representação em hexadecimal (#rrggbb)
 * @param color
 * @returns
 */
export const colorRGBToHex = (color: RGBColor): HexColor => {
  const [r, g, b] = color.split(',').map(Number);
  const rHex = Number(r).toString(16).padStart(2, '0');
  const gHex = Number(g).toString(16).padStart(2, '0');
  const bHex = Number(b).toString(16).padStart(2, '0');
  return `#${rHex}${gHex}${bHex}`;
}

/**
 * Converte uma string no formato '#rrggbb' para sua representação em 'r,g,b'
 */
export const colorHexToRGB = (color: HexColor): RGBColor => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r},${g},${b}`;
}

/**
 * Valida um email usando regex
 */
export const emailIsValid = (email: string): boolean => {
  const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  return regex.test(email);
}

/**
 * Verifica se uma string é uma cor no formato 'r,g,b'
 */
export const stringIsRGBColor = (color: string): boolean => {
  const regex = /^(\d{1,3},){2}\d{1,3}$/;
  return regex.test(color);
}

/**
 * Verifica se uma string é uma cor no formato '#rrggbb'
 */
export const stringIsHexColor = (color: string): boolean => {
  const regex = /^#[0-9A-Fa-f]{6}$/;
  return regex.test(color);
}

export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const downloadModeloImportacaoProdutos = () => {
  const a = document.createElement('a');
  a.href = '/assets/modelos/planilha-modelo-produtos.xlsx';
  a.download = 'smartis-modelo-importacao-produtos.xlsx';
  a.click();
}

export const selectPlanilhaImportacaoProdutos = (): Promise<File> => {
  const a = document.createElement('input');
  a.type = 'file';
  a.accept = AllowedExtensionsImportPeriodo.join(',');
  a.click();
  return new Promise((resolve) => {
    a.onchange = () => {
      const file = a.files[0];
      resolve(file);
    }
  });
}

export const keyPressOnTagsProdutoInput = (event: KeyboardEvent, callback: (inputValue: string) => void, thisArg: any) => {
  if (!['Enter', ','].includes(event.key)) return;
  event.preventDefault();
  eventsInputTagsProduto(event.target as HTMLInputElement, callback, thisArg);
}

export const focusLostInputTagsProdutoInput = (event: FocusEvent, callback: (inputValue: string) => void, thisArg: any) => {
  eventsInputTagsProduto(event.target as HTMLInputElement, callback, thisArg);
}

const eventsInputTagsProduto = (inputEl: HTMLInputElement, callback: (inputValue: string) => void, thisArg: any) => {
  if (!inputEl.value) return;
  const inputValue = inputEl.value.trim();
  inputEl.value = '';
  callback.call(thisArg, inputValue);
}

export const importarPlanilhaProdutos = async (file: File, produtoService: ProdutoService, periodo: string, toastService: ToastService, modalService: ModalService): Promise<boolean> => {
  const loading = modalService.presentLoading('Importando planilha', true);

  try {
    const res = await produtoService.importarProdutosPeriodoArquivo(periodo, file);
    if (res?.errors) {
      console.error(res);
      if (!showApiErrorMessages(modalService, res.errors)) toastService.show({ body: 'Erro ao importar planilha', color: 'danger' });
      return false;
    }
    toastService.show({ body: 'Planilha importada', color: 'success' });
    return true;
  } catch (err) {
    console.error(err);
    if (!showApiErrorMessages(modalService, err)) toastService.show({ body: 'Erro ao importar planilha', color: 'danger' });
    return false;
  } finally {
    loading.dismiss();
  }
}

export const showApiErrorMessages = (
  modalService: ModalService,
  error: {
    validations?: Array<ValidationError>,
    error?: { validations?: Array<ValidationError> },
    errors?: { validations?: Array<ValidationError> }
  },
  title: string = "Ocorreu um erro") => {
  const messages = [error?.validations?.map(v => v.message), error?.error?.validations?.map(x => x.message), error?.errors?.validations?.map(x => x.message)]?.flat()?.filter(x => x);
  if (messages?.length > 0) {
    modalService.presentAlert(title, messages.join('\n'));
    return true;
  }
  return false;
}
