import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseClass } from '../../../../../globals/base-class';
import { ColumnTableProdutoList, ColumnsTableProdutoListToDisplay } from '../../../../../custom-components/table-produto-list/table-produto-list.component';
import { ColorDirective } from '../../../../../directives/color.directive';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { CardWithIconComponent } from '../../../../../custom-components/card-with-icon/card-with-icon.component';
import { NgMatIconComponent } from '../../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { deepCopy } from '../../../../../globals/utils';

type TColuna = {
  column: ColumnTableProdutoList;
  label: string;
  selected: boolean;
  locked?: boolean;
}

@Component({
  selector: 'app-selecionar-colunas-lista-produtos',
  standalone: true,
  imports: [
    ColorDirective,
    NgMatIconComponent,
    SmallLabelComponent,
    CardWithIconComponent,
  ],
  templateUrl: './selecionar-colunas-lista-produtos.component.html',
  styleUrl: './selecionar-colunas-lista-produtos.component.scss'
})
export class SelecionarColunasListaProdutosComponent extends BaseClass() {

  colunas: Array<TColuna> = Object.entries(ColumnsTableProdutoListToDisplay).filter(x => x[1]).map(x => {
    return { column: x[0] as ColumnTableProdutoList, label: x[1], selected: false, locked: x[0] as ColumnTableProdutoList === 'nome' };
  });

  selectedColumns: Array<ColumnTableProdutoList> = [];

  constructor(
    @Inject('data') public data: { displayedColumns: Array<ColumnTableProdutoList> },
    private activeModal: NgbActiveModal,
  ) {
    super();

    this.selectedColumns = deepCopy(data.displayedColumns);
    this.colunas.forEach(coluna => {
      coluna.selected = this.selectedColumns.includes(coluna.column);
    });
  }

  selectColumn(coluna: TColuna) {
    if (coluna.locked) return;
    coluna.selected = !coluna.selected;
    if (coluna.selected) this.selectedColumns.push(coluna.column);
    else this.selectedColumns = this.selectedColumns.filter(x => x !== coluna.column);
  }

  confirm() {
    this.activeModal.close(this.colunas.filter(x => x.selected).map(x => x.column));
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
