import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { SiglaEstado } from '../model/custom-types';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CnpjRfService {

  constructor(private http: HttpClient) {}

  getDetailsCnpjPromise(cnpj: string): Promise<ResponseCnpjReceita> {
    return lastValueFrom(this.http.jsonp<ResponseCnpjReceita>(environment.apiReceitaFederal + cnpj, 'callback'));
  }
}

export type ResponseCnpjReceita = {
  atividade_principal: { text: string, code: string }[];
  data_situacao: string;
  complemento: string;
  tipo: string;
  nome: string;
  uf: SiglaEstado;
  telefone: string;
  atividades_secundarias: { text: string, code: string }[];
  qsa: { qual: string, nome: string }[];
  situacao: string;
  bairro: string;
  logradouro: string;
  numero: string;
  cep: string;
  municipio: string;
  porte: string;
  abertura: string;
  natureza_juridica: string;
  fantasia: string;
  cnpj: string;
  ultima_atualizacao: string;
  status: "OK" | "ERROR";
  email: string;
  efr: string;
  motivo_situacao: string;
  situacao_especial: string;
  data_situacao_especial: string;
  capital_social: string;
  extra: {};
  billing: { free: true, database: true };
  message?: string;
}
