import { Component, Input } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { getAppPages, PagesApp, routerLinkActiveOptions } from '../app-pages';
import { IconComponent } from '../../../custom-components/icon/icon.component';
import { Menu } from '../../../model/menu.model';
import { AsyncPipe, NgClass } from '@angular/common';
import { BaseClass } from '../../../globals/base-class';
import { ColorDirective } from '../../../directives/color.directive';
import { Usuario } from '../../../model/usuario.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../services/modal.service';
import { defaultClassModal } from '../../../globals/utils';
import { ChangePasswordComponent } from '../../../login/change-password/change-password.component';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'sidenav',
  standalone: true,
  imports: [RouterModule, NgClass, AsyncPipe, IconComponent, ColorDirective, NgbPopover, RouterLink, RouterLinkActive],
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss', './menu-behavior.scss'],
})
export class SidenavComponent extends BaseClass() {

  @Input() menu: Menu;
  @Input() usuario: Partial<Usuario>;

  routerLinkActiveOptions = routerLinkActiveOptions;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.pages = getAppPages(this.usuario);
  }

  pages: PagesApp;

  isActiveChange(isActive: boolean = true) {
    if (!isActive) this.closeMenu();
  }

  closeMenu() {
    if (this.menu?.opened) this.menu?.close();
  }

  changePassword() {
    this.modalService.presentModal(ChangePasswordComponent, defaultClassModal(this), null, { windowClass: 'blur-backdrop', });
  }

  logout() {
    this.router.navigate(['/login'], { replaceUrl: true }).then(() => this.authService.logout());
  }
}
