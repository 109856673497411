import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseClass } from '../../globals/base-class';
import { ColorDirective } from '../../directives/color.directive';
import { AppColor, RGBColor } from '../../model/custom-types';

@Component({
  selector: 'app-confirm',
  standalone: true,
  imports: [ColorDirective],
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.scss'
})
export class ConfirmComponent extends BaseClass() {
  header: string;
  message: string;
  noText: string;
  yesText: string;
  buttonsColor: AppColor | RGBColor;

  constructor(
    @Inject('data') private data: Record<string, any>,
    private activeModal: NgbActiveModal,
  ) {
    super();

    this.header = this.data?.['header'] || 'Confirmar';
    this.message = this.data?.['message'];
    this.noText = this.data?.['noText'] || 'Não';
    this.yesText = this.data?.['yesText'] || 'Sim';
    this.buttonsColor = this.data?.['buttonsColor'] || 'dark';
  }

  close(confirmed: boolean = null) {
    this.activeModal.close(confirmed);
  }

}
