import { Component, Input, ViewChild } from '@angular/core';
import { Categoria } from '../../../../../model/categoria.model';
import { BaseClass } from '../../../../../globals/base-class';
import { ColumnTableProdutoList, ProdutoLocalList, TableProdutoListComponent } from '../../../../../custom-components/table-produto-list/table-produto-list.component';
import { ProdutoService } from '../../../../../services/produto.service';
import { Produto, Produtos } from '../../../../../model/produto.model';
import {
  BehaviorSubject,
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs';
import { OperationInProgressComponent } from '../../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { PaginatorComponent, PaginatorOpts } from '../../../../../custom-components/paginator/paginator.component';
import { StorageService } from '../../../../../services/storage.service';
import { ToastService } from '../../../../../services/toast.service';
import { AsyncPipe } from '@angular/common';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorDirective } from '../../../../../directives/color.directive';
import { IconComponent } from '../../../../../custom-components/icon/icon.component';
import { NovoProdutoOverlayComponent } from '../../../produtos/overlays/novo-produto-overlay/novo-produto-overlay.component';
import { ModalService, ModalType } from '../../../../../services/modal.service';
import { defaultClassModal } from '../../../../../globals/utils';
import { SpinnerComponent } from '../../../../../custom-components/spinner/spinner.component';
import { NgbTooltipModule, NgbTypeahead, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { DelayShowTooltip, PeriodoLancamentosSelecionado$ } from '../../../../../globals/globals';
import { NgMatIconComponent } from '../../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { ProdutoListComponent } from '../../../produtos/produto-list/produto-list.component';


@Component({
  selector: 'produtos-categoria',
  standalone: true,
  imports: [
    TableProdutoListComponent,
    OperationInProgressComponent,
    PaginatorComponent,
    FormsModule,
    AsyncPipe,
    SmallLabelComponent,
    ReactiveFormsModule,
    ColorDirective,
    IconComponent,
    SpinnerComponent,
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgMatIconComponent,
    ProdutoListComponent,
  ],
  templateUrl: './produtos-categoria.component.html',
  styleUrls: ['./produtos-categoria.component.scss', '../../categorias-section-common.scss']
})
export class ProdutosCategoriaComponent extends BaseClass() {
  @Input() categoria: Categoria;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('produtoListRef') produtoListRef: ProdutoListComponent;

  focus$ = new Subject<Produto>();
  click$ = new Subject<Produto>();

  currentPeriodo$ = PeriodoLancamentosSelecionado$;

  operationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  displayedColumns: Array<ColumnTableProdutoList> = ['nome', 'margem', 'edit'];
  // produtos: Array<ProdutoLocalList>;
  produtosSearch: Produtos;

  paginatorOpts: PaginatorOpts;

  produtoSelecionado: Produto;

  DelayShowTooltip = DelayShowTooltip;

  /**
   * tempo após o último caractere digitado para realizar a busca
   * */
  delaySearch: number = 1500;
  searchingProdutos: boolean = false;
  errorSearchProdutos: boolean = false;

  constructor(
    private modalService: ModalService,
    private storageService: StorageService,
    private produtoService: ProdutoService,
    private toastService: ToastService,
  ) {
    super();

  }

  ngOnChanges() {
    if (this.currentPeriodo$?.value && this.categoria?.id) {
      this.getProdutos();
      // this.getProdutosSearch('');
    }
  }

  ngAfterViewInit() {
    const sub = this.currentPeriodo$.pipe().subscribe({
      next: (p) => {
        if (p && p.id !== this.currentPeriodo$.value.id && this.categoria?.id) {
          this.getProdutos();
          this.getProdutosSearch('');
        }
      }
    })
    this.appendSubscription(sub);
  }

  getProdutos() {
    if (this.produtoListRef) {
      this.produtoListRef.operationInProgress$.next(true);
      this.produtoListRef.IdsCategorias = [this.categoria.id];
      this.produtoListRef.updatePeriodo.next(this.currentPeriodo$.value);
    }
  }

  navigatePage(page: number) {
    this.paginatorOpts.page = page;
    this.getProdutos();
  }

  updatePageSize(size: number) {
    this.paginatorOpts.pageSize = size;
    this.storageService.set('PAGE_SIZE_LIST_PRODUTOS', size);
    this.getProdutos();
  }

  adicionarProdutoCategoria() {
    if (!this.produtoSelecionado) return;

    if (this.produtoSelecionado.idCategoria === this.categoria.id)
      return this.toastService.show({ body: 'Produto já adicionado a esta categoria', color: 'warning' });

    const toUpdate: Produto = {
      ...this.produtoSelecionado,
      idCategoria: this.categoria.id,
    }

    this.produtoService.updateProdutoPeriodo(this.currentPeriodo$.value.id, toUpdate).then((res) => {
      if (!res.success) {
        const message = res.error?.map(e => e.message).join('\n') || res.errors?.validations?.map(e => e.message).join('\n') || 'Erro ao adicionar produto';
        this.modalService.presentAlert("Erro", message);
        return;
      }

      this.toastService.show({ body: 'Produto adicionado com sucesso' });
      this.produtoSelecionado = null;
      this.getProdutos();
    }).catch((err) => {
      console.log(err);
      this.modalService.presentAlert("Erro", 'Erro ao adicionar produto');
    });
  }

  addEditProduto(produto: ProdutoLocalList = null) {
    const modal = this.modalService.presentModal(NovoProdutoOverlayComponent, defaultClassModal(this), { periodo: this.currentPeriodo$?.value?.id, produto }, { windowClass: 'blur-backdrop' });
    modal.dismissed.subscribe({
      next: (res) => {
        if (res === 'saved' || res === 'deleted') this.getProdutos()
      }
    });
  }

  search: OperatorFunction<string, readonly Produto[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.delaySearch),
      distinctUntilChanged(),
      switchMap((term) =>
        this.getProdutosSearch(term),
      ),
    );

  async getProdutosSearch(term: string): Promise<Produtos> {
    this.searchingProdutos = true;

    try {
      const res = await this.produtoService.searchProdutosPeriodo(this.currentPeriodo$.value.id, term);
      this.errorSearchProdutos = false;
      this.produtosSearch = res.data;
      return this.produtosSearch?.filter(p => p.idCategoria !== this.categoria.id) || [];
    } catch {
      this.errorSearchProdutos = true;
      return [];
    } finally {
      this.searchingProdutos = false;
    }
  }

  resultSearchFormatter(result: Produto) {
    return result.nome;
  }

}
