import { Component, Inject } from '@angular/core';

@Component({
  selector: 'loading',
  standalone: true,
  templateUrl: './loading.component.html',
})
export class LoadingComponent {
  message: string = 'Carregando...';

  constructor(@Inject('data') private data: Record<string, any> = null) {
    if (this.data?.['message']) this.message = this.data['message'];
  }
}
