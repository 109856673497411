import { MaterialIcon } from "material-icons";
import { HexColor } from "./custom-types";
import { TipoAplicacaoDespesa, TipoDespesa } from "./despesa.model";

export type Categoria = {
  id: string;
  idPeriodo: string;
  nome: string;
  icone: MaterialIcon;
  cor: HexColor;
  status: StatusCategoriaEnum;
  margemLucro: number;

  quantidadeProdutos?: number;
  somatorioDespesasVenda?: number;
}
export type Categorias = Array<Categoria>;

export enum StatusCategoriaEnum {
  ATIVO = 1,
  INATIVO = 2,
}

export type DespesaVendaCategoria = {
  idCategoria: string;
  idDespesa: string;
  nomeDespesa: string;
  valor: number;
  tipoDespesa: TipoDespesa;
  tipoAplicacao: TipoAplicacaoDespesa;
}

export type DespesasVendaCategoria = Array<DespesaVendaCategoria>;
