import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseClass } from '../../globals/base-class';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { FormControl } from '@angular/forms';
import { SmallLabelComponent } from '../small-label/small-label.component';
import { PaginatorPageSize } from '../../globals/globals';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';

@Component({
  selector: 'paginator',
  standalone: true,
  imports: [NgbPagination, DropdownComponent, SmallLabelComponent, ColorDirective],
  templateUrl: './paginator.component.html',
})
export class PaginatorComponent extends BaseClass() {

  @Input() showLabels: boolean = true;
  @Input() showPageSizeSelector: boolean = true;

  @Input() pages: number;
  @Input() page: number;
  @Input() pageSize: number;
  @Input() rowsCount: number;
  @Input() pageSizes: Array<number> = PaginatorPageSize;
  @Input() color: AppColor = 'info';

  @Input() isDisabled: boolean = false;

  pagesArr: Array<number>;

  @Output() navigatePage: EventEmitter<number> = new EventEmitter();
  @Output() updatePageSize: EventEmitter<number> = new EventEmitter();

  pageSizeControl: FormControl<number> = new FormControl();

  constructor() {
    super();
  }

  ngOnInit() {
    this.pagesArr = [...Array(this.pages).keys()].map(i => i + 1);
    this.pageSizeControl.setValue(this.pageSize);
  }

  pageSizeChanged(newPageSize: number) {
    this.pageSize = newPageSize;
    this.updatePageSize.emit(newPageSize);
    this.pageSizeControl.setValue(newPageSize);
  }

}

export type PaginatorOpts = {
  pages: number,
  page: number;
  pageSize: number;
  rowsCount: number;
};
