<div id="container" [style.--gridTemplateColumns]="gridTemplateColumns" [style.--gridTemplateAreas]="'&quot;' + gridTemplateAreas + '&quot;'">
  <div id="list" class="rounded-4 max-width-100 overflow-x-auto">
    @if (isDesktop) {
      <div id="header" class="list-row" [class.rounded-top-4]="isDesktop" [class.operation-in-progress]="operationInProgress$ | async">
        @if (columns.includes("checkbox")) {
          <div class="cell checkbox">
            <input type="checkbox" class="form-check-input" />
          </div>
        }
        @if (columns.includes("nome")) {
          <div class="cell nome allow-ordering" [class.ordering]="ordering.by === 'nome'" [ngClass]="ordering.by === 'nome' ? ordering.direction : ''" (click)="orderBy('nome')">
            <label>Nome</label>
          </div>
        }
        @if (columns.includes("tipo")) {
          <div class="cell tipo allow-ordering" [class.ordering]="ordering.by === 'tipo'" [ngClass]="ordering.by === 'tipo' ? ordering.direction : ''" (click)="orderBy('tipo')">
            <label>Tipo</label>
          </div>
        }
        @if (columns.includes("cpfCnpj")) {
          <div class="cell cpfCnpj allow-ordering" [class.ordering]="ordering.by === 'cpfCnpj'" [ngClass]="ordering.by === 'cpfCnpj' ? ordering.direction : ''" (click)="orderBy('cpfCnpj')">
            <label>CPF/CNPJ</label>
          </div>
        }
        @if (columns.includes("logradouro")) {
          <div class="cell logradouro allow-ordering" [class.ordering]="ordering.by === 'logradouro'" [ngClass]="ordering.by === 'logradouro' ? ordering.direction : ''" (click)="orderBy('logradouro')">
            <label>Logradouro</label>
          </div>
        }
        @if (columns.includes("bairro")) {
          <div class="cell bairro allow-ordering" [class.ordering]="ordering.by === 'bairro'" [ngClass]="ordering.by === 'bairro' ? ordering.direction : ''" (click)="orderBy('bairro')">
            <label>Bairro</label>
          </div>
        }
        @if (columns.includes("cidade")) {
          <div class="cell cidade allow-ordering" [class.ordering]="ordering.by === 'cidade'" [ngClass]="ordering.by === 'cidade' ? ordering.direction : ''" (click)="orderBy('cidade')">
            <label>Cidade</label>
          </div>
        }
        @if (columns.includes("estado")) {
          <div class="cell estado allow-ordering" [class.ordering]="ordering.by === 'estado'" [ngClass]="ordering.by === 'estado' ? ordering.direction : ''" (click)="orderBy('estado')">
            <label>UF</label>
          </div>
        }

        @if (columns.includes("status") || columns.includes("edit")) {
          <div class="cell actions">
            <label>Ações</label>
          </div>
        }
      </div>
    }

    <div class="list-content">
      @for (empresa of empresas; track $index) {
        <div class="rounded-4 item-list">
          <h2>
            <div class="list-row py-2" [class.rounded-4]="!isDesktop">
              @if (isDesktop && columns.includes("checkbox")) {
                <div class="cell checkbox">
                  <input #check type="checkbox" class="form-check-input" (change)="checkedChanged.emit({ empresa, checked: check.checked })" />
                </div>
              }
              @if (columns.includes("nome")) {
                <div class="cell nome fw-bold">
                  <label [color]="!isDesktop ? 'primary' : 'titulo'" [ngClass]="{ 'd-flex gap-3 align-items-center justify-content-between w-100': !isDesktop }">
                    <span class="cursor-pointer" (click)="addEditEmpresa.emit(empresa)"> {{ empresa.nome }} </span>
                  </label>
                </div>
              }

              @if (isDesktop && columns.includes("tipo")) {
                <div class="cell tipo">
                  <label>
                    <span> {{ empresa.tipo | tipoPessoa }}</span>
                  </label>
                </div>
              }

              @if (columns.includes("cpfCnpj")) {
                <div class="cell cpfCnpj">
                  <label>
                    @if (!isDesktop) {
                      <span class="fw-bold"> {{ empresa.cpfCnpj?.length > 14 ? "CNPJ" : "CPF" }}: </span>
                    }
                    <span> {{ empresa.cpfCnpj | mask: "CPF_CNPJ" }}</span>
                    @if (!isDesktop) {
                      <span class="fw-bold ms-1">({{ empresa.tipo | tipoPessoa }})</span>
                    }
                  </label>
                </div>
              }
              @if (columns.includes("logradouro")) {
                <div class="cell logradouro">
                  <label>
                    @if (!isDesktop) {
                      <span class="fw-bold"> Logradouro: </span>
                    }
                    <span> {{ empresa.logradouro || "-" }} </span>
                  </label>
                </div>
              }

              @if (columns.includes("bairro")) {
                <div class="cell bairro">
                  <label>
                    @if (!isDesktop) {
                      <span class="fw-bold"> Bairro: </span>
                    }
                    <span> {{ empresa.bairro || "-" }} </span>
                  </label>
                </div>
              }

              @if (columns.includes("cidade")) {
                <div class="cell cidade">
                  <label>
                    @if (!isDesktop) {
                      <span class="fw-bold"> Cidade: </span>
                    }
                    <span> {{ empresa.cidade || "-" }}</span>
                  </label>
                </div>
              }

              @if (columns.includes("estado")) {
                <div class="cell estado">
                  <label>
                    @if (!isDesktop) {
                      <span class="fw-bold"> UF: </span>
                    }
                    <span> {{ empresa.estado || "-" }}</span>
                  </label>
                </div>
              }

              @if (columns.includes("status") || columns.includes("edit")) {
                <div class="cell actions d-flex gap-2">
                  @if (columns.includes("status")) {
                    <div class="form-check form-switch">
                      <input #checkbox class="form-check-input" type="checkbox" role="switch" (change)="ativoToggleChange.emit({ empresa, input: checkbox })" [checked]="empresa._statusToggle" />
                    </div>
                  }

                  @if (columns.includes("edit")) {
                    <button class="btn" (click)="addEditEmpresa.emit(empresa)">
                      <icon name="edit" />
                    </button>
                  }
                </div>
              }
            </div>
          </h2>
        </div>
      }

      @if (operationInProgress$ | async) {
        @if (empresas?.length) {
          <div class="loading">
            <operation-in-progress [absolute]="true" />
          </div>
        } @else {
          <div class="list-row no-results">
            <div class="cell justify-content-center" [class.rounded-4]="!isDesktop">
              <label> Carregando empresas... </label>
            </div>
          </div>
        }
      }

      @if (empresas?.length === 0) {
        <div class="list-row no-results">
          <div class="cell justify-content-center" [class.rounded-4]="!isDesktop">
            <label> Nenhuma empresa encontrado </label>
          </div>
        </div>
      }
    </div>
  </div>

  <paginator
    [page]="paginatorOpts?.page"
    [pages]="paginatorOpts.pages"
    [pageSize]="paginatorOpts.pageSize"
    [rowsCount]="paginatorOpts.rowsCount"
    [isDisabled]="(operationInProgress$ | async) || !empresas?.length"
    (updatePageSize)="_updatePageSize($event)"
    (navigatePage)="_navigatePage($event)"
  />
</div>
