import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PeriodoLancamentos } from '../../../../model/periodo.model';
import { BehaviorSubject } from 'rxjs';
import { IconInEllipseComponent } from '../../../../custom-components/icon-in-ellipse/icon-in-ellipse.component';
import { SmallLabelComponent } from '../../../../custom-components/small-label/small-label.component';
import { Categoria, DespesasVendaCategoria, StatusCategoriaEnum } from '../../../../model/categoria.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BaseClass } from '../../../../globals/base-class';
import { ColorDirective } from '../../../../directives/color.directive';
import { ToastService } from '../../../../services/toast.service';
import { ModalService } from '../../../../services/modal.service';
import { CurrencyPipe, Location, NgClass, PercentPipe } from '@angular/common';
import { SelectIconColorCategoriaComponent } from '../../../../custom-components/select-icon-color-categoria/select-icon-color-categoria.component';
import { colorHexToRGB, colorRGBToHex, defaultClassModal } from '../../../../globals/utils';
import { RGBColors } from '../../../../globals/globals';
import { Segment, SegmentsComponent } from '../../../../custom-components/segments/segments.component';
import { DespesasVendaCategoriaComponent } from './despesas-venda-categoria/despesas-venda-categoria.component';
import { MargemLucroCategoriaComponent } from './margem-lucro-categoria/margem-lucro-categoria.component';
import { ProdutosCategoriaComponent } from './produtos-categoria/produtos-categoria.component';
import { CategoriaService } from '../../../../services/categoria.service';
import { ActivatedRoute } from '@angular/router';
import { NgMatIconComponent } from '../../../../custom-components/ng-mat-icon/ng-mat-icon.component';
import { MaterialIcon } from 'material-icons';
import { RGBColor } from '../../../../model/custom-types';

type PickedFields = Pick<Categoria, 'nome' | 'icone' | 'cor' | 'status'>;
type TForm = {
  [key in keyof PickedFields]: FormControl;
};

@Component({
  selector: 'detalhes-categoria',
  standalone: true,
  imports: [
    ColorDirective,
    IconInEllipseComponent,
    SmallLabelComponent,
    NgClass,
    ReactiveFormsModule,
    PercentPipe,
    SegmentsComponent,
    DespesasVendaCategoriaComponent,
    MargemLucroCategoriaComponent,
    ProdutosCategoriaComponent,
    NgMatIconComponent,
    CurrencyPipe,
  ],
  templateUrl: './detalhes-categoria.component.html',
  styleUrls: ['./detalhes-categoria.component.scss', '../categorias-section-common.scss'],
})
export class DetalhesCategoriaComponent extends BaseClass() {
  @Input({ required: true }) periodo: PeriodoLancamentos;
  @Input() updatePeriodo: EventEmitter<PeriodoLancamentos> = new EventEmitter();
  @Input() categoria: Categoria;
  @Input({ required: true }) operationInProgress$: BehaviorSubject<boolean>;
  @Input() segment: SegmentDetalhesCategoria = 'margem';

  @Input() updateUrl: (categoria: string, segment: SegmentDetalhesCategoria) => void;

  @Output() refreshCategoria: EventEmitter<Categoria> = new EventEmitter();
  @Output() categoriaAdded: EventEmitter<Categoria> = new EventEmitter();
  @Output() dismiss: EventEmitter<void> = new EventEmitter();
  @Output() categoriaExcluida: EventEmitter<void> = new EventEmitter();

  iconPlaceholder: string = 'receipt';

  defaultIcon: MaterialIcon = 'category';
  defaultColor: RGBColor = RGBColors[16];

  form: FormGroup<TForm> = new FormGroup({
    nome: new FormControl(null),
    icone: new FormControl(this.defaultIcon),
    cor: new FormControl(this.defaultColor),
    status: new FormControl(1),
  });

  segments: Array<SegmentLocal> = [
    { path: ['margem'], title: 'Margem de lucro da categoria', segmentPage: 'margem' },
    { path: ['despesas'], title: 'Despesas de venda da categoria', segmentPage: 'despesas' },
    { path: ['produtos'], title: 'Produtos da categoria', segmentPage: 'produtos' },
  ];

  activeSegment: SegmentLocal = this.segments[0];

  margemLucroControl: FormControl<number> = new FormControl();

  CategoriaStatus = StatusCategoriaEnum;

  carregandoDespesas: BehaviorSubject<boolean> = new BehaviorSubject(true);
  despesasVenda: DespesasVendaCategoria;

  constructor(
    private route: ActivatedRoute,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
    private modalService: ModalService,
    private _location: Location, // important
  ) {
    super();
  }

  ngOnInit() {
    const sub = this.operationInProgress$.subscribe({
      next: (v) => v ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false })
    });
    this.appendSubscription(sub);

    const queryParams = this.route.snapshot.queryParams;
    if (queryParams?.['segment']) {
      this.segment = queryParams['segment'] as SegmentDetalhesCategoria;
      this.activeSegment = this.segments.find((s) => s.segmentPage === this.segment);
    }
  }

  private async getDespesasVendaCategoria() {
    this.carregandoDespesas.next(true);

    try {
      const res = await this.categoriaService.getDespesasVendaCategoria(this.periodo.id, this.categoria.id);
      this.despesasVenda = res;
    } catch (err) {
      console.log(err);
      this.presentErrorToast('Erro ao carregar despesas');
    } finally {
      this.carregandoDespesas.next(false);
    }
  }

  get somatorioDespesasVenda() : number {
    return this.despesasVenda?.reduce((sum, current) => sum + current.valor, 0);
  }

  ngOnChanges() {
    if (this.categoria) {
      const { cor, ...rest } = this.categoria;
      this.form.patchValue({ ...rest, cor: cor ? colorHexToRGB(cor) : this.defaultColor });
      this.margemLucroControl.setValue(this.categoria.margemLucro);
      this.setStatus(this.categoria.status);
      this.getDespesasVendaCategoria();
    } else this.form.reset({
      icone: this.defaultIcon,
      cor: this.defaultColor,
      status: 1
    });
  }

  segmentChanged(segment: Segment) {
    this.activeSegment = { ...segment, segmentPage: segment.path[0] as SegmentDetalhesCategoria };
    this.updateUrl(this.categoria.id, this.activeSegment.segmentPage);
  }

  selectIconColor() {
    const { icone, cor } = this.form.value;
    const modal = this.modalService.presentModal(SelectIconColorCategoriaComponent, defaultClassModal(this), { icone, cor }, { windowClass: 'blur-backdrop' });

    modal.closed.subscribe({
      next: (res) => {
        if (res) {
          this.form.controls.icone.setValue(res.icone);
          this.form.controls.cor.setValue(res.cor);
        }
      }
    })
  }

  setStatus(value: StatusCategoriaEnum) {
    this.form.controls.status.setValue(value);
  }

  save() {
    this.operationInProgress$.next(true);

    const { cor, ...formValue } = this.form.value;

    const toSave: Partial<Categoria> = {
      ...this.categoria,
      ...formValue,
      cor: cor ? colorRGBToHex(cor) : null,
      idPeriodo: this.periodo.id,
      margemLucro: this.margemLucroControl.value ? this.margemLucroControl.value : null,
    };

    if (!toSave.nome) return this.presentErrorToast('Você precisa informar o nome da categoria', true);
    // if (!toSave.icone) return this.presentErrorToast('Você precisa escolher um ícone', true);
    // if (!toSave.cor) return this.presentErrorToast('Você precisa escolher uma cor', true);

    this.operationInProgress$.next(true);
    const loading = this.modalService.presentLoading('Salvando categoria...', true);

    const promise = this.categoria?.id ? this.categoriaService.updateCategoriaPeriodo(this.periodo.id, { ...toSave, id: this.categoria.id }) : this.categoriaService.insertCategoriaPeriodo(this.periodo.id, toSave);

    promise.then((res) => {
      if (!res.success) return this.presentErrorToast('Erro ao salvar categoria');

      this.toastService.show({ body: `Categoria salva com sucesso`, color: 'success' });
      if (!this.categoria?.id) this.dismiss.emit();
      this.form.reset({ ...res.data, cor: res.data.cor ? colorHexToRGB(res.data.cor) : this.defaultColor });
      this.categoria = { ...this.categoria, ...res.data };
      this.categoriaAdded.emit(res.data);
    }).catch((err) => {
      console.log(err);
      this.presentErrorToast('Erro ao salvar categoria');
    }).finally(() => {
      loading.dismiss();
    });
  }

  deleteCategoria() {
    const confirm = this.modalService.presentConfirm('Tem Certeza?', 'Deseja realmente excluir esta categoria?', true);
    const sub = confirm.closed.subscribe({
      next: (res) => {
        if (!res) return;

        this.operationInProgress$.next(true);
        const loading = this.modalService.presentLoading('Excluindo categoria...', true);

        this.categoriaService.deleteCategoriaPeriodo(this.periodo.id, this.categoria.id).then((res) => {
          if (!res.success) return this.presentErrorToast('Erro ao excluir categoria');

          this.toastService.show({ body: `Categoria excluída com sucesso`, color: 'success' });
          this.categoriaExcluida.emit();
          this.form.reset({ cor: this.defaultColor, status: 1 });
          this.categoria = null;
          if (!this.isDesktop) this.dismiss.emit();
        }).catch((err) => {
          console.log(err);
          this.presentErrorToast('Erro ao excluir categoria');
        }).finally(() => {
          loading.dismiss();
          this.operationInProgress$.next(false);
        });
      }
    });
    this.appendSubscription(sub);
  }

  private presentErrorToast(body: string, enableOperations: boolean = false) {
    this.toastService.show({ body, color: 'danger' });
    if (enableOperations) this.operationInProgress$.next(false);
  }

}

export type SegmentDetalhesCategoria = 'margem' | 'despesas' | 'produtos';
export type SegmentLocal = Segment & { segmentPage: SegmentDetalhesCategoria };
