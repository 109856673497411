import { IsActiveMatchOptions, Params } from "@angular/router";
import { Usuario } from "../../model/usuario.model";

const AppPagesCommon: PagesApp = [
  { title: 'Suporte', path: '/home/suporte', icon: 'phone' },
];

export const AppPagesMaster: PagesApp = [
  { title: 'Empresas', path: '/home/empresas', icon: 'business' },
  ...AppPagesCommon,
];

export const AppPagesUsuario: PagesApp = [
  { title: 'Dashboard', path: '/home/dashboard', icon: 'layout' },
  { title: 'Produtos', path: '/home/produtos', icon: 'package' },
  { title: 'Analisar e Comparar', path: '/home/analisar', icon: 'trending-up' },
  { title: 'Simulação', path: '/home/simulador', icon: 'play', queryParams: { simulador: true } },
  { title: 'Períodos', path: '/home/periodos', icon: 'calendar', queryParams: { simulador: false } },
  { title: 'Usuários', path: '/home/usuarios', icon: 'people' },
  ...AppPagesCommon,
];

export const getAppPages = (usuario: Partial<Usuario>) => {
  return usuario.master ? AppPagesMaster : AppPagesUsuario;
};

export type PageAppPath = '/home/dashboard' | '/home/produtos' | '/home/analisar' | '/home/simulador' | '/home/periodos' | '/home/empresas' | '/home/suporte' | '/home/usuarios';
export type PagesApp = Array<PageApp>
export type PageApp = {
  title: string;
  path: PageAppPath;
  icon: string;
  queryParams?: Params;
};

/**
 * ## por queryParams ser dinâmico é necessário fazer match exact apenas com o fragment
 * */
export const routerLinkActiveOptions: IsActiveMatchOptions = {
  fragment: 'exact',
  matrixParams: 'ignored',
  paths: 'subset',
  queryParams: 'ignored',
};
