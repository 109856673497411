import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { BaseHttpService } from './base-http.service';
import { ApiConstraintGetCategorias } from '../model/api.model';
import { DefaultPageSize } from '../globals/globals';
import { environment } from '../../environments/environment.development';
import { Categoria, DespesaVendaCategoria } from '../model/categoria.model';
import { DespesasVendaCategoria } from '../model/categoria.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService extends BaseHttpService {

  getCategoriasPeriodo(periodo: string, constraints?: ApiConstraintGetCategorias) {
    const storageService = new StorageService();
    const params: ApiConstraintGetCategorias = Object.fromEntries(Object.entries({
      Page: constraints?.Page || 1,
      PageSize: constraints?.PageSize || storageService.get('PAGE_SIZE_LIST_CATEGORIAS') || DefaultPageSize.categorias,
      FirstRow: constraints?.FirstRow || null,
      Termo: constraints?.Termo || null,
    } as ApiConstraintGetCategorias).filter(x => x[1]))
    return this.get<Categoria>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.categorias}`, params);
  }

  getCategoriaById(periodo: string, categoriaId: string) {
    return this.getById<Categoria>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.categorias}`, categoriaId);
  }

  getProdutoCategoria(periodo: string, categoriaId: string) {
    return this.get<Categoria>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.categorias}/${categoriaId}/${environment.api.endpoints.produtos}`);
  }

  insertCategoriaPeriodo(periodo: string, categoria: Partial<Categoria>) {
    return this.post<Categoria>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.categorias}`, categoria);
  }

  updateCategoriaPeriodo(periodo: string, categoria: Partial<Categoria>) {
    return this.put<Categoria>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.categorias}/${categoria.id}`, categoria);
  }

  deleteCategoriaPeriodo(periodo: string, categoriaId: string) {
    return this.delete(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.categorias}`, categoriaId);
  }

  getDespesasVendaCategoria(periodo: string, categoriaId: string) {
    return this.get(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.categorias}/${categoriaId}/${environment.api.endpoints.despesasVendaCategoria}`) as unknown as Promise<DespesasVendaCategoria>;
  }

  updateDespesasVendaCategoria(periodo: string, categoriaId: string, despesasVenda: DespesaVendaCategoria) {
    return this.put<DespesaVendaCategoria>(`${environment.api.endpoints.periodos}/${periodo}/${environment.api.endpoints.categorias}/${categoriaId}/${environment.api.endpoints.despesasVendaCategoria}`, despesasVenda);
  }

}
