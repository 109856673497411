<div>
  <div class="options list w-100" [ngClass]="{ 'has-items gap-4': periodos?.length > 0 }">
    @if (!periodos) {
      <operation-in-progress [message]="'Carregando ' + ((eSimulacao$ | async) ? 'simulações' : 'períodos...')" />
    } @else if (periodos?.length === 0) {
      <no-results [message]="((eSimulacao$ | async) ? 'Nenhuma simulação' : 'Nenhum período') + ' encontrado.'" />
    } @else {
      @for (periodo of periodos; track $index) {
        <div
          class="card-local option d-flex flex-column align-items-start gap-2 cursor-pointer"
          [routerLink]="['/home', (eSimulacao$ | async) ? 'simulador' : 'periodos', 'novo']"
          [queryParams]="{ tipoBase: 'editar', id: periodo.id }"
          [class.selected]="periodo.id === (PeriodoLancamentosSelecionado$ | async).id"
        >
          <div class="option-title fw-500 w-100 d-flex justify-content-between align-items-center gap-3">
            <span> {{ periodo.nome }} </span>
            <div class="icons d-flex align-items-center gap-2">
              <!-- <icon name="copy" /> -->
              <icon name="chevron-right" />
            </div>
          </div>
          @if(periodo.dataInicio) {
            <div class="option-body">Período: {{ periodo.dataInicio | date: "dd/MM/yyyy" }} - {{ periodo.dataFim | date: "dd/MM/yyyy" }}</div>
          }
          @else {
            <div class="option-body">Período: Indefinido</div>
          }
        </div>
      }

      @if (paginatorOpts) {
        <paginator
          [page]="paginatorOpts?.page"
          [pages]="paginatorOpts.pages"
          [pageSize]="paginatorOpts.pageSize"
          [rowsCount]="paginatorOpts.rowsCount"
          [isDisabled]="(operationInProgress$ | async) || !periodos?.length"
          (updatePageSize)="updatePageSize($event)"
          (navigatePage)="navigatePage($event)"
        />
      }
    }
  </div>
</div>
