import { Component, Input } from '@angular/core';
import { OptionNovaBaseValue } from '../../periodos.component';
import { PeriodoLancamentos } from '../../../../../model/periodo.model';
import { StepperComponent } from '../../../../../custom-components/stepper/stepper.component';
import { CardWithIconComponent } from '../../../../../custom-components/card-with-icon/card-with-icon.component';
import { BaseClass } from '../../../../../globals/base-class';
import { AsyncPipe, CurrencyPipe, JsonPipe, NgClass, PercentPipe } from '@angular/common';
import { OperationInProgressComponent } from '../../../../../custom-components/operation-in-progress/operation-in-progress.component';
import { ColorDirective } from '../../../../../directives/color.directive';
import { SmallLabelComponent } from '../../../../../custom-components/small-label/small-label.component';
import { IconComponent } from '../../../../../custom-components/icon/icon.component';
import { cardsSmall } from '../../../../dashboard/dashboard.component';
import { defaultClassModal } from '../../../../../globals/utils';
import { CardSmallComponent } from '../../../../dashboard/card-small/card-small.component';
import { DashboardService } from '../../../../../services/dashboard.service';
import { DashboardData } from '../../../../../model/dashboard.model';
import { ModalService } from '../../../../../services/modal.service';
import { ModalCardSmallDashboard, TDataModalCardSmallDashboard, TypeModalDashboard } from '../../../../dashboard/modal-card-small-dashboard';

@Component({
  selector: 'app-conferir-dados-novo-periodo',
  standalone: true,
  imports: [
    CardWithIconComponent,
    CurrencyPipe,
    PercentPipe,
    OperationInProgressComponent,
    ColorDirective,
    SmallLabelComponent,
    IconComponent,
    NgClass,
    CardSmallComponent,
    AsyncPipe,
    JsonPipe
  ],
  providers: [
    CurrencyPipe,
    PercentPipe,
  ],
  templateUrl: './conferir-dados-novo-periodo.component.html',
  styleUrl: './conferir-dados-novo-periodo.component.scss'
})
export class ConferirDadosNovoPeriodoComponent extends BaseClass(ModalCardSmallDashboard) {
  @Input() periodo: PeriodoLancamentos;
  @Input() tipoBase: OptionNovaBaseValue;
  @Input() Stepper: StepperComponent<PeriodoLancamentos>;

  cardsSmall = cardsSmall().filter(card => (['despesas-venda', 'despesas-fixas', 'margem-contribuicao', 'custo-variavel'] as Array<TypeModalDashboard>).includes(card.modal));
  cardPontoEquilibrio = cardsSmall().find(card => card.modal === 'ponto-equilibrio');

  dashboardData: DashboardData;
  dashboardDataError: boolean;

  constructor(
    private dashboardService: DashboardService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.getDadosDashboard();
  }

  async getDadosDashboard() {
    try {
      const dashboardData = await this.dashboardService.getDashboardData(this.periodo.id);

      if (!dashboardData.errors) {
        this.dashboardData = dashboardData;
        this.cardsSmall[0].value = dashboardData.totalDespesasVendas;
        this.cardsSmall[1].value = `${dashboardData.margemContribuicao > 0 ? this.currencyPipe.transform(dashboardData.margemContribuicao) : this.currencyPipe.transform(0)}  (${this.percentPipe.transform(dashboardData.percentualMargemContribuicao / 100, '1.0-2')})`;
        this.cardsSmall[2].value = dashboardData.totalDespesasFixas;
        this.cardsSmall[3].value = dashboardData.custoVariavel;

        this.cardPontoEquilibrio.value = `${dashboardData.pontoEquilibrio > 0 ? this.currencyPipe.transform(dashboardData.pontoEquilibrio) : this.currencyPipe.transform(0)}  (${this.percentPipe.transform(dashboardData.percentualPontoEquilibrio / 100, '1.0-2')})`;
        this.cardPontoEquilibrio.pipe = null;
      } else {
        this.cardsSmall.forEach(c => c.value = 'error');
        this.cardPontoEquilibrio.value = 'error';
        this.cardPontoEquilibrio.pipe = null;
      }
    } catch (err) {
      this.cardsSmall.forEach(c => c.value = 'error');

      this.cardPontoEquilibrio.pipe = null;
      this.cardPontoEquilibrio.value = 'error';

      this.dashboardDataError = true;
    } finally {
      this.Stepper.lock = false;
    }
  }

  async presentModal(type: TypeModalDashboard, data: TDataModalCardSmallDashboard) {
    const modal = await this.presentModalCardSmall(this.modalService, type, data, defaultClassModal(this), this.periodo);
    const sub = modal.dismissed.subscribe(() => this.getDadosDashboard());
    this.appendSubscription(sub);
  }
}
