import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { SidenavComponent } from './nav/sidenav/sidenav.component';
import { ToolbarComponent } from './nav/toolbar/toolbar.component';
import { TabbarComponent } from './nav/tabbar/tabbar.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { Menu } from '../model/menu.model';
import { BaseClass } from '../globals/base-class';
import { getAppPages, PageApp, PageAppPath } from './nav/app-pages';
import { session$ } from '../globals/globals';

const COMPONENTS = [SidenavComponent, ToolbarComponent, TabbarComponent];
const MODULES = [RouterModule, NgbToastModule];
const PIPES = [AsyncPipe, NgClass];

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MODULES, COMPONENTS, PIPES],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent extends BaseClass() {
  mainMenu: Menu = new Menu();
  currentPage: PageApp;

  session = session$;

  appPages = getAppPages(this.session.value.usuario);

  constructor(private router: Router) {
    super();

    const sub = this.router.events.subscribe({
      next: (ev) => {
        if (ev instanceof NavigationEnd) {
          const page = this.appPages.find(k => ev.url.indexOf(k.path) != -1);
          if (page) this.currentPage = page;
        }
      }
    });

    this.appendSubscription(sub);

    const isMaster = this.session.value.usuario.master;
    const requestedPath: PageAppPath = '/' + this.router.url.split('/').slice(1, 3).join('/') as PageAppPath;
    if (!this.appPages.find(k => k.path === requestedPath)) {
      this.router.navigate(isMaster ? ['/home/empresas'] : ['/home/dashboard']);
    }
  }
}
