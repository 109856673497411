<div id="container">
  <div class="m-4">
    <div id="header" class="mb-5">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">Faturamento</h3>
        <button class="btn" (click)="dismiss()">
          <icon name="x" />
        </button>
      </div>

      <!-- <div id="subtitle">
        <p class="fw-500" color="cinza">Aqui vem as informações explicando o que é o termo em questão, links com chamadas para vídeos explicativos, artigos, etc.</p>
      </div> -->
    </div>

    <div id="content">
      <div class="mb-5">
        <dropdown
          [isDisabled]="!periodos?.length || operationInProgress"
          [inline]="true"
          label="Período de lançamentos"
          [placeholder]="periodos?.length === 0 ? 'Nenhum período cadastrado' : 'Selecione um período'"
          [options]="periodos"
          [control]="controlPeriodo"
          displayField="nome"
          (optionSelected)="selectPeriodo($event)"
        />
      </div>

      <div class="mb-5">
        <card-with-icon color="white" icon="receipt" iconBgColor="azul" [showFooter]="false" [showForwardIcon]="false">
          <div class="subcards d-flex align-items-center gap-4">
            <div class="subcard">
              <div class="header d-flex gap-2 flex-column">
                <small-label>
                  <span color="cinza"> Faturamento calculado </span>
                </small-label>

                <label>
                  @if (dadosFaturamento) {
                    <span color="titulo"> {{ (dadosFaturamento.faturamentoCalculado | currency) || "N/D" }} </span>
                  } @else {
                    <span class="spinner-border spinner-border-sm"></span>
                  }
                </label>
              </div>
            </div>
          </div>
        </card-with-icon>
      </div>

      @if (dadosFaturamento) {
        <div id="form">
          <div class="d-flex" [ngClass]="{ 'gap-5 align-items-end': isDesktop, 'gap-3 justify-content-between flex-column': !isDesktop }">
            <div class="cell d-flex flex-column gap-2 flex-grow-1" [class.flex-grow-1]="showForm">
              <small-label>
                <span color="cinza" class="fw-600"> Faturamento estimado </span>
              </small-label>

              <label color="dark" class="fw-bold text-nowrap"> {{ dadosFaturamento.faturamentoEstimado | currency }} </label>
            </div>

            @if (showForm || !isDesktop) {
              <div class="cell form-group d-flex flex-column gap-2" [class.flex-grow-1]="showForm">
                <small-label>
                  <span color="cinza" class="fw-600"> Alterar o faturamento estimado </span>
                </small-label>
                <input type="number" class="form-control lh-2" id="nome" placeholder="Insira o novo valor" [(ngModel)]="novoFaturamentoEstimado" />
              </div>

              @if (isDesktop) {
                <div class="cell d-flex gap-2 flex-column">
                  <div class="buttons d-flex gap-2 align-items-center justify-content-center">
                    <button class="btn" color="azul" (click)="save()">
                      <icon name="check-white" />
                    </button>
                    <button class="btn btn-outline" color="azul" (click)="showForm = false">
                      <icon name="x-azul" />
                    </button>
                  </div>
                </div>
              }
            } @else {
              <div class="cell d-flex gap-2 flex-column" [class.flex-grow-1]="showForm">
                <div class="buttons d-flex gap-2 align-items-center justify-content-center">
                  <button class="btn btn-outline" color="azul" (click)="showForm = true">
                    <icon name="edit-azul" />
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>

    <div id="footer" class="mt-5">
      <div class="d-flex align-items-center justify-content-end gap-3" [ngClass]="{ 'mt-6 me-3 mb-3 position-absolute bottom-0 end-0': !isDesktop }">
        <button class="btn btn-outline" color="azul" (click)="dismiss()">
          <span>Fechar</span>
        </button>

        @if (!isDesktop) {
          <button class="btn" color="azul" (click)="save()">
            <span>Salvar</span>
          </button>
        }
      </div>
    </div>
  </div>
</div>
