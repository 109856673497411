import { Component, Input } from '@angular/core';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';

@Component({
  selector: 'spinner',
  standalone: true,
  imports: [ColorDirective],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
  @Input() color: AppColor = 'primary';
}
