import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';
import { LogoHeaderComponent } from '../../custom-components/logo-header/logo-header.component';
import { ApiResponsePost } from '../../model/api.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SmallLabelComponent } from '../../custom-components/small-label/small-label.component';
import { ColorDirective } from '../../directives/color.directive';
import { minLengthPassword } from '../../globals/globals';
import { emailIsValid } from '../../globals/utils';

@Component({
  selector: 'app-esqueci-senha',
  standalone: true,
  imports: [ReactiveFormsModule, LogoHeaderComponent, SmallLabelComponent, ColorDirective],
  templateUrl: './esqueci-senha.component.html',
  styleUrl: './esqueci-senha.component.scss'
})
export class EsqueciSenhaComponent {

  formRequestCode: FormGroup<{
    email: FormControl<string>,
  }> = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  formChangePassword: FormGroup<{
    email: FormControl<string>,
    code: FormControl<string>,
    password: FormControl<string>,
    confirmPassword: FormControl<string>,
  }> = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    code: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(minLengthPassword)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(minLengthPassword)]),
  });

  jaTemCodido: boolean = false;

  minLengthPassword = minLengthPassword;

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
  ) {}

  async requestCode() {
    if (this.formRequestCode.invalid) return this.toastService.show({ body: 'Verifique se o email foi preenchido corretamente', color: 'warning' });

    const loading = this.modalService.presentLoading('Verificando, aguarde...');
    this.formRequestCode.disable();

    try {
      const res = await this.authService.solicitaCodigo(this.formRequestCode.value.email);

      if (!res.success) {
        const message = res.validations?.map((v) => v?.message).join('\n') || 'Ocorreu um erro ao enviar o email de recuperação';
        this.modalService.presentAlert('Aviso', message, true);
        this.formRequestCode.enable();
        loading.close();
        return;
      }

      this.toastService.show({ body: 'Um email de recuperação foi enviado para o endereço informado', color: 'success' });
      this.jaTemCodido = true;
      this.formChangePassword.controls.email.setValue(this.formRequestCode.controls.email?.value);
      this.formRequestCode.reset();
    } catch (_error) {
      console.log(_error);
      const responseError = (_error as HttpErrorResponse);
      const apiError: ApiResponsePost<null> = responseError.error;
      const message = apiError.validations?.map((v) => v?.message).join('\n') || 'Ocorreu um erro ao enviar o email de recuperação';
      this.toastService.show({ body: message, color: 'danger' });
      this.formRequestCode.enable();
    } finally {
      loading.close();

    }
  }

  async changePassword() {
    const { email, code, password } = this.formChangePassword.value;

    if (password.length < minLengthPassword)
      return this.toastService.show({ body: `A senha deve ter no mínimo ${minLengthPassword} caracteres`, color: 'warning' });

    if (!emailIsValid(email))
      return this.toastService.show({ body: 'Email inválido', color: 'warning' });

    if (!code)
      return this.toastService.show({ body: 'Código de recuperação inválido', color: 'warning' });

    if (this.formChangePassword.controls.password.value !== this.formChangePassword.controls.confirmPassword.value)
      return this.toastService.show({ body: 'As senhas não coincidem', color: 'warning' });

    const loading = this.modalService.presentLoading('Alterando senha, aguarde...');
    this.formChangePassword.disable();

    try {
      const res = await this.authService.redefinirSenha(email, code, password);

      if (!res.success) {
        const message = res.validations?.map((v) => v?.message).join('\n') || 'Ocorreu um erro ao alterar a senha';
        this.modalService.presentAlert('Aviso', message, true);
        this.formChangePassword.enable();
        loading.close();
      }

      this.toastService.show({ body: 'Senha alterada com sucesso', color: 'success' });
      this.activeModal.dismiss();
    } catch (_error) {
      console.log(_error);
      const responseError = (_error as HttpErrorResponse);
      const apiError: ApiResponsePost<null> = responseError.error;
      const message = apiError.validations?.map((v) => v?.message).join('\n') || 'Ocorreu um erro ao alterar a senha';
      this.toastService.show({ body: message, color: 'danger' });
      this.formChangePassword.enable();
    } finally {
      loading.close();
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
