import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastService } from '../services/toast.service';
import { ToastComponent } from '../custom-components/toast/toast.component';
import { initializeSession, minLengthPassword } from '../globals/globals';
import { StorageService } from '../services/storage.service';
import { Session, UsuarioSession } from '../model/usuario.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { EsqueciSenhaComponent } from './esqueci-senha/esqueci-senha.component';
import { defaultClassModal } from '../globals/utils';
import { LogoHeaderComponent } from '../custom-components/logo-header/logo-header.component';
import { BaseClass } from '../globals/base-class';
import { CriarContaComponent } from './criar-conta/criar-conta.component';
import { ApiResponseError } from '../model/api.model';
import { NgMatIconComponent } from '../custom-components/ng-mat-icon/ng-mat-icon.component';
import { environment } from '../../environments/environment.development';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ToastComponent, LogoHeaderComponent, NgMatIconComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent extends BaseClass() {

  @ViewChild("inputEmail", { read: ElementRef }) inputEmail: ElementRef<HTMLInputElement>;
  @ViewChild("inputPassword", { read: ElementRef }) inputPassword: ElementRef<HTMLInputElement>;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    senha: new FormControl('', [Validators.required, Validators.minLength(minLengthPassword)]),
    manterConectado: new FormControl(false),
  });

  fazendoLogin: boolean = false;

  hidePassword: boolean = true;

  redirectTo: string;

  linkHotmart: string = environment.linkHotmart;

  constructor(
    private toastService: ToastService,
    private storageService: StorageService,
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.redirectTo = this.route.snapshot.queryParams?.['redirectTo'];
  }

  ngAfterViewInit() {
    this.inputEmail.nativeElement.onkeyup = (ev) => {
      if (ev.key === 'Enter') this.inputPassword.nativeElement.focus();
    }

    this.inputPassword.nativeElement.onkeyup = (ev) => {
      if (ev.key === 'Enter') this.login();
    }
  }

  login() {
    if (this.form.invalid) return this.toastService.show({ body: 'Verifique se os campos estão preenchidos corretamente', color: 'warning' });

    this.fazendoLogin = true;
    this.form.disable();

    const { email, senha } = this.form.value;
    this.authService.login(email, senha).then(async (res) => {
      if (!res.accessToken) return this.toastService.show({ body: 'Ocorreu um erro ao tentar fazer login', color: 'danger' });
      const { nameid, unique_name, email, TenantId, TenantName } = JSON.parse(new TextDecoder().decode(Uint8Array.from(atob(res.accessToken.split('.')[1]), m => m.charCodeAt(0))));

      const usuario: UsuarioSession = {
        id: nameid,
        nome: unique_name,
        email,
        nomeEmpresa: TenantName
      };

      const session: Session = {
        accessToken: res.accessToken,
        timestamp: Date.now(),
        keepConnected: this.form.value.manterConectado,
        empresa: TenantId,
        usuario,
      }
      this.storageService.setSession(session);
      initializeSession(session);

      this.redirectTo ? this.router.navigateByUrl(this.redirectTo) : this.router.navigate(['/home']);
    }).catch((err: ApiResponseError) => {
      console.error(err);
      this.toastService.show({ body: err?.error?.map(x => x.message)?.join('\n') || 'Ocorreu um erro ao tentar fazer login', color: 'danger' });
    }).finally(() => {
      this.fazendoLogin = false;
      this.form.enable();
    });
  }

  forgotPassword() {
    this.modalService.presentModal(EsqueciSenhaComponent, defaultClassModal(this), null, { windowClass: 'blur-backdrop', });
  }

  createAccount() {
    this.modalService.presentModal(CriarContaComponent, defaultClassModal(this), null, { backdrop: 'static', keyboard: false, windowClass: 'blur-backdrop', });
  }
}
