<div id="conainer">
  <!-- @if (!produtos) {
    <operation-in-progress />
  } @else { -->
  <div class="d-flex gap-2 mb-4 align-items-end" id="form" [class.flex-column]="!isDesktop">
    <div class="form-group d-flex flex-column gap-2 flex-grow-1" [class.w-100]="!isDesktop">
      <small-label> Adicionar um produto a categoria </small-label>
      <input
        id="nome"
        type="text"
        class="form-control lh-2"
        placeholder="Busque pelo nome do produto"
        [ngbTypeahead]="search"
        [(ngModel)]="produtoSelecionado"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        #instance="ngbTypeahead"
        [inputFormatter]="resultSearchFormatter"
        [resultFormatter]="resultSearchFormatter"
        [readOnly]="searchingProdutos"
        [editable]="false"
      />

      @if (searchingProdutos) {
        <spinner color="roxo" slot="end" placement="end" ngbTooltip="Procurando..." [openDelay]="DelayShowTooltip" />
      } @else {
        @if (errorSearchProdutos) {
          <ng-mat-icon class="cursor-pointer" slot="end" name="error" color="danger" placement="top" ngbTooltip="Erro ao buscar produtos" [openDelay]="DelayShowTooltip" [tooltipClass]="'color-danger'" />
        } @else {
          <ng-mat-icon class="cursor-pointer" slot="end" name="search" color="roxo" placement="top" ngbTooltip="Digite ao menos 3 letras para pesquisar" [openDelay]="DelayShowTooltip" />
        }
      }
    </div>

    <div class="form-group d-flex flex-column gap-2" [class.w-100]="!isDesktop">
      <div class="btn-group" role="group">
        <button [disabled]="operationInProgress$.value || !produtoSelecionado" type="button" class="btn" (click)="adicionarProdutoCategoria()" color="roxo">Adicionar à categoria</button>
      </div>
    </div>
  </div>

  <produto-list
    #produtoListRef
    [periodo]="currentPeriodo$ | async"
    [IdsCategorias]="[categoria.id]"
    [displayedColumns]="displayedColumns"
    [columnsActions]="['edit']"
    [showActions]="false"
    (operationInProgress$)="operationInProgress$.next($event)"
  />
</div>
