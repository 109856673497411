<div class="w-100 h-100 d-flex align-items-center justify-content-center">
  <div class="m-auto">
    <h1 class="display-4 text-danger fw-bolder">404</h1>
    <p class="lead">A página que você está procurando não foi encontrada.</p>
    <hr class="my-4" />
    <p>Por favor, verifique o endereço digitado e tente novamente.</p>
    <p class="lead">
      <a class="btn btn-outline-dark" href="#" role="button" href="/">Voltar para a página inicial</a>
    </p>
  </div>
</div>
