import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { AsyncPipe, NgClass, NgComponentOutlet } from '@angular/common';
import { BaseClass } from '../../globals/base-class';
import { AppColor } from '../../model/custom-types';
import { ColorDirective } from '../../directives/color.directive';

@Component({
  selector: 'stepper',
  standalone: true,
  imports: [IconComponent, NgComponentOutlet, NgClass, AsyncPipe, ColorDirective],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss'
})
export class StepperComponent<T> extends BaseClass() {
  @ViewChild("stepperEl", { read: ElementRef }) stepperEl: ElementRef<HTMLDivElement>;

  @ViewChild("buttondel", { read: ElementRef }) buttondel: ElementRef<HTMLButtonElement>;
  @ViewChild("buttonprev", { read: ElementRef }) buttonprev: ElementRef<HTMLButtonElement>;
  @ViewChild("buttonnext", { read: ElementRef }) buttonnext: ElementRef<HTMLButtonElement>;

  /**
   * ### currentStep é o número do step atual (1, 2, 3, ...) NÃO é o index.
   * */
  @Input() currentStep: number = 1;
  /**
   * ### steps é um array de objetos que representam os steps do stepper. Não é necessário forncecer o id do step.
   */
  @Input({ required: true }) steps: Array<Step & { id?: number }> = [];
  @Input({ required: true }) drawColor: AppColor = 'verde';

  @Input() lock: boolean = false;
  @Input() cancelButtonText: string = 'Cancelar';
  @Input() previousButtonText: string = 'Voltar';
  @Input() nextButtonText: string = 'Próximo Passo';
  @Input() finishButtonText: string = 'Finalizar';
  @Input() showDeleteButton: boolean = false;

  @Output() onStepChangeBlocked: EventEmitter<StepChangedEvent> = new EventEmitter();
  @Output() onStepChange: EventEmitter<StepChangedEvent> = new EventEmitter();

  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Output() finished: EventEmitter<void> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  @Output() childEvent: EventEmitter<{ data: T, previousIndex: number, periodoACopiar?: T, file?: File }> = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  protected ngOnInit() {
    this.steps.map((x, i) => x.id = i + 1);
    this.changeDetectorRef.detectChanges();
    const sub = this.onStepChange.pipe().subscribe({
      next: () => this.scrollStepper()
    });
    this.appendSubscription(sub);

    if (!this.currentStep) return this.activateStep(0);

    this.activateStep(this.currentStep - 1);
  }

  private scrollStepper() {
    const stepper = this.stepperEl.nativeElement;
    const ul = stepper.firstElementChild;

    const ulWidthPerChild = ul.clientWidth / ul.children.length;
    const stepperWidthFactor = this.isDesktop ? (stepper.clientWidth / (2 * ulWidthPerChild)) : 1;
    const navItemWidthFactor = this.isDesktop ? ulWidthPerChild / 2 : 0;

    const x = ulWidthPerChild * (this.currentStep - stepperWidthFactor) - navItemWidthFactor;

    stepper.scrollTo(x, 0);
  }

  activateStep(index: number) {
    if (this.lock) return this.onStepChangeBlocked.emit({ currentIndex: this.currentStep, newIndex: index + 1 });
    const step = this.steps[index];
    this.steps.map(x => {
      if (x.id === step.id) {
        x.status = 'active';
        return;
      }
      x.status = x.id < step.id ? 'completed' : 'pending';
    });

    this.onStepChange.emit({ currentIndex: this.currentStep, newIndex: index + 1 });
    this.currentStep = index + 1;
  }

  previous() {
    const stepIndex = this.steps.findIndex(x => x.id === this.currentStep - 1);
    if (stepIndex > -1) this.activateStep(stepIndex);
    else this.cancel.emit();
  }

  next() {
    const stepIndex = this.steps.findIndex(x => x.id === this.currentStep + 1);
    if (stepIndex > -1) this.activateStep(stepIndex);
    else this.finished.emit();
  }
}

export type Step = {
  title: string;
  component: Type<any>;
  data: any;
  status: StepStatus;
}

export type StepStatus = 'pending' | 'active' | 'completed';

export type StepChangedEvent = {
  currentIndex: number;
  newIndex: number;
}
