<div id="container" class="pb-5">
  <div id="content" class="m-4">
    <div id="header">
      <div id="title" class="d-flex justify-content-between align-items-center">
        <h3 color="titulo" class="fw-600">{{ produto?.nome }}</h3>
        <button class="btn" (click)="dismiss()">
          <icon name="x" />
        </button>
      </div>
    </div>

    <div id="content" class="mt-4">
      <div class="d-flex gap-3" [ngClass]="{ 'align-items-end justify-content-center': isDesktop, 'flex-column': !isDesktop }">
        <div class="d-flex flex-column gap-2">
          <small-label> Quantidade </small-label>
          <div class="input-group">
            <input type="number" class="form-control" placeholder="999" [(ngModel)]="quantidade" />
          </div>
        </div>

        <div class="input-with-tag d-flex flex-column gap-2">
          <small-label> Preço de custo </small-label>
          <div class="input-group">
            <label class="input-group-text fw-bold" color="neutro">R$</label>
            <input type="number" class="form-control text-center" placeholder="999.99" [(ngModel)]="precoCusto" />
          </div>
        </div>

        <div class="input-with-tag d-flex flex-column gap-2">
          <small-label> Preço de venda </small-label>
          <div class="input-group">
            <label class="input-group-text fw-bold" [color]="produto?.precoVenda < produto?.precoIdeal ? 'danger' : 'neutro'">R$</label>
            <input type="number" class="form-control text-center" placeholder="999.99" [(ngModel)]="precoVenda" />
          </div>
        </div>

        <div class="d-flex flex-column gap-2">
          <small-label> Preço ideal calculado </small-label>
          <label class="fw-bold"> {{ (produto?.precoCalculadoSmartis | currency) || "R$ -" }} </label>
        </div>
      </div>

      <div id="buttons" class="d-flex justify-content-end mt-4 gap-4">
        <button type="button" class="px-4 btn btn-outline-primary" (click)="dismiss()">Cancelar</button>

        <button type="button" class="px-4 btn btn-primary" (click)="salvar()">Salvar</button>
      </div>

      <hr class="divider my-4" />

      <div class="d-flex flex-column gap-4">
        <div id="resultados" class="d-flex flex-column gap-3">
          <small-label> Resultados </small-label>

          <card-with-icon [showFooter]="false" [showForwardIcon]="false" class="w-fit-content" [borderColor]="(produto | precificacaoProdutoFace)?.color">
            <div class="d-flex gap-4">
              <div class="d-flex flex-column gap-2">
                <small-label class="fw-500"> Unitário </small-label>
                <div class="fw-bold" [color]="(produto | precificacaoProdutoFace)?.color">{{ (produto?.lucroAtual | currency) || "-" }}</div>
              </div>

              <hr class="divider vertical" />

              <div class="d-flex flex-column gap-2">
                <small-label class="fw-500"> Total </small-label>
                <div class="fw-bold" [color]="(produto | precificacaoProdutoFace)?.color">{{ (produto?.lucroTotalAtual | currency) || "-" }}</div>
              </div>

              <hr class="divider vertical" />

              <div class="d-flex gap-2 align-items-center">
                <icon-in-ellipse [matIcon]="(produto | precificacaoProdutoFace)?.icon" [color]="(produto | precificacaoProdutoFace)?.color" />

                <div class="d-flex flex-column gap-2">
                  <small-label class="fw-500"> Lucro Final </small-label>
                  <div class="fw-bold" [color]="(produto | precificacaoProdutoFace)?.color">{{ produto?.percentualLucroAtual | percent: "1.0-2" }}</div>
                </div>
              </div>
            </div>
          </card-with-icon>
        </div>

        <div id="valores" class="d-flex gap-3" [ngClass]="{ 'flex-column': !isDesktop }">
          @for (item of ["uni", "percent", "total"]; track $index) {
            <div class="section flex-grow-1 d-flex flex-column gap-3">
              @switch (item) {
                @case ("uni") {
                  <small-label class="fw-500"> Valor unitário </small-label>
                }
                @case ("percent") {
                  <small-label class="fw-500"> Composição percentual </small-label>
                }
                @case ("total") {
                  <small-label class="fw-500"> Valor total </small-label>
                }
              }

              <div class="d-flex rounded-3 flex-column gap-2 p-3">
                @switch (item) {
                  @case ("uni") {
                    <small-label class="fw-bold"> Preço ideal calculado </small-label>
                  }
                  @case ("percent") {
                    <small-label class="fw-bold"> Percentual </small-label>
                  }
                  @case ("total") {
                    <small-label class="fw-bold"> Faturamento ideal </small-label>
                  }
                }

                @switch (item) {
                  @case ("uni") {
                    <div class="fw-bold mt-2 lh-2">{{ (produto?.precoCalculadoSmartis | currency) || "N/D" }}</div>
                  }
                  @case ("percent") {
                    <div class="fw-bold mt-2 lh-2">{{ 1 | percent }}</div>
                  }
                  @case ("total") {
                    <div class="fw-bold mt-2 lh-2">{{ (produto?.faturamentoPossivel | currency) || "N/D" }}</div>
                  }
                }

                <hr class="divider mt-2" />

                <div class="resumo mt-2">
                  <div class="d-grid column-gap-3">
                    @switch (item) {
                      @case ("uni") {
                        <span class="fw-600"> {{ (produto?.precoCusto | currency) || "N/D" }} </span>
                      }
                      @case ("percent") {
                        <span class="fw-600"> {{ (produto?.percentualPrecoCustoPrecoIdeal / 100 | percent) || "N/D" }} </span>
                      }
                      @case ("total") {
                        <span class="fw-600"> {{ (produto?.custoTotal | currency) || "N/D" }} </span>
                      }
                    }
                    <span class="fw-200"> Custo </span>

                    @switch (item) {
                      @case ("uni") {
                        <span class="fw-600"> {{ (produto?.despesasFixas | currency) || "N/D" }} </span>
                      }
                      @case ("percent") {
                        <span class="fw-600"> {{ produto?.percentualDespesasFixasPrecoIdeal / 100 | percent }} </span>
                      }
                      @case ("total") {
                        <span class="fw-600"> {{ (produto?.totalDespesasFixas | currency) || "N/D" }} </span>
                      }
                    }
                    <span class="fw-200"> Despesa fixa </span>

                    @switch (item) {
                      @case ("uni") {
                        <span class="fw-600"> {{ (produto?.despesasVendaIdeal || 0 | currency) || "N/D" }} </span>
                      }
                      @case ("percent") {
                        <span class="fw-600"> {{ produto?.percentualDespesasVendaPrecoIdeal / 100 | percent }} </span>
                      }
                      @case ("total") {
                        <span class="fw-600"> {{ (produto?.totalDespesasVendaIdeal | currency) || "N/D" }} </span>
                      }
                    }
                    <span class="fw-200"> Despesa de venda </span>

                    @switch (item) {
                      @case ("uni") {
                        <span class="fw-600"> {{ (produto?.lucroEstimado | currency) || "N/D" }} </span>
                      }
                      @case ("percent") {
                        <span class="fw-600"> {{ (produto?.percentualLucroEstimado / 100 | percent) || "N/D" }} </span>
                      }
                      @case ("total") {
                        <span class="fw-600"> {{ (produto?.totalLucroEstimado | currency) || "N/D" }} </span>
                      }
                    }
                    <span class="fw-200"> Lucro </span>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>

        <div id="participacao" class="d-flex flex-column gap-3">
          <small-label> Participação </small-label>

          <div class="progress-bars d-flex flex-column gap-3">
            @for (progressBarConfig of progressBars | keyvalue; track $index) {
              <div class="d-flex flex-column gap-3">
                <small-label class="fw-bold"> {{ progressBarConfig.value.label }}: {{ progressBarConfig.value.value | currency}} ({{ progressBarConfig.value.value / progressBarConfig.value.total | percent: "1.0-2" }}) </small-label>
                <div class="w-100 d-grid align-items-center justify-content-center gap-3 progresses">
                  <small-label class=""> {{ 0 | currency }} </small-label>
                  <progress-bar class="flex-grow-1" [config]="progressBarConfig.value" />
                  <small-label class=""> {{ progressBarConfig.value.total | currency }} </small-label>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
