import { Directive, ElementRef, Input } from '@angular/core';
import { AppColor, RGBColor } from '../model/custom-types';

@Directive({
  selector: '[color]',
  standalone: true
})
export class ColorDirective {

  @Input() color: AppColor | RGBColor = 'dark';

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnChanges(): void {
    if (!this.color) this.color = 'dark';

    switch (this.el.nativeElement.tagName) {
      case 'NGB-TOAST':
        this.el.nativeElement.style.setProperty('--bg-color', `var(--bs-${this.color}-rgb, var(--color-${this.color}-rgb))`);
        this.el.nativeElement.style.setProperty('--color', `var(--bs-${this.color}-bg-subtle, var(--color-${this.color}))`);
        break;

      case 'NOTE':
        this.el.nativeElement.style.setProperty('--alert-text', `var(--bs-${this.color}-contrast, var(--color-${this.color}-contrast))`);
        this.el.nativeElement.style.setProperty('--alert-bg', `var(--bs-${this.color}, var(--color-${this.color}))`);
        break;

      case 'CUSTOM-ICON':
        this.el.nativeElement.style.setProperty('background', `var(--bs-${this.color}, var(--color-${this.color}))`);
        if (this.el.nativeElement.classList.contains('input-group-text'))
          this.el.nativeElement.style.setProperty('--bs-border-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
        break;

      case 'CARD-WITH-ICON':
        const card = this.el.nativeElement?.firstElementChild?.firstElementChild as HTMLElement;
        if (card?.id === 'card') card.style.setProperty('background', `var(--bs-${this.color}, var(--color-${this.color}))`);
        break;

      case 'NGB-PROGRESSBAR':
        if (this.color.includes(',')) {
          this.el.nativeElement.style.setProperty('--bs-progress-bar-bg', `rgb(${this.color})`);
          break;
        }
        this.el.nativeElement.style.setProperty('--bs-progress-bar-bg', `var(--bs-${this.color}, var(--color-${this.color}))`);
        break;

      case 'INPUT':
        if (this.el.nativeElement.classList.contains('form-check-input') && this.el.nativeElement.getAttribute('role') === 'switch') {
          this.el.nativeElement.style.setProperty('--color-track-checked', `var(--bs-${this.color}, var(--color-${this.color}))`);
          this.el.nativeElement.style.setProperty('--color-handle-checked', `var(--bs-${this.color}-shade, var(--color-${this.color}-shade))`);
        }
        break;

      case 'BUTTON':
        if (this.el.nativeElement.classList.contains('btn')) {
          this.el.nativeElement.classList.add('has-custom-color');

          if (this.color.includes(',')) {
            this.el.nativeElement.style.setProperty('--bs-btn-color', `rgb(${this.color})`);
            this.el.nativeElement.style.setProperty('--bs-btn-color-contrast', `rgb(255, 255, 255)`);
            // this.el.nativeElement.style.setProperty('--bs-btn-color-contrast', `rgb(${this.color})`);
            this.el.nativeElement.style.setProperty('--bs-btn-color-shade', `rgb(${this.color})`);
            this.el.nativeElement.style.setProperty('--bs-btn-bg', `rgb(${this.color})`);
            this.el.nativeElement.style.setProperty('--bs-btn-active-bg', `rgb(${this.color})`);
            this.el.nativeElement.style.setProperty('--bs-btn-active-border-color', `rgb(${this.color})`);
            this.el.nativeElement.style.setProperty('--bs-btn-hover-bg', `rgb(${this.color})`);
            this.el.nativeElement.style.setProperty('--bs-btn-disabled-bg', `rgb(${this.color})`);
            this.el.nativeElement.style.setProperty('--bs-btn-disabled-border-color', `rgb(${this.color})`);
            break;
          }

          this.el.nativeElement.style.setProperty('--bs-btn-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
          this.el.nativeElement.style.setProperty('--bs-btn-color-contrast', `var(--bs-${this.color}-contrast, var(--color-${this.color}-contrast))`);
          this.el.nativeElement.style.setProperty('--bs-btn-color-shade', `var(--bs-${this.color}, var(--color-${this.color}))`);
          this.el.nativeElement.style.setProperty('--bs-btn-bg', `var(--bs-${this.color}, var(--color-${this.color}))`);
          this.el.nativeElement.style.setProperty('--bs-btn-active-bg', `var(--bs-${this.color}, var(--color-${this.color}))`);
          this.el.nativeElement.style.setProperty('--bs-btn-active-border-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
          this.el.nativeElement.style.setProperty('--bs-btn-hover-bg', `var(--bs-${this.color}, var(--color-${this.color}))`);
          this.el.nativeElement.style.setProperty('--bs-btn-disabled-bg', `var(--bs-${this.color}, var(--color-${this.color}))`);
          this.el.nativeElement.style.setProperty('--bs-btn-disabled-border-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
        }
        break;

      case 'NGB-PAGINATION':
        const ul = this.el.nativeElement.children.item(0) as HTMLUListElement;
        ul.style.setProperty('--bs-pagination-active-bg', `var(--bs-${this.color}, var(--color-${this.color}))`);
        // ul.style.setProperty('--bs-pagination-active-border-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
        break;

      case 'SEGMENTS':
        if (this.el.nativeElement.children[0].classList.contains('nav')) {
          (this.el.nativeElement.children[0] as HTMLUListElement).style.setProperty('--nav-active-border-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
          (this.el.nativeElement.children[0] as HTMLUListElement).style.setProperty('--nav-active-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
        }
        break;

      case 'ICON-IN-ELLIPSE': case 'PAGINATOR':
        break;

      case 'MAT-SLIDER':
        this.el.nativeElement.style.setProperty('--mdc-slider-active-track-color', `rgb(from var(--bs-${this.color}, var(--color-${this.color})) r g b / .5)`);
        this.el.nativeElement.style.setProperty('--mdc-slider-handle-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
        this.el.nativeElement.style.setProperty('--mdc-slider-focus-handle-color', `var(--bs-${this.color}, var(--color-${this.color}))`);
        break;

      default:
        if (this.el.nativeElement.id === 'icon-in-ellipse')
          this.el.nativeElement.style.setProperty('--background', `var(--bs-${this.color}, var(--color-${this.color}))`);

        else if (this.el.nativeElement.classList.contains('input-group-text'))
          this.el.nativeElement.style.setProperty('background', `var(--bs-${this.color}, var(--color-${this.color}))`);

        else if (this.el.nativeElement.classList.contains('badge'))
          this.el.nativeElement.style.setProperty('background', `var(--bs-${this.color}, var(--color-${this.color}))`);

        else
          this.el.nativeElement.style.setProperty('color', `var(--bs-${this.color}, var(--color-${this.color}))`);

        break;
    }
  }

}
