<div id="container" class="d-flex flex-column" [style.--draw-color-rgb]="'var(' + ((eSimulacao$ | async) ? '--color-laranja-forte-rgb' : '--color-verde-rgb') + ')'">
  <section id="periodo-novo" class="d-flex flex-column">
    <h1 class="title fw-500 m-0">Configurações de {{ (eSimulacao$ | async) ? "simulações" : "períodos" }}</h1>

    <div class="content card-local">
      <h6 class="title-section fw-500">Criar uma nova base de {{ (eSimulacao$ | async) ? "simulações" : "períodos" }}</h6>

      <div class="options w-100 has-items gap-4">
        @for (option of optionsNovaBase; track $index) {
          <div class="card-local static-content option d-flex flex-column align-items-start cursor-pointer" [class.selected]="selectedOption === option.value" (click)="selectOption(option)">
            <div class="option-title fw-500 w-100 d-flex justify-content-between align-items-center gap-3">
              <span> {{ option.title }} </span>
              <div class="icons d-flex align-items-center gap-2">
                @if (selectedOption === option.value) {
                  <icon [name]="(eSimulacao$ | async) ? 'check-square-checked-laranja-forte' : 'check-square-checked-green'" />
                } @else {
                  <icon name="check-square" />
                }
              </div>
            </div>
            <div class="option-body">{{ option.text }}</div>
          </div>
        }
      </div>

      <div id="actions">
        <button class="btn float-end mt-2" [routerLink]="['/home', (eSimulacao$ | async) ? 'simulador' : 'periodos', 'novo']" [queryParams]="{ tipoBase: selectedOption, simulador: (eSimulacao$ | async) }">Próximo Passo</button>
      </div>
    </div>
  </section>

  <section id="periodo-list" class="d-flex flex-column">
    <h1 class="title fw-500 m-0">{{ (eSimulacao$ | async) ? "Simulações" : "Períodos" }} existentes</h1>

    <div class="content card-local">
      <h6 class="title-section fw-500">Bases existentes de {{ (eSimulacao$ | async) ? "simulações" : "períodos" }}</h6>
      <periodos-list />
    </div>
  </section>
</div>
