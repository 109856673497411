<div id="container" [ngClass]="{ 'p-5': isDesktop, 'p-3': !isDesktop }">
  <div id="content" class="rounded-3 p-4">
    <div id="header" class="mb-4 mt-1">
      <button id="btn-nova" type="button" class="btn btn-primary gap-3" (click)="addEditEmpresa()">
        <ng-mat-icon name="add_business" color="white" />
        <span> Nova Empresa </span>
      </button>

      <div id="searchbox" class="input-group input-icon-end position-relative input-icon-end">
        <input
          type="text"
          class="form-control lh-2 pe-5"
          id="search-input"
          placeholder="Busque pelo nome da empresa"
          [debounce]="defaultDebounceInput"
          [operationObs$]="operationInProgress$"
          [enterOnly]="false"
          (onChange)="inputOnSearch()"
          [disabled]="!empresas || (operationInProgress$ | async)"
          [(ngModel)]="searchTerm"
        />
        <ng-mat-icon placement="top" class="cursor-pointer" slot="end" name="search" color="primary" />
      </div>
    </div>

    @if (empresas) {
      <table-empresa-list
        [columns]="[displayedColumns, columnsActions].flat()"
        [empresas]="empresas"
        [paginatorOpts]="paginatorOpts"
        [operationInProgress$]="operationInProgress$"
        (ativoToggleChange)="ativoToggleChange($event.empresa, $event.input)"
        (addEditEmpresa)="addEditEmpresa($event)"
        (updatePageSize)="updatePageSize($event)"
        (navigatePage)="navigatePage($event)"
        (checkedChanged)="empresaChecked($event)"
      />
    } @else {
      <operation-in-progress />
    }
  </div>
</div>
